import {
  Row,
  Col,
  Table,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Form,
  FormGroup,
  FormControl,
  Checkbox,
} from 'react-bootstrap';
import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';

import { LinkContainer } from 'react-router-bootstrap';

import debounce from 'lodash/debounce';
import last from 'lodash/last';
import upperFirst from 'lodash/upperFirst';

import { currentSettingsSet } from '../actions/current_setting_actions';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import { filterDo } from '../actions/filter_actions';

import Confirm from '../components/confirm';
import Loader from '../components/loader';

import { queriesReady } from '../lib/utils';

import checkDeleteMutation from '../mutations/check_delete_mutation';

import checkListQuery from '../queries/check_list_query';
import checkCategoryListQuery from '../queries/check_category_list_query';

moment.updateLocale('en-nz');

class CheckList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterCheckCategoryId: this.props.currentSettingsFilterCheckCategoryId,
      excludeResponsible: false,
    };
    this.handleFilter = this.handleFilter.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleFilterCheckCategoryIdChange =
      this.handleFilterCheckCategoryIdChange.bind(this);
    this.handleExcludeResponsibleChecked =
      this.handleExcludeResponsibleChecked.bind(this);
  }

  componentDidMount() {
    this.delayedHandleFilter = debounce((e) => {
      if (e.target.value.length > 1 || e.target.value === '') {
        this.props.filterDo({
          type: 'check',
          q: e.target.value,
          ...(this.state.excludeResponsible && { exclude_responsible: true }),
        });
      }
    }, 400);
    if (this.props.filterQ) {
      this.delayedHandleFilter({ target: { value: this.props.filterQ } });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.filterQ && this.isLoading(this.props) && this.isLoaded(nextProps)) {
      this.delayedHandleFilter({ target: { value: nextProps.filterQ } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleFilter.cancel();
  }

  displayBoolean = (attribute) => {
    if (attribute) {
      return 'Yes';
    } else {
      return 'No';
    }
  };

  humanizeText = (string) => upperFirst(string.split('_').join(' '));

  rowClassName = (checkStatus) => {
    switch (checkStatus) {
      case 'completed':
        return 'success';
      case 'overdue':
        return 'danger';
      case 'due_now':
        return 'warning';
      default:
        return '';
    }
  };

  getCheckStatus = (model) => {
    const {
      check_start_on: checkStartOn,
      check_end_on: checkEndOn,
      check_complete_on: checkCompleteOn,
    } = model;
    if (checkCompleteOn) {
      return 'completed';
    } else if (moment(checkEndOn).isBefore(moment())) {
      return 'overdue';
    } else if (moment(checkStartOn).isBefore(moment())) {
      return 'due_now';
    } else {
      return 'not_due';
    }
  };

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(testProps.checkListQuery, testProps.checkCategoryListQuery);
  }

  handleExcludeResponsibleChecked() {
    this.setState(
      (prevState) => ({ excludeResponsible: !prevState.excludeResponsible }),
      () => {
        this.delayedHandleFilter({ target: { value: this.props.filterQ } });
      }
    );
  }

  handleDeleteClick(e) {
    this.props.mutationSet(true);
    const checkId = last(e.currentTarget.id.split('-'));
    this.props
      .checkDeleteMutation({
        variables: {
          id: checkId,
        },
      })
      .then(() => {
        this.props.mutationSuccess('Check delete');
      })
      .catch((res) => {
        this.props.mutationFailure(res);
      });
  }

  handleFilter(e) {
    e.persist();
    this.delayedHandleFilter(e);
  }

  handleFilterCheckCategoryIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value, 10))
      ? ''
      : parseInt(e.target.value, 10);
    this.setState({
      filterCheckCategoryId: value,
    });
    this.props.currentSettingsSet({
      filterCheckCategoryId: value,
    });
  }

  renderCheckCategoryFilter() {
    return (
      <FormControl
        componentClass="select"
        value={this.state.filterCheckCategoryId}
        onChange={this.handleFilterCheckCategoryIdChange}
      >
        <option key={0} value="">
          All
        </option>
        {this.props.checkCategoryListQuery.data.map((model) => {
          const { id, name } = model;
          return (
            <option key={id} value={id}>
              {name}
            </option>
          );
        })}
      </FormControl>
    );
  }

  renderOverlay() {
    if (this.props.filtering || this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (this.isLoaded()) {
      const { filterQ: filtered, currentContact } = this.props;
      return (
        <div>
          <Row>
            <Col sm={12}>
              <h3>Checks</h3>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="clearfix">
                <div className="pull-left">
                  <Form inline>
                    <FormGroup
                      style={{ marginLeft: '16px', paddingBottom: '10px' }}
                      bsSize="sm"
                      controlId="filterId"
                    >
                      {this.renderCheckCategoryFilter()}
                    </FormGroup>
                  </Form>
                </div>
                {currentContact['manager?'] && (
                  <div className="pull-right">
                    <Checkbox
                      style={{ marginRight: '1em' }}
                      inline
                      checked={this.state.excludeResponsible}
                      onChange={this.handleExcludeResponsibleChecked}
                    >
                      Exclude responsible
                    </Checkbox>
                    <FormControl
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        width: '200px',
                        marginRight: '10px',
                      }}
                      type="text"
                      placeholder="filter..."
                      defaultValue={this.props.filterQ}
                      onChange={this.handleFilter}
                    />

                    <div className="pull-right">
                      <ButtonToolbar>
                        <ButtonGroup>
                          <LinkContainer to="/checks/new">
                            <Button bsStyle="primary">Add a Check</Button>
                          </LinkContainer>
                        </ButtonGroup>
                      </ButtonToolbar>
                    </div>
                    <span
                      className="badge"
                      style={
                        filtered
                          ? { marginRight: '10px' }
                          : { marginRight: '10px', opacity: '0.5' }
                      }
                    >
                      {filtered
                        ? this.props.filterIds.length
                        : this.props.checkListQuery.data.length}
                    </span>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Table hover className="vertical-align">
            <thead>
              <tr>
                <th>Id</th>
                <th>Category</th>
                <th>Type</th>
                <th>Checkable</th>
                <th>Responsible</th>
                <th>Crit</th>
                <th>Start On</th>
                <th>End On</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.props.checkListQuery.data
                .filter((model) => {
                  if (
                    this.state.filterCheckCategoryId &&
                    model.check_category_id !== this.state.filterCheckCategoryId
                  ) {
                    return false;
                  }
                  if (
                    !currentContact['manager?'] &&
                    currentContact.id !== model.checkable.id
                  ) {
                    return false;
                  }
                  if (filtered && !this.props.filterIds.includes(model.id)) {
                    return false;
                  }
                  return true;
                })
                .map((model) => {
                  const {
                    id,
                    checkType: { name: checkTypeName, critical: checkTypeCritical },
                    checkCategory: { name: checkCategoryName },
                    manager: { fullName: managerFullName },
                    checkable: { fullName: checkableFullName },
                    check_start_on: checkStartOn,
                    check_end_on: checkEndOn,
                  } = model;

                  const checkStatus = this.getCheckStatus(model);

                  return (
                    <tr key={id} className={this.rowClassName(checkStatus)}>
                      <td>{id}</td>
                      <td>{checkCategoryName}</td>
                      <td>{checkTypeName}</td>
                      <td>{checkableFullName}</td>
                      <td>{managerFullName}</td>
                      <td>{this.displayBoolean(checkTypeCritical)}</td>
                      <td>{moment(checkStartOn).format('DD/MM/YYYY')}</td>
                      <td>{moment(checkEndOn).format('DD/MM/YYYY')}</td>
                      <td>{this.humanizeText(checkStatus)}</td>
                      <td>
                        {currentContact['manager?'] && checkStatus !== 'completed' && (
                          <Confirm
                            confirmId={`check-delete-${id}`}
                            disabled={checkStatus === 'Completed'}
                            onConfirm={this.handleDeleteClick}
                            title="Delete Check"
                            body="Are you sure you want to delete this check"
                            confirmText="Confirm"
                          >
                            <Button
                              bsStyle="link"
                              bsSize="xsmall"
                              disabled={checkStatus === 'Completed'}
                            >
                              delete
                            </Button>
                          </Confirm>
                        )}
                        {currentContact['manager?'] && checkStatus !== 'completed' && (
                          <LinkContainer
                            to={`/checks/${id}/edit`}
                            disabled={checkStatus === 'Completed'}
                          >
                            <Button
                              bsStyle="link"
                              bsSize="xsmall"
                              disabled={checkStatus === 'Completed'}
                            >
                              edit
                            </Button>
                          </LinkContainer>
                        )}
                        {checkStatus !== 'completed' && (
                          <LinkContainer to={`/checks/${id}`}>
                            <Button bsStyle="link" bsSize="xsmall">
                              show
                            </Button>
                          </LinkContainer>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      );
    }
    return undefined;
  }

  render() {
    return (
      <div>
        {this.renderOverlay()}
        {this.renderData()}
      </div>
    );
  }
}

CheckList.propTypes = {
  filterIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  filtering: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const filter = state.filters.check;
  return {
    filterIds: filter.ids,
    filterQ: filter.q,
    filtering: filter.filtering,
    currentContact: state.currentContact,
    currentSettingsMutating: state.currentSettings.mutating,
    currentSettingsFilterCheckCategoryId: state.currentSettings.filterCheckCategoryId,
  };
}

export default compose(
  graphql(checkDeleteMutation, {
    name: 'checkDeleteMutation',
  }),
  graphql(checkCategoryListQuery, {
    name: 'checkCategoryListQuery',
  }),
  graphql(checkListQuery, {
    name: 'checkListQuery',
  }),
  connect(mapStateToProps, {
    filterDo,
    mutationSuccess,
    mutationFailure,
    mutationSet,
    currentSettingsSet,
  })
)(CheckList);
