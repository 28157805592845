import {
  Row,
  Col,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
} from 'react-bootstrap';
import { Component } from 'react';
import moment from 'moment';
import DateTime from 'react-datetime';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import _compact from 'lodash/compact';
import debounce from 'lodash/debounce';
import defaultTo from 'lodash/defaultTo';
import first from 'lodash/first';
import get from 'lodash/get';
import has from 'lodash/has';

import isEqual from 'lodash/isEqual';

import { validationState, validationText } from '../form/helpers';

moment.updateLocale('en-nz');

class FlightSegmentStartDateLocationAndTimeInputFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: '',
      time: '',
      dateError: {},
      timeError: {},
      locationOptions: [],
      locationLongName: '',
      persistedLocationLongName: '',
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);

    this.handleLocationSearch = this.handleLocationSearch.bind(this);
    this.handleLocationIdChange = this.handleLocationIdChange.bind(this);
    this.handleLocationIdInputChange = this.handleLocationIdInputChange.bind(this);
    this.handleLocationIdBlur = this.handleLocationIdBlur.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.start_at) {
      this.receiveStartAt();
      if (this.props.prevEndAt) {
        this.normalizeStartAt();
      }
    }
    if (this.props.start_location_id) {
      this.receiveStartLocationId();
    }
    this.delayedHandleLocationIdChange = debounce(
      (locations) => {
        const customOption = get(first(locations), 'customOption', false);
        const blurOption = get(first(locations), 'blurOption', false);
        const locationLongName = get(first(locations), 'label', '');
        const locationId = get(first(locations), 'id', '');
        if (
          !this.props.addingLocation &&
          !(blurOption && locationLongName === this.state.persistedLocationLongName)
        ) {
          if (customOption) {
            if (this.props.handleAddLocationModalClicked) {
              this.props.handleAddLocationModalClicked(
                this.props.index,
                true,
                locationLongName
              );
            }
          } else if (blurOption) {
            if (locationLongName) {
              if (this.props.handleAddLocationClicked) {
                if (window.confirm('Add location without lat/lng?')) {
                  const args = {
                    long_name: locationLongName,
                    short_name: locationLongName,
                    shorthand_name: locationLongName.replace(/\s+/g, '').toUpperCase(),
                    location_status_id: 1,
                  };
                  this.props.handleAddLocationClicked(args, this.props.index, true);
                }
              }
            } else {
              const newLocationId = '';
              const currentLocationId = defaultTo(
                parseInt(this.props.start_location_id, 10),
                ''
              );
              if (currentLocationId !== newLocationId) {
                if (this.props.handleLocationChanged) {
                  this.props.handleLocationChanged(this.props.index, locationId);
                }
              }
            }
          } else if (locationId) {
            const currentLocationId = defaultTo(
              parseInt(this.props.start_location_id, 10),
              ''
            );
            const newLocationId = defaultTo(parseInt(locationId, 10), '');
            if (currentLocationId !== newLocationId) {
              if (this.props.handleLocationChanged) {
                this.props.handleLocationChanged(this.props.index, locationId);
              }
            }
          }
        }
      },
      200,
      { leading: true, trailing: false }
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.start_at !== nextProps.start_at) {
      this.receiveStartAt(nextProps);
    }
    if (this.props.start_location_id !== nextProps.start_location_id) {
      this.receiveStartLocationId(nextProps);
    }
    if (
      !isEqual(
        this.props.locationsTypeaheadCollection,
        nextProps.locationsTypeaheadCollection
      )
    ) {
      const locationOptions = this.getLocationOptions(nextProps);
      this.setState({
        locationOptions,
      });
    }
    if (nextProps.start_at && nextProps.prevEndAt) {
      this.normalizeStartAt(nextProps, this.props);
    }
  }

  componentWillUnmount() {
    this.delayedHandleLocationIdChange.cancel();
  }

  receiveStartAt(props = this.props) {
    this.setState({
      date: moment(props.start_at).format('DD/MM/YYYY'),
      time: moment(props.start_at).format('HHmm'),
    });
  }

  normalizeStartAt(props = this.props, prevProps = {}) {
    const mmStartAt = moment(props.start_at);
    const mmPrevEndAt = moment(props.prevEndAt);
    if (mmPrevEndAt.isBefore(mmStartAt)) {
      if (prevProps.prevEndAt && prevProps.start_at) {
        if (prevProps.start_at === props.start_at) {
          const mmPrevStartAt = moment(prevProps.start_at);
          const mmPrevPrevEndAt = moment(prevProps.prevEndAt);
          const prevDayDiff = mmPrevStartAt.diff(mmPrevPrevEndAt, 'days');
          const currentDayDiff = mmStartAt.diff(mmPrevEndAt, 'days');
          if (currentDayDiff > prevDayDiff) {
            mmStartAt.subtract(currentDayDiff - prevDayDiff, 'days');
            const input = get(props, `${props.field}.start_at.input`);
            input.onChange(mmStartAt.format());
          }
        }
      }
    } else if (mmPrevEndAt.isAfter(mmStartAt)) {
      if (mmPrevEndAt.isAfter(mmStartAt, 'day')) {
        mmStartAt.set({
          year: mmPrevEndAt.year(),
          month: mmPrevEndAt.month(),
          date: mmPrevEndAt.date(),
        });
      }
      if (mmPrevEndAt.isAfter(mmStartAt, 'second')) {
        mmStartAt.set({
          hour: mmPrevEndAt.hour(),
          minute: mmPrevEndAt.minute(),
          second: mmPrevEndAt.second(),
        });
      }
      const input = get(props, `${props.field}.start_at.input`);
      input.onChange(mmStartAt.format());
    }
  }

  receiveStartLocationId(props = this.props) {
    let locationLongName = '';
    const locationId = defaultTo(parseInt(props.start_location_id, 10), '');
    if (locationId) {
      // we use long name as that matches the typeahead label return from server.
      locationLongName = get(this.props.locationsDataSelector, [locationId, 'longName']);
    }
    this.setState({
      locationLongName,
      persistedLocationLongName: locationLongName,
    });
  }

  handleDateChange(date) {
    if (moment.isMoment(date)) {
      date = date.format('DD/MM/YYYY');
      this.setState({
        date,
        dateError: {},
      });
      const { time, timeError } = this.state;
      if (!timeError.invalid) {
        const input = get(this.props, `${this.props.field}.start_at.input`);
        input.onChange(moment(`${date} ${time}`, 'DD/MM/YYYY HHmm').format());
      }
    } else {
      this.setState({
        date,
        dateError: { touched: true, invalid: true, error: 'DD/MM/YYYY' },
      });
    }
  }

  handleTimeChange(e) {
    let time = e.target.value;
    time = time === '2400' ? '2359' : time;
    if (time.match(/^(0[0-9]|1[0-9]|2[0-3])[0-5][0-9]$/)) {
      this.setState({
        time,
        timeError: {},
      });
      const { date, dateError } = this.state;
      if (!dateError.invalid) {
        const input = get(this.props, `${this.props.field}.start_at.input`);
        input.onChange(moment(`${date} ${time}`, 'DD/MM/YYYY HHmm').format());
      }
    } else {
      this.setState({
        time,
        timeError: { touched: true, invalid: true, error: 'HHMM' },
      });
    }
  }

  handleLocationSearch(query) {
    if (this.props.handleLocationSearch) {
      this.props.handleLocationSearch(query, this.props.index, true);
    }
  }

  handleLocationIdInputChange(locationLongName) {
    this.setState({
      locationLongName,
    });
  }

  handleLocationIdChange(locations) {
    if (locations.length === 0 || has(first(locations), 'id')) {
      this.delayedHandleLocationIdChange(locations);
    }
  }

  handleLocationIdBlur(e) {
    const locations = [
      {
        label: e.target.value,
        blurOption: true,
        id: '',
      },
    ];
    this.delayedHandleLocationIdChange(locations);
  }

  getLocationOptions(props) {
    return props.locationsTypeaheadCollection.filter((typeahead) => typeahead.id !== 0);
  }

  render() {
    const {
      start_at: { input: startAtInput },
      start_location_id: { input: startLocationIdInput, meta: startLocationIdMeta },
    } = get(this.props, this.props.field);

    return (
      <Row>
        <Col xs={4} className="compact_r">
          <FormGroup
            controlId={`${startAtInput.name}-date`}
            validationState={validationState(this.state.dateError)}
            bsSize="sm"
          >
            {this.props.tog ? (
              <ControlLabel style={{ fontSize: '11px', fontWeight: 'normal' }}>
                {`ToG ${this.props.tog}`}
              </ControlLabel>
            ) : (
              <ControlLabel>&nbsp;</ControlLabel>
            )}
            <DateTime
              value={this.state.date}
              dateFormat="DD/MM/YYYY"
              timeFormat={false}
              closeOnSelect
              onChange={this.handleDateChange}
              inputProps={{ tabIndex: '-1' }}
            />
            <HelpBlock>{validationText(this.state.dateError)}</HelpBlock>
          </FormGroup>
        </Col>
        <Col xs={5} className="compact_b">
          <FormGroup
            controlId={startLocationIdInput.name}
            validationState={validationState(startLocationIdMeta)}
            bsSize="sm"
          >
            <ControlLabel>&nbsp;</ControlLabel>
            <AsyncTypeahead
              id={`${startLocationIdInput.name}-id`}
              isLoading={this.props.typeaheadFetching}
              ref={`${startLocationIdInput.name}-ref`}
              inputProps={{ name: startLocationIdInput.name }}
              align="left"
              useCache={false}
              onSearch={this.handleLocationSearch}
              onInputChange={this.handleLocationIdInputChange}
              onChange={this.handleLocationIdChange}
              onBlur={this.handleLocationIdBlur}
              options={this.state.locationOptions}
              selected={_compact([this.state.locationLongName])}
              allowNew
              newSelectionPrefix="Add new and pick lat/lng: "
              filterBy={['searchable_label']}
            />
            <HelpBlock>{validationText(startLocationIdMeta)}</HelpBlock>
          </FormGroup>
        </Col>
        <Col xs={3} className="compact_l">
          <FormGroup
            controlId={`${startAtInput.name}-time`}
            validationState={validationState(this.state.timeError)}
            bsSize="sm"
          >
            <ControlLabel>&nbsp;</ControlLabel>
            <FormControl
              type="text"
              value={this.state.time}
              onChange={this.handleTimeChange}
              style={
                this.props.start_critical
                  ? { backgroundColor: 'yellow' }
                  : { backgroundColor: 'white' }
              }
            />
            <HelpBlock>{validationText(this.state.timeError)}</HelpBlock>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default FlightSegmentStartDateLocationAndTimeInputFields;
