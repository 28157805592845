import { Row, Col, Table, Form, FormGroup, FormControl, Button } from 'react-bootstrap';
import { Component } from 'react';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import accounting from 'accounting';
import { connect } from 'react-redux';

import debounce from 'lodash/debounce';

import { currentSettingsSet } from '../actions/current_setting_actions';

import {
  mutationSet,
  // mutationFailure,
} from '../actions/mutation_actions';

import ReactDateTimeFilter from '../components/form/react_date_time_filter';
import Loader from '../components/loader';
import ReportHeader from '../components/report_header';

import { queriesReady, getExport } from '../lib/utils';

import pilotDutyRecordAccumulationListQuery from '../queries/pilot_duty_record_accumulation_list_query';
import pilotFlightLogAccumulationListQuery from '../queries/pilot_flight_log_accumulation_list_query';
import contactItemListQuery from '../queries/contact_item_list_query';

const moment = extendMoment(Moment);
moment.updateLocale('en-nz');

class ReportPilotCumulativeHour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterPilotId: this.props.currentSettingsReportPilotId,
      filterPilotIds: [],
    };
    this.handleDisplayPilotIdChange = this.handleDisplayPilotIdChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.startAtDate) {
      const date = moment(this.props.params.startAtDate, 'MM-YYYY');
      this.props.currentSettingsSet({
        reportStart: date.clone().startOf('day').toISOString(),
        reportEnd: date.clone().endOf('month').toISOString(),
      });
    }
    if (this.props.params.pilotId) {
      this.handleDisplayPilotIdChange({
        target: { value: parseInt(this.props.params.pilotId, 10) },
      });
    }
    if (
      !moment(this.props.currentSettingsReportStart).isSame(
        moment(this.props.currentSettingsReportStart).startOf('month')
      )
    ) {
      this.props.currentSettingsSet({
        reportStart: moment(this.props.currentSettingsReportStart)
          .startOf('month')
          .toISOString(),
      });
    }
    if (
      !moment(this.props.currentSettingsReportEnd).isSame(
        moment(this.props.currentSettingsReportStart).endOf('month')
      )
    ) {
      this.props.currentSettingsSet({
        reportEnd: moment(this.props.currentSettingsReportStart)
          .endOf('month')
          .toISOString(),
      });
    }
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    this.delayedHandleRefetch = debounce(() => {
      this.props.pilotDutyRecordAccumulationListQuery.refetch();
      this.props.pilotFlightLogAccumulationListQuery.refetch();
    }, 250);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { filterPilotIds } = this.state;
    if (this.isLoaded(nextProps)) {
      filterPilotIds = nextProps.pilotDutyRecordAccumulationListQuery.data.map(
        (data) => data.pilot_id
      );
    }
    this.setState({
      filterPilotIds,
    });
    let filterPilotId = nextProps.currentSettingsReportPilotId;
    if (filterPilotId && this.isLoaded()) {
      if (filterPilotIds.length > 0 && filterPilotIds.indexOf(filterPilotId) === -1) {
        filterPilotId = '';
      }
    }
    if (filterPilotId !== this.props.currentSettingsReportPilotId) {
      this.handleDisplayPilotIdChange({ target: { value: filterPilotId } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleRefetch.cancel();
  }

  getExport = (e) => {
    this.props.mutationSet(true);
    const reportName = e.target.getAttribute('data-report-name');
    const args = {
      startAt: this.props.currentSettingsReportStart,
      endAt: this.props.currentSettingsReportEnd,
      pilotId: this.props.currentSettingsReportPilotId,
    };
    getExport(reportName, args)
      .then(() => {
        this.props.mutationSet(false);
      })
      .catch((err) => this.props.mutationFailure(err));
  };

  sumSlice = (slice) =>
    accounting.toFixed(
      slice.reduce((sum, value) => sum + value, 0),
      2
    );

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(
      testProps.pilotDutyRecordAccumulationListQuery,
      testProps.pilotFlightLogAccumulationListQuery,
      testProps.pilotListQuery
    );
  }

  handleDisplayPilotIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value, 10))
      ? ''
      : parseInt(e.target.value, 10);
    this.setState({
      filterPilotId: value,
    });
    this.props.currentSettingsSet({
      reportPilotId: value,
    });
  }

  renderRow(day, index, dutyTotals, logTotals) {
    return (
      <tr key={index}>
        <td>{day.format('DD/MM/YYYY')}</td>
        <td className="text-center">{logTotals[index]}</td>
        <td className="text-center">
          {this.sumSlice(logTotals.slice(index, index + 7))}
        </td>
        <td className="text-center">
          {this.sumSlice(logTotals.slice(index, index + 28))}
        </td>
        <td className="text-center">
          {this.sumSlice(logTotals.slice(index, index + 90))}
        </td>
        <td className="text-center">
          {this.sumSlice(logTotals.slice(index, index + 365))}
        </td>
        <td
          style={{ borderTop: 'none', borderBottom: 'none', backgroundColor: '#fcfcfc' }}
        >
          &nbsp;
        </td>
        <td>{day.format('DD/MM/YYYY')}</td>
        <td className="text-center">{dutyTotals[index]}</td>
        <td className="text-center">
          {this.sumSlice(dutyTotals.slice(index, index + 7))}
        </td>
        <td className="text-center">
          {this.sumSlice(dutyTotals.slice(index, index + 30))}
        </td>
        <td className="text-center">
          {this.sumSlice(dutyTotals.slice(index, index + 90))}
        </td>
        <td className="text-center">
          {this.sumSlice(dutyTotals.slice(index, index + 365))}
        </td>
      </tr>
    );
  }

  renderPilotCumulativeHours(range, dutyTotals, logTotals) {
    return (
      <tbody>
        {range.map((day, index) => this.renderRow(day, index, dutyTotals, logTotals))}
      </tbody>
    );
  }

  renderHeaderRowA = () => (
    <thead>
      <tr>
        <th colSpan={6} className="text-center">
          Flying Hours
        </th>
        <th style={{ borderBottom: 'none' }}>&nbsp;</th>
        <th colSpan={6} className="text-center">
          Duty Hours
        </th>
      </tr>
    </thead>
  );

  renderHeaderRowB = () => (
    <thead>
      <tr>
        <th className="text-left" style={{ borderTop: 'none' }}>
          Date
        </th>
        <th className="text-center" style={{ borderTop: 'none' }}>
          1
        </th>
        <th className="text-center" style={{ borderTop: 'none' }}>
          7
        </th>
        <th className="text-center" style={{ borderTop: 'none' }}>
          28
        </th>
        <th className="text-center" style={{ borderTop: 'none' }}>
          90
        </th>
        <th className="text-center" style={{ borderTop: 'none' }}>
          365
        </th>
        <th className="text-center" style={{ borderTop: 'none', borderBottom: 'none' }}>
          &nbsp;
        </th>
        <th className="text-left" style={{ borderTop: 'none' }}>
          Date
        </th>
        <th className="text-center" style={{ borderTop: 'none' }}>
          1
        </th>
        <th className="text-center" style={{ borderTop: 'none' }}>
          7
        </th>
        <th className="text-center" style={{ borderTop: 'none' }}>
          30
        </th>
        <th className="text-center" style={{ borderTop: 'none' }}>
          90
        </th>
        <th className="text-center" style={{ borderTop: 'none' }}>
          365
        </th>
      </tr>
    </thead>
  );

  renderPilots() {
    const MMRange = moment(this.props.currentSettingsReportStart).range('month');
    const range = Array.from(MMRange.reverseBy('day'));
    return (
      <Row>
        <Col xs={12}>
          {this.props.pilotDutyRecordAccumulationListQuery.data
            .filter((data) => {
              if (
                this.state.filterPilotId &&
                data.pilot_id !== this.state.filterPilotId
              ) {
                return false;
              }
              return true;
            })
            .map((data) => {
              const pilot = this.props.pilotListQuery.data.find(
                (model) => model.id === data.pilot_id
              );
              if (pilot) {
                const pilotFullName = pilot.fullName;
                const dutyTotals = data ? data.duty_totals : [];
                const log = this.props.pilotFlightLogAccumulationListQuery.data.find(
                  (logs) => logs.pilot_id === data.pilot_id
                );
                const logTotals = log ? log.log_totals : [];
                return (
                  <Row key={data.pilot_id} style={{ marginBottom: '20px' }}>
                    <Col xs={12}>
                      <h4>{pilotFullName}</h4>
                    </Col>
                    <Col xs={12}>
                      <Table striped>
                        {this.renderHeaderRowA()}
                        {this.renderHeaderRowB()}
                        {dutyTotals.length > 0 &&
                          logTotals.length > 0 &&
                          this.renderPilotCumulativeHours(range, dutyTotals, logTotals)}
                      </Table>
                    </Col>
                  </Row>
                );
              }
              return undefined;
            })}
        </Col>
      </Row>
    );
  }

  renderPilotFilter() {
    const dataPilots = this.props.pilotListQuery.data.filter(
      (model) => this.state.filterPilotIds.indexOf(model.id) > -1
    );
    return (
      <FormControl
        componentClass="select"
        value={this.state.filterPilotId}
        onChange={this.handleDisplayPilotIdChange}
      >
        <option key={0} value="">
          All
        </option>
        {dataPilots.map((model) => {
          const { id, fullName } = model;
          return (
            <option key={id} value={id}>
              {fullName}
            </option>
          );
        })}
      </FormControl>
    );
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (this.isLoading()) {
      return undefined;
    }
    return (
      <div>
        <Row>
          <Col sm={12}>
            <div className="clearfix">
              <div className="pull-left">
                <ReportHeader
                  title="Cumulative Hours Report"
                  start={this.props.currentSettingsReportStart}
                  end={this.props.currentSettingsReportEnd}
                />
              </div>
              <div className="pull-right">
                <Button
                  bsStyle="primary"
                  data-report-name="pilot_cumulative_hours"
                  onClick={this.getExport}
                >
                  PDF
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: '10px' }}>
          <Col xs={12}>
            <Form inline>
              <Button
                style={{ margin: '0', padding: '0 0 8px 0' }}
                bsStyle="link"
                bsSize="xsmall"
                onClick={this.delayedHandleRefetch}
              >
                <span className="glyphicon glyphicon-repeat" />
              </Button>
              <ReactDateTimeFilter
                currentSettingsReportStart={this.props.currentSettingsReportStart}
                currentSettingsReportEnd={this.props.currentSettingsReportEnd}
                onChange={this.props.currentSettingsSet}
                locked
              />
              <FormGroup
                style={{ marginLeft: '16px', paddingBottom: '10px' }}
                bsSize="sm"
                controlId="filterId"
              >
                {this.renderPilotFilter()}
              </FormGroup>
            </Form>
          </Col>
        </Row>
        {this.renderPilots()}
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderOverlay()}
        {this.renderData()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsReportPilotId: state.currentSettings.reportPilotId,
    currentSettingsReportStart: state.currentSettings.reportStart,
    currentSettingsReportEnd: state.currentSettings.reportEnd,
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default compose(
  connect(mapStateToProps, { currentSettingsSet, mutationSet }),
  graphql(contactItemListQuery, {
    name: 'pilotListQuery',
    options: { variables: { role: 'pilot' } },
  }),
  graphql(pilotDutyRecordAccumulationListQuery, {
    name: 'pilotDutyRecordAccumulationListQuery',
    options: (props) => ({
      variables: {
        startAt: props.currentSettingsReportStart,
        endAt: props.currentSettingsReportEnd,
      },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  graphql(pilotFlightLogAccumulationListQuery, {
    name: 'pilotFlightLogAccumulationListQuery',
    options: (props) => ({
      variables: {
        startAt: props.currentSettingsReportStart,
        endAt: props.currentSettingsReportEnd,
      },
      fetchPolicy: 'cache-and-network',
    }),
  })
)(ReportPilotCumulativeHour);
