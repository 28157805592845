import {
  Form,
  Row,
  Col,
  Table,
  FormGroup,
  FormControl,
  Button,
  Glyphicon,
} from 'react-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import moment from 'moment';

import _debounce from 'lodash/debounce';
import _set from 'lodash/set';
import _get from 'lodash/get';

import { currentSettingsSet } from '../actions/current_setting_actions';

import { mutationSet, mutationFailure } from '../actions/mutation_actions';

import ReactDateTimeFilter from '../components/form/react_date_time_filter';
import Loader from '../components/loader';
import ReportHeader from '../components/report_header';

import { queriesReady, getExport } from '../lib/utils';

import bookingFlightTimeForMonthByProviderQuery from '../queries/booking_flight_time_for_month_by_provider_query';
import contactItemListQuery from '../queries/contact_item_list_query';
import aircraftListQuery from '../queries/aircraft_list_query';

moment.updateLocale('en-nz');

class ReportProviderFlightHour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterProviderId: this.props.currentSettingsReportProviderId,
      filterProviderIds: [],
    };
    this._handleDisplayProviderIdChange = this._handleDisplayProviderIdChange.bind(this);
    this._handleNavAircraftFlightRecord = this._handleNavAircraftFlightRecord.bind(this);
    this._handleNavChargeableFlightRecord =
      this._handleNavChargeableFlightRecord.bind(this);
    this._handleNavChargeableInvoice = this._handleNavChargeableInvoice.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.startAtDate) {
      const date = moment(this.props.params.startAtDate, 'MM-YYYY');
      this.props.currentSettingsSet({
        reportStart: date.clone().startOf('day').toISOString(),
        reportEnd: date.clone().endOf('month').toISOString(),
      });
    }
    if (this.props.params.providerId) {
      this._handleDisplayProviderIdChange({
        target: { value: this.props.params.providerId },
      });
    }
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    this.delayedHandleRefetch = _debounce(() => {
      this.props.bookingFlightTimeForMonthByProviderQuery.refetch();
    }, 250);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { filterProviderIds } = this.state;
    if (this.isLoaded(nextProps)) {
      filterProviderIds = nextProps.bookingFlightTimeForMonthByProviderQuery.data.map(
        (data) => data.provider_id
      );
    }
    this.setState({
      filterProviderIds,
    });
    let filterProviderId = nextProps.currentSettingsReportProviderId;
    if (filterProviderId && this.isLoaded(nextProps)) {
      if (
        filterProviderIds.length > 0 &&
        filterProviderIds.indexOf(filterProviderId) === -1
      ) {
        filterProviderId = '';
      }
    }
    if (filterProviderId !== this.props.currentSettingsReportProviderId) {
      this._handleDisplayProviderIdChange({ target: { value: filterProviderId } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleRefetch.cancel();
  }

  getExport = (e) => {
    this.props.mutationSet(true);
    const reportName = e.target.getAttribute('data-report-name');
    const args = {
      startAt: this.props.currentSettingsReportStart,
      endAt: this.props.currentSettingsReportEnd,
      providerId: this.props.currentSettingsReportProviderId,
    };
    getExport(reportName, args)
      .then(() => {
        this.props.mutationSet(false);
      })
      .catch((err) => this.props.mutationFailure(err));
  };

  _renderFixed = (value) => (value ? value.toFixed(2) : '-');

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const newProps = props || this.props;
    return !queriesReady(
      newProps.bookingFlightTimeForMonthByProviderQuery,
      newProps.providerListQuery,
      newProps.aircraftListQuery
    );
  }

  _handleDisplayProviderIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value, 10))
      ? ''
      : parseInt(e.target.value, 10);
    this.setState({
      filterProviderId: value,
    });
    this.props.currentSettingsSet({
      reportProviderId: value,
    });
  }

  _handleNavAircraftFlightRecord(e) {
    const aircraftId = parseInt(e.target.getAttribute('data-id'), 10);
    this.props.currentSettingsSet({
      reportAircraftId: aircraftId,
    });
    this.props.navigate('/reports/aircraft_flight_record');
  }

  _handleNavChargeableFlightRecord(e) {
    const chargeableId = parseInt(e.target.getAttribute('data-id'), 10);
    this.props.currentSettingsSet({
      reportChargeableId: chargeableId,
    });
    this.props.navigate('/reports/chargeable_flight_record');
  }

  _handleNavChargeableInvoice(e) {
    const chargeableId = parseInt(e.target.getAttribute('data-id'), 10);
    this.props.currentSettingsSet({
      reportChargeableId: chargeableId,
    });
    this.props.navigate('/reports/chargeable_invoice');
  }

  _renderRow(chargeableId, chargeableFullName, totals) {
    const complete = totals.completed.every((b) => b);
    const invoiced = totals.invoiced.every((b) => b);
    return (
      <tr key={chargeableId}>
        <td>{chargeableFullName}</td>
        <td className="text-right">{this._renderFixed(totals.total)}</td>
        {this.props.aircraftListQuery.data.map((model) => {
          const aircraftId = model.id;
          return (
            <td key={chargeableId + aircraftId} className="text-right">
              {this._renderFixed(_get(totals, [aircraftId]))}
            </td>
          );
        })}
        <td>
          <Glyphicon
            style={{ color: complete ? 'green' : 'red' }}
            glyph={complete ? 'ok' : 'exclamation-sign'}
          />
        </td>
        <td>
          <Glyphicon
            style={{ color: invoiced ? 'green' : 'red' }}
            glyph={invoiced ? 'ok' : 'exclamation-sign'}
          />
        </td>
        <td>
          <Button
            data-id={chargeableId}
            bsStyle="link"
            bsSize="xsmall"
            onClick={this._handleNavChargeableFlightRecord}
          >
            flt
          </Button>
          <Button
            data-id={chargeableId}
            bsStyle="link"
            bsSize="xsmall"
            onClick={this._handleNavChargeableInvoice}
          >
            inv
          </Button>
        </td>
      </tr>
    );
  }

  _renderChargeables(bookings, totals) {
    const chargeableIds = [...new Set(bookings.map((booking) => booking.chargeable_id))];
    return (
      <tbody>
        {chargeableIds.map((chargeableId) => {
          const chargeableBookings = [
            ...new Set(
              bookings.filter((booking) => booking.chargeable_id === chargeableId)
            ),
          ];
          const { chargeable_full_name: chargeableFullName } = chargeableBookings[0];
          return this._renderRow(
            chargeableId,
            chargeableFullName,
            totals.chargeables[chargeableId]
          );
        })}
      </tbody>
    );
  }

  _renderHeaderRow(providerId) {
    return (
      <thead>
        <tr>
          <th width="20%">Chargeable</th>
          <th width="10%">Total</th>
          {this.props.aircraftListQuery.data.map((model) => {
            const { id, registration_abbreviated: registrationAbbreviated } = model;
            return (
              <th key={providerId + id}>
                <Button
                  data-id={id}
                  bsStyle="link"
                  bsSize="xsmall"
                  onClick={this._handleNavAircraftFlightRecord}
                >
                  {registrationAbbreviated}
                </Button>
              </th>
            );
          })}
          <th>Complete</th>
          <th>Invoiced</th>
          <th>Reports</th>
        </tr>
      </thead>
    );
  }

  _renderFooterRow(providerFullName, totals) {
    return (
      <tfoot>
        <tr>
          <th>{`Totals for ${providerFullName}`}</th>
          <th className="text-right">{this._renderFixed(totals.total)}</th>
          {this.props.aircraftListQuery.data.map((model) => {
            const aircraftId = model.id;
            return (
              <td key={providerFullName + aircraftId} className="text-right">
                {this._renderFixed(_get(totals, ['aircrafts', aircraftId]))}
              </td>
            );
          })}
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
        </tr>
      </tfoot>
    );
  }

  _renderGrandTotalFooterRow(totals) {
    return (
      <tfoot>
        <tr>
          <th>&nbsp;</th>
          <th className="text-right">Total</th>
          {this.props.aircraftListQuery.data.map((model) => {
            const aircraftregistrationAbbreviated = model.registration_abbreviated;
            return (
              <th key={model.id} className="text-right">
                {aircraftregistrationAbbreviated}
              </th>
            );
          })}
        </tr>
        <tr>
          <th>Totals</th>
          <td className="text-right">{this._renderFixed(totals.total)}</td>
          {this.props.aircraftListQuery.data.map((model) => {
            const aircraftId = model.id;
            return (
              <td key={aircraftId} className="text-right">
                {this._renderFixed(_get(totals, ['aircrafts', aircraftId]))}
              </td>
            );
          })}
        </tr>
      </tfoot>
    );
  }

  _renderProviders() {
    const grandTotals = { total: 0 };
    return (
      <Row>
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              {this.props.bookingFlightTimeForMonthByProviderQuery.data
                .filter((data) => {
                  if (
                    this.state.filterProviderId &&
                    data.provider_id !== this.state.filterProviderId
                  ) {
                    return false;
                  }
                  return true;
                })
                .sort((a, b) =>
                  b.provider_id === this.props.currentTenantId
                    ? 1
                    : a.provider_full_name.localeCompare(b.provider_full_name)
                )
                .map((data) => {
                  const { id, bookings, provider_full_name: providerFullName } = data;
                  // const totals = {
                  //   total: 0,
                  // };
                  const totals = bookings.reduce(
                    (accum, booking) => {
                      const {
                        aircraft_id: aircraftId,
                        chargeable_id: chargeableId,
                        chargeable_flight_time: chargeableFlightTime,
                        has_invoice_reference: hasInvoiceReference,
                        is_complete: isComplete,
                      } = booking;
                      _set(
                        accum,
                        ['total'],
                        _get(accum, ['total'], 0) + chargeableFlightTime
                      );
                      _set(
                        accum,
                        ['aircrafts', aircraftId],
                        _get(accum, ['aircrafts', aircraftId], 0) + chargeableFlightTime
                      );
                      _set(
                        accum,
                        ['chargeables', chargeableId, 'total'],
                        _get(accum, ['chargeables', chargeableId, 'total'], 0) +
                          chargeableFlightTime
                      );
                      _set(
                        accum,
                        ['chargeables', chargeableId, aircraftId],
                        _get(accum, ['chargeables', chargeableId, aircraftId], 0) +
                          chargeableFlightTime
                      );
                      _set(
                        accum,
                        ['chargeables', chargeableId, 'invoiced'],
                        [
                          ..._get(accum, ['chargeables', chargeableId, 'invoiced'], []),
                          hasInvoiceReference,
                        ]
                      );
                      _set(
                        accum,
                        ['chargeables', chargeableId, 'completed'],
                        [
                          ..._get(accum, ['chargeables', chargeableId, 'completed'], []),
                          isComplete,
                        ]
                      );
                      grandTotals.total += chargeableFlightTime;
                      _set(
                        grandTotals,
                        ['aircrafts', aircraftId],
                        _get(grandTotals, ['aircrafts', aircraftId], 0) +
                          chargeableFlightTime
                      );
                      return accum;
                    },
                    {
                      total: 0,
                    }
                  );
                  return (
                    <Row key={id} style={{ marginBottom: '20px' }}>
                      <Col xs={12}>
                        <h4>{providerFullName}</h4>
                      </Col>
                      <Col xs={12}>
                        <Table striped>
                          {this._renderHeaderRow(id)}
                          {this._renderChargeables(bookings, totals)}
                          {this._renderFooterRow(providerFullName, totals)}
                        </Table>
                      </Col>
                    </Row>
                  );
                })}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Table striped>{this._renderGrandTotalFooterRow(grandTotals)}</Table>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  _renderProviderFilter() {
    const dataProviders = this.props.providerListQuery.data.filter(
      (model) => this.state.filterProviderIds.indexOf(model.id) > -1
    );
    return (
      <FormControl
        componentClass="select"
        value={this.state.filterProviderId}
        onChange={this._handleDisplayProviderIdChange}
      >
        <option key={0} value="">
          All
        </option>
        {dataProviders.map((model) => {
          const { id, fullName } = model;
          return (
            <option key={id} value={id}>
              {fullName}
            </option>
          );
        })}
      </FormControl>
    );
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (this.isLoading()) {
      return undefined;
    }
    return (
      <div>
        <Row>
          <Col sm={12}>
            <div className="clearfix">
              <div className="pull-left">
                <ReportHeader
                  title="Flight Hour Matrix"
                  start={this.props.currentSettingsReportStart}
                  end={this.props.currentSettingsReportEnd}
                />
              </div>
              <div className="pull-right">
                <Button
                  bsStyle="primary"
                  data-report-name="booking_flight_time_for_month_by_provider"
                  onClick={this.getExport}
                >
                  PDF
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: '10px' }}>
          <Col xs={12}>
            <Form inline>
              <Button
                style={{ margin: '0', padding: '0 0 8px 0' }}
                bsStyle="link"
                bsSize="xsmall"
                onClick={this.delayedHandleRefetch}
              >
                <span className="glyphicon glyphicon-repeat" />
              </Button>
              <ReactDateTimeFilter
                currentSettingsReportStart={this.props.currentSettingsReportStart}
                currentSettingsReportEnd={this.props.currentSettingsReportEnd}
                onChange={this.props.currentSettingsSet}
              />
              <FormGroup
                style={{ marginLeft: '16px', paddingBottom: '10px' }}
                bsSize="sm"
                controlId="filterId"
              >
                {this._renderProviderFilter()}
              </FormGroup>
            </Form>
          </Col>
        </Row>
        {this._renderProviders()}
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderOverlay()}
        {this.renderData()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentTenantId: state.currentTenant.id,
    currentSettingsReportProviderId: state.currentSettings.reportProviderId,
    currentSettingsReportStart: state.currentSettings.reportStart,
    currentSettingsReportEnd: state.currentSettings.reportEnd,
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default compose(
  connect(mapStateToProps, {
    currentSettingsSet,
    mutationFailure,
    mutationSet,
  }),
  graphql(contactItemListQuery, {
    name: 'providerListQuery',
    options: { variables: { role: 'provider' } },
  }),
  graphql(aircraftListQuery, {
    name: 'aircraftListQuery',
  }),
  graphql(bookingFlightTimeForMonthByProviderQuery, {
    name: 'bookingFlightTimeForMonthByProviderQuery',
    options: (props) => ({
      variables: {
        startAt: props.currentSettingsReportStart,
        endAt: props.currentSettingsReportEnd,
      },
      fetchPolicy: 'cache-and-network',
    }),
  })
)(ReportProviderFlightHour);
