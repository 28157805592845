import {
  Col,
  Form,
  FormGroup,
  Button,
  ButtonToolbar,
  ButtonGroup,
} from 'react-bootstrap';
import { Component } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { reduxForm, Field, getFormValues } from 'redux-form';

import { LinkContainer } from 'react-router-bootstrap';

import Validator from 'validatorjs';

import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _isNil from 'lodash/isNil';
import _pick from 'lodash/pick';
import _omitBy from 'lodash/omitBy';
import _set from 'lodash/set';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import InputField from '../components/form/input_field';
import CheckboxField from '../components/form/checkbox_field';
import ReactDateTimeField from '../components/form/react_date_time_field';

import smsActionQuery from '../queries/sms_action_query';
import smsActionListQuery from '../queries/sms_action_list_query';

import smsControlListQuery from '../queries/sms_control_list_query';

import contactItemListQuery from '../queries/contact_item_list_query';

import smsActionUpdateMutation from '../mutations/sms_action_update_mutation';
import smsActionCreateMutation from '../mutations/sms_action_create_mutation';

import { queryReady, queriesReady, typeInput } from '../lib/utils';
import Loader from '../components/loader';

let isInitialisedSmsActionForm = false;

class SmsActionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: !!this.props.params.id,
    };
    this._handleSubmit = this._handleSubmit.bind(this);
  }

  componentWillUnmount() {
    isInitialisedSmsActionForm = false;
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(
      props.smsControlListQuery,
      props.managerListQuery,
      props.employeeListQuery,
      [props.smsActionQuery, true]
    );
  }

  _handleSubmit(data) {
    const submitData = _cloneDeep(data);

    this.props.mutationSet(true);
    let mutation;
    let mutationMessage;
    const mutationData = {
      variables: {
        input: typeInput(submitData),
      },
      refetchQueries: () => [
        { query: smsActionListQuery },
        { query: smsControlListQuery },
      ],
    };
    if (this.state.updating) {
      mutation = this.props.smsActionUpdateMutation;
      mutationMessage = 'Action update';
      mutationData.variables.id = this.props.params.id;
    } else {
      mutation = this.props.smsActionCreateMutation;
      mutationMessage = 'Action create';
    }
    return mutation(mutationData)
      .then((res) => {
        this.props.mutationSuccess(mutationMessage);
        this.props.navigate(this.props.currentSettingsReturnRouteSmsAction);
      })
      .catch((err) => this.props.mutationFailure(err, true));
  }

  _renderTitle() {
    if (this.state.updating) {
      return 'Edit Action';
    } else {
      return 'New Action';
    }
  }

  _renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
  }

  _renderData() {
    if (
      isInitialisedSmsActionForm &&
      this.isLoaded() &&
      _get(this.props, 'formValues') &&
      (!this.state.updating || _get(this.props, 'formValues.id'))
    ) {
      const { handleSubmit, pristine, submitting, valid } = this.props;

      return (
        <div>
          <Form horizontal onSubmit={handleSubmit(this._handleSubmit)}>
            <h2>{this._renderTitle()}</h2>
            <fieldset>
              <legend>Details</legend>
              <Field
                type="text"
                name="description"
                component={InputField}
                componentClass="textarea"
                rows={4}
                inputWidth={6}
              >
                Description
              </Field>
              <Field
                type="text"
                name="status"
                inputWidth={3}
                noTab
                component={InputField}
                componentClass="select"
                defaultSelectOption={false}
                selectOptions={this.props.currentSettingsSmsActionStatuses.map((s) => ({
                  id: s,
                  name: s,
                }))}
              >
                Status
              </Field>
              <Field
                type="text"
                name="saturation"
                inputWidth={3}
                noTab
                component={InputField}
                componentClass="select"
                defaultSelectOption={false}
                selectOptions={this.props.currentSettingsSmsActionSaturations.map(
                  (s) => ({
                    id: s,
                    name: s,
                  })
                )}
              >
                Saturation
              </Field>
              <Field
                name="dueOn"
                inputWidth={3}
                component={ReactDateTimeField}
                helpText="DD/MM/YYYY"
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                closeOnSelect
              >
                Action Due On
              </Field>
              <Field
                type="text"
                name="controlId"
                inputWidth={6}
                component={InputField}
                componentClass="select"
                selectOptions={this.props.smsControlListQuery.data.map((c) => ({
                  id: c.id,
                  name: c.description,
                }))}
              >
                Related Control
              </Field>
              <Field
                type="text"
                name="employeeId"
                inputWidth={3}
                component={InputField}
                componentClass="select"
                selectOptions={this.props.employeeListQuery.data.map((e) => ({
                  id: e.id,
                  name: e.fullName,
                }))}
              >
                Responsible Employee
              </Field>
              <Field
                type="text"
                name="managerId"
                inputWidth={3}
                component={InputField}
                componentClass="select"
                selectOptions={this.props.managerListQuery.data.map((m) => ({
                  id: m.id,
                  name: m.fullName,
                }))}
              >
                Responsible Manager
              </Field>
            </fieldset>
            {this.state.updating && (
              <fieldset>
                <legend>Completion</legend>
                <Field
                  type="text"
                  name="completion"
                  component={InputField}
                  componentClass="textarea"
                  rows={4}
                  inputWidth={6}
                >
                  Completion Notes
                </Field>
                <Field
                  name="completionResult"
                  component={CheckboxField}
                  inputOffset={3}
                  inputWidth={3}
                >
                  Completed
                </Field>
              </fieldset>
            )}
            {this.state.updating &&
              this.props.currentContact['manager?'] &&
              _includes(this.props.currentContact.statutory_role_ids, 2) && (
                <fieldset>
                  <legend>Verification</legend>
                  <Field
                    type="text"
                    name="verification"
                    component={InputField}
                    componentClass="textarea"
                    rows={4}
                    inputWidth={6}
                  >
                    Verification Notes
                  </Field>
                  <Field
                    name="verificationResult"
                    component={CheckboxField}
                    inputOffset={3}
                    inputWidth={3}
                  >
                    Verified
                  </Field>
                </fieldset>
              )}
            <FormGroup>
              <Col sm={12}>
                <div className="pull-right">
                  <ButtonToolbar>
                    <ButtonGroup>
                      <LinkContainer to={this.props.currentSettingsReturnRouteSmsAction}>
                        <Button type="reset" bsStyle="danger" disabled={submitting}>
                          Cancel
                        </Button>
                      </LinkContainer>
                    </ButtonGroup>
                    <ButtonGroup>
                      <Button
                        type="submit"
                        bsStyle="primary"
                        disabled={pristine || submitting || !valid}
                      >
                        {submitting ? (
                          <span className="glyphicon glyphicon-refresh glyphicon-spin" />
                        ) : (
                          ''
                        )}{' '}
                        {this.state.updating ? 'Update' : 'Create'}
                      </Button>
                    </ButtonGroup>
                  </ButtonToolbar>
                </div>
              </Col>
            </FormGroup>
          </Form>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        {this._renderOverlay()}
        {this._renderData()}
      </div>
    );
  }
}

const validationAliases = {};

const validationRules = {
  description: 'required',
  status: 'required',
  saturation: 'required',
  dueOn: 'required',
  employeeId: 'required',
  managerId: 'required',
  controlId: 'required',
};

const validationCustomMessages = {};

function validate(data, props) {
  const validator = new Validator(data, validationRules, validationCustomMessages);
  validator.setAttributeNames(validationAliases);
  validator.passes();
  const errors = validator.errors.all();
  const result = {};
  Object.keys(errors).forEach((key) => {
    _set(result, key, errors[key].join(' '));
  });
  return result;
}

const attributeWhiteList = [
  'id',
  'description',
  'status',
  'saturation',
  'dueOn',
  'completion',
  'completionResult',
  'verification',
  'verificationResult',
  'employeeId',
  'managerId',
  'controlId',
];

function pickInitialValues(smsActionQuery, updating) {
  if (!isInitialisedSmsActionForm) {
    if (!updating) {
      isInitialisedSmsActionForm = true;
      return {
        status: 'new',
        saturation: 'easy',
      };
    } else if (queryReady(smsActionQuery)) {
      isInitialisedSmsActionForm = true;
      const editSmsAction = _pick(
        _omitBy(_cloneDeep(smsActionQuery.data), _isNil),
        attributeWhiteList
      );
      return editSmsAction;
    }
  }
}

function mapStateToProps(state, props) {
  const initialValues = pickInitialValues(props.smsActionQuery, !!props.params.id);
  return {
    initialValues,
    currentContact: state.currentContact,
    currentSettingsReturnRouteSmsAction: state.currentSettings.returnRouteSmsAction,
    currentSettingsMutating: state.currentSettings.mutating,
    currentSettingsSmsActionStatuses: state.currentSettings.sms_action_statuses,
    currentSettingsSmsActionSaturations: state.currentSettings.sms_action_saturations,
    formValues: getFormValues('SmsActionForm')(state),
  };
}

export default compose(
  graphql(smsActionCreateMutation, {
    name: 'smsActionCreateMutation',
  }),
  graphql(smsActionUpdateMutation, {
    name: 'smsActionUpdateMutation',
  }),
  graphql(smsControlListQuery, {
    name: 'smsControlListQuery',
  }),
  graphql(contactItemListQuery, {
    name: 'managerListQuery',
    options: { variables: { role: 'manager' } },
  }),
  graphql(contactItemListQuery, {
    name: 'employeeListQuery',
    options: { variables: { role: 'employee' } },
  }),
  graphql(smsActionQuery, {
    name: 'smsActionQuery',
    skip: (props) => !props.params.id,
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'network-only',
    }),
  }),
  connect(mapStateToProps, { mutationSuccess, mutationFailure, mutationSet }),
  reduxForm({
    form: 'SmsActionForm',
    validate,
  })
)(SmsActionForm);
