import {
  Row,
  Col,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
} from 'react-bootstrap';
import { Component } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import _compact from 'lodash/compact';
import debounce from 'lodash/debounce';
import defaultTo from 'lodash/defaultTo';
import first from 'lodash/first';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import { validationState, validationText } from '../form/helpers';

class SeatAssignmentPassengerAndWeightInputFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passengerOptions: [],
      paxName: '',
    };
    this._handlePassengerSearch = this._handlePassengerSearch.bind(this);
    this._handlePaxNameChange = this._handlePaxNameChange.bind(this);
    this._handlePaxNameInputChange = this._handlePaxNameInputChange.bind(this);
    this._handlePaxNameBlur = this._handlePaxNameBlur.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.delayedHandlePaxNameChange = debounce((passengers) => {
      const customOption = get(first(passengers), 'customOption', false);
      const blurOption = get(first(passengers), 'blurOption', false);
      const paxName = get(first(passengers), 'label', '');
      let passengerId = get(first(passengers), 'id', '');
      if (
        !this.props.addingPassenger &&
        !(blurOption && paxName === this.props.pax_name)
      ) {
        if (customOption) {
          passengerId = '';
          if (this.props.handleAddPassengerModalClicked) {
            this.props.handleAddPassengerModalClicked(
              this.props.index,
              this.props.flightSegmentIndex,
              paxName,
              this.props.weight
            );
          }
        } else if (paxName || blurOption) {
          const currentPassengerId = defaultTo(parseInt(this.props.passenger_id), '');
          const newPassengerId = defaultTo(parseInt(passengerId), '');
          const currentPaxName = defaultTo(this.props.pax_name, '');
          const newPaxName = defaultTo(paxName, '');
          if (currentPassengerId !== newPassengerId || currentPaxName !== newPaxName) {
            if (this.props.handlePassengerChanged) {
              this.props.handlePassengerChanged(
                this.props.index,
                this.props.flightSegmentIndex,
                newPassengerId,
                newPaxName
              );
            }
          }
        }
      }
    }, 200);
  }

  componentDidMount() {
    if (this.props.pax_name) {
      this._receivePaxName();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.pax_name !== nextProps.pax_name) {
      this._receivePaxName(nextProps);
    }
    if (
      !isEqual(
        this.props.passengersTypeaheadCollection,
        nextProps.passengersTypeaheadCollection
      )
    ) {
      const passengerOptions = this._getPassengerOptions(nextProps);
      this.setState({
        passengerOptions,
      });
    }
  }

  componentWillUnmount() {
    this.delayedHandlePaxNameChange.cancel();
  }

  _receivePaxName(props = this.props) {
    this.setState({
      paxName: props.pax_name,
    });
  }

  _getPassengerOptions(props) {
    return props.passengersTypeaheadCollection.filter((typeahead) => typeahead.id !== 0);
  }

  _handlePassengerSearch(query) {
    if (this.props.handlePassengerSearch) {
      this.props.handlePassengerSearch(
        query,
        this.props.index,
        this.props.flightSegmentIndex
      );
    }
  }

  _handlePaxNameInputChange(paxName) {
    this.setState({
      paxName,
    });
  }

  _handlePaxNameChange(passengers) {
    this.delayedHandlePaxNameChange(passengers);
  }

  _handlePaxNameBlur(e) {
    const passengers = [
      {
        label: e.target.value,
        blurOption: true,
        id: '',
      },
    ];
    this.delayedHandlePaxNameChange(passengers);
  }

  _calcClass() {
    if (this.props.isXs) {
      if ((this.props.index & 1) === 0) {
        return 'compact_r';
      } else {
        return 'compact_l';
      }
    } else {
      return 'compact_b';
    }
  }

  render() {
    const {
      weight: { input: weightInput, meta: weightMeta },
      pax_name: { input: paxNameInput, meta: paxNameMeta },
    } = get(this.props, this.props.field);

    return (
      <Row>
        <Col sm={12} className={this._calcClass()}>
          <FormGroup
            controlId={paxNameInput.name}
            validationState={validationState(paxNameMeta)}
            bsSize="sm"
          >
            <ControlLabel>{`Pax ${this.props.index + 1}`}</ControlLabel>
            <AsyncTypeahead
              id={`${paxNameInput.name}-id`}
              isLoading={this.props.typeaheadFetching}
              ref={`${paxNameInput.name}-ref`}
              inputProps={{ name: paxNameInput.name }}
              align="left"
              useCache={false}
              onSearch={this._handlePassengerSearch}
              onInputChange={this._handlePaxNameInputChange}
              onChange={this._handlePaxNameChange}
              onBlur={this._handlePaxNameBlur}
              options={this.state.passengerOptions}
              selected={_compact([this.state.paxName])}
              allowNew
              newSelectionPrefix="Add with details: "
              filterBy={['searchable_label']}
            />
            <HelpBlock>{validationText(paxNameMeta)}</HelpBlock>
          </FormGroup>
        </Col>
        <Col sm={12} className={this._calcClass()}>
          <FormGroup
            controlId={weightInput.name}
            validationState={validationState(weightMeta)}
            bsSize="sm"
          >
            <FormControl type="text" {...weightInput} />
            <HelpBlock>{validationText(weightMeta)}</HelpBlock>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default SeatAssignmentPassengerAndWeightInputFields;
