import { Row, Col, Form, FormGroup, FormControl, Button, Panel } from 'react-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import moment from 'moment';

import _cloneDeep from 'lodash/cloneDeep';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import _uniq from 'lodash/uniq';

import {
  aircraftsData,
  contactsData,
  flightTypesData,
  fuelBowsersData,
  fuelTankersData,
  locationsData,
} from '../selectors';

import { currentSettingsSet } from '../actions/current_setting_actions';

import { mutationSuccess, mutationFailure } from '../actions/mutation_actions';

import FlightSignoffInfo from '../components/flight_signoff/flight_signoff_info';
import FlightSignoffForm from '../components/flight_signoff/flight_signoff_form';

import Loader from '../components/loader';
import ReportHeader from '../components/report_header';
import ReactDateTimeFilter from '../components/form/react_date_time_filter';

import {
  queriesReady,
  typeInput,
  mapPickValues,
  pickValues,
  mapOmitValues,
  omitValues,
} from '../lib/utils';

import bookingUpdateMutation from '../mutations/booking_update_mutation';

import aircraftListQuery from '../queries/aircraft_list_query';
import aircraftEngineListQuery from '../queries/aircraft_engine_list_query';
import bookingAdminListQuery from '../queries/booking_admin_list_query';
import contactItemListQuery from '../queries/contact_item_list_query';
import flightTypeListQuery from '../queries/flight_type_list_query';
import fuelBowserListQuery from '../queries/fuel_bowser_list_query';
import fuelTankerListQuery from '../queries/fuel_tanker_list_query';
import locationListQuery from '../queries/location_min_list_query';

const bookingWhiteList = ['id'];

const flightSegmentWhiteList = [
  'end_location_id',
  'end_location_landing_fee',
  'id',
  'oncharge_end_location_landing_fee',
];

moment.updateLocale('en-nz');

class FlightSignoff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterAircraftId: this.props.currentSettingsReportAircraftId,
      filterAircraftIds: [],
      submitting: false,
    };
    this.submitting = false;
    this.handleDisplayAircraftIdChange = this.handleDisplayAircraftIdChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.startAtDate) {
      const date = moment(this.props.params.startAtDate, 'MM-YYYY');
      this.props.currentSettingsSet({
        reportStart: date.clone().startOf('day').toISOString(),
        reportEnd: date.clone().endOf('month').toISOString(),
      });
    }
    if (this.props.params.aircraftId) {
      this.handleDisplayAircraftIdChange({
        target: { value: this.props.params.aircraftId },
      });
    }
    if (this.isLoaded(this.props)) {
      this.receiveBookings(this.props);
    }
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    this.delayedHandleRefetch = _debounce((e) => {
      this.props.bookingListQuery.refetch();
    }, 250);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { filterAircraftIds } = this.state;
    if (!this.submitting && this.isLoaded(nextProps)) {
      filterAircraftIds = _uniq(
        nextProps.bookingListQuery.data.map((booking) => booking.aircraft_id)
      );
    }
    this.setState({
      filterAircraftIds,
    });
    let filterAircraftId = nextProps.currentSettingsReportAircraftId;
    if (filterAircraftId && this.isLoaded(nextProps)) {
      if (
        filterAircraftIds.length > 0 &&
        filterAircraftIds.indexOf(filterAircraftId) === -1
      ) {
        filterAircraftId = '';
      }
    }
    if (filterAircraftId !== this.props.currentSettingsReportAircraftId) {
      this.handleDisplayAircraftIdChange({ target: { value: filterAircraftId } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleRefetch.cancel();
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(
      props.bookingListQuery,
      props.aircraftListQuery,
      props.aircraftEngineListQuery,
      props.providerListQuery,
      props.pilotListQuery,
      props.chargeableListQuery,
      props.flightTypeListQuery,
      props.fuelBowserListQuery,
      props.fuelTankerListQuery,
      props.locationListQuery
    );
  }

  receiveBookings(props) {
    if (!this.submitting) {
      const filterAircraftIds = _uniq(
        props.bookingListQuery.data.map((booking) => booking.aircraft_id)
      );
      this.setState({
        filterAircraftIds,
      });
    }
  }

  handleDisplayAircraftIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value, 10))
      ? ''
      : parseInt(e.target.value, 10);
    this.setState({
      filterAircraftId: value,
    });
    this.props.currentSettingsSet({
      reportAircraftId: value,
    });
  }

  onSubmit(bookingId) {
    return (data) => {
      data.updated_by_admin_id = this.props.currentContactId;
      if (!data.admin_flight_record_attributes.admin_id) {
        data.admin_flight_record_attributes.admin_id = this.props.currentContactId;
      }
      return this.props
        .bookingUpdateMutation({
          variables: {
            id: bookingId,
            input: typeInput(data),
          },
        })
        .then((res) => {
          this.props.mutationSuccess('Booking signoff');
        })
        .catch((err) => this.props.mutationFailure(err, true));
    };
  }

  _pickInitialValues = (b) => {
    const bookingData = _cloneDeep(b);
    const booking = pickValues(bookingData, bookingWhiteList);
    booking.flight_segments_attributes = mapPickValues(
      _cloneDeep(bookingData.flightSegments),
      flightSegmentWhiteList
    );
    booking.admin_flight_record_attributes = omitValues(_get(b, 'adminFlightRecord'));
    booking.pilot_flight_expenses_attributes = mapOmitValues(
      _get(b, 'pilotFlightExpenses')
    );
    return booking;
  };

  _renderAircrafts() {
    return (
      <Row>
        <Col xs={12}>
          {this.state.filterAircraftIds.map((aircraftId) => {
            if (
              this.state.filterAircraftId &&
              aircraftId !== this.state.filterAircraftId
            ) {
              return false;
            }
            const bookings = this.props.bookingListQuery.data.filter(
              (booking) => booking.aircraft_id === aircraftId
            );
            const aircraftRegistrationAbbreviated = _get(
              this.props.aircraftsDataSelector,
              [aircraftId, 'registrationAbbreviated']
            );
            return (
              <Row key={aircraftId} style={{ marginBottom: '20px' }}>
                <Col xs={12}>
                  <h4>{aircraftRegistrationAbbreviated}</h4>
                  {bookings.map((booking) => (
                    <Row key={booking.id} style={{ marginBottom: '20px' }}>
                      <Col xs={12}>
                        <Panel>
                          <Panel.Body>
                            <FlightSignoffInfo
                              booking={booking}
                              chargeables={this.props.chargeableListQuery.data}
                              providers={this.props.providerListQuery.data}
                              pilots={this.props.pilotListQuery.data}
                              currentSettingsFuelBowserFillDefaultQuantityUnit={
                                this.props
                                  .currentSettingsFuelBowserFillDefaultQuantityUnit
                              }
                              contactsDataSelector={this.props.contactsDataSelector}
                              flightTypesDataSelector={this.props.flightTypesDataSelector}
                              fuelBowsersDataSelector={this.props.fuelBowsersDataSelector}
                              fuelTankersDataSelector={this.props.fuelTankersDataSelector}
                            />
                            <FlightSignoffForm
                              booking={booking}
                              form={`AdminSignOff-${booking.id}`}
                              enableReinitialize
                              keepDirtyOnReinitialize
                              initialValues={this._pickInitialValues(booking)}
                              onSubmit={this.onSubmit(booking.id)}
                              currentContactId={this.props.currentContactId}
                              locationsDataSelector={this.props.locationsDataSelector}
                              contactsDataSelector={this.props.contactsDataSelector}
                              currentSettingsPilotFlightExpenseOvernightText={
                                this.props.currentSettingsPilotFlightExpenseOvernightText
                              }
                            />
                          </Panel.Body>
                        </Panel>
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    );
  }

  _renderAircraftFilter() {
    const dataAircrafts = this.props.aircraftListQuery.data.filter(
      (model) => this.state.filterAircraftIds.indexOf(model.id) > -1
    );
    return (
      <FormControl
        componentClass="select"
        value={this.state.filterAircraftId}
        onChange={this.handleDisplayAircraftIdChange}
      >
        <option key={0} value="">
          All
        </option>
        {dataAircrafts.map((model) => {
          const { id, registration_abbreviated: registrationAbbreviated } = model;
          return (
            <option key={id} value={id}>
              {registrationAbbreviated}
            </option>
          );
        })}
      </FormControl>
    );
  }

  render() {
    if ((this.submitting && this.state.submitting) || this.isLoading()) {
      return <Loader />;
    }
    return (
      <div>
        <ReportHeader
          title="Admin Sign Off Report"
          start={this.props.currentSettingsReportStart}
          end={this.props.currentSettingsReportEnd}
        />
        <Row style={{ marginTop: '10px' }}>
          <Col xs={12}>
            <Form inline>
              <Button
                style={{ margin: '0', padding: '0 0 8px 0' }}
                bsStyle="link"
                bsSize="xsmall"
                onClick={this.delayedHandleRefetch}
              >
                <span className="glyphicon glyphicon-repeat" />
              </Button>
              <ReactDateTimeFilter
                currentSettingsReportStart={this.props.currentSettingsReportStart}
                currentSettingsReportEnd={this.props.currentSettingsReportEnd}
                onChange={this.props.currentSettingsSet}
              />
              <FormGroup
                style={{ marginLeft: '16px', paddingBottom: '10px' }}
                bsSize="sm"
                controlId="filterId"
              >
                {this._renderAircraftFilter()}
              </FormGroup>
            </Form>
          </Col>
        </Row>
        {this._renderAircrafts()}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    currentSettingsPilotFlightExpenseOvernightText:
      state.currentSettings.pilot_flight_expense_overnight_text,
    currentSettingsFuelBowserFillDefaultQuantityUnit:
      state.currentSettings.fuel_bowser_fill_default_quantity_unit,
    currentSettingsReportStart: state.currentSettings.reportStart,
    currentSettingsReportEnd: state.currentSettings.reportEnd,
    currentSettingsReportAircraftId: state.currentSettings.reportAircraftId,
    currentContactId: state.currentContact.id,
    aircraftsDataSelector: aircraftsData(props),
    contactsDataSelector: contactsData(props),
    flightTypesDataSelector: flightTypesData(props),
    fuelBowsersDataSelector: fuelBowsersData(props),
    fuelTankersDataSelector: fuelTankersData(props),
    locationsDataSelector: locationsData(props),
  };
}

export default compose(
  graphql(bookingUpdateMutation, {
    name: 'bookingUpdateMutation',
  }),
  graphql(aircraftListQuery, {
    name: 'aircraftListQuery',
  }),
  graphql(aircraftEngineListQuery, {
    name: 'aircraftEngineListQuery',
  }),
  graphql(contactItemListQuery, {
    name: 'chargeableListQuery',
    options: { variables: { role: 'chargeable' } },
  }),
  graphql(contactItemListQuery, {
    name: 'pilotListQuery',
    options: { variables: { role: 'pilot' } },
  }),
  graphql(contactItemListQuery, {
    name: 'providerListQuery',
    options: { variables: { role: 'provider' } },
  }),
  graphql(flightTypeListQuery, {
    name: 'flightTypeListQuery',
  }),
  graphql(fuelBowserListQuery, {
    name: 'fuelBowserListQuery',
  }),
  graphql(fuelTankerListQuery, {
    name: 'fuelTankerListQuery',
  }),
  graphql(locationListQuery, {
    name: 'locationListQuery',
  }),
  connect(mapStateToProps, {
    currentSettingsSet,
    mutationSuccess,
    mutationFailure,
  }),
  graphql(bookingAdminListQuery, {
    name: 'bookingListQuery',
    options: (props) => ({
      variables: {
        start_at: props.currentSettingsReportStart,
        end_at: props.currentSettingsReportEnd,
      },
    }),
  })
)(FlightSignoff);
