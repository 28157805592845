import { Modal, Button } from 'react-bootstrap';
import { Component } from 'react';
import L from 'leaflet';

import _merge from 'lodash/merge';

const MAPBOX_API_TOKEN =
  'pk.eyJ1IjoiZ29yZG9ua2luZzAyIiwiYSI6ImNpbnUxbWJ3NjExZ2x1aWtqaThraGM3dmsifQ.rKb2iQMCm5ZPhJGfsSg_mg';

const baseLayerTemplates = [
  {
    name: 'Mapbox - Satellite Streets',
    maptype: 'mapbox/satellite-streets-v11',
    url: `https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${MAPBOX_API_TOKEN}`,
    attribution:
      '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  },
  {
    name: 'Mapbox - Street',
    maptype: 'mapbox/streets-v11',
    url: `https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${MAPBOX_API_TOKEN}`,
    attribution:
      '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  },
  {
    name: 'Mapbox - Satellite',
    maptype: 'mapbox/satellite-v9',
    url: `https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${MAPBOX_API_TOKEN}`,
    attribution:
      '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  },
];

class FlightSegmentLocationAddModal extends Component {
  constructor(props) {
    super(props);

    this._mountMap = this._mountMap.bind(this);
    this._handleMarkerDragend = this._handleMarkerDragend.bind(this);
    this._handleMapZoomend = this._handleMapZoomend.bind(this);

    this._handleAddLocationCancel = this._handleAddLocationCancel.bind(this);
    this._handleAddLocationSubmit = this._handleAddLocationSubmit.bind(this);

    this.map = null;
    this.marker = null;
    this.position = [-36.8484, 174.7622];
    this.zoom = 14;
  }

  createBaseLayer = (baseLayer) => {
    const { maptype, url, attribution } = baseLayer;
    const element = L.tileLayer(url, {
      attribution,
      id: maptype,
      accessToken: MAPBOX_API_TOKEN,
      tileSize: 512,
      zoomOffset: -1,
    });
    return Object.assign({}, baseLayer, { element });
  };

  _resetMap() {
    this.position = [-36.8484, 174.7622];
    this.zoom = 14;
  }

  _handleAddLocationCancel() {
    this._resetMap();
    this._setMap();
    if (this.props.handleAddLocationCancel) {
      this.props.handleAddLocationCancel();
    }
  }

  _handleAddLocationSubmit() {
    if (this.props.handleAddLocationSubmit) {
      this.props.handleAddLocationSubmit(...this.position);
    }
    this._resetMap();
    this._setMap();
  }

  _renderAddLocationTitle() {
    return `Select Lat Lng of ${this.props.label} by setting the map marker`;
  }

  _setMap() {
    if (this.map) {
      this.map.setView(this.position, this.zoom);
      this.marker.setLatLng(this.position);
    }
  }

  _mountMap(ref) {
    if (ref) {
      if (!this.map && this.props.show) {
        this.map = L.map('location-flight-segment-add-map', {
          zoom: this.zoom,
          center: this.position,
        }).on('zoomend', this._handleMapZoomend);

        this.baseLayers = baseLayerTemplates.map((baseLayer) =>
          this.createBaseLayer(baseLayer)
        );
        const baseLayersMenu = this.baseLayers.reduce(
          (result, layer) => _merge({}, result, { [layer.name]: layer.element }),
          {}
        );
        L.control.layers(baseLayersMenu, {}, { position: 'topright' }).addTo(this.map);
        baseLayersMenu[this.baseLayers[0].name].addTo(this.map);

        this.marker = L.marker(this.position, { draggable: true })
          .on('dragend', this._handleMarkerDragend)
          .addTo(this.map);
      }
    } else {
      if (this.map) {
        this.map.remove();
      }
      this.map = null;
      this.marker = null;
    }
  }

  _handleMarkerDragend(e) {
    const { lat, lng } = e.target.getLatLng();
    this.position = [lat, lng];
    this._setMap();
  }

  _handleMapZoomend(e) {
    this.zoom = e.target.getZoom();
    this._setMap();
  }

  render() {
    const { show, index } = this.props;

    return (
      <Modal
        id={`booking-flight-segment-${index}-add-location-modal`}
        show={show}
        onHide={this._handleAddLocationCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this._renderAddLocationTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            id="location-flight-segment-add-map"
            style={{ height: '500px' }}
            ref={(input) => {
              this._mountMap(input);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" bsStyle="danger" onClick={this._handleAddLocationCancel}>
            Cancel
          </Button>
          <Button type="button" bsStyle="primary" onClick={this._handleAddLocationSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default FlightSegmentLocationAddModal;
