import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import {
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Glyphicon,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import {
  reduxForm,
  Field,
  Fields,
  FieldArray,
  getFormValues,
  arrayPush as pushArrayValue,
  arrayRemove as removeArrayValue,
  change as changeFieldValue,
} from 'redux-form';
import moment from 'moment';

import _clone from 'lodash/clone';
import _cloneDeep from 'lodash/cloneDeep';
import _forEach from 'lodash/forEach';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _omitBy from 'lodash/omitBy';
import _pick from 'lodash/pick';

import InputField from '../components/form/input_field';
import ReactDateTimeField from '../components/form/react_date_time_field';
import DocumentFields from '../components/form/document_fields';
import Confirm from '../components/confirm';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import checkQuery from '../queries/check_query';
import checkUpdateMutation from '../mutations/check_update_mutation';
import checkCreateMutation from '../mutations/check_create_mutation';
import checkTypeListQuery from '../queries/check_type_list_query';
import checkCategoryListQuery from '../queries/check_category_list_query';
import otherAssetListQuery from '../queries/other_asset_list_query';
import contactItemListQuery from '../queries/contact_item_list_query';

import { queriesReady, queryReady, typeInput } from '../lib/utils';
import Loader from '../components/loader';

moment.updateLocale('en-nz');

let isInitialisedCheckForm = false;

class CheckForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: !!this.props.params.id,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCompleteSubmit = this.handleCompleteSubmit.bind(this);
    this.handleRolloverSubmit = this.handleRolloverSubmit.bind(this);
    this.handleDocumentUpload = this.handleDocumentUpload.bind(this);
    this.handleNewDocumentClick = this.handleNewDocumentClick.bind(this);
    this.handleDeleteDocumentClick = this.handleDeleteDocumentClick.bind(this);
    this.mapCheckableOptions = this.mapCheckableOptions.bind(this);
    this.renderDocumentFieldArray = this.renderDocumentFieldArray.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      _get(this.props, 'formValues.check_category_id') &&
      nextProps.formValues.check_category_id !== this.props.formValues.check_category_id
    ) {
      if (nextProps.formValues.check_type_id) {
        this.props.changeFieldValue(this.props.form, 'check_type_id', '');
      }
      if (nextProps.formValues.checkable_id) {
        this.props.changeFieldValue(this.props.form, 'checkable_id', '');
      }
    }
  }

  componentWillUnmount() {
    isInitialisedCheckForm = false;
  }

  displayBoolean = (attribute) => {
    if (attribute) {
      return 'Yes';
    } else {
      return 'No';
    }
  };

  handleSubmit(data) {
    const submitData = _cloneDeep(data);
    _forEach(submitData.documents_attributes, (da) => {
      if (da.document) {
        // eslint-disable-next-line no-param-reassign
        da.uploader_id = this.props.currentContact.id;
        // eslint-disable-next-line no-param-reassign
        da.uploaded_at = moment().format();
      }
    });
    const checkCategoryIdValue = submitData.check_category_id;
    if (checkCategoryIdValue) {
      const checkCategory = this.props.checkCategoryListQuery.data.find(
        (model) => model.id === parseInt(checkCategoryIdValue, 10)
      );
      submitData.checkable_type =
        this.props.currentSettingsCheckCategoryCheckableGroups[
          checkCategory.checkable_group
        ];
    }
    this.props.mutationSet(true);
    let mutation;
    let mutationMessage;
    const mutationData = {
      variables: { input: typeInput(submitData) },
      context: { hasUpload: true },
    };
    if (this.state.updating) {
      mutation = this.props.checkUpdateMutation;
      mutationMessage = 'Check update';
      mutationData.variables.id = this.props.params.id;
    } else {
      mutation = this.props.checkCreateMutation;
      mutationMessage = 'Check create';
    }
    return mutation(mutationData)
      .then(() => {
        this.props.mutationSuccess(mutationMessage);
        this.props.navigate('/checks');
      })
      .catch((err) => this.props.mutationFailure(err, true));
  }

  handleCompleteSubmit(data) {
    const submitData = _cloneDeep(data);
    const today = new Date();
    submitData.check_complete_on = today.toISOString().substring(0, 10);
    this.handleSubmit(submitData);
  }

  handleRolloverSubmit(data) {
    const submitData = _cloneDeep(data);
    submitData.rollover = true;
    this.handleCompleteSubmit(submitData);
  }

  handleNewDocumentClick() {
    this.props.pushArrayValue(this.props.form, 'documents_attributes', {});
  }

  handleDeleteDocumentClick(index, id) {
    if (id) {
      this.props.changeFieldValue(
        this.props.form,
        `documents_attributes[${index}]._destroy`,
        true
      );
    } else {
      this.props.removeArrayValue(this.props.form, 'documents_attributes', index);
    }
  }

  handleDocumentUpload(index, file) {
    this.props.changeFieldValue(
      this.props.form,
      `documents_attributes[${index}].document`,
      file
    );
  }

  mapCheckableOptions() {
    const checkCategoryIdValue = this.props.formValues.check_category_id;
    if (checkCategoryIdValue) {
      const { checkable_group: checkableGroup } =
        this.props.checkCategoryListQuery.data.find(
          (model) => model.id === parseInt(checkCategoryIdValue, 10)
        );
      if (checkableGroup === 'pilots') {
        return this.props.pilotListQuery.data.map((model) => ({
          id: model.id,
          name: model.fullName,
        }));
      } else if (checkableGroup === 'employees') {
        return this.props.employeeListQuery.data.map((model) => ({
          id: model.id,
          name: model.fullName,
        }));
      } else if (checkableGroup === 'passengers') {
        return this.props.passengerListQuery.data.map((model) => ({
          id: model.id,
          name: model.fullName,
        }));
      } else if (checkableGroup === 'equipment') {
        return this.props.otherAssetListQuery.data.map((model) => ({
          id: model.id,
          name: model.name,
        }));
      } else if (checkableGroup === 'tenant') {
        return [
          { id: this.props.currentTenant.id, name: this.props.currentTenant.fullName },
        ];
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(
      testProps.checkTypeListQuery,
      testProps.checkCategoryListQuery,
      testProps.otherAssetListQuery,
      testProps.pilotListQuery,
      testProps.managerListQuery,
      testProps.employeeListQuery,
      testProps.passengerListQuery,
      [testProps.checkQuery, true] // ignore if undefined
    );
  }

  renderTitle() {
    if (this.state.updating) {
      return 'Edit Check';
    } else {
      return 'New Check';
    }
  }

  renderDocumentFieldArray(documents) {
    return (
      <div>
        {documents.fields.map((field, index) => {
          // eslint-disable-next-line no-underscore-dangle
          if (!this.props.formValues.documents_attributes[index]._destroy) {
            const values = this.props.formValues.documents_attributes[index] || {};
            return (
              <Fields
                key={field}
                index={index}
                values={values}
                names={[`${field}.document`, `${field}.description`]}
                component={DocumentFields}
                document_file_extension={
                  values.document_file_name
                    ? values.document_file_name.split('.').pop()
                    : 'unknown'
                }
                onDeleteClicked={this.handleDeleteDocumentClick}
                onUpload={this.handleDocumentUpload}
              />
            );
          }
          return undefined;
        })}
        <Col smOffset={3} sm={9}>
          <Button type="button" bsStyle="default" onClick={this.handleNewDocumentClick}>
            <Glyphicon glyph="plus" /> Add Document
          </Button>
        </Col>
      </div>
    );
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (
      isInitialisedCheckForm &&
      this.isLoaded() &&
      _get(this.props, 'formValues') &&
      (!this.state.updating || _get(this.props, 'formValues.id'))
    ) {
      const { handleSubmit, pristine, submitting, formValues } = this.props;

      const checkTypeId = this.props.formValues.check_type_id;
      let checkType = {
        critical: false,
        lead_period_days: '',
        rollover_period_months: '',
      };
      if (checkTypeId) {
        checkType = this.props.checkTypeListQuery.data.find(
          (model) => model.id === parseInt(checkTypeId, 10)
        );
      }
      const {
        critical,
        rollover_period_months: rolloverPeriodMonths,
        lead_period_days: leadPeriodDays,
      } = checkType;

      const completed = !!this.props.formValues.check_complete_on;

      return (
        <div>
          <Form horizontal onSubmit={handleSubmit(this.handleSubmit)}>
            <h2>{this.renderTitle()}</h2>
            <fieldset>
              <legend>Check Details</legend>
              <Field
                type="text"
                name="check_category_id"
                inputWidth={3}
                component={InputField}
                componentClass="select"
                selectOptions={this.props.checkCategoryListQuery.data.map((model) => ({
                  id: model.id,
                  name: model.name,
                }))}
              >
                Category
              </Field>
              <Field
                type="text"
                name="check_type_id"
                inputWidth={3}
                component={InputField}
                componentClass="select"
                selectOptions={this.props.checkTypeListQuery.data
                  .filter(
                    (model) =>
                      model.check_category_id ===
                      parseInt(formValues.check_category_id, 10)
                  )
                  .map((model) => ({ id: model.id, name: model.name }))}
              >
                Type
              </Field>
              <Field
                type="text"
                name="checkable_id"
                inputWidth={3}
                component={InputField}
                componentClass="select"
                selectOptions={this.mapCheckableOptions()}
              >
                Checkable
              </Field>
              <Field
                type="text"
                name="manager_id"
                inputWidth={3}
                component={InputField}
                componentClass="select"
                selectOptions={this.props.managerListQuery.data.map((model) => ({
                  id: model.id,
                  name: model.fullName,
                }))}
              >
                Responsible Manager
              </Field>
              <Field
                name="check_end_on"
                inputWidth={3}
                component={ReactDateTimeField}
                helpText="DD/MM/YYYY"
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                closeOnSelect
              >
                Check Due On
              </Field>
              {this.state.updating && (
                <FormGroup>
                  <Col componentClass={ControlLabel} sm={3}>
                    Critical
                  </Col>
                  <Col sm={3}>
                    <FormControl.Static>
                      {this.displayBoolean(critical)}
                    </FormControl.Static>
                  </Col>
                </FormGroup>
              )}
              {this.state.updating && (
                <FormGroup>
                  <Col componentClass={ControlLabel} sm={3}>
                    Rollover Period Months
                  </Col>
                  <Col sm={3}>
                    <FormControl.Static>{rolloverPeriodMonths}</FormControl.Static>
                  </Col>
                </FormGroup>
              )}
              {this.state.updating && (
                <FormGroup>
                  <Col componentClass={ControlLabel} sm={3}>
                    Lead Period Days
                  </Col>
                  <Col sm={3}>
                    <FormControl.Static>{leadPeriodDays}</FormControl.Static>
                  </Col>
                </FormGroup>
              )}
            </fieldset>
            <fieldset>
              <legend>Manage Documents</legend>
              <FieldArray
                name="documents_attributes"
                component={this.renderDocumentFieldArray}
              />
            </fieldset>
            <FormGroup>
              <Col sm={12}>
                <ButtonToolbar className="pull-right">
                  <ButtonGroup>
                    <LinkContainer to="/checks">
                      <Button type="reset" bsStyle="danger" disabled={submitting}>
                        Cancel
                      </Button>
                    </LinkContainer>
                  </ButtonGroup>
                  <ButtonGroup>
                    {this.state.updating && (
                      <Confirm
                        disabled={completed}
                        onConfirm={handleSubmit(this.handleCompleteSubmit)}
                        title="Complete Check"
                        body="Are you sure you want to complete this check? It will be marked completed but NOT rolled over "
                        confirmText="Complete"
                      >
                        <Button type="button" bsStyle="primary" disabled={submitting}>
                          Complete
                        </Button>
                      </Confirm>
                    )}
                    {this.state.updating && (
                      <Confirm
                        disabled={completed}
                        onConfirm={handleSubmit(this.handleRolloverSubmit)}
                        title="Rollover Check"
                        body="Are you sure you want to rollover this check? It will be marked completed AND rolled over "
                        confirmText="Rollover"
                      >
                        <Button type="button" bsStyle="primary" disabled={submitting}>
                          Rollover
                        </Button>
                      </Confirm>
                    )}
                  </ButtonGroup>
                  <ButtonGroup>
                    <Button
                      type="submit"
                      bsStyle="primary"
                      disabled={pristine || submitting}
                    >
                      {submitting ? (
                        <span className="glyphicon glyphicon-refresh glyphicon-spin" />
                      ) : (
                        ''
                      )}{' '}
                      {this.state.updating ? 'Update' : 'Create'}
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </Col>
            </FormGroup>
          </Form>
        </div>
      );
    }
    return undefined;
  }

  render() {
    return (
      <div>
        {this.renderOverlay()}
        {this.renderData()}
      </div>
    );
  }
}

function validate() {
  return {};
}

const checkWhiteList = [
  'id',
  'check_type_id',
  'check_category_id',
  'checkable_type',
  'checkable_id',
  'manager_id',
  'check_start_on',
  'check_end_on',
  'check_complete_on',
];

const documentWhiteList = ['id', 'document_file_name', 'description'];

function pickInitialValues(checkQueryInitial, updating) {
  if (!isInitialisedCheckForm) {
    if (!updating) {
      isInitialisedCheckForm = true;
      return {
        check_end_on: moment().add(1, 'months').format('YYYY-MM-DD'),
      };
    } else if (queryReady(checkQueryInitial)) {
      isInitialisedCheckForm = true;
      const check = _pick(
        _omitBy(_cloneDeep(checkQueryInitial.data), _isNil),
        checkWhiteList
      );
      check.documents_attributes = _clone(
        _get(checkQueryInitial, 'data.documents', [])
      ).map((doc) => _pick(_omitBy(doc, _isNil), documentWhiteList));
      return check;
    }
  }
  return undefined;
}

function mapStateToProps(state, props) {
  const initialValues = pickInitialValues(props.checkQuery, !!props.params.id);
  return {
    initialValues,
    currentTenant: state.currentTenant,
    currentContact: state.currentContact,
    // eslint-disable-next-line max-len
    currentSettingsCheckCategoryCheckableGroups:
      state.currentSettings.check_category_checkable_groups,
    currentSettingsMutating: state.currentSettings.mutating,
    formValues: getFormValues('CheckForm')(state),
  };
}

export default compose(
  graphql(checkCreateMutation, {
    name: 'checkCreateMutation',
  }),
  graphql(checkUpdateMutation, {
    name: 'checkUpdateMutation',
  }),
  graphql(contactItemListQuery, {
    name: 'managerListQuery',
    options: { variables: { role: 'manager' } },
  }),
  graphql(contactItemListQuery, {
    name: 'pilotListQuery',
    options: { variables: { role: 'pilot' } },
  }),
  graphql(contactItemListQuery, {
    name: 'employeeListQuery',
    options: { variables: { role: 'employee' } },
  }),
  graphql(contactItemListQuery, {
    name: 'passengerListQuery',
    options: { variables: { role: 'passenger' } },
  }),
  graphql(checkTypeListQuery, {
    name: 'checkTypeListQuery',
  }),
  graphql(checkCategoryListQuery, {
    name: 'checkCategoryListQuery',
  }),
  graphql(otherAssetListQuery, {
    name: 'otherAssetListQuery',
  }),
  graphql(checkQuery, {
    name: 'checkQuery',
    skip: (props) => !props.params.id,
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'network-only',
    }),
  }),
  connect(mapStateToProps, {
    changeFieldValue,
    pushArrayValue,
    removeArrayValue,
    mutationSuccess,
    mutationFailure,
    mutationSet,
  }),
  reduxForm({
    form: 'CheckForm',
    // enableReinitialize: true,
    // keepDirtyOnReinitialize: true,
    validate,
  })
)(CheckForm);
