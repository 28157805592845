import get from 'lodash/get';
import defaultTo from 'lodash/defaultTo';
import memoize from 'lodash/memoize';

import { BookingWeightDataMemo } from './booking_weight_data_memo';

export const FlightSegmentWeightDataMemo = memoize(
  ({
    luggageWeight,
    paxWeight,
    pilotId,
    copilotId,
    pilots,
    aircraftId,
    aircrafts,
    aircraftTypes,
  }) => {
    if (aircraftId) {
      const bookingWeightData = BookingWeightDataMemo({
        pilotId,
        copilotId,
        pilots,
        aircraftId,
        aircrafts,
        aircraftTypes,
      });

      const defaultLuggageWeight = defaultTo(parseInt(luggageWeight), 0);
      const defaultPaxWeight = defaultTo(parseInt(paxWeight), 0);

      const weightData = Object.assign({}, bookingWeightData, {
        luggageWeight: defaultLuggageWeight,
        paxWeight: defaultPaxWeight,
        cabinWeight: defaultLuggageWeight + defaultPaxWeight,
      });
      weightData.aircraftNetWeight =
        get(weightData, 'aircraftGrossWeight', 0) - get(weightData, 'cabinWeight', 0);
      return weightData;
    } else {
      return {};
    }
  },
  ({ luggageWeight, paxWeight, aircraftId, pilotId, copilotId }) => {
    if (aircraftId) {
      return [aircraftId, pilotId, copilotId, luggageWeight, paxWeight]
        .map((i) => String(i))
        .join('.');
    } else {
      return 'unknown';
    }
  }
);
