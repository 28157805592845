import { Row } from 'react-bootstrap';
import { Component } from 'react';
import { Fields } from 'redux-form';

import get from 'lodash/get';

import FlightSignoffFormPilotFlightExpenseFields from './flight_signoff_form_pilot_flight_expense_fields';

class FlightSignoffFormPilotFlightExpenses extends Component {
  render() {
    return (
      <Row>
        {this.props.pilotFlightExpenses.fields.map((field, index) => {
          const values = get(this.props, `formValues.${field}`, {});
          if (!values._destroy) {
            const fields = [
              `${field}.notes`,
              `${field}.total`,
              `${field}.includes_gst`,
              `${field}.onchargeable`,
              `${field}.reimbursable`,
            ];
            return (
              <Fields
                key={`pfe-${index}`}
                index={index}
                parentField={field}
                names={fields}
                component={FlightSignoffFormPilotFlightExpenseFields}
                formValues={values}
                overNight={
                  this.props.currentSettingsPilotFlightExpenseOvernightText ===
                  values.notes
                }
                contactsDataSelector={this.props.contactsDataSelector}
              />
            );
          }
        })}
      </Row>
    );
  }
}

export default FlightSignoffFormPilotFlightExpenses;
