import { Modal } from 'react-bootstrap';
import { Component } from 'react';

import SmsRiskCreateFormModal from './sms_risk_create_form_modal';

class SmsRiskCreateModal extends Component {
  constructor(props) {
    super(props);
    this.handleCreateCancel = this.handleCreateCancel.bind(this);
    this.handleCreateSubmit = this.handleCreateSubmit.bind(this);
  }

  handleCreateSubmit(data) {
    if (this.props.handleCreateSubmit) {
      this.props.handleCreateSubmit(data);
    }
  }

  handleCreateCancel() {
    if (this.props.handleCreateCancel) {
      this.props.handleCreateCancel();
    }
  }

  render() {
    return (
      <Modal show={this.props.isCreating} onHide={this.handleCreateCancel} bsSize="large">
        <Modal.Header closeButton>
          <Modal.Title>Create New Risk</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SmsRiskCreateFormModal
            form="SmsRiskCreateFormModal"
            enableReinitialize
            initialValues={{
              description: '',
              consequence: 'moderate',
              likelihood: 'remote',
              riskGroup: 'medium',
              riskLevel: '3C',
            }}
            handleCreateSubmit={this.handleCreateSubmit}
            handleCreateCancel={this.handleCreateCancel}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

export default SmsRiskCreateModal;
