import get from 'lodash/get';
import memoize from 'lodash/memoize';

export const BookingPassengerSeatsMemo = memoize(
  ({
    aircraftId,
    aircraftConfigurationId,
    copilotId,
    aircrafts,
    aircraftTypes,
    aircraftConfigurations,
  }) => {
    if (aircraftId) {
      const aircraft = aircrafts.find((a) => a.id === parseInt(aircraftId));
      const aircraftType = aircraftTypes.find(
        (at) => at.id === get(aircraft, 'aircraft_type_id')
      );
      const aircraftTypeNumberOfPassengerSeats = get(
        aircraftType,
        'number_of_passenger_seats',
        0
      );

      let aircraftConfigurationSeatReduction = 0;

      if (aircraftConfigurationId) {
        const aircraftConfiguration = aircraftConfigurations.find(
          (ac) => ac.id === parseInt(aircraftConfigurationId)
        );
        aircraftConfigurationSeatReduction = get(
          aircraftConfiguration,
          'seat_reduction',
          0
        );
      }
      const passengerSeats =
        aircraftTypeNumberOfPassengerSeats -
        aircraftConfigurationSeatReduction -
        (copilotId ? 1 : 0);
      return Math.max(passengerSeats, 0);
    } else {
      return 0;
    }
  },
  ({ aircraftId, aircraftConfigurationId, copilotId }) => {
    if (aircraftId) {
      return [aircraftId, aircraftConfigurationId, !!copilotId]
        .map((i) => String(i))
        .join('.');
    } else {
      return 'unknown';
    }
  }
);
