import get from 'lodash/get';
import includes from 'lodash/includes';
import memoize from 'lodash/memoize';

import { LocationFuelDataMemo } from './location_fuel_data_memo';
import { AircraftFuelDataMemo } from './aircraft_fuel_data_memo';

export const FlightSegmentFuelDataMemo = memoize(
  ({
    aircraftId,
    aircrafts,
    aircraftTypes,
    fuelTypes,
    locationId,
    fuelBowsers,
    fuelTankers,
  }) => {
    if (aircraftId && locationId) {
      const locationFuelData = LocationFuelDataMemo({
        locationId,
        fuelBowsers,
        fuelTankers,
      });
      const aircraftFuelData = AircraftFuelDataMemo({
        aircraftId,
        aircrafts,
        aircraftTypes,
        fuelTypes,
      });
      const aircraftTypeFuelTypeId = get(aircraftFuelData, 'aircraftTypeFuelTypeId');
      const flightSegmentFuelBowsers = get(
        locationFuelData,
        'locationBowsers',
        []
      ).filter((fb) => fb.fuel_type_id === aircraftTypeFuelTypeId);
      const flightSegmentFuelTankers = get(
        locationFuelData,
        'locationTankers',
        []
      ).filter((ft) => ft.fuel_type_id === aircraftTypeFuelTypeId);
      const flightSegmentFuelData = Object.assign({}, aircraftFuelData, {
        flightSegmentFuelBowsers,
        flightSegmentFuelTankers,
        locationId,
      });
      return flightSegmentFuelData;
    } else {
      return {};
    }
  },
  ({ locationId, aircraftId }) => {
    if (locationId && aircraftId) {
      return [locationId, aircraftId].map((i) => String(i)).join('.');
    } else {
      return 'unknown';
    }
  }
);
