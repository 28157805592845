import { Col, FormGroup, FormControl, ControlLabel, HelpBlock } from 'react-bootstrap';
import { Component } from 'react';

import defaultTo from 'lodash/defaultTo';
import get from 'lodash/get';
import { validationState, validationText } from '../form/helpers';

class AircraftConfigurationInputField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectOptions: [],
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.aircraft_id) {
      this.setState({
        selectOptions: this.getSelectOptions(),
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.aircraft_id !== nextProps.aircraft_id) {
      const aircraftConfigurationId = defaultTo(
        parseInt(nextProps.aircraft_configuration_id, 10),
        ''
      );
      const aircraftId = defaultTo(parseInt(nextProps.aircraft_id, 10), '');
      let newAircraftConfigurationId;
      let selectOptions;
      if (aircraftId) {
        selectOptions = this.getSelectOptions(nextProps);
        const aircraft = this.props.aircrafts.find((c) => c.id === aircraftId);
        newAircraftConfigurationId = get(
          aircraft,
          'default_aircraft_configuration_id',
          ''
        );
        const aircraftType = this.props.aircraftTypes.find(
          (at) => at.id === get(aircraft, 'aircraft_type_id')
        );
        nextProps.change(
          'cruise_airspeed_sl_knots',
          get(aircraftType, 'cruise_airspeed_sl_knots', '')
        );
        nextProps.change(
          'cruise_fuel_consumption_sl_kg',
          get(aircraftType, 'cruise_fuel_consumption_sl_kg', '')
        );
        nextProps.change(
          'company_planned_reserve',
          get(aircraftType, 'company_planned_reserve', '')
        );
      } else {
        selectOptions = [];
        newAircraftConfigurationId = '';
        nextProps.change('cruise_airspeed_sl_knots', '');
        nextProps.change('cruise_fuel_consumption_sl_kg', '');
        nextProps.change('company_planned_reserve', '');
      }
      this.setState({
        selectOptions,
      });
      if (newAircraftConfigurationId !== aircraftConfigurationId) {
        this.props.input.onChange(newAircraftConfigurationId);
      }
    }
  }

  getSelectOptions(props = this.props) {
    return props.aircraftConfigurations.filter(
      (ac) => ac.aircraft_id === defaultTo(parseInt(props.aircraft_id, 10), '')
    );
  }

  renderFormControl() {
    const {
      noTab,
      placeholder,
      type,
      input,
      inputStyle,
      componentClass,
      defaultSelectOption,
      rows,
    } = this.props;

    const commonProps = {
      type,
      value: input.value,
      ...(placeholder && { placeholder }),
      ...(componentClass && { componentClass }),
      ...(rows && { rows }),
      ...(noTab && { tabIndex: -1 }),
      ...(inputStyle && { style: inputStyle }),
    };

    return (
      <FormControl {...commonProps} onChange={input.onChange}>
        {defaultSelectOption ? (
          <option key={0} value="">
            {}
          </option>
        ) : (
          ''
        )}
        {this.state.selectOptions.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </FormControl>
    );
  }

  render() {
    const {
      input,
      meta,
      bsSize,
      groupStyle,
      labelWidth,
      inputWidth,
      helpText,
      helpBlock,
    } = this.props;

    return (
      <FormGroup
        controlId={input.name}
        validationState={validationState(meta)}
        {...(bsSize && { bsSize })}
        {...(groupStyle && { style: groupStyle })}
      >
        {this.props.labelWidth > 0 && (
          <Col componentClass={ControlLabel} sm={labelWidth}>
            {this.props.children}
          </Col>
        )}
        <Col sm={inputWidth}>
          {this.renderFormControl()}
          {helpBlock && <HelpBlock>{validationText(meta, helpText)}</HelpBlock>}
        </Col>
      </FormGroup>
    );
  }
}

AircraftConfigurationInputField.defaultProps = {
  bsSize: 'sm',
  labelWidth: 4,
  inputWidth: 8,
  defaultSelectOption: true,
  helpText: '',
  helpBlock: true,
  noTab: true,
};

export default AircraftConfigurationInputField;
