import { useEffect, useRef } from 'react';
import moment from 'moment';
import { useDeepCompareEffect } from 'react-use';

import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import momentPlugin from '@fullcalendar/moment';

import renderEventContent from './booking_manager_calendar_render';
import renderEventPopover from './booking_manager_calendar_popover';
import getResources from './booking_manager_calendar_resources';
import getEvents from './booking_manager_calendar_events';

moment.updateLocale('en-nz');

const BookingManagerCalendar = ({
  aircraftGroup,
  currentSettingsBookingCollectionView,
  currentSettingsBookingCollectionStartDate,
  currentSettingsBookingCollectionEndDate,
  currentSettingsBookingCalendarTypeFlight,
  bookings,
  onEventClicked,
  onDisplayDateChanged,
}) => {
  const calendarRef = useRef();
  const calendarDomRef = useRef();
  const events = useRef([]);
  const resources = useRef([]);

  const dateClicked = useRef((info) => {
    if (onDisplayDateChanged) {
      const {
        dateStr,
        view: { type: viewType },
      } = info;
      if (viewType !== 'resourceTimelineDay') {
        const startCalendar = moment(dateStr).startOf('day').format();
        const endCalendar = moment(dateStr).endOf('day').format();
        onDisplayDateChanged(startCalendar, endCalendar, 'resourceTimelineDay');
      }
    }
  });

  const eventClicked = useRef((info) => {
    if (onEventClicked) {
      const {
        event: {
          extendedProps: { bookingId, adminComplete, isFlight },
        },
      } = info;
      if (bookingId) {
        onEventClicked(bookingId, adminComplete, isFlight);
      }
    }
  });

  useEffect(() => {
    const calendar = new Calendar(calendarDomRef.current, {
      // customButtons: {
      //   aircraftGroup: {
      //     text: aircraftGroup.name,
      //   },
      // },
      initialView: currentSettingsBookingCollectionView,
      initialDate: currentSettingsBookingCollectionStartDate,
      height: 'auto',
      plugins: [
        interactionPlugin,
        momentPlugin,
        dayGridPlugin,
        timeGridPlugin,
        resourceTimelinePlugin,
      ],
      schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
      timeZone: 'local',
      locale: 'en-nz',
      // headerToolbar: {
      //   left: '',
      //   center: 'title',
      //   right: '',
      // },
      headerToolbar: false,
      firstDay: 1,
      events: (fetchInfo, callback) => callback(events.current),
      resources: (fetchInfo, callback) => callback(resources.current),
      views: {
        resourceTimelineDay: {
          titleFormat: 'ddd, D MMMM YYYY',
        },
        timeGridWeek: {
          allDaySlot: true,
          scrollTime: '06:00:00',
        },
      },
      scrollTime: '06:00:00',
      resourceOrder: 'extendedProps.position',
      resourceAreaWidth: '150px',
      dateClick: (info) => dateClicked.current(info),
      eventClick: (info) => eventClicked.current(info),
      eventContent: (info) => renderEventContent(info),
      eventDidMount: (info) => renderEventPopover(info),
    });
    calendarRef.current = calendar;
    calendar.render();
    return () => calendar.destroy();
    // view and start date controlled via imperative methods
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDeepCompareEffect(() => {
    const calendarApi = calendarRef.current;
    resources.current = getResources(bookings, aircraftGroup);
    events.current = getEvents(
      bookings,
      aircraftGroup,
      currentSettingsBookingCollectionStartDate,
      currentSettingsBookingCollectionEndDate,
      currentSettingsBookingCollectionView,
      currentSettingsBookingCalendarTypeFlight
    );
    calendarApi.batchRendering(() => {
      calendarApi.changeView(
        currentSettingsBookingCollectionView,
        currentSettingsBookingCollectionStartDate
      );
      calendarApi.refetchResources();
      calendarApi.refetchEvents();
    });
  }, [
    currentSettingsBookingCollectionStartDate,
    currentSettingsBookingCollectionEndDate,
    currentSettingsBookingCollectionView,
    currentSettingsBookingCalendarTypeFlight,
    bookings,
  ]);

  return (
    <div>
      <div id="bookingCalendar" ref={calendarDomRef} />
    </div>
  );
};

export default BookingManagerCalendar;
