import { Row, Col, Button, ButtonGroup } from 'react-bootstrap';
import { Component } from 'react';
import { Fields } from 'redux-form';

import defaultTo from 'lodash/defaultTo';
import compact from 'lodash/compact';
import get from 'lodash/get';

import BookingPilotFlightExpenseFields from './booking_pilot_flight_expense_fields';

class PilotFlightExpenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectOptions: [],
    };
    this._handleAddButtonClick = this._handleAddButtonClick.bind(this);
    this._handleAddOvernightButtonClick = this._handleAddOvernightButtonClick.bind(this);
    this._handleDeleteButtonClick = this._handleDeleteButtonClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    this._getSelectOptions();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      get(this.props, 'formValues.pilot_id', '') !==
        get(nextProps, 'formValues.pilot_id', '') ||
      get(this.props, 'formValues.copilot_id', '') !==
        get(nextProps, 'formValues.copilot_id', '')
    ) {
      this._getSelectOptions(nextProps);
    }
  }

  _getSelectOptions(props = this.props) {
    const pilotId = get(props, 'formValues.pilot_id', '');
    const copilotId = get(props, 'formValues.copilot_id', '');
    const selectOptions = [];
    if (pilotId) {
      selectOptions.push({
        id: pilotId,
        name: get(this.props.contactsDataSelector, [pilotId, 'fullName']),
      });
    }
    if (copilotId) {
      selectOptions.push({
        id: copilotId,
        name: get(this.props.contactsDataSelector, [copilotId, 'fullName']),
      });
    }
    this.setState({
      selectOptions,
    });
  }

  _handleAddButtonClick() {
    this.props.pilotFlightExpenses.fields.push({
      onchargeable: false,
      reimbursable: false,
      includes_gst: true,
      total: '',
    });
  }

  _handleAddOvernightButtonClick() {
    this.props.pilotFlightExpenses.fields.push({
      onchargeable: true,
      reimbursable: true,
      includes_gst: true,
      notes: this.props.currentSettingsPilotFlightExpenseOvernightText,
      total: this.props.currentSettingsPilotFlightExpenseOvernightRate,
    });
  }

  _handleDeleteButtonClick(index) {
    this.props.pilotFlightExpenses.fields.remove(index);
  }

  _renderPilotFlightExpensesList() {
    const pilotIds = compact([
      defaultTo(parseInt(get(this.props, 'formValues.pilot_id')), ''),
      defaultTo(parseInt(get(this.props, 'formValues.copilot_id')), ''),
    ]);
    return (
      <Col sm={12}>
        {this.props.pilotFlightExpenses.fields.map((field, index) => {
          const values = get(this.props, `formValues.${field}`, {});
          if (!values._destroy) {
            const fields = [
              `${field}._destroy`,
              `${field}.pilot_id`,
              `${field}.notes`,
              `${field}.total`,
              `${field}.total_excl_gst`,
              `${field}.includes_gst`,
              `${field}.onchargeable`,
              `${field}.reimbursable`,
            ];
            return (
              <Fields
                key={`fbf-${index}`}
                index={index}
                parentField={field}
                names={fields}
                component={BookingPilotFlightExpenseFields}
                formValues={values}
                pilotIds={pilotIds}
                overNight={
                  this.props.currentSettingsPilotFlightExpenseOvernightText ===
                  values.notes
                }
                handleDeleteButtonClick={this._handleDeleteButtonClick}
                selectOptions={this.state.selectOptions}
              />
            );
          }
        })}
      </Col>
    );
  }

  _renderHeader() {
    return (
      <Col sm={12}>
        <Row>
          <Col sm={2}>
            <label>Pilot</label>
          </Col>
          <Col sm={2}>
            <label>Notes</label>
          </Col>
          <Col sm={2}>
            <label>Total</label>
          </Col>
          <Col sm={1}>
            <label>Incl. Gst</label>
          </Col>
          <Col sm={1}>
            <label>Oncharge</label>
          </Col>
          <Col sm={1}>
            <label>Reimburse</label>
          </Col>
          <Col sm={1} />
        </Row>
      </Col>
    );
  }

  _renderPilotFlightExpenses() {
    return (
      <Row>
        {this._renderHeader()}
        {this._renderPilotFlightExpensesList()}
      </Row>
    );
  }

  render() {
    const expensesLength = get(
      this.props,
      'formValues.pilot_flight_expenses_attributes',
      []
    ).filter((pfe) => !pfe._destroy).length;
    return (
      <Row>
        <Col sm={2}>
          <p>
            <strong>Pilot Flight Expenses</strong>
          </p>
        </Col>
        <Col sm={10}>
          {expensesLength > 0 && this._renderPilotFlightExpenses()}
          <Row>
            <Col sm={12}>
              <ButtonGroup>
                <Button
                  style={{ paddingTop: 0, paddingLeft: 0 }}
                  type="button"
                  bsStyle="link"
                  bsSize="small"
                  onClick={this._handleAddButtonClick}
                  tabIndex={-1}
                  disabled={!this.props.formValues.pilot_id}
                >
                  add...
                </Button>
                <Button
                  style={{ paddingTop: 0, paddingLeft: 0 }}
                  type="button"
                  bsStyle="link"
                  bsSize="small"
                  onClick={this._handleAddOvernightButtonClick}
                  tabIndex={-1}
                  disabled={!this.props.formValues.pilot_id}
                >
                  add overnight...
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default PilotFlightExpenses;
