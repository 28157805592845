import { Row, Col, Table, Form, Button, Glyphicon } from 'react-bootstrap';
import { Component } from 'react';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment';
import { connect } from 'react-redux';

import last from 'lodash/last';
import debounce from 'lodash/debounce';

import { currentSettingsSet } from '../actions/current_setting_actions';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import Confirm from '../components/confirm';
import ReactDateTimeFilter from '../components/form/react_date_time_filter';
import ReportHeader from '../components/report_header';
import Loader from '../components/loader';

import { queriesReady, typeInput, getExport } from '../lib/utils';

import bookingUpdateMutation from '../mutations/booking_update_mutation';

import bookingHobbBalanceForMonthByAircraftQuery from '../queries/booking_hobb_balance_for_month_by_aircraft_query';
import aircraftListQuery from '../queries/aircraft_list_query';

moment.updateLocale('en-nz');

class ReportDataIntegrity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleIncomplete: [],
    };
    this.handleNavAdminSignOff = this.handleNavAdminSignOff.bind(this);
    this.handleNavAircraftFlightRecord = this.handleNavAircraftFlightRecord.bind(this);

    this.handleConfirmComplete = this.handleConfirmComplete.bind(this);
    this.handleConfirmEdit = this.handleConfirmEdit.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (
      !moment(this.props.currentSettingsReportStart).isSame(
        moment(this.props.currentSettingsReportStart).startOf('month')
      )
    ) {
      this.props.currentSettingsSet({
        reportStart: moment(this.props.currentSettingsReportStart)
          .startOf('month')
          .toISOString(),
      });
    }
    if (
      !moment(this.props.currentSettingsReportEnd).isSame(
        moment(this.props.currentSettingsReportStart).endOf('month')
      )
    ) {
      this.props.currentSettingsSet({
        reportEnd: moment(this.props.currentSettingsReportStart)
          .endOf('month')
          .toISOString(),
      });
    }
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    this.delayedHandleRefetch = debounce(() => {
      this.props.bookingHobbBalanceForMonthByAircraftQuery.refetch();
    }, 250);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { visibleIncomplete } = this.state;
    if (nextProps.currentSettingsReportStart !== this.props.currentSettingsReportStart) {
      visibleIncomplete = [];
    }
    this.setState({
      visibleIncomplete,
    });
  }

  componentWillUnmount() {
    this.delayedHandleRefetch.cancel();
  }

  getExport = (e) => {
    this.props.mutationSet(true);
    const reportName = e.target.getAttribute('data-report-name');
    const args = {
      startAt: this.props.currentSettingsReportStart,
      endAt: this.props.currentSettingsReportEnd,
    };
    getExport(reportName, args)
      .then(() => {
        this.props.mutationSet(false);
      })
      .catch((err) => this.props.mutationFailure(err));
  };

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const nextProps = props || this.props;
    return !queriesReady(
      nextProps.bookingHobbBalanceForMonthByAircraftQuery,
      nextProps.aircraftListQuery
    );
  }

  handleNavAdminSignOff(e) {
    const aircraftId = parseInt(last(e.target.id.split('-')), 10);
    this.props.currentSettingsSet({
      reportAircraftId: aircraftId,
    });
    this.props.navigate('/reports/admin_sign_off');
  }

  handleNavAircraftFlightRecord(e) {
    const aircraftId = parseInt(last(e.target.id.split('-')), 10);
    this.props.currentSettingsSet({
      reportAircraftId: aircraftId,
    });
    this.props.navigate('/reports/aircraft_flight_record');
  }

  handleConfirmComplete(e) {
    const bookingId = e.target.getAttribute('data-id');
    const data = {
      id: bookingId,
      audit_created: true,
      audit_created_at: moment().format(),
      audit_created_by_admin_id: this.props.currentContact.id,
      updated_by_admin_id: this.props.currentContact.id,
    };
    this.props
      .bookingUpdateMutation({
        variables: {
          id: bookingId,
          input: typeInput(data),
        },
      })
      .then(() => {
        this.props.mutationSuccess('Booking complete');
        this.props.navigate(`/flights/${bookingId}/edit`);
      })
      .catch((err) => {
        this.props.mutationFailure(err);
      });
  }

  handleConfirmEdit(e) {
    const bookingId = e.target.getAttribute('data-id');
    this.props.navigate(`/flights/${bookingId}/edit`);
  }

  handleIncomplete(id) {
    let ids = this.state.visibleIncomplete;
    const idIndex = ids.indexOf(id);
    if (idIndex > -1) {
      ids.splice(idIndex, 1);
    } else {
      ids = [...ids, id];
    }
    this.setState({
      visibleIncomplete: ids,
    });
  }

  renderEditLink(booking) {
    const { id, booking_id: bookingId, reference, is_audited: isAudited } = booking;

    if (isAudited) {
      return (
        <LinkContainer
          key={id}
          style={{ display: 'block', textAlign: 'left' }}
          to={`/flights/${bookingId}/edit`}
        >
          <Button bsStyle="link" bsSize="xsmall">
            {`#${reference}`}
          </Button>
        </LinkContainer>
      );
    } else {
      const completeMessage =
        "Click 'Complete' to set flight as completed then edit, or 'Edit' to proceed as normal.";
      return (
        <Confirm
          key={id}
          dataId={bookingId}
          confirmId={`admin-confirm-complete-${bookingId}`}
          onConfirm={this.handleConfirmComplete}
          confirmText="Complete"
          showMiddleButton
          middleId={`admin-confirm-edit-${bookingId}`}
          onMiddle={this.handleConfirmEdit}
          middleText="Edit"
          middleBSStyle="warning"
          title="Choose Edit Action"
          body={completeMessage}
          style={{ textAlign: 'left' }}
        >
          <Button block bsStyle="link" bsSize="xsmall">
            {`#${reference}`}
          </Button>
        </Confirm>
      );
    }
  }

  renderIncomplete(aircraft_id, incomplete_bookings) {
    const now = moment();
    const finished_incomplete_bookings = incomplete_bookings.filter((booking) =>
      moment(booking.end_at).isBefore(now, 'minute')
    );
    if (finished_incomplete_bookings.length < 2) {
      return finished_incomplete_bookings.map((booking) => this.renderEditLink(booking));
    } else {
      const count = finished_incomplete_bookings.length;
      const visible = this.state.visibleIncomplete.includes(aircraft_id);
      return (
        <div>
          <Button
            block
            bsStyle="link"
            bsSize="xsmall"
            onClick={this.handleIncomplete.bind(this, aircraft_id)}
            style={{ textAlign: 'left' }}
          >
            {visible ? 'hide ...' : `show ${count} ...`}
          </Button>
          <div style={{ display: visible ? 'inline-block' : 'none' }}>
            {finished_incomplete_bookings.map((booking) => this.renderEditLink(booking))}
          </div>
        </div>
      );
    }
  }

  renderFixed(value) {
    return value ? value.toFixed(1) : '-';
  }

  renderAircraftInactive(data) {
    const { id, registration_abbreviated } = data;
    return (
      <tr key={id}>
        <td colSpan={13}>{registration_abbreviated}</td>
      </tr>
    );
  }

  renderAircraftActive(data, aircraft_registration_abbreviated) {
    const {
      id,
      aircraft_id,
      bookings_length,
      incomplete_bookings,
      prev_end_hobb,
      start_hobb,
      end_hobb,
      next_start_hobb,
      calc_hobb,
      sum_hobb,
      balanced_hobb,
      is_pilot_completed,
      is_admin_completed,
    } = data;

    return (
      <tr key={id}>
        <td>{aircraft_registration_abbreviated}</td>
        <td>{bookings_length}</td>
        <td>
          <Glyphicon
            style={{ color: balanced_hobb ? 'green' : 'red' }}
            glyph={balanced_hobb ? 'ok' : 'exclamation-sign'}
          />
        </td>
        <td>
          <Glyphicon
            style={{ color: is_pilot_completed ? 'green' : 'red' }}
            glyph={is_pilot_completed ? 'ok' : 'exclamation-sign'}
          />
        </td>
        <td>
          <Glyphicon
            style={{ color: is_admin_completed ? 'green' : 'red' }}
            glyph={is_admin_completed ? 'ok' : 'exclamation-sign'}
          />
        </td>
        <td>{this.renderIncomplete(aircraft_id, incomplete_bookings)}</td>
        <td className="text-right">{this.renderFixed(prev_end_hobb)}</td>
        <td className="text-right">{this.renderFixed(start_hobb)}</td>
        <td className="text-right">{this.renderFixed(end_hobb)}</td>
        <td className="text-right">{this.renderFixed(next_start_hobb)}</td>
        <td className="text-right">{this.renderFixed(calc_hobb)}</td>
        <td className="text-right">{this.renderFixed(sum_hobb)}</td>
        <td>
          <Button
            id={`flight-record-${aircraft_id}`}
            bsStyle="link"
            bsSize="xsmall"
            onClick={this.handleNavAircraftFlightRecord}
          >
            flt
          </Button>
          <Button
            id={`admin-signoff-${aircraft_id}`}
            bsStyle="link"
            bsSize="xsmall"
            onClick={this.handleNavAdminSignOff}
          >
            admin
          </Button>
        </td>
      </tr>
    );
  }

  renderAircraft(aircraft) {
    const data = this.props.bookingHobbBalanceForMonthByAircraftQuery.data.find(
      (booking) => booking.aircraft_id === aircraft.id
    );
    if (data && data.bookings_length > 0) {
      return this.renderAircraftActive(data, aircraft.registration_abbreviated);
    } else {
      return this.renderAircraftInactive(aircraft);
    }
  }

  renderAircrafts() {
    return (
      <tbody>
        {this.props.aircraftListQuery.data.map((model) => this.renderAircraft(model))}
      </tbody>
    );
  }

  renderHobbHeader() {
    return (
      <tr>
        <th colSpan={6} style={{ borderBottom: 'none' }}>
          &nbsp;
        </th>
        <th colSpan={6} style={{ textAlign: 'center' }}>
          Hobb Data
        </th>
        <th style={{ borderBottom: 'none' }}>&nbsp;</th>
      </tr>
    );
  }

  renderHeader() {
    return (
      <tr>
        <th style={{ borderTop: 'none' }}>Reg</th>
        <th style={{ borderTop: 'none' }}>No.</th>
        <th style={{ borderTop: 'none' }}>Hobbs</th>
        <th style={{ borderTop: 'none' }}>Pilot</th>
        <th style={{ borderTop: 'none' }}>Admin</th>
        <th style={{ borderTop: 'none' }}>Incomplete</th>
        <th style={{ borderTop: 'none' }} className="text-right">
          Prev End
        </th>
        <th style={{ borderTop: 'none' }} className="text-right">
          Mth Min
        </th>
        <th style={{ borderTop: 'none' }} className="text-right">
          Mth Max
        </th>
        <th style={{ borderTop: 'none' }} className="text-right">
          Next Start
        </th>
        <th style={{ borderTop: 'none' }} className="text-right">
          Max-Prev End
        </th>
        <th style={{ borderTop: 'none' }} className="text-right">
          Flight Total
        </th>
        <th style={{ borderTop: 'none' }}>Reports</th>
      </tr>
    );
  }

  render() {
    return (
      <div>
        {this.renderOverlay()}
        {this.renderData()}
      </div>
    );
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (this.isLoading()) {
      return undefined;
    }
    return (
      <div>
        <Row>
          <Col sm={12}>
            <div className="clearfix">
              <div className="pull-left">
                <ReportHeader
                  title="Data Integrity Report"
                  start={this.props.currentSettingsReportStart}
                  end={this.props.currentSettingsReportEnd}
                />
              </div>
              <div className="pull-right">
                <Button
                  bsStyle="primary"
                  data-report-name="booking_hobb_balance_for_month_by_aircraft"
                  onClick={this.getExport}
                >
                  PDF
                </Button>
              </div>
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: '10px' }}>
          <Col xs={12}>
            <Form inline>
              <Button
                style={{ margin: '0', padding: '0 0 8px 0' }}
                bsStyle="link"
                bsSize="xsmall"
                onClick={this.delayedHandleRefetch}
              >
                <span className="glyphicon glyphicon-repeat" />
              </Button>
              <ReactDateTimeFilter
                currentSettingsReportStart={this.props.currentSettingsReportStart}
                currentSettingsReportEnd={this.props.currentSettingsReportEnd}
                onChange={this.props.currentSettingsSet}
                locked
              />
            </Form>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Table striped>
              <thead>
                {this.renderHobbHeader()}
                {this.renderHeader()}
              </thead>
              {this.renderAircrafts()}
            </Table>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentContact: state.currentContact,
    currentSettingsReportStart: state.currentSettings.reportStart,
    currentSettingsReportEnd: state.currentSettings.reportEnd,
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

ReportDataIntegrity = compose(
  graphql(bookingUpdateMutation, {
    name: 'bookingUpdateMutation',
  }),
  graphql(aircraftListQuery, {
    name: 'aircraftListQuery',
  }),
  graphql(bookingHobbBalanceForMonthByAircraftQuery, {
    name: 'bookingHobbBalanceForMonthByAircraftQuery',
    options: (props) => ({
      variables: {
        startAt: props.currentSettingsReportStart,
        endAt: props.currentSettingsReportEnd,
      },
      fetchPolicy: 'cache-and-network',
    }),
  })
)(ReportDataIntegrity);

export default connect(mapStateToProps, {
  currentSettingsSet,
  mutationSet,
  mutationSuccess,
  mutationFailure,
})(ReportDataIntegrity);
