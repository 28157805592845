import {
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  ButtonToolbar,
  ButtonGroup,
} from 'react-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import moment from 'moment';
import { LinkContainer } from 'react-router-bootstrap';

import { reduxForm, Field, FieldArray, getFormValues } from 'redux-form';

import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _pick from 'lodash/pick';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import { typeaheadsFilter, typeaheadsFilterClear } from '../actions/typeahead_actions';

import { contactsData } from '../selectors';

import Loader from '../components/loader';
import InputField from '../components/form/input_field';

import BookingCreators from '../components/flight_form/booking_creators';
import BookingTitle from '../components/flight_form/booking_title';

import EmployeeInputField from '../components/flight_form/employee_input_field';
import StartDateAndTimeInputFields from '../components/flight_form/start_date_and_time_input_fields';
import EndDateAndTimeInputFields from '../components/flight_form/end_date_and_time_input_fields';

import { defaultNonFlightBooking } from '../defaults';

import bookingUpdateMutation from '../mutations/booking_update_mutation';
import bookingCreateMutation from '../mutations/booking_create_mutation';

import {
  queriesReady,
  queryReady,
  queryJustReady,
  typeInput,
  pickValues,
  mapOmitValues,
} from '../lib/utils';

import aircraftListQuery from '../queries/aircraft_list_query';
import bookingQuery from '../queries/booking_query';
import contactItemListQuery from '../queries/contact_item_list_query';

moment.updateLocale('en-nz');

let isInitialised = false;

class NonFlightForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: !!this.props.params.id,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderEmployeesFieldArray = this.renderEmployeesFieldArray.bind(this);
    this.handleEmployeeSearch = this.handleEmployeeSearch.bind(this);
    this.handleEmployeeSearchClear = this.handleEmployeeSearchClear.bind(this);
  }

  componentWillUnmount() {
    isInitialised = false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.bookingQuery &&
      queryJustReady(this.props.bookingQuery, nextProps.bookingQuery)
    ) {
      if (
        _get(nextProps.bookingQuery, 'data.audit_created_at') &&
        this.state.tabKey === 2
      ) {
        this.setState({
          tabKey: 3,
        });
      }
    }
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(
      [props.bookingQuery, true], // ignore if undefined
      props.aircraftListQuery,
      props.employeeListQuery
    );
  }

  handleSubmit(data) {
    const submitData = _cloneDeep(data);
    const adminId = this.props.currentContact.id;
    if (this.state.updating) {
      submitData.updated_by_admin_id = adminId;
    } else {
      submitData.employee_ids = submitData.booking_employees_attributes.map(
        (bea) => bea.employee_id
      );
      delete submitData.booking_employees_attributes;
      submitData.updated_by_admin_id = adminId;
      submitData.created_by_admin_id = adminId;
    }
    // console.log('submit')
    // console.log(submitData)
    // return

    this.props.mutationSet(true);
    let mutation;
    let mutationMessage;
    const mutationData = { variables: { input: typeInput(submitData) } };
    if (this.state.updating) {
      mutation = this.props.bookingUpdateMutation;
      mutationMessage = 'Booking update';
      mutationData.variables.id = this.props.params.id;
    } else {
      mutation = this.props.bookingCreateMutation;
      mutationMessage = 'Booking create';
    }
    return mutation(mutationData)
      .then((res) => {
        this.props.mutationSuccess(mutationMessage);
        this.props.navigate(this.props.currentSettingsReturnRoute);
      })
      .catch((err) => this.props.mutationFailure(err, true));
  }

  handleEmployeeSearch(query) {
    this.props.typeaheadsFilter(
      {
        str: query,
        class: 'contacts',
        role: 'employee',
        fields: ['first_name', 'last_name', 'display_name'],
      },
      {
        collection: 'employees',
      }
    );
  }

  handleEmployeeSearchClear() {
    this.props.typeaheadsFilterClear({ collection: 'employees' });
  }

  renderEmployeesFieldArray(bookingEmployees) {
    return (
      <EmployeeInputField
        updating={this.state.updating}
        change={this.props.change}
        form={this.props.form}
        employees={this.props.employeeListQuery.data}
        typeaheadFetching={this.props.typeaheadFetching}
        employeesTypeaheadCollection={this.props.employeesTypeaheadCollection}
        bookingEmployees={bookingEmployees}
        handleEmployeeSearch={this.handleEmployeeSearch}
        handleEmployeeSearchClear={this.handleEmployeeSearchClear}
        formValues={_pick(this.props.formValues, ['booking_employees_attributes'])}
      />
    );
  }

  _statusesFor() {
    switch (_get(this.props, 'formValues.calendar_type')) {
      case 'meeting':
        return [
          ['confirmed', 'Confirmed'],
          ['to_be_confirmed', 'To be confirmed'],
          ['cancelled', 'Cancelled'],
        ];
      case 'leave':
        return [
          ['tentative', 'Request'],
          ['confirmed', 'Confirmed'],
          ['cancelled', 'Cancelled'],
        ];
      case 'rostered':
        return [
          ['confirmed', 'Priority'],
          ['to_be_confirmed', 'Planned'],
        ];
      case 'banner':
        return [
          ['confirmed', 'Confirmed'],
          ['to_be_confirmed', 'To be confirmed'],
        ];
      default:
        return [];
    }
  }

  renderStatus() {
    if (_get(this.props, 'formValues.calendar_type') !== 'maintenance') {
      return (
        <Row>
          <Col xs={12}>
            <Row>
              <Col xs={12}>Status</Col>
            </Row>
            <Row>
              <Field
                type="text"
                name="status"
                bsSize="sm"
                labelWidth={0}
                inputWidth={12}
                noTab
                defaultSelectOption={false}
                component={InputField}
                componentClass="select"
                selectOptions={this._statusesFor().map((status) => ({
                  id: status[0],
                  name: status[1],
                }))}
              />
            </Row>
          </Col>
        </Row>
      );
    }
  }

  renderAircraft() {
    return (
      <Row>
        <Col xs={12}>
          <Row>
            <Col xs={12}>Aircraft</Col>
          </Row>
          <Row>
            <Field
              type="text"
              name="aircraft_id"
              bsSize="sm"
              labelWidth={0}
              inputWidth={12}
              noTab
              component={InputField}
              componentClass="select"
              selectOptions={this.props.aircraftListQuery.data.map((aircraft) => ({
                id: aircraft.id,
                name: aircraft.registration_abbreviated,
              }))}
            >
              Aircraft
            </Field>
          </Row>
        </Col>
      </Row>
    );
  }

  renderEmployees() {
    return (
      <Row>
        <Col xs={12}>
          <Row>
            <Col xs={12}>Scheduled for Employee(s)</Col>
          </Row>
          <Row>
            <FieldArray
              name="booking_employees_attributes"
              component={this.renderEmployeesFieldArray}
            />
          </Row>
        </Col>
      </Row>
    );
  }

  renderTarget() {
    if (_get(this.props, 'formValues.calendar_type') === 'maintenance') {
      return this.renderAircraft();
    } else if (_get(this.props, 'formValues.calendar_type') !== 'banner') {
      return this.renderEmployees();
    }
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
  }

  renderData() {
    if (
      isInitialised &&
      this.isLoaded() &&
      (!this.state.updating || _get(this.props, 'formValues.id'))
    ) {
      const { handleSubmit, submitting, error } = this.props;

      return (
        <Form className="form form-booking" onSubmit={handleSubmit(this.handleSubmit)}>
          <BookingTitle
            updating={this.state.updating}
            reference={_get(this.props, 'bookingQuery.data.reference')}
            startAt={_get(this.props, 'bookingQuery.data.start_at')}
            calendarType={_get(this.props, 'formValues.calendar_type')}
          />
          {error && <strong>{error}</strong>}
          <Row>
            <Col xs={3}>
              {this.renderStatus()}
              {this.renderTarget()}
            </Col>
            <Col xs={9}>
              <Row>
                <Col xs={12}>Job Notes</Col>
              </Row>
              <Row>
                <Field
                  type="text"
                  name="job_notes"
                  component={InputField}
                  bsSize="sm"
                  labelWidth={0}
                  inputWidth={12}
                  componentClass="textarea"
                  rows={4}
                  noTab
                />
              </Row>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <Row>
                <Col sm={12}>
                  <Field
                    type="text"
                    name="start_at"
                    component={StartDateAndTimeInputFields}
                    {..._pick(this.props.formValues, ['start_at'])}
                    withoutTime={
                      _get(this.props, 'formValues.calendar_type') ===
                      this.props.currentSettingsBookingCalendarTypeBanner
                    }
                  >
                    Start Date
                  </Field>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <Row>
                <Col sm={12}>
                  <Field
                    type="text"
                    name="end_at"
                    component={EndDateAndTimeInputFields}
                    {..._pick(this.props.formValues, ['end_at', 'start_at'])}
                    withoutTime={
                      _get(this.props, 'formValues.calendar_type') ===
                      this.props.currentSettingsBookingCalendarTypeBanner
                    }
                  >
                    End Date
                  </Field>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <hr />
            </Col>
          </Row>
          <FormGroup>
            <Col sm={12}>
              {this.state.updating && (
                <div className="pull-left">
                  <BookingCreators
                    contactsDataSelector={this.props.contactsDataSelector}
                    {..._pick(_get(this.props.bookingQuery, 'data', {}), [
                      'created_by_admin_id',
                      'created_at',
                      'updated_by_admin_id',
                      'updated_at',
                    ])}
                  />
                </div>
              )}
              <div className="pull-right">
                <ButtonToolbar>
                  <ButtonGroup>
                    <LinkContainer to={this.props.currentSettingsReturnRoute}>
                      <Button type="reset" bsStyle="danger" disabled={submitting}>
                        Cancel
                      </Button>
                    </LinkContainer>
                  </ButtonGroup>
                  <ButtonGroup>
                    <Button type="submit" bsStyle="primary" disabled={submitting}>
                      {submitting && (
                        <span className="glyphicon glyphicon-refresh glyphicon-spin" />
                      )}{' '}
                      {this.state.updating ? 'Update' : 'Create'}
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </div>
            </Col>
          </FormGroup>
        </Form>
      );
    }
  }

  render() {
    return (
      <div>
        {this.renderOverlay()}
        {this.renderData()}
      </div>
    );
  }
}

const bookingWhiteList = [
  'aircraft_id',
  'calendar_type',
  'end_at',
  'id',
  'job_notes',
  'status',
  'start_at',
];

function validate() {
  return {};
}

function pickInitialValues(
  bookingQueryInitial,
  currentSettingsBookingCollectionStartDate,
  currentSettingsBookingRequestedCalendarType,
  updating
) {
  if (!isInitialised) {
    if (!updating) {
      isInitialised = true;
      return defaultNonFlightBooking(
        currentSettingsBookingCollectionStartDate,
        currentSettingsBookingRequestedCalendarType
      );
    } else if (queryReady(bookingQueryInitial)) {
      isInitialised = true;
      const bookingQueryInitialData = _cloneDeep(bookingQueryInitial.data);
      const booking = pickValues(bookingQueryInitialData, bookingWhiteList);
      booking.booking_employees_attributes = mapOmitValues(
        _get(bookingQueryInitialData, 'bookingEmployees')
      );
      return booking;
    }
  }
  return undefined;
}

function mapStateToProps(state, props) {
  const initialValues = pickInitialValues(
    props.bookingQuery,
    state.currentSettings.bookingCollectionStartDate,
    state.currentSettings.bookingRequestedCalendarType,
    !!props.params.id
  );
  return {
    initialValues,
    currentContact: state.currentContact,
    typeaheadFetching: state.typeaheads.fetching,
    employeesTypeaheadCollection: state.typeaheads.employees,
    currentSettingsBookingCalendarTypeBanner:
      state.currentSettings.booking_calendar_type_banner,
    currentSettingsReturnRoute: state.currentSettings.returnRoute,
    currentSettingsMutating: state.currentSettings.mutating,
    contactsDataSelector: contactsData(props),
    formValues: getFormValues('NonFlightForm')(state),
  };
}

export default compose(
  graphql(bookingCreateMutation, {
    name: 'bookingCreateMutation',
  }),
  graphql(bookingUpdateMutation, {
    name: 'bookingUpdateMutation',
  }),
  graphql(bookingQuery, {
    name: 'bookingQuery',
    skip: (props) => !props.params.id,
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'network-only',
    }),
  }),
  graphql(aircraftListQuery, {
    name: 'aircraftListQuery',
  }),
  graphql(contactItemListQuery, {
    name: 'employeeListQuery',
    options: { variables: { role: 'employee' } },
  }),
  connect(mapStateToProps, {
    mutationSuccess,
    mutationFailure,
    mutationSet,
    typeaheadsFilter,
    typeaheadsFilterClear,
  }),
  reduxForm({
    form: 'NonFlightForm',
    validate,
  })
)(NonFlightForm);
