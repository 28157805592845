import { Col, Button, Glyphicon, Thumbnail, ButtonGroup } from 'react-bootstrap';
import { Component } from 'react';

import get from 'lodash/get';
import has from 'lodash/has';

import Confirm from '../confirm';

const { FileReader } = window;

class LocationFormLocationImageImageField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inlineImage: null,
    };
    this._handleDeleteClick = this._handleDeleteClick.bind(this);
    this._handleImageChange = this._handleImageChange.bind(this);
    this._handlePromoteClick = this._handlePromoteClick.bind(this);
    this._handleDemoteClick = this._handleDemoteClick.bind(this);
    this.defaultImage =
      'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      get(this.props, 'formValues.image.lastModified') !==
      get(nextProps, 'formValues.image.lastModified')
    ) {
      if (get(nextProps, 'formValues.image.lastModified')) {
        const reader = new FileReader();
        reader.onload = (upload) => {
          this.setState({
            inlineImage: upload.target.result,
          });
        };
        reader.readAsDataURL(get(nextProps, 'formValues.image'));
      } else {
        this.setState({
          inlineImage: null,
        });
      }
    }
  }

  _handlePromoteClick() {
    if (this.props.handlePromoteClick) {
      this.props.handlePromoteClick(this.props.index);
    }
  }

  _handleDemoteClick() {
    if (this.props.handleDemoteClick) {
      this.props.handleDemoteClick(this.props.index);
    }
  }

  _handleDeleteClick() {
    if (this.props.handleDeleteClick) {
      this.props.handleDeleteClick(this.props.index);
    }
  }

  _renderDeleteMessage() {
    let msg = 'Are you sure you want to delete this image?';
    if (get(this.props, 'formValues.id')) {
      msg +=
        ' Note that while the image will be removed from the list, it will not be permanently deleted until you save this Location.';
    }
    return msg;
  }

  _handleImageChange(e) {
    const file = e.target.files[0];
    if (['image/jpg', 'image/jpeg', 'image/png'].indexOf(file.type) > -1) {
      this.props.input.onChange(file);
    }
  }

  render() {
    const { promoteDisabled, demoteDisabled, locationImageUrl } = this.props;

    return (
      <Col xs={3}>
        <Thumbnail
          bsClass="thumbnail location-image-thumbnail"
          src={this.state.inlineImage || locationImageUrl || this.defaultImage}
        >
          <div className="clearfix">
            <div className="pull-left">
              <ButtonGroup>
                <Button
                  type="button"
                  bsStyle="default"
                  bsSize="xsmall"
                  onClick={this._handlePromoteClick}
                  disabled={promoteDisabled}
                >
                  <Glyphicon glyph="arrow-left" />
                </Button>
                <Button
                  type="button"
                  bsStyle="default"
                  bsSize="xsmall"
                  onClick={this._handleDemoteClick}
                  disabled={demoteDisabled}
                >
                  <Glyphicon glyph="arrow-right" />
                </Button>
              </ButtonGroup>
            </div>
            <div className="pull-right">
              <Confirm
                onConfirm={this._handleDeleteClick}
                title="Delete Location Image"
                body={this._renderDeleteMessage()}
                confirmText="Confirm"
              >
                <Button type="button" bsStyle="default" bsSize="xsmall">
                  <Glyphicon glyph="trash" />
                </Button>
              </Confirm>
              {has(this.props, 'formValues.id') || (
                <label className="btn btn-default btn-xs" style={{ marginLeft: '8px' }}>
                  <Glyphicon glyph="upload" />
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={this._handleImageChange}
                  />
                </label>
              )}
            </div>
          </div>
        </Thumbnail>
      </Col>
    );
  }
}

export default LocationFormLocationImageImageField;
