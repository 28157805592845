import {
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  FormControl,
  HelpBlock,
  Button,
  Panel,
} from 'react-bootstrap';
import { Component } from 'react';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment';
import { connect } from 'react-redux';

import debounce from 'lodash/debounce';

import { currentSettingsSet } from '../actions/current_setting_actions';

import { mutationSet, mutationFailure } from '../actions/mutation_actions';

import ReactDateTimeFilter from '../components/form/react_date_time_filter';
import Loader from '../components/loader';
import ReportHeader from '../components/report_header';

import { queriesReady, getExport } from '../lib/utils';

import bookingDetailForMonthByAircraftQuery from '../queries/booking_detail_for_month_by_aircraft_query';
import aircraftListQuery from '../queries/aircraft_list_query';

moment.updateLocale('en-nz');

class ReportAllocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterAircraftId: this.props.currentSettingsReportAircraftId,
      filterAircraftIds: [],
    };
    this._handleDisplayAircraftIdChange = this._handleDisplayAircraftIdChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.startAtDate) {
      const date = moment(this.props.params.startAtDate, 'MM-YYYY');
      this.props.currentSettingsSet({
        reportStart: date.clone().startOf('month').toISOString(),
        reportEnd: date.clone().endOf('month').toISOString(),
      });
    }
    if (this.props.params.aircraftId) {
      this._handleDisplayAircraftIdChange({
        target: { value: this.props.params.aircraftId },
      });
    }
    if (
      !moment(this.props.currentSettingsReportStart).isSame(
        moment(this.props.currentSettingsReportStart).startOf('month')
      )
    ) {
      this.props.currentSettingsSet({
        reportStart: moment(this.props.currentSettingsReportStart)
          .startOf('month')
          .toISOString(),
      });
    }
    if (
      !moment(this.props.currentSettingsReportEnd).isSame(
        moment(this.props.currentSettingsReportStart).endOf('month')
      )
    ) {
      this.props.currentSettingsSet({
        reportEnd: moment(this.props.currentSettingsReportStart)
          .endOf('month')
          .toISOString(),
      });
    }
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    this.delayedHandleRefetch = debounce((e) => {
      this.props.bookingDetailForMonthByAircraftQuery.refetch();
    }, 250);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { filterAircraftIds } = this.state;
    if (this.isLoaded(nextProps)) {
      filterAircraftIds = nextProps.bookingDetailForMonthByAircraftQuery.data.map(
        (data) => data.aircraft_id
      );
    }
    this.setState({
      filterAircraftIds,
    });
    let filterAircraftId = nextProps.currentSettingsReportAircraftId;
    if (filterAircraftId && this.isLoaded(nextProps)) {
      if (
        filterAircraftIds.length > 0 &&
        filterAircraftIds.indexOf(filterAircraftId) === -1
      ) {
        filterAircraftId = '';
      }
    }
    if (filterAircraftId !== this.props.currentSettingsReportAircraftId) {
      this._handleDisplayAircraftIdChange({ target: { value: filterAircraftId } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleRefetch.cancel();
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(
      props.bookingDetailForMonthByAircraftQuery,
      props.aircraftListQuery
    );
  }

  getExport = (e) => {
    this.props.mutationSet(true);
    const reportName = e.target.getAttribute('data-report-name');
    const args = {
      startAt: this.props.currentSettingsReportStart,
      endAt: this.props.currentSettingsReportEnd,
      aircraftId: this.props.currentSettingsReportAircraftId,
    };
    getExport(reportName, args)
      .then(() => {
        this.props.mutationSet(false);
      })
      .catch((err) => this.props.mutationFailure(err));
  };

  _handleDisplayAircraftIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value);
    this.setState({
      filterAircraftId: value,
    });
    this.props.currentSettingsSet({
      reportAircraftId: value,
    });
  }

  _renderFixed(value) {
    return value ? value.toFixed(1) : '-';
  }

  _renderSummary(data, name) {
    const { start_hobb, end_hobb, calc_hobb, sum_hobb } = data;

    const MM_currentSettingsReportStart = moment(this.props.currentSettingsReportStart);

    return (
      <Row>
        <Col xs={4}>
          <Panel>
            <Panel.Heading>{`Summary for ${name}`}</Panel.Heading>
            <Panel.Body>
              <dl className="dl-horizontal">
                <dt>{`${MM_currentSettingsReportStart.format('MMMM')} Min Hobb`}</dt>
                <dd>{this._renderFixed(start_hobb)}</dd>
              </dl>
              <dl className="dl-horizontal">
                <dt>{`${MM_currentSettingsReportStart.format('MMMM')} Max Hobb`}</dt>
                <dd>{this._renderFixed(end_hobb)}</dd>
              </dl>
              <dl className="dl-horizontal">
                <dt>Calculated Max - Min</dt>
                <dd>{this._renderFixed(calc_hobb)}</dd>
              </dl>
              <dl className="dl-horizontal">
                <dt>Sum of Flight Times</dt>
                <dd>{this._renderFixed(sum_hobb)}</dd>
              </dl>
            </Panel.Body>
          </Panel>
        </Col>
      </Row>
    );
  }

  _renderProviderRow(provider_full_name, aircraft_registration_abbreviated) {
    return (
      <tr>
        <th
          colSpan={8}
        >{`${aircraft_registration_abbreviated} bookings for ${provider_full_name}`}</th>
      </tr>
    );
  }

  _renderHeaderRow() {
    return (
      <tr>
        <th>Ref#</th>
        <th>Date</th>
        <th>Pilot</th>
        <th>Start Hobb</th>
        <th>End Hobb</th>
        <th>Flight Time</th>
        <th>Flight Summary</th>
        <th>Charge To</th>
      </tr>
    );
  }

  _renderRow(booking) {
    const {
      id,
      booking_id,
      reference,
      start_at_s,
      pilot_display_name,
      copilot_display_name,
      start_hobb,
      end_hobb,
      flight_time,
      booking_summary,
      chargeable_full_names,
    } = booking;
    return (
      <tr key={id}>
        <td>
          <LinkContainer
            style={{ display: 'block', textAlign: 'left' }}
            to={`/flights/${booking_id}/edit`}
          >
            <Button bsStyle="link" bsSize="xsmall">
              {`#${reference}`}
            </Button>
          </LinkContainer>
        </td>
        <td>{start_at_s}</td>
        <td>
          {[pilot_display_name, copilot_display_name].filter((name) => name).join(', ')}
        </td>
        <td>{this._renderFixed(start_hobb)}</td>
        <td>{this._renderFixed(end_hobb)}</td>
        <td>{this._renderFixed(flight_time)}</td>
        <td>{booking_summary}</td>
        <td>{chargeable_full_names}</td>
      </tr>
    );
  }

  _renderRows(bookings) {
    return <tbody>{bookings.map((booking) => this._renderRow(booking))}</tbody>;
  }

  _renderFooterRow(provider_full_name, aircraft_registration_abbreviated, flight_time) {
    return (
      <tr>
        <th
          colSpan={5}
          style={{ width: '50%', textAlign: 'right' }}
        >{`Total ${aircraft_registration_abbreviated} Flight Time for ${provider_full_name}:`}</th>
        <th colSpan={3} style={{ textAlign: 'left' }}>
          {flight_time || '-'}
        </th>
      </tr>
    );
  }

  _renderProviders(data, aircraft_registration_abbreviated) {
    const grouped_bookings = data.bookings.reduce((bookings, booking) => {
      bookings[booking.provider_id]
        ? (bookings[booking.provider_id] = [...bookings[booking.provider_id], booking])
        : (bookings[booking.provider_id] = [booking]);
      return bookings;
    }, {});
    return (
      <Col xs={12}>
        {Object.keys(grouped_bookings).map((provider_id) => {
          const provider_bookings = grouped_bookings[provider_id];
          const { provider_full_name } = provider_bookings[0];
          const flight_time = provider_bookings
            .reduce((sum, booking) => sum + (booking.flight_time || 0), 0)
            .toFixed(1);
          return (
            <Table key={data.id + provider_id} striped>
              <thead>
                {this._renderProviderRow(
                  provider_full_name,
                  aircraft_registration_abbreviated
                )}
                {this._renderHeaderRow()}
              </thead>
              {this._renderRows(provider_bookings)}
              <tfoot>
                {this._renderFooterRow(
                  provider_full_name,
                  aircraft_registration_abbreviated,
                  flight_time
                )}
              </tfoot>
            </Table>
          );
        })}
      </Col>
    );
  }

  _renderAircrafts() {
    return (
      <Row>
        <Col xs={12}>
          {this.props.bookingDetailForMonthByAircraftQuery.data
            .filter((data) => {
              if (
                this.state.filterAircraftId &&
                data.aircraft_id !== this.state.filterAircraftId
              ) {
                return false;
              }
              return true;
            })
            .map((data) => (
              <Row key={data.aircraft_id} style={{ marginBottom: '20px' }}>
                <h4>{data.aircraft_registration_abbreviated}</h4>
                {this._renderProviders(data, data.aircraft_registration_abbreviated)}
                <Col xs={12}>
                  {this._renderSummary(data, data.aircraft_registration_abbreviated)}
                </Col>
              </Row>
            ))}
        </Col>
      </Row>
    );
  }

  _renderAircraftFilter() {
    const dataAircrafts = this.props.aircraftListQuery.data.filter(
      (model) => this.state.filterAircraftIds.indexOf(model.id) > -1
    );
    return (
      <FormControl
        componentClass="select"
        value={this.state.filterAircraftId}
        onChange={this._handleDisplayAircraftIdChange}
      >
        <option key={0} value="">
          All
        </option>
        {dataAircrafts.map((model) => {
          const { id, registration_abbreviated } = model;
          return (
            <option key={id} value={id}>
              {registration_abbreviated}
            </option>
          );
        })}
      </FormControl>
    );
  }

  render() {
    return (
      <div>
        {this.renderOverlay()}
        {this.renderData()}
      </div>
    );
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (this.isLoading()) {
      return undefined;
    }
    return (
      <div>
        <Row>
          <Col sm={12}>
            <div className="clearfix">
              <div className="pull-left">
                <ReportHeader
                  title="Aircraft Allocation Report"
                  start={this.props.currentSettingsReportStart}
                  end={this.props.currentSettingsReportEnd}
                />
              </div>
              <div className="pull-right">
                <Button
                  bsStyle="primary"
                  data-report-name="booking_detail_for_month_by_aircraft"
                  onClick={this.getExport}
                >
                  PDF
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: '10px' }}>
          <Col xs={12}>
            <Form inline>
              <Button
                style={{ margin: '0', padding: '0 0 8px 0' }}
                bsStyle="link"
                bsSize="xsmall"
                onClick={this.delayedHandleRefetch}
              >
                <span className="glyphicon glyphicon-repeat" />
              </Button>
              <ReactDateTimeFilter
                currentSettingsReportStart={this.props.currentSettingsReportStart}
                currentSettingsReportEnd={this.props.currentSettingsReportEnd}
                onChange={this.props.currentSettingsSet}
                locked
              />
              <FormGroup
                style={{ marginLeft: '16px', paddingBottom: '10px' }}
                bsSize="sm"
                controlId="filterId"
              >
                {this._renderAircraftFilter()}
              </FormGroup>
            </Form>
          </Col>
        </Row>
        {this._renderAircrafts()}
      </div>
    );
  }
}

ReportAllocation = compose(
  graphql(aircraftListQuery, {
    name: 'aircraftListQuery',
  }),
  graphql(bookingDetailForMonthByAircraftQuery, {
    name: 'bookingDetailForMonthByAircraftQuery',
    options: (props) => ({
      variables: {
        startAt: props.currentSettingsReportStart,
        endAt: props.currentSettingsReportEnd,
      },
      fetchPolicy: 'cache-and-network',
    }),
  })
)(ReportAllocation);

function mapStateToProps(state) {
  return {
    currentSettingsReportStart: state.currentSettings.reportStart,
    currentSettingsReportEnd: state.currentSettings.reportEnd,
    currentSettingsReportAircraftId: state.currentSettings.reportAircraftId,
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default connect(mapStateToProps, {
  currentSettingsSet,
  mutationFailure,
  mutationSet,
})(ReportAllocation);
