import { Component } from 'react';
import { Col, FormGroup, ControlLabel, HelpBlock } from 'react-bootstrap';

import DateTime from 'react-datetime';
import moment from 'moment';
import { validationState, validationText } from './helpers';

moment.updateLocale('en-nz');

class ReactDateTimeField extends Component {
  constructor(props) {
    super(props);
    this.fieldName = props.input.name;
    this.fieldNameError = `${props.input.name}_error`;
    this.fieldFormat = [props.dateFormat, props.timeFormat]
      .filter((format) => format)
      .join(' ');
    this.state = {
      [this.fieldName]: props.input.value
        ? moment(props.input.value).format(this.fieldFormat)
        : '',
      [this.fieldNameError]: {},
    };
    this._handleInputFieldChange = this._handleInputFieldChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    const args = {
      [this.fieldName]: nextProps.input.value
        ? moment(nextProps.input.value).format(this.fieldFormat)
        : '',
      [this.fieldNameError]: {},
    };
    this.setState(args);
  }

  _handleInputFieldChange(date) {
    if (moment.isMoment(date)) {
      // todo this needs work for fields with times.  Perhaps a switch statement bases on the fieldFormat?
      const newDate = date.clone().startOf('day');
      const args = {
        [this.fieldName]: date.format(this.fieldFormat),
        [this.fieldNameError]: {},
      };
      this.props.input.onChange(newDate.format());
      this.setState(args);
    } else {
      const args = {
        [this.fieldNameError]: {
          touched: true,
          invalid: true,
          error: this.props.helpText,
        },
      };
      this.setState(args);
    }
  }

  _renderInput() {
    const { inputStyle, dateFormat, timeFormat, closeOnSelect } = this.props;

    return (
      <DateTime
        value={this.state[this.fieldName]}
        {...(inputStyle && { style: inputStyle })}
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        onChange={this._handleInputFieldChange}
        closeOnSelect={closeOnSelect}
      />
    );
  }

  render() {
    const { input, bsSize, groupStyle, labelWidth, inputWidth, helpText } = this.props;

    return (
      <FormGroup
        controlId={input.name}
        validationState={validationState(this.state[this.fieldNameError])}
        {...(bsSize && { bsSize })}
        {...(groupStyle && { style: groupStyle })}
      >
        <Col componentClass={ControlLabel} sm={labelWidth}>
          {this.props.children}
        </Col>
        <Col sm={inputWidth}>
          {this._renderInput()}
          <HelpBlock>
            {validationText(this.state[this.fieldNameError], helpText)}
          </HelpBlock>
        </Col>
      </FormGroup>
    );
  }
}

ReactDateTimeField.defaultProps = {
  labelWidth: 3,
  inputWidth: 9,
  helpText: '',
  dateFormat: false,
  timeFormat: false,
  closeOnSelect: false,
};

export default ReactDateTimeField;
