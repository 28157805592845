import { Component } from 'react';
// import PropTypes from 'prop-types'
import { graphql } from '@apollo/client/react/hoc';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Row,
  Col,
  Table,
  Button,
  ButtonToolbar,
  ButtonGroup,
  FormControl,
} from 'react-bootstrap';
import Confirm from '../components/confirm';

// import _debounce from 'lodash/debounce'
// import _last from 'lodash/last'

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import { currentSettingsSet } from '../actions/current_setting_actions';

// import {
//   filterDo
// } from '../actions/filter_actions'

import SmsRiskListTable from '../components/sms/sms_risk_list_table';

import smsRiskListQuery from '../queries/sms_risk_list_query';
import smsRiskDeleteMutation from '../mutations/sms_risk_delete_mutation';

import { queriesReady } from '../lib/utils';
import Loader from '../components/loader';

class SmsRiskList extends Component {
  constructor(props) {
    super(props);
    // this._handleFilter = this._handleFilter.bind(this)
    this._handleDeleteClick = this._handleDeleteClick.bind(this);
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRouteSmsRisk: this.props.location.pathname });
    // this.delayedHandleFilter = _debounce(e => {
    //   if (e.target.value.length > 1 || e.target.value === '') {
    //     this.props.filterDo({type: 'document', q: e.target.value})
    //   }
    // }, 400)
    // if (this.props.filter_q) {
    //   this.delayedHandleFilter({ target: { value: this.props.filter_q }})
    // }
  }

  // componentWillReceiveProps (nextProps) {
  //   if (nextProps.filter_q && this.isLoading(this.props) && this.isLoaded(nextProps)) {
  //     this.delayedHandleFilter({ target: { value: nextProps.filter_q } })
  //   }
  // }

  // componentWillUnmount () {
  //   this.delayedHandleFilter.cancel()
  // }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(props.smsRiskListQuery);
  }

  _handleDeleteClick(smsRiskId) {
    this.props.mutationSet(true);
    this.props
      .smsRiskDeleteMutation({
        variables: {
          id: smsRiskId,
        },
        refetchQueries: () => [{ query: smsRiskListQuery }],
      })
      .then((res) => {
        this.props.mutationSuccess('SMS Risk delete');
      })
      .catch((err) => {
        this.props.mutationFailure(err);
      });
  }

  // _handleFilter (e) {
  //   e.persist()
  //   this.delayedHandleFilter(e)
  // }

  _renderOverlay() {
    // if (this.props.filtering || this.props.currentSettingsMutating || this.isLoading()) { return <Loader /> }
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
  }

  _renderData() {
    if (this.isLoaded()) {
      // const filtered = this.props.filter_q
      return (
        <div>
          <Row>
            <Col sm={12}>
              <div className="clearfix">
                <div className="pull-left">
                  <h3>SMS Risks</h3>
                </div>
                <div className="pull-right">
                  {/*
                    <FormControl
                      style={{display: 'inline-block', verticalAlign: 'middle', width: '200px', marginRight: '10px'}}
                      type='text'
                      placeholder='filter...'
                      defaultValue={this.props.filter_q}
                      onChange={this._handleFilter}
                    />
                  */}
                  <div className="pull-right">
                    <ButtonToolbar>
                      <ButtonGroup>
                        <LinkContainer to="/sms/risks/new">
                          <Button bsStyle="primary">Add a SMS Risk</Button>
                        </LinkContainer>
                      </ButtonGroup>
                    </ButtonToolbar>
                  </div>
                  {/*
                    <span
                      className='badge'
                      style={filtered ? { marginRight: '10px' } : { marginRight: '10px', opacity: '0.5' }}
                    >{filtered ? this.props.filter_ids.length : this.props.smsRiskListQuery.data.length }</span>
                  */}
                </div>
              </div>
            </Col>
          </Row>
          <SmsRiskListTable
            risks={this.props.smsRiskListQuery.data}
            handleDeleteClick={this._handleDeleteClick}
            withDelete
            withEdit
          />
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        {this._renderOverlay()}
        {this._renderData()}
      </div>
    );
  }
}

SmsRiskList.propTypes = {
  // filter_ids: PropTypes.array.isRequired,
  // filtering: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  // const filter = state.filters.document
  return {
    // filter_ids: filter.ids,
    // filter_q: filter.q,
    // filtering: filter.filtering,
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default compose(
  graphql(smsRiskDeleteMutation, {
    name: 'smsRiskDeleteMutation',
  }),
  graphql(smsRiskListQuery, {
    name: 'smsRiskListQuery',
  }),
  connect(mapStateToProps, {
    mutationSuccess,
    mutationFailure,
    mutationSet,
    currentSettingsSet,
  })
)(SmsRiskList);
