import { Component } from 'react';
import { Row, Col, Panel, Table, Glyphicon } from 'react-bootstrap';

class BookingSnapshot extends Component {
  _renderFlightSegmentSummary(flightSegmentSummary) {
    const {
      id,
      start_at: startAt,
      start_location: startLocation,
      end_location: endLocation,
      pax,
    } = flightSegmentSummary;
    return (
      <tr key={id}>
        <td
          style={{
            borderTop: 'none',
            padding: '0 10px 0 0',
            width: '1%',
            whiteSpace: 'nowrap',
          }}
          className="text-right"
        >
          <small>{startAt}</small>
        </td>
        <td
          style={{
            borderTop: 'none',
            padding: '0 10px 0 0',
            width: '1%',
            whiteSpace: 'nowrap',
          }}
          className="text-left"
        >
          <small>{`${startLocation} - ${endLocation}`}</small>
        </td>
        <td
          style={{ borderTop: 'none', padding: '0 10px 0 0', width: '97%' }}
          className="text-left"
        >
          <small>
            {pax ? <Glyphicon style={{ paddingRight: '5px' }} glyph="user" /> : ''}
            {pax}
          </small>
        </td>
        <td style={{ borderTop: 'none', padding: '0 10px 0 0' }}>&nbsp;</td>
      </tr>
    );
  }

  _renderFlightSegmentSummaries(flightSegmentSummaries) {
    return (
      <Table condensed>
        <tbody>
          {flightSegmentSummaries.map((flightSegmentSummary) =>
            this._renderFlightSegmentSummary(flightSegmentSummary)
          )}
        </tbody>
      </Table>
    );
  }

  render() {
    const {
      id,
      chargeable_full_names: chargeableFullNames,
      provider_full_name: providerFullName,
      aircraft_display_name_with_type: aircraftDisplayNameWithType,
      aircraft_configuration_name: aircraftConfigurationName,
      pilot_display_name: pilotDisplayName,
      copilot_display_name: copilotDisplayName,
      requested_by: requestedBy,
      customer_reference: customerReference,
      pilot_notes: pilotNotes,
      job_notes: jobNotes,
      public_notes: publicNotes,
      flight_segment_summaries: flightSegmentSummaries,
    } = this.props;

    return (
      <Row style={{ marginTop: '20px' }}>
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <Panel>
                <Panel.Heading>Details</Panel.Heading>
                <Panel.Body>
                  <dl className="dl-horizontal">
                    <dt>ID</dt>
                    <dd>{id}</dd>
                  </dl>
                  <dl className="dl-horizontal">
                    <dt>Chargeable</dt>
                    <dd>{chargeableFullNames}</dd>
                  </dl>
                  <dl className="dl-horizontal">
                    <dt>Provider</dt>
                    <dd>{providerFullName}</dd>
                  </dl>
                  <dl className="dl-horizontal">
                    <dt>Aircraft</dt>
                    <dd>{aircraftDisplayNameWithType}</dd>
                  </dl>
                  <dl className="dl-horizontal">
                    <dt>Configuration</dt>
                    <dd>{aircraftConfigurationName}</dd>
                  </dl>
                  <dl className="dl-horizontal">
                    <dt>Pilot</dt>
                    <dd>{pilotDisplayName}</dd>
                  </dl>
                  <dl className="dl-horizontal">
                    <dt>Copilot</dt>
                    <dd>{copilotDisplayName}</dd>
                  </dl>
                  <dl className="dl-horizontal">
                    <dt>Requested By</dt>
                    <dd>{requestedBy}</dd>
                  </dl>
                  <dl className="dl-horizontal">
                    <dt>Customer Reference</dt>
                    <dd>{customerReference}</dd>
                  </dl>
                  <dl className="dl-horizontal">
                    <dt>Pilot Notes</dt>
                    <dd>{pilotNotes}</dd>
                  </dl>
                  <dl className="dl-horizontal">
                    <dt>Job Notes</dt>
                    <dd>{jobNotes}</dd>
                  </dl>
                  <dl className="dl-horizontal">
                    <dt>Public Notes</dt>
                    <dd>{publicNotes}</dd>
                  </dl>
                  <dl className="dl-horizontal">
                    <dt>Flight Segments</dt>
                    <dd>
                      {flightSegmentSummaries &&
                        this._renderFlightSegmentSummaries(flightSegmentSummaries)}
                    </dd>
                  </dl>
                </Panel.Body>
              </Panel>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default BookingSnapshot;
