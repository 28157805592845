import actionTypes from '../constants';
import { manageCatch, toastSuccessDev } from '../lib/action_helpers';
import Api from '../lib/api';

export function datasReset() {
  return {
    type: actionTypes.DATA_RESET,
  };
}

export function datasFetch() {
  return {
    type: actionTypes.DATA_FETCH,
  };
}

export function datasUnfetch() {
  return {
    type: actionTypes.DATA_UNFETCH,
  };
}

export function datasLoadSuccess(payload, meta) {
  return {
    type: actionTypes.DATA_LOAD_SUCCESS,
    payload,
    meta,
  };
}

export function datasLoadFailure() {
  return {
    type: actionTypes.DATA_LOAD_FAILURE,
  };
}

export function datasLoad(
  args = {},
  meta = {},
  success = datasLoadSuccess,
  failure = datasLoadFailure
) {
  return function datasLoadDispatch(dispatch) {
    dispatch(datasFetch());
    return Api.get('/api/data', args)
      .then((res) => {
        dispatch(success(res.data, meta));
        toastSuccessDev('Settings loaded');
      })
      .catch((res) => {
        manageCatch(dispatch, res, failure);
      })
      .then(() => {
        dispatch(datasUnfetch());
      });
  };
}
