import actionTypes from '../constants';
import Api from '../lib/api';
import { manageCatch, toastSuccessDev } from '../lib/action_helpers';

export function suggestionsReset() {
  return {
    type: actionTypes.SUGGESTION_RESET,
  };
}

export function suggestionsFilterSuccess(payload) {
  return {
    type: actionTypes.SUGGESTION_FILTER_SUCCESS,
    payload,
  };
}

export function suggestionsFilterFailure() {
  return {
    type: actionTypes.SUGGESTION_FILTER_FAILURE,
  };
}

export function suggestionsFilterClear() {
  return {
    type: actionTypes.SUGGESTION_FILTER_CLEAR,
  };
}

export function suggestionsFilter(
  args = {},
  success = suggestionsFilterSuccess,
  failure = suggestionsFilterFailure,
  clear = suggestionsFilterClear
) {
  return function suggestionsFilterDispatch(dispatch) {
    if (args.q) {
      Api.get(`/api/search/all/${args.q}`)
        .then((res) => {
          dispatch(success(res.data.sort((a, b) => a.name.localeCompare(b.name))));
          toastSuccessDev('SUGGESTION filtered');
        })
        .catch((res) => {
          manageCatch(dispatch, res, failure);
        });
    } else {
      dispatch(clear());
      toastSuccessDev('SUGGESTION cleared');
    }
  };
}
