/* eslint-disable implicit-arrow-linebreak */
import { ApolloClient, ApolloLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
// import { WebSocketLink } from '@apollo/client/link/ws';
// import { SubscriptionClient } from 'subscriptions-transport-ws';
import { getMainDefinition } from '@apollo/client/utilities';

import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
// import { createClient } from 'graphql-ws';

import { onError } from '@apollo/client/link/error';
import { InMemoryCache } from '@apollo/client/cache';

import _get from 'lodash/get';
import _pick from 'lodash/pick';

import { getIdToken } from './local_storage';
import subscriptionClient from './subscription_client';

// const makeApolloClient = (connectionCallback) => {
const httpLinkOptions = {
  uri: `${process.env.EXPRESS_API_URL}/graphql`,
};

// const subscriptionClient = new SubscriptionClient(
//   `${process.env.EXPRESS_WS_URL}/subscriptions`,
//   {
//     reconnect: true,
//     connectionParams: () => ({
//       token: getIdToken() && `${getIdToken()}`,
//     }),
//     connectionCallback,
//   }
// );

// const subscriptionLink = new WebSocketLink(subscriptionClient);

// const subscriptionClient = createClient({
//   url: `${process.env.EXPRESS_WS_URL}/subscriptions`,
//   // reconnect: true,
//   lazy: false,
//   connectionParams: () => ({
//     token: getIdToken() && `${getIdToken()}`,
//   }),
//   on: {
//     // connected: connectionCallback,
//     closed: (resp) => console.log('closed', resp),
//     connected: (resp) => console.log('connected', resp),
//     connecting: (resp) => console.log('connecting', resp),
//     error: (resp) => console.log('error', resp),
//     message: (resp) => console.log('message', resp),
//     opened: (resp) => console.log('opened', resp),
//   },
// });

const subscriptionLink = new GraphQLWsLink(subscriptionClient);

const httpLinks = ApolloLink.split(
  (operation) => operation.getContext().hasUpload,
  createUploadLink(httpLinkOptions),
  new BatchHttpLink(httpLinkOptions)
);

const httpLink = ApolloLink.split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  subscriptionLink,
  httpLinks
);
// const httpLink = httpLinks;

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: `Bearer ${getIdToken()}`,
    },
  });
  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const reportError = window.$NODE_ENV !== 'development';
  let isUnauthorized = false;
  let isForbidden = false;

  if (networkError) {
    isUnauthorized = networkError.statusCode === 401;
    if (isUnauthorized && reportError) {
      window.Rollbar.info(networkError, networkError.result);
    }
  }
  if (graphQLErrors && Array.isArray(graphQLErrors)) {
    isUnauthorized = graphQLErrors.some(
      (graphQLError) => _get(graphQLError, 'extensions.code') === 'UNAUTHENTICATED'
    );
    if (isUnauthorized && reportError) {
      const graphQLError = graphQLErrors.find(
        (err) => _get(err, 'extensions.code') === 'UNAUTHENTICATED'
      );
      window.Rollbar.info('UNAUTHENTICATED', graphQLError);
    }

    isForbidden = graphQLErrors.some(
      (graphQLError) => _get(graphQLError, 'extensions.code') === 'FORBIDDEN'
    );
    if (isForbidden && reportError) {
      const graphQLError = graphQLErrors.find(
        (err) => _get(err, 'extensions.code') === 'FORBIDDEN'
      );
      window.Rollbar.info('FORBIDDEN', graphQLError);
    }
  }

  if (window.$NODE_ENV === 'development') {
    console.log('NETWORK: ', JSON.stringify(networkError, undefined, 2));
    console.log('GRAPHQL: ', JSON.stringify(graphQLErrors, undefined, 2));
    console.log({ reportError, isUnauthorized, isForbidden });
  }

  if (isUnauthorized || isForbidden) {
    // todo brittle
    window.location = '/auth/logout';
  } else if (networkError && reportError) {
    window.Rollbar.error(networkError, networkError.result);
  } else if (graphQLErrors && Array.isArray(graphQLErrors) && reportError) {
    graphQLErrors.forEach((graphQLError) => {
      const code = _get(graphQLError, 'extensions.code', '[GraphQL error]');
      if (code === 'BAD_USER_INPUT') {
        window.Rollbar.info(
          code,
          _pick(_get(graphQLError, ['extensions']), ['code', 'data', 'config'])
        );
      } else {
        window.Rollbar.error(code, graphQLError);
      }
    });
  }
});

const link = ApolloLink.from([errorLink, authLink, httpLink]);

const cache = new InMemoryCache({
  addTypename: true,
  // dataIdFromObject: (o) => {
  //   const id = o.id ? o.id : o.Id;
  //   // eslint-disable-next-line no-underscore-dangle
  //   return `${o.__typename}-${id}`;
  // },
  possibleTypes: {
    CheckableType: ['ContactType', 'OtherAssetType'],
  },
  typePolicies: {
    BookingType: {
      fields: {
        bookingEmployees: {
          merge: false,
        },
        employees: {
          merge: false,
        },
        bookingChargeables: {
          merge: false,
        },
        chargeables: {
          merge: false,
        },
        flightSegments: {
          merge: false,
        },
        flightSegmentSumaries: {
          merge: false,
        },
        engineEvents: {
          merge: false,
        },
        fuelTankerFills: {
          merge: false,
        },
        fuelBowserFills: {
          merge: false,
        },
        oilFills: {
          merge: false,
        },
        pilotFlightExpenses: {
          merge: false,
        },
        pilotFlightLogs: {
          merge: false,
        },
      },
    },
    ContactType: {
      fields: {
        addresses: {
          merge: false,
        },
        documents: {
          merge: false,
        },
        phoneNumbers: {
          merge: false,
        },
        providerAircrafts: {
          merge: false,
        },
        relatedAircrafts: {
          merge: false,
        },
        relatedChargeables: {
          merge: false,
        },
        relatedPilots: {
          merge: false,
        },
        roles: {
          merge: false,
        },
        statutoryRoles: {
          merge: false,
        },
        taggings: {
          merge: false,
        },
      },
    },
    LocationType: {
      fields: {
        locationImages: {
          merge: false,
        },
        fuelBowsers: {
          merge: false,
        },
        namedPilots: {
          merge: false,
        },
        locationServices: {
          merge: false,
        },
        locationImageLargeUrls: {
          merge: false,
        },
      },
    },
    Query: {
      fields: {
        bookingList: {
          merge: false,
        },
        contactList: {
          merge: false,
        },
        locationList: {
          merge: false,
        },
        documentList: {
          merge: false,
        },
        checkList: {
          merge: false,
        },
      },
    },
  },
  // cacheRedirects: {
  //   Query: {
  //     batch: (_, args, { getCacheKey }) =>
  // getCacheKey({ __typename: 'BatchType', id: args.id }),
  //     batchTemplate: (_, args, { getCacheKey }) =>
  // getCacheKey({ __typename: 'BatchTemplateType', id: args.id }),
  //     reporter: (_, args, { getCacheKey }) =>
  // getCacheKey({ __typename: 'ReporterType', id: args.id }),
  //   },
  // },
});

const apolloClient = new ApolloClient({
  link,
  cache,
  // // eslint-disable-next-line no-underscore-dangle
  // cache: cache.restore(window.__APOLLO_STATE__ || {}),
  // connectToDevTools: true, // allows in production
  // shouldBatch: true,
  // local state
  // https://blog.apollographql.com/announcing-apollo-client-2-5-c12230cabbb7
  // resolvers: { ... },
  // typeDefs: { ... },
});

// client.closeWSConnection = (permanent = true) => {
//   // subscriptionClient.close(permanent);
//   // if (permanent) {
//   //   subscriptionClient.dispose();
//   // } else {
//   //   subscriptionClient.terminate();
//   // }
// };

// client.openWSConnection = () => {
//   // subscriptionClient.connect();
//   // subscriptionClient.subscribe();
// };

// client.resetWSConnection = () => {
//   // subscriptionClient.close(true);
//   // subscriptionClient.connect();
//   // subscriptionClient.terminate();
// };

// const hydrateCache = async () => {
//   client.query({
//     query: hydrateQuery,
//     fetchPolicy: 'network-only',
//   });
// };

// return {
//   client,
//   subscriptionClient,
// };
// };

// export default makeApolloClient;
export default apolloClient;
