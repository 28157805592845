import assign from 'lodash/assign';
import omit from 'lodash/omit';

export function isInvalid(meta) {
  return meta.touched && meta.invalid;
}

export function validationStates(fields) {
  return fields.map((field) => validationState(field)).filter((field) => field).length > 0
    ? 'error'
    : null;
}

export function validationState(field) {
  return field.touched && field.invalid ? 'error' : null;
}

export function validationText(field, helpText = '') {
  return field.touched && field.error && field.error !== '' ? field.error : helpText;
}
