import { Col } from 'react-bootstrap';
import { Component } from 'react';
import moment from 'moment';

import _first from 'lodash/first';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import _last from 'lodash/last';

import FlightSegment from './flight_segment';

import { FlightSegmentsTogsMemo } from '../../memos';

moment.updateLocale('en-nz');

class FlightSegmentFieldArray extends Component {
  constructor(props) {
    super(props);
    this._handleDeleteClicked = this._handleDeleteClicked.bind(this);
  }

  _handleDeleteClicked(index) {
    const flightSegmentValues = get(this.props, ['flight_segments_attributes', index]);
    if (get(flightSegmentValues, 'id')) {
      this.props.change(
        ['flight_segments_attributes', index, '_destroy'].join('.'),
        true
      );
    } else {
      this.props.flightSegments.fields.remove(index);
    }
  }

  _getTogs(flightSegments) {
    const datetimes = [
      '',
      ...flatten(flightSegments.map((fs) => [fs.start_at, fs.end_at])),
    ];
    return FlightSegmentsTogsMemo(datetimes);
  }

  _getPrevEndAts(flightSegments) {
    return [this.props.start_at, ...flightSegments.map((fs) => fs.end_at)];
  }

  render() {
    let activeIndex = -1;
    const activeFlightSegments = get(this.props, 'flight_segments_attributes', []).filter(
      (fs) => !fs._delete
    );
    const activeTogs = this._getTogs(activeFlightSegments);
    const activePrevEndAts = this._getPrevEndAts(activeFlightSegments);
    return (
      <Col sm={12}>
        {this.props.flightSegments.fields.map((field, index) => {
          const values = get(this.props, field, {});
          if (!values._destroy) {
            activeIndex += 1;
            return (
              <FlightSegment
                key={`fs-${index}`}
                index={index}
                activeIndex={activeIndex}
                updating={this.props.updating}
                field={field}
                isMd={this.props.containerWidth < 1200}
                isSm={this.props.containerWidth < 992}
                isXs={this.props.containerWidth < 768}
                formValues={values}
                addingPassenger={this.props.addingPassenger}
                typeaheadFetching={this.props.typeaheadFetching}
                passengersTypeaheadIndex={this.props.passengersTypeaheadIndex}
                passengersTypeaheadCollection={
                  _last(this.props.passengersTypeaheadIndex) === index
                    ? this.props.passengersTypeaheadCollection
                    : []
                }
                handlePassengerSearch={this.props.handlePassengerSearch}
                handleAddPassengerModalClicked={this.props.handleAddPassengerModalClicked}
                handlePassengerChanged={this.props.handlePassengerChanged}
                addingLocation={this.props.addingLocation}
                handleDeleteClicked={this._handleDeleteClicked}
                handleAddFlightSegmentModalClicked={
                  this.props.handleAddFlightSegmentModalClicked
                }
                handleAddLocationClicked={this.props.handleAddLocationClicked}
                handleAddLocationModalClicked={this.props.handleAddLocationModalClicked}
                handleStartLocationChanged={this.props.handleStartLocationChanged}
                handleEndLocationChanged={this.props.handleEndLocationChanged}
                handleLocationSearch={this.props.handleLocationSearch}
                handleCriticalLocationModalClicked={
                  this.props.handleCriticalLocationModalClicked
                }
                locationsTypeaheadIndex={this.props.locationsTypeaheadIndex}
                locationsTypeaheadCollection={
                  _first(this.props.locationsTypeaheadIndex) === index
                    ? this.props.locationsTypeaheadCollection
                    : []
                }
                tog={activeTogs[activeIndex]}
                prevEndAt={activePrevEndAts[activeIndex]}
                adjustedFlightDistance={get(this.props.distanceData, [
                  activeIndex,
                  'adjusted',
                ])}
                aircraftNetWeight={get(this.props.weightData, [
                  activeIndex,
                  'aircraftNetWeight',
                ])}
                loadedFlighttime={get(this.props.flighttimeData, [
                  activeIndex,
                  'flightSegmentLoadedFlighttime',
                ])}
                alertFuelOver={get(
                  this.props.fuelData,
                  [activeIndex, 'alertFuelOver'],
                  false
                )}
                alertFuelUnder={get(
                  this.props.fuelData,
                  [activeIndex, 'alertFuelUnder'],
                  false
                )}
                startFuelCalcKg={get(
                  this.props.fuelData,
                  [activeIndex, 'startFuelCalcKg'],
                  0
                )}
                bookingPilotId={this.props.pilot_id}
                bookingAircraftId={this.props.aircraft_id}
                bookingPassengerSeats={this.props.bookingPassengerSeats}
                aircraftsDataSelector={this.props.aircraftsDataSelector}
                contactsDataSelector={this.props.contactsDataSelector}
                locationsDataSelector={this.props.locationsDataSelector}
              />
            );
          }
        })}
      </Col>
    );
  }
}

export default FlightSegmentFieldArray;
