import { Component } from 'react';
import { Row, Col, FormGroup, FormControl, Button, ControlLabel } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment';

import first from 'lodash/first';
import get from 'lodash/get';

moment.updateLocale('en-nz');

class FlightSignoffInfo extends Component {
  _renderFixed(value) {
    return value ? value.toFixed(1) : '-';
  }

  _renderFlightSegmentSummary(flightSegmentSummary) {
    const {
      start_at: startAt,
      start_location: startLocation,
      end_location: endLocation,
      pax,
    } = flightSegmentSummary;
    const summary = `${startAt} ${startLocation} - ${endLocation}`;
    return (
      <span>
        <strong>
          {summary} {pax ? ' | ' : ''}
        </strong>
        {pax}
      </span>
    );
  }

  _renderFuelBowserFill(fuelBowserFill) {
    const fuelBowserName = get(this.props.fuelBowsersDataSelector, [
      get(fuelBowserFill, 'fuel_bowser_id'),
      'name',
    ]);
    const value = get(fuelBowserFill, 'quantity_value');
    const quantityUnit = this.props.currentSettingsFuelBowserFillDefaultQuantityUnit;
    const names = fuelBowserName.split('/');
    const locationName = first(names);
    const type = names[1];
    return (
      <span>
        <strong>{value.toFixed(2)}</strong> {quantityUnit}
        <strong> {locationName}</strong> - {type}
      </span>
    );
  }

  _renderFuelTankerFill(fuelTankerFill) {
    const fuelTankerName = get(this.props.fuelTankersDataSelector, [
      get(fuelTankerFill, 'fuel_tanker_id'),
      'name',
    ]);
    const value = get(fuelTankerFill, 'quantity_value');
    const quantityUnit = this.props.currentSettingsFuelBowserFillDefaultQuantityUnit;
    return (
      <span>
        <strong>{value.toFixed(2)}</strong> {quantityUnit}
        <strong> {fuelTankerName}</strong>
        {' - tanker'}
      </span>
    );
  }

  _renderEngineEvent(engineEvent) {
    const { event_value: eventValue, event_type: eventType } = engineEvent;
    const summary = `${eventType} ${eventValue}`;
    return (
      <span>
        <strong>{summary}</strong>
      </span>
    );
  }

  render() {
    const {
      booking: {
        id,
        start_at,
        reference,
        provider_id,
        flight_type_id,
        pilot_id,
        bookingChargeables,
        hobbRecord: { start_hobb: startHobb, end_hobb: endHobb, flight_time: flightTime },
        flightSegmentSummaries,
        fuelBowserFills,
        fuelTankerFills,
        engineEvents,
      },
    } = this.props;

    const chargeableFullNames = bookingChargeables
      .map((bca) => get(this.props.contactsDataSelector, [bca.chargeable_id, 'fullName']))
      .join(', ');

    const flightTypeName = get(this.props.flightTypesDataSelector, [
      flight_type_id,
      'name',
    ]);
    const pilotFullName = get(this.props.contactsDataSelector, [pilot_id, 'fullName']);
    const providerFullName = get(this.props.contactsDataSelector, [
      provider_id,
      'fullName',
    ]);

    return (
      <Col xs={4}>
        <Row>
          <Col className="form-horizontal">
            <FormGroup bsSize="sm" style={{ marginBottom: 0 }}>
              <Col componentClass={ControlLabel} sm={4}>
                Date:
              </Col>
              <Col sm={8}>
                <FormControl.Static>
                  <strong>{moment(start_at).format('llll')}</strong>
                </FormControl.Static>
              </Col>
            </FormGroup>
            <FormGroup bsSize="sm" style={{ marginBottom: 0 }}>
              <Col componentClass={ControlLabel} sm={4}>
                Reference:
              </Col>
              <Col sm={8}>
                <FormControl.Static>
                  <LinkContainer
                    style={{ display: 'block', textAlign: 'left' }}
                    to={`/flights/${id}/edit`}
                  >
                    <Button bsStyle="link" bsSize="xsmall">
                      <strong>{`#${reference}`}</strong>
                    </Button>
                  </LinkContainer>
                </FormControl.Static>
              </Col>
            </FormGroup>
            <FormGroup bsSize="sm" style={{ marginBottom: 0 }}>
              <Col componentClass={ControlLabel} sm={4}>
                Charge to:
              </Col>
              <Col sm={8}>
                <FormControl.Static>
                  <strong>{chargeableFullNames}</strong>
                </FormControl.Static>
              </Col>
            </FormGroup>
            <FormGroup bsSize="sm" style={{ marginBottom: 0 }}>
              <Col componentClass={ControlLabel} sm={4}>
                Allocate to:
              </Col>
              <Col sm={8}>
                <FormControl.Static>
                  <strong>{providerFullName}</strong>
                </FormControl.Static>
              </Col>
            </FormGroup>
            <FormGroup bsSize="sm" style={{ marginBottom: 0 }}>
              <Col componentClass={ControlLabel} sm={4}>
                Flight type:
              </Col>
              <Col sm={8}>
                <FormControl.Static>
                  <strong>{flightTypeName}</strong>
                </FormControl.Static>
              </Col>
            </FormGroup>
            <FormGroup bsSize="sm" style={{ marginBottom: 0 }}>
              <Col componentClass={ControlLabel} sm={4}>
                Pilot:
              </Col>
              <Col sm={8}>
                <FormControl.Static>
                  <strong>{pilotFullName}</strong>
                </FormControl.Static>
              </Col>
            </FormGroup>
            <FormGroup bsSize="sm" style={{ marginBottom: 0 }}>
              <Col componentClass={ControlLabel} sm={4}>
                Summary:
              </Col>
              <Col sm={8}>
                <FormControl.Static style={{ height: 'inherit' }}>
                  {flightSegmentSummaries.map((flightSegmentSummary, index) => (
                    <span
                      key={`flight-segment-summary-${index}`}
                      style={{ display: 'block' }}
                    >
                      {this._renderFlightSegmentSummary(flightSegmentSummary)}
                    </span>
                  ))}
                </FormControl.Static>
              </Col>
            </FormGroup>
            <FormGroup bsSize="sm" style={{ marginBottom: 0 }}>
              <Col componentClass={ControlLabel} sm={4}>
                Hobb Record:
              </Col>
              <Col sm={8}>
                <FormControl.Static style={{ height: 'inherit' }}>
                  <span style={{ display: 'block' }}>
                    <strong>{`${this._renderFixed(startHobb)} / ${this._renderFixed(
                      endHobb
                    )}`}</strong>
                  </span>
                  <span style={{ display: 'block' }}>
                    <strong>{this._renderFixed(flightTime)}</strong>
                  </span>
                </FormControl.Static>
              </Col>
            </FormGroup>
            <FormGroup bsSize="sm" style={{ marginBottom: 0 }}>
              <Col componentClass={ControlLabel} sm={4}>
                Fuel:
              </Col>
              <Col sm={8}>
                <FormControl.Static style={{ height: 'inherit' }}>
                  {fuelBowserFills
                    ? fuelBowserFills.map((fuelBowserFill, index) => (
                        <span key={`fuel-fill-${index}`} style={{ display: 'block' }}>
                          {this._renderFuelBowserFill(fuelBowserFill)}
                        </span>
                      ))
                    : ''}
                  {fuelTankerFills ? (
                    fuelTankerFills.map((fuelTankerFill, index) => (
                      <span key={`fuel-tanker-${index}`} style={{ display: 'block' }}>
                        {this._renderFuelTankerFill(fuelTankerFill)}
                      </span>
                    ))
                  ) : (
                    <span>{!(fuelBowserFills || fuelTankerFills) ? '-' : ''}</span>
                  )}
                </FormControl.Static>
              </Col>
            </FormGroup>
            <FormGroup bsSize="sm" style={{ marginBottom: 0 }}>
              <Col componentClass={ControlLabel} sm={4}>
                Events:
              </Col>
              <Col sm={8}>
                <FormControl.Static style={{ height: 'inherit' }}>
                  {engineEvents.map((engineEvent, index) => (
                    <span key={`engine-event-${index}`} style={{ display: 'block' }}>
                      {this._renderEngineEvent(engineEvent)}
                    </span>
                  ))}
                </FormControl.Static>
              </Col>
            </FormGroup>
          </Col>
        </Row>
      </Col>
    );
  }
}

export default FlightSignoffInfo;
