import get from 'lodash/get';
import memoize from 'lodash/memoize';

import { BookingAirspeedDataMemo } from './booking_airspeed_data_memo';

export const FlightSegmentAirspeedDataMemo = memoize(
  ({
    airspeedAdj,
    bookingCruiseAirspeedSlKnots,
    aircraftId,
    aircrafts,
    aircraftTypes,
  }) => {
    if (aircraftId) {
      const bookingAirspeedData = BookingAirspeedDataMemo({
        bookingCruiseAirspeedSlKnots,
        aircraftId,
        aircrafts,
        aircraftTypes,
      });
      return Object.assign({}, bookingAirspeedData, {
        adjustment: airspeedAdj,
        // todo is this right?
        adjustmentKnots: airspeedAdj ? airspeedAdj / 1.852 : 0,
        flightSegmentAirspeedKnots:
          get(bookingAirspeedData, 'bookingAirspeedKnots', 0) +
          (airspeedAdj ? airspeedAdj / 1.852 : 0),
        flightSegmentAirspeedKmph:
          get(bookingAirspeedData, 'bookingAirspeedKnots', 0) * 1.852 +
          (airspeedAdj || 0),
      });
    } else {
      return {};
    }
  },
  ({ airspeedAdj, bookingCruiseAirspeedSlKnots, aircraftId }) => {
    if (aircraftId) {
      return [aircraftId, bookingCruiseAirspeedSlKnots, airspeedAdj]
        .map((i) => String(i))
        .join('.');
    } else {
      return 'unknown';
    }
  }
);
