import { FormGroup, Col, Checkbox, HelpBlock, ControlLabel } from 'react-bootstrap';

import clone from 'lodash/clone';
import upperFirst from 'lodash/upperFirst';

import { validationState, validationText } from '../form/helpers';

const LocationFormNamedPilotIdsField = (props) => {
  const {
    children,
    input,
    meta,
    pilots,
    formValues: { named_pilot_ids: namedPilotIds },
  } = props;

  function _handleCheckboxChange(e) {
    const ids = clone(namedPilotIds);
    let id = null;
    if (isNaN(e.target.getAttribute('data-id'))) {
      id = e.target.getAttribute('data-id');
    } else {
      id = parseInt(e.target.getAttribute('data-id'));
    }
    if (id) {
      const idIndex = ids.indexOf(id);
      if (e.target.checked) {
        if (idIndex === -1) {
          input.onChange([id, ...ids]);
        }
      } else if (idIndex > -1) {
        ids.splice(idIndex, 1);
        input.onChange(ids);
      }
    }
  }

  return (
    <FormGroup controlId="locationNamedPilotIds" validationState={validationState(meta)}>
      <Col componentClass={ControlLabel} sm={3}>
        {children}
      </Col>
      <Col sm={9}>
        {pilots.map((pilot) => {
          const { id, fullName } = pilot;
          return (
            <Col sm={3} key={id}>
              <Checkbox
                data-id={id}
                checked={namedPilotIds.indexOf(id) > -1}
                value={id}
                onChange={_handleCheckboxChange}
              >
                {fullName}
              </Checkbox>
            </Col>
          );
        })}
        <HelpBlock>{validationText(meta)}</HelpBlock>
      </Col>
    </FormGroup>
  );
};

export default LocationFormNamedPilotIdsField;
