import { Row, Col, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { Component } from 'react';

import { graphql } from '@apollo/client/react/hoc';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import { currentSettingsSet } from '../actions/current_setting_actions';

import SmsControlListTable from '../components/sms/sms_control_list_table';

import smsControlListQuery from '../queries/sms_control_list_query';
import smsControlDeleteMutation from '../mutations/sms_control_delete_mutation';

import { queriesReady } from '../lib/utils';
import Loader from '../components/loader';

class SmsControlList extends Component {
  constructor(props) {
    super(props);
    this._handleDeleteClick = this._handleDeleteClick.bind(this);
  }

  componentDidMount() {
    this.props.currentSettingsSet({
      returnRouteSmsControl: this.props.location.pathname,
    });
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(props.smsControlListQuery);
  }

  _handleDeleteClick(smsControlId) {
    this.props.mutationSet(true);
    this.props
      .smsControlDeleteMutation({
        variables: {
          id: smsControlId,
        },
        refetchQueries: () => [{ query: smsControlListQuery }],
      })
      .then((res) => {
        this.props.mutationSuccess('SMS Control delete');
      })
      .catch((err) => {
        this.props.mutationFailure(err);
      });
  }

  _renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
  }

  _renderData() {
    if (this.isLoaded()) {
      return (
        <div>
          <Row>
            <Col sm={12}>
              <div className="clearfix">
                <div className="pull-left">
                  <h3>SMS Controls</h3>
                </div>
                <div className="pull-right">
                  <div className="pull-right">
                    <ButtonToolbar>
                      <ButtonGroup>
                        <LinkContainer to="/sms/controls/new">
                          <Button bsStyle="primary">Add a SMS Control</Button>
                        </LinkContainer>
                      </ButtonGroup>
                    </ButtonToolbar>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <SmsControlListTable
            controls={this.props.smsControlListQuery.data}
            handleDeleteClick={this._handleDeleteClick}
            withDelete
            withEdit
          />
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        {this._renderOverlay()}
        {this._renderData()}
      </div>
    );
  }
}

SmsControlList.propTypes = {
  // filter_ids: PropTypes.array.isRequired,
  // filtering: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default compose(
  graphql(smsControlDeleteMutation, {
    name: 'smsControlDeleteMutation',
  }),
  graphql(smsControlListQuery, {
    name: 'smsControlListQuery',
  }),
  connect(mapStateToProps, {
    mutationSuccess,
    mutationFailure,
    mutationSet,
    currentSettingsSet,
  })
)(SmsControlList);
