import {
  Col,
  Form,
  FormGroup,
  ControlLabel,
  Button,
  ButtonToolbar,
  ButtonGroup,
} from 'react-bootstrap';
import { Component } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { reduxForm, Field, getFormValues } from 'redux-form';

import { LinkContainer } from 'react-router-bootstrap';

import Validator from 'validatorjs';

import _cloneDeep from 'lodash/cloneDeep';
import _difference from 'lodash/difference';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
// import _intersection from 'lodash/intersection'
import _isNil from 'lodash/isNil';
import _pick from 'lodash/pick';
import _omitBy from 'lodash/omitBy';
import _set from 'lodash/set';
import _without from 'lodash/without';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import { currentSettingsSet } from '../actions/current_setting_actions';

import InputField from '../components/form/input_field';

import SmsRiskListTable from '../components/sms/sms_risk_list_table';
import SmsRiskCreateModal from '../components/sms/sms_risk_create_modal';
import SmsReportRiskAlterModal from '../components/sms/sms_report_risk_alter_modal';

import SmsControlCreateModal from '../components/sms/sms_control_create_modal';

import smsReportQuery from '../queries/sms_report_query';
import smsReportListQuery from '../queries/sms_report_list_query';
import smsRiskListQuery from '../queries/sms_risk_list_query';
import contactItemListQuery from '../queries/contact_item_list_query';

import smsReportUpdateMutation from '../mutations/sms_report_update_mutation';
import smsReportCreateMutation from '../mutations/sms_report_create_mutation';

import smsRiskCreateMutation from '../mutations/sms_risk_create_mutation';

import smsReportRiskCreateMutation from '../mutations/sms_report_risk_create_mutation';
import smsReportRiskDeleteMutation from '../mutations/sms_report_risk_delete_mutation';

import smsControlCreateMutation from '../mutations/sms_control_create_mutation';

import { queryReady, queriesReady, typeInput } from '../lib/utils';
import Loader from '../components/loader';

let isInitialisedSmsReportForm = false;

class SmsReportForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: !!this.props.params.id,
      isRiskAltering: false,
      isRiskCreating: false,
      isControlCreating: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAlterRiskClicked = this.handleAlterRiskClicked.bind(this);
    this.handleAlterRiskCancel = this.handleAlterRiskCancel.bind(this);
    this.handleAlterRiskSubmit = this.handleAlterRiskSubmit.bind(this);

    this.handleCreateRiskClicked = this.handleCreateRiskClicked.bind(this);
    this.handleCreateRiskCancel = this.handleCreateRiskCancel.bind(this);
    this.handleCreateRiskSubmit = this.handleCreateRiskSubmit.bind(this);

    this.handleRemoveRiskClicked = this.handleRemoveRiskClicked.bind(this);

    this.handleCreateControlClick = this.handleCreateControlClick.bind(this);
    this.handleCreateControlCancel = this.handleCreateControlCancel.bind(this);
    this.handleCreateControlSubmit = this.handleCreateControlSubmit.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.currentSettingsSet({ returnRouteSmsRisk: this.props.location.pathname });
  }

  componentWillUnmount() {
    isInitialisedSmsReportForm = false;
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(
      testProps.smsRiskListQuery,
      testProps.employeeListQuery,
      [testProps.smsReportQuery, true] // ignore if undefined
    );
  }

  handleSubmit(data) {
    // 'remove riskIds from data'
    const submitData = _cloneDeep(data);
    delete submitData.riskIds;
    this.props.mutationSet(true);
    let mutation;
    let mutationMessage;
    const mutationData = {
      variables: {
        input: typeInput(submitData),
      },
      refetchQueries: () => [{ query: smsReportListQuery }],
    };
    if (this.state.updating) {
      mutation = this.props.smsReportUpdateMutation;
      mutationMessage = 'Report update';
      mutationData.variables.id = this.props.params.id;
    } else {
      mutation = this.props.smsReportCreateMutation;
      mutationMessage = 'Report create';
    }
    return mutation(mutationData)
      .then(() => {
        this.props.mutationSuccess(mutationMessage);
        this.props.navigate('/sms/reports');
      })
      .catch((err) => this.props.mutationFailure(err, true));
  }

  handleAlterRiskClicked() {
    this.setState({ isRiskAltering: true });
  }

  handleAlterRiskCancel() {
    this.setState({ isRiskAltering: false });
  }

  handleAlterRiskSubmit(riskIds) {
    this.setState({ isRiskAltering: false });
    const createIds = _difference(riskIds, this.props.formValues.riskIds);
    const deleteIds = _difference(this.props.formValues.riskIds, riskIds);
    if (createIds.length > 0 || deleteIds.length > 0) {
      this.props.mutationSet(true);
      let createMutations = [Promise.resolve({})];
      let deleteMutations = [Promise.resolve({})];
      if (createIds.length > 0) {
        createMutations = createIds.map((id) =>
          this.props.smsReportRiskCreateMutation({
            variables: { input: { reportId: this.props.params.id, riskId: id } },
          })
        );
      }
      if (deleteIds.length > 0) {
        deleteMutations = deleteIds.map((id) => {
          const risk = this.props.smsRiskListQuery.data.find(
            (r) => r.id === parseInt(id, 10)
          );
          const reportRisk = risk.reportRisks.find(
            (rr) => rr.reportId === parseInt(this.props.params.id, 10)
          );
          if (reportRisk) {
            return this.props.smsReportRiskDeleteMutation({
              variables: { id: reportRisk.id },
            });
          } else {
            return Promise.resolve({});
          }
        });
      }
      Promise.all([...createMutations, ...deleteMutations])
        .then(() => {
          this.props.mutationSuccess('Risk adds and removes');
          this.props.smsRiskListQuery.refetch();
          this.props.smsReportQuery.refetch();
          this.props.change('riskIds', riskIds);
        })
        .catch((err) => this.props.mutationFailure(err));
    }
  }

  handleCreateRiskClicked() {
    this.setState({ isRiskCreating: true });
  }

  handleCreateRiskCancel() {
    this.setState({ isRiskCreating: false });
  }

  handleCreateRiskSubmit(data) {
    this.setState({ isRiskCreating: false });
    this.props.mutationSet(true);
    return this.props
      .smsRiskCreateMutation({
        variables: { input: typeInput(data) },
      })
      .then((resRisk) => {
        const riskId = resRisk.data.smsRiskCreate.id;
        return this.props
          .smsReportRiskCreateMutation({
            variables: { input: { reportId: this.props.params.id, riskId } },
          })
          .then(() => {
            this.props.mutationSuccess('Risk create');
            this.props.smsRiskListQuery.refetch();
            this.props.smsReportQuery.refetch();
            this.props.change('riskIds', [...this.props.formValues.riskIds, riskId]);
          })
          .catch((err) => this.props.mutationFailure(err));
      })
      .catch((errRisk) => this.props.mutationFailure(errRisk));
  }

  handleRemoveRiskClicked(riskId) {
    const riskIds = _without(this.props.formValues.riskIds, parseInt(riskId, 10));
    this.handleAlterRiskSubmit(riskIds);
  }

  handleCreateControlClick(riskId) {
    this.setState({ isControlCreating: riskId });
  }

  handleCreateControlCancel() {
    this.setState({ isControlCreating: false });
  }

  handleCreateControlSubmit(data) {
    const submitData = _cloneDeep(data);
    submitData.riskId = this.state.isControlCreating;
    this.setState({ isControlCreating: false });
    this.props.mutationSet(true);
    return this.props
      .smsControlCreateMutation({
        variables: { input: typeInput(submitData) },
      })
      .then(() => {
        this.props.mutationSuccess('Control create');
        this.props.smsRiskListQuery.refetch();
      })
      .catch((err) => this.props.mutationFailure(err));
  }

  renderTitle() {
    if (this.state.updating) {
      return 'Edit Report';
    } else {
      return 'New Report';
    }
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (
      isInitialisedSmsReportForm &&
      this.isLoaded() &&
      _get(this.props, 'formValues') &&
      (!this.state.updating || _get(this.props, 'formValues.id'))
    ) {
      const { handleSubmit, pristine, submitting, valid } = this.props;

      return (
        <div>
          <SmsReportRiskAlterModal
            risks={this.props.smsRiskListQuery.data}
            riskIds={this.props.formValues.riskIds}
            isAltering={this.state.isRiskAltering}
            handleAlterCancel={this.handleAlterRiskCancel}
            handleAlterSubmit={this.handleAlterRiskSubmit}
          />
          <SmsRiskCreateModal
            isCreating={this.state.isRiskCreating}
            handleCreateCancel={this.handleCreateRiskCancel}
            handleCreateSubmit={this.handleCreateRiskSubmit}
          />
          <SmsControlCreateModal
            isCreating={!!this.state.isControlCreating}
            handleCreateCancel={this.handleCreateControlCancel}
            handleCreateSubmit={this.handleCreateControlSubmit}
          />
          <Form horizontal onSubmit={handleSubmit(this.handleSubmit)}>
            <h2>{this.renderTitle()}</h2>
            <fieldset>
              <legend>Details</legend>
              <Field
                type="text"
                name="status"
                inputWidth={3}
                noTab
                component={InputField}
                componentClass="select"
                defaultSelectOption={false}
                selectOptions={this.props.currentSettingsSmsReportStatuses.map(
                  (status) => ({
                    id: status,
                    name: status,
                  })
                )}
              >
                Status
              </Field>
              <Field
                type="text"
                name="category"
                inputWidth={3}
                noTab
                component={InputField}
                componentClass="select"
                defaultSelectOption={false}
                selectOptions={this.props.currentSettingsSmsReportCategories.map(
                  (category) => ({
                    id: category,
                    name: category,
                  })
                )}
              >
                Category
              </Field>
              <Field
                type="text"
                name="employeeId"
                inputWidth={3}
                component={InputField}
                componentClass="select"
                selectOptions={this.props.employeeListQuery.data.map((e) => ({
                  id: e.id,
                  name: e.fullName,
                }))}
              >
                Reporting Employee
              </Field>
              <Field
                type="text"
                name="shortDescription"
                component={InputField}
                componentClass="textarea"
                rows={2}
              >
                Short Description
              </Field>
              <Field
                type="text"
                name="longDescription"
                component={InputField}
                componentClass="textarea"
                rows={8}
              >
                Long Description
              </Field>
              <Field
                type="text"
                name="suggestedAction"
                component={InputField}
                componentClass="textarea"
                rows={4}
              >
                Suggested Action
              </Field>
            </fieldset>
            {this.state.updating &&
              this.props.currentContact['manager?'] &&
              _includes(this.props.currentContact.statutory_role_ids, 2) && (
                <fieldset>
                  <legend>Assessment</legend>
                  <Field
                    type="text"
                    name="investigation"
                    component={InputField}
                    componentClass="textarea"
                    rows={8}
                  >
                    Investigation
                  </Field>
                </fieldset>
              )}
            {this.state.updating &&
              this.props.currentContact['manager?'] &&
              _includes(this.props.currentContact.statutory_role_ids, 2) && (
                <fieldset>
                  <legend>Risks</legend>
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={3}>
                      Assigned Risks
                    </Col>
                    <Col sm={9}>
                      <SmsRiskListTable
                        risks={this.props.smsRiskListQuery.data.filter((risk) =>
                          _includes(this.props.formValues.riskIds, risk.id)
                        )}
                        handleRemoveClick={this.handleRemoveRiskClicked}
                        handleCreateControlClick={this.handleCreateControlClick}
                        withRemove
                        withEdit
                        withCreateControl
                      />
                      <ButtonGroup>
                        <Button
                          // style={{margin: '0', padding: '0 0 8px 0'}}
                          bsStyle="link"
                          // bsSize='xsmall'
                          onClick={this.handleAlterRiskClicked}
                        >
                          Add and remove existing risks...
                        </Button>
                        <Button
                          // style={{margin: '0', padding: '0 0 8px 0'}}
                          bsStyle="link"
                          // bsSize='xsmall'
                          onClick={this.handleCreateRiskClicked}
                        >
                          Create a new risk...
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </FormGroup>
                </fieldset>
              )}
            <FormGroup>
              <Col sm={12}>
                <div className="pull-right">
                  <ButtonToolbar>
                    <ButtonGroup>
                      <LinkContainer to="/sms/reports">
                        <Button type="reset" bsStyle="danger" disabled={submitting}>
                          Cancel
                        </Button>
                      </LinkContainer>
                    </ButtonGroup>
                    <ButtonGroup>
                      <Button
                        type="submit"
                        bsStyle="primary"
                        disabled={pristine || submitting || !valid}
                      >
                        {submitting ? (
                          <span className="glyphicon glyphicon-refresh glyphicon-spin" />
                        ) : (
                          ''
                        )}{' '}
                        {this.state.updating ? 'Update' : 'Create'}
                      </Button>
                    </ButtonGroup>
                  </ButtonToolbar>
                </div>
              </Col>
            </FormGroup>
          </Form>
        </div>
      );
    }
    return undefined;
  }

  render() {
    return (
      <div>
        {this.renderOverlay()}
        {this.renderData()}
      </div>
    );
  }
}

const validationAliases = {
  shortDescription: 'Short Description',
  longDescription: 'Long Description',
  suggestedAction: 'Suggested Action',
  employeeId: 'Employee',
};

const validationRules = {
  shortDescription: 'required',
  longDescription: 'required',
  suggestedAction: 'required',
  employeeId: 'required',
};

const validationCustomMessages = {};

function validate(data) {
  const validator = new Validator(data, validationRules, validationCustomMessages);
  validator.setAttributeNames(validationAliases);
  validator.passes();
  const errors = validator.errors.all();
  const result = {};
  Object.keys(errors).forEach((key) => {
    _set(result, key, errors[key].join(' '));
  });
  return result;
}

const attributeWhiteList = [
  'id',
  'status',
  'category',
  'employeeId',
  'shortDescription',
  'longDescription',
  'suggestedAction',
  'investigation',
  'riskIds',
];

function pickInitialValues(smsReportQueryInitial, updating) {
  if (!isInitialisedSmsReportForm) {
    if (!updating) {
      isInitialisedSmsReportForm = true;
      return {
        status: 'new',
        category: 'incident',
        riskIds: [],
      };
    } else if (queryReady(smsReportQueryInitial)) {
      isInitialisedSmsReportForm = true;
      const editSmsReport = _pick(
        _omitBy(_cloneDeep(smsReportQueryInitial.data), _isNil),
        attributeWhiteList
      );
      return editSmsReport;
    }
  }
  return undefined;
}

function mapStateToProps(state, props) {
  const initialValues = pickInitialValues(props.smsReportQuery, !!props.params.id);
  return {
    initialValues,
    currentContact: state.currentContact,
    currentSettingsMutating: state.currentSettings.mutating,
    currentSettingsSmsReportStatuses: state.currentSettings.sms_report_statuses,
    currentSettingsSmsReportCategories: state.currentSettings.sms_report_categories,
    tagsCollection: state.tags.collection,
    formValues: getFormValues('SmsReportForm')(state),
  };
}

export default compose(
  graphql(smsReportCreateMutation, {
    name: 'smsReportCreateMutation',
  }),
  graphql(smsReportUpdateMutation, {
    name: 'smsReportUpdateMutation',
  }),
  graphql(smsRiskCreateMutation, {
    name: 'smsRiskCreateMutation',
  }),
  graphql(smsReportRiskCreateMutation, {
    name: 'smsReportRiskCreateMutation',
  }),
  graphql(smsReportRiskDeleteMutation, {
    name: 'smsReportRiskDeleteMutation',
  }),
  graphql(smsControlCreateMutation, {
    name: 'smsControlCreateMutation',
  }),
  graphql(contactItemListQuery, {
    name: 'employeeListQuery',
    options: { variables: { role: 'employee' } },
  }),
  graphql(smsRiskListQuery, {
    name: 'smsRiskListQuery',
  }),
  graphql(smsReportQuery, {
    name: 'smsReportQuery',
    skip: (props) => !props.params.id,
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'network-only',
    }),
  }),
  connect(mapStateToProps, {
    mutationSuccess,
    mutationFailure,
    mutationSet,
    currentSettingsSet,
  }),
  reduxForm({
    form: 'SmsReportForm',
    validate,
  })
)(SmsReportForm);
