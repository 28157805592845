/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
import { Component } from 'react';
import {
  Navbar,
  Nav,
  NavItem,
  NavDropdown,
  MenuItem,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import Autosuggest from 'react-autosuggest';
import { connect } from 'react-redux';

import _debounce from 'lodash/debounce';
import _upperFirst from 'lodash/upperFirst';

import { storeReload } from '../actions/store_actions';

import { suggestionsFilter } from '../actions/suggestion_actions';

class Header extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      value: '',
    };
    this.handleStoreReload = this.handleStoreReload.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.onChange = this.onChange.bind(this);
    this.isFlight = /^flight/.test(window.location.hostname);
  }

  UNSAFE_componentWillMount() {
    this.delayedOnSuggestionsFetchRequested = _debounce((value) => {
      this.getSuggestions(value);
    }, 500);
  }

  componentWillUnmount() {
    this.delayedOnSuggestionsFetchRequested.cancel();
  }

  onChange(event, { newValue, method }) {
    if (method === 'type') {
      this.setState({
        value: newValue,
      });
    }
  }

  onSuggestionsFetchRequested({ value }) {
    this.delayedOnSuggestionsFetchRequested(value);
  }

  // eslint-disable-next-line no-useless-return
  onSuggestionsClearRequested = () => undefined;

  onSuggestionSelected(event, { suggestion }) {
    this.setState({
      value: '',
    });
    const { id, klass } = suggestion;
    const path = `/${klass}s/${id}`;
    window.open(path, _upperFirst(klass) + id, 'width=1200, height=800');
  }

  getSuggestions(value) {
    this.props.suggestionsFilter({ q: value });
  }

  getSuggestionValue = (suggestion) => suggestion.name;

  renderAdminLink = () => {
    const tenant = window.location.hostname.split('.')[0];
    if (process.env.NODE_ENV === 'development') {
      return `http://${tenant}.lvh.me:1527/admin`;
    }
    return `https://${tenant}.rails.saam.co.nz/admin`;
  };

  reloadPage = () => {
    window.location.reload(true);
  };

  isEmployee() {
    return this.props.currentContact['employee?'];
  }

  isManager() {
    return this.props.currentContact['manager?'];
  }

  handleStoreReload() {
    this.props.storeReload();
  }

  renderSuggestion = (suggestion) => {
    const icon = ((klass) => {
      switch (klass) {
        case 'contact':
          return 'user';
        case 'location':
          return 'mountains';
        default:
          return 'glass';
      }
    })(suggestion.klass);

    return (
      <div>
        <span
          style={{ marginRight: '10px' }}
          className={`glyphicons glyphicons-${icon}`}
        />
        <span>{suggestion.name}</span>
      </div>
    );
  };

  render() {
    const inputProps = {
      placeholder: 'search...',
      value: this.state.value,
      onChange: this.onChange,
      style: { width: '210px' },
    };

    return (
      <Navbar>
        <Navbar.Header>
          <Navbar.Brand>
            <Link to="/">{this.isFlight ? 'SAAM FLIGHT' : 'SAAM POLICE'}</Link>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav>
            <LinkContainer to={{ pathname: '/bookings' }}>
              <NavItem eventKey={2}>Bookings</NavItem>
            </LinkContainer>
            {this.isEmployee() && (
              <LinkContainer to={{ pathname: '/locations' }}>
                <NavItem eventKey={3}>Locations</NavItem>
              </LinkContainer>
            )}
            {this.isEmployee() && (
              <LinkContainer to={{ pathname: '/contacts' }}>
                <NavItem eventKey={4}>Contacts</NavItem>
              </LinkContainer>
            )}
            {this.isEmployee() && (
              <NavDropdown eventKey={5} title="Other" id="basic-nav-dropdown-other">
                <LinkContainer to={{ pathname: '/authorizations' }}>
                  <MenuItem eventKey={5.1}>Authorizations</MenuItem>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/documents' }}>
                  <MenuItem eventKey={5.2}>Documents</MenuItem>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/checks' }}>
                  <MenuItem eventKey={5.3}>Checks</MenuItem>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/fuel_tankers' }}>
                  <MenuItem eventKey={5.4}>Fuel Tankers</MenuItem>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/locations/merge' }}>
                  <MenuItem eventKey={5.4}>Merge Locations</MenuItem>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/reports/pilot_duty_record' }}>
                  <MenuItem eventKey={5.5}>Pilot Duty Records</MenuItem>
                </LinkContainer>
              </NavDropdown>
            )}
            {this.isEmployee() && (
              <NavDropdown
                eventKey={6}
                title="Reports"
                id="basic-nav-dropdown-reports"
                className="header-reports-dropdown"
              >
                <LinkContainer to={{ pathname: '/reports/integrity' }}>
                  <MenuItem eventKey={6.1}>Data Integrity</MenuItem>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/flights/signoff' }}>
                  <MenuItem eventKey={6.0}>Admin Sign Off</MenuItem>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/reports/aircraft_flight_record' }}>
                  <MenuItem eventKey={6.3}>Aircraft Flight Record</MenuItem>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/reports/aircraft_flight_time_event' }}>
                  <MenuItem eventKey={6.4}>Aircraft Daily Hours & Cycles</MenuItem>
                </LinkContainer>
                <LinkContainer
                  to={{ pathname: '/reports/aircraft_flight_type_category' }}
                >
                  <MenuItem eventKey={6.5}>Aircraft Operating Statistics</MenuItem>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/reports/allocation' }}>
                  <MenuItem eventKey={6.6}>Allocation</MenuItem>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/reports/provider_flight_hour' }}>
                  <MenuItem eventKey={6.7}>Flight Hour Matrix</MenuItem>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/reports/chargeable_flight_record' }}>
                  <MenuItem eventKey={6.8}>Customer Flight Record</MenuItem>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/reports/chargeable_invoice' }}>
                  <MenuItem eventKey={6.9}>Customer Invoice Report</MenuItem>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/reports/pilot_flight_log' }}>
                  <MenuItem eventKey={6.1}>Pilot Flight Log</MenuItem>
                </LinkContainer>
                {/*
                  <LinkContainer to={{pathname: '/reports/pilot_flight_log_exception'}}>
                    <MenuItem eventKey={6.71}>Pilot Flight Log Exceptions</MenuItem>
                  </LinkContainer>
                  */}
                <LinkContainer to={{ pathname: '/reports/pilot_flight_expense' }}>
                  <MenuItem eventKey={6.11}>Pilot Flight Expense</MenuItem>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/reports/pilot_cumulative_hour' }}>
                  <MenuItem eventKey={6.12}>Pilot Cumulative Hours</MenuItem>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/reports/provider_flight_record' }}>
                  <MenuItem eventKey={6.13}>Provider Flight Record</MenuItem>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/reports/provider_swap_summary' }}>
                  <MenuItem eventKey={6.14}>Provider Swap Summary</MenuItem>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/reports/provider_swap_detail' }}>
                  <MenuItem eventKey={6.15}>Provider Swap Detail</MenuItem>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/reports/location_landing_fee' }}>
                  <MenuItem eventKey={6.16}>Location Landing Fee</MenuItem>
                </LinkContainer>
                <LinkContainer
                  to={{ pathname: '/reports/fuel_bowser_fill_for_aircraft' }}
                >
                  <MenuItem eventKey={6.17}>Aircraft Refuel by Bowser</MenuItem>
                </LinkContainer>
                <LinkContainer
                  to={{ pathname: '/reports/fuel_tanker_fill_for_aircraft' }}
                >
                  <MenuItem eventKey={6.18}>Aircraft Refuel by Tanker</MenuItem>
                </LinkContainer>
                <LinkContainer
                  to={{ pathname: '/reports/fuel_bowser_fill_for_fuel_tanker' }}
                >
                  <MenuItem eventKey={6.15}>Tanker Refuel by Bowser</MenuItem>
                </LinkContainer>
              </NavDropdown>
            )}
            {this.isFlight && this.isManager() && (
              <NavDropdown eventKey={7} title="SMS" id="basic-nav-dropdown-sms">
                <LinkContainer to={{ pathname: '/sms/risks' }}>
                  <MenuItem eventKey={7.1}>Risk Register</MenuItem>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/sms/reports' }}>
                  <MenuItem eventKey={7.2}>Reports</MenuItem>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/sms/controls' }}>
                  <MenuItem eventKey={7.3}>Controls</MenuItem>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/sms/actions' }}>
                  <MenuItem eventKey={7.3}>Actions</MenuItem>
                </LinkContainer>
              </NavDropdown>
            )}
            <NavDropdown eventKey={8} title="Tools" id="basic-nav-dropdown-other">
              {this.isEmployee() && (
                <NavItem eventKey={8.1} target="_blank" href={this.renderAdminLink()}>
                  Admin
                </NavItem>
              )}
              <NavItem eventKey={8.2} href="#" onClick={this.handleStoreReload}>
                Reload
              </NavItem>
              <LinkContainer to={{ pathname: '/auth/logout' }}>
                <NavItem eventKey={8.3}>Sign Out</NavItem>
              </LinkContainer>
            </NavDropdown>
            {this.isFlight && this.isEmployee() && (
              <NavItem
                id="header-safety-link"
                className="btn-primary"
                eventKey={9}
                target="_blank"
                href="https://aviation-sms.knack.com/risk-registers#advanced-flight-registers/"
              >
                Safety Report
              </NavItem>
            )}
            {this.props.currentSettingVersionCurrent || (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-header-version-link">
                    Your application version is out of date. Click to refresh your browser
                  </Tooltip>
                }
              >
                <NavItem
                  id="header-version-link"
                  className="btn-primary"
                  eventKey={10}
                  onClick={this.reloadPage}
                >
                  <span
                    style={{ fontSize: '24px' }}
                    className="glyphicons glyphicons-exclamation-sign"
                  />
                </NavItem>
              </OverlayTrigger>
            )}
          </Nav>
          {this.isEmployee() && (
            <Navbar.Form pullRight>
              <Autosuggest
                suggestions={this.props.suggestionsCollection}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                onSuggestionSelected={this.onSuggestionSelected}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
              />
            </Navbar.Form>
          )}
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingVersionCurrent: state.currentSettings.versionCurrent,
    suggestionsCollection: state.suggestions.collection,
    currentContact: state.currentContact,
  };
}

export default connect(mapStateToProps, { suggestionsFilter, storeReload })(Header);
