import get from 'lodash/get';
import memoize from 'lodash/memoize';

export const AircraftTypeAirspeedDataMemo = memoize(
  ({ aircraftTypeId, aircraftTypes }) => {
    if (aircraftTypeId) {
      const aircraftType = aircraftTypes.find((at) => at.id === parseInt(aircraftTypeId));
      return {
        aircraftTypeCruiseAirspeedSlKnots: get(aircraftType, 'cruise_airspeed_sl_knots'),
        aircraftTypeDepartureAndArrivalLoading: get(
          aircraftType,
          'departure_and_arrival_loading'
        ),
      };
    } else {
      return {};
    }
  },
  ({ aircraftTypeId }) => {
    if (aircraftTypeId) {
      return [aircraftTypeId].map((i) => String(i)).join('.');
    } else {
      return 'unknown';
    }
  }
);
