import { Component } from 'react';
import { Col, Checkbox } from 'react-bootstrap';

class CheckboxField extends Component {
  render() {
    const { input, inline, inputWidth, inputOffset } = this.props;

    return (
      <Col smOffset={inputOffset} sm={inputWidth}>
        <Checkbox inline={inline} checked={input.value} onChange={input.onChange}>
          {this.props.children}
        </Checkbox>
      </Col>
    );
  }
}

CheckboxField.defaultProps = {
  inline: false,
  inputWidth: 1,
};

export default CheckboxField;
