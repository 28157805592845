import { Row, Col, FormGroup, FormControl, HelpBlock } from 'react-bootstrap';
import { Component } from 'react';
import DateTime from 'react-datetime';
import moment from 'moment';

import get from 'lodash/get';

import { validationStates, validationText } from '../form/helpers';

moment.updateLocale('en-nz');

class BookingPilotDutyRecordFields extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleDutyStartAtTimeChange = this.handleDutyStartAtTimeChange.bind(this);
    this.handleDutyEndAtTimeChange = this.handleDutyEndAtTimeChange.bind(this);
    this.handleRestStartAtTimeChange = this.handleRestStartAtTimeChange.bind(this);
    this.handleRestEndAtTimeChange = this.handleRestEndAtTimeChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setTimestamps();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setTimestamps(nextProps);
  }

  handleDutyStartAtTimeChange(date) {
    if (moment.isMoment(date)) {
      const newDate = moment(
        get(this.props, `formValues.${this.props.parentField}.duty_start_at`)
      )
        .hours(date.format('HH'))
        .minutes(date.format('mm'));
      const input = get(this.props, `${this.props.parentField}.duty_start_at.input`);
      input.onChange(newDate.format());
    } else {
      this.setState({
        dutyStartAtTime: date,
        dutyStartAtTimeError: { touched: true, invalid: true, error: 'HHmm' },
      });
    }
  }

  handleDutyEndAtTimeChange(date) {
    if (moment.isMoment(date)) {
      const newDate = moment(
        get(this.props, `formValues.${this.props.parentField}.duty_end_at`)
      )
        .hours(date.format('HH'))
        .minutes(date.format('mm'));
      const input = get(this.props, `${this.props.parentField}.duty_end_at.input`);
      input.onChange(newDate.format());
    } else {
      this.setState({
        dutyEndAtTime: date,
        dutyEndAtTimeError: { touched: true, invalid: true, error: 'HHmm' },
      });
    }
  }

  handleRestStartAtTimeChange(date) {
    if (moment.isMoment(date)) {
      let newDate;
      if (get(this.props, `formValues.${this.props.parentField}.rest_start_at`)) {
        newDate = moment(
          get(this.props, `formValues.${this.props.parentField}.rest_start_at`)
        )
          .hours(date.format('HH'))
          .minutes(date.format('mm'));
      } else {
        newDate = moment(
          get(this.props, `formValues.${this.props.parentField}.duty_start_at`)
        )
          .hours(date.format('HH'))
          .minutes(date.format('mm'));
      }
      const input = get(this.props, `${this.props.parentField}.rest_start_at.input`);
      input.onChange(newDate.format());
    } else {
      this.setState({
        restStartAtTime: date,
        restStartAtTimeError: { touched: true, invalid: true, error: 'HHmm' },
      });
    }
  }

  handleRestEndAtTimeChange(date) {
    if (moment.isMoment(date)) {
      let newDate;
      if (get(this.props, `formValues.${this.props.parentField}.rest_end_at`)) {
        newDate = moment(
          get(this.props, `formValues.${this.props.parentField}.rest_end_at`)
        )
          .hours(date.format('HH'))
          .minutes(date.format('mm'));
      } else {
        newDate = moment(
          get(this.props, `formValues.${this.props.parentField}.duty_end_at`)
        )
          .hours(date.format('HH'))
          .minutes(date.format('mm'));
      }
      const input = get(this.props, `${this.props.parentField}.rest_end_at.input`);
      input.onChange(newDate.format());
    } else {
      this.setState({
        restEndAtTime: date,
        restEndAtTimeError: { touched: true, invalid: true, error: 'HHmm' },
      });
    }
  }

  setTimestamps(props = this.props) {
    const timestamps = {
      dutyStartAtTime: '',
      dutyEndAtTime: '',
      restStartAtTime: '',
      restEndAtTime: '',
      dutyStartAtTimeError: {},
      dutyEndAtTimeError: {},
      restStartAtTimeError: {},
      restEndAtTimeError: {},
      total: '0',
    };
    const dutyStartAt = get(
      props,
      `formValues.${this.props.parentField}.duty_start_at`,
      ''
    );
    const dutyEndAt = get(props, `formValues.${this.props.parentField}.duty_end_at`, '');
    const restStartAt = get(
      props,
      `formValues.${this.props.parentField}.rest_start_at`,
      ''
    );
    const restEndAt = get(props, `formValues.${this.props.parentField}.rest_end_at`, '');
    if (dutyStartAt) {
      timestamps.dutyStartAtTime = moment(dutyStartAt).format('HHmm');
    }
    if (dutyEndAt) {
      timestamps.dutyEndAtTime = moment(dutyEndAt).format('HHmm');
    }
    if (restStartAt) {
      timestamps.restStartAtTime = moment(restStartAt).format('HHmm');
    }
    if (restEndAt) {
      timestamps.restEndAtTime = moment(restEndAt).format('HHmm');
    }
    timestamps.total = this.calcTotal(
      moment(dutyStartAt),
      moment(dutyEndAt),
      moment(restStartAt),
      moment(restEndAt)
    );
    this.setState(timestamps);
  }

  calcTotal = (dutyStartAt, dutyEndAt, restStartAt, restEndAt) => {
    let tot = '0';
    if (dutyStartAt.isValid() && dutyEndAt.isValid()) {
      let totD = dutyEndAt.diff(dutyStartAt);
      if (totD > 0) {
        if (restStartAt.isValid() && restEndAt.isValid()) {
          const totR = restEndAt.diff(restStartAt);
          if (totR > 0) {
            totD -= totR;
          }
        }
        const hours = `${moment.duration(totD).hours()}`;
        const mins = `${moment.duration(totD).minutes()}`;
        tot = `${'00'.substring(0, 2 - hours.length) + hours}:${
          '00'.substring(0, 2 - mins.length) + mins
        }`;
      }
    }
    return tot;
  };

  renderPilotName() {
    return get(
      this.props.contactsDataSelector,
      [get(this.props, `formValues.${this.props.parentField}.pilot_id`), 'fullName'],
      'Unknown'
    );
  }

  renderAttributes() {
    const {
      duty_start_at: { input: dutyStartAtInput, meta: dutyStartAtMeta },
      duty_end_at: { meta: dutyEndAtMeta },
      rest_start_at: { meta: restStartAtMeta },
      rest_end_at: { meta: restEndAtMeta },
    } = get(this.props, this.props.parentField);
    return (
      <Col sm={12}>
        <Row className="form-horizontal">
          <Col sm={12}>
            <FormGroup
              bsSize="sm"
              controlId={dutyStartAtInput.name}
              validationState={validationStates([
                this.state.dutyStartAtTimeError,
                this.state.dutyEndAtTimeError,
                this.state.restStartAtTimeError,
                this.state.restEndAtTimeError,
                dutyStartAtMeta,
                dutyEndAtMeta,
                restStartAtMeta,
                restEndAtMeta,
              ])}
            >
              <Col sm={2}>
                <FormControl.Static style={{ padding: 0 }}>
                  {this.renderPilotName()}
                </FormControl.Static>
              </Col>
              <Col sm={2}>
                <DateTime
                  value={this.state.dutyStartAtTime}
                  dateFormat={false}
                  timeFormat="HHmm"
                  tabIndex={-1}
                  onChange={this.handleDutyStartAtTimeChange}
                />
                <HelpBlock>
                  {validationText(
                    Object.keys(this.state.dutyStartAtTimeError).length > 0
                      ? this.state.dutyStartAtTimeError
                      : dutyStartAtMeta
                  )}
                </HelpBlock>
              </Col>
              <Col sm={2}>
                <DateTime
                  value={this.state.dutyEndAtTime}
                  dateFormat={false}
                  timeFormat="HHmm"
                  tabIndex={-1}
                  onChange={this.handleDutyEndAtTimeChange}
                />
                <HelpBlock>
                  {validationText(
                    Object.keys(this.state.dutyEndAtTimeError).length > 0
                      ? this.state.dutyEndAtTimeError
                      : dutyEndAtMeta
                  )}
                </HelpBlock>
              </Col>
              <Col sm={2}>
                <DateTime
                  value={this.state.restStartAtTime}
                  dateFormat={false}
                  timeFormat="HHmm"
                  tabIndex={-1}
                  onChange={this.handleRestStartAtTimeChange}
                />
                <HelpBlock>
                  {validationText(
                    Object.keys(this.state.restStartAtTimeError).length > 0
                      ? this.state.restStartAtTimeError
                      : restStartAtMeta
                  )}
                </HelpBlock>
              </Col>
              <Col sm={2}>
                <DateTime
                  value={this.state.restEndAtTime}
                  dateFormat={false}
                  timeFormat="HHmm"
                  tabIndex={-1}
                  onChange={this.handleRestEndAtTimeChange}
                />
                <HelpBlock>
                  {validationText(
                    Object.keys(this.state.restEndAtTimeError).length > 0
                      ? this.state.restEndAtTimeError
                      : restEndAtMeta
                  )}
                </HelpBlock>
              </Col>
              <Col sm={2}>
                <FormControl.Static style={{ padding: 0 }}>
                  {this.state.total}
                </FormControl.Static>
              </Col>
            </FormGroup>
          </Col>
        </Row>
      </Col>
    );
  }

  renderHeader = () => (
    <Col sm={12}>
      <Row>
        <Col sm={2}>
          <p>Pilot</p>
        </Col>
        <Col sm={2}>
          <p>Duty Start</p>
        </Col>
        <Col sm={2}>
          <p>Duty End</p>
        </Col>
        <Col sm={2}>
          <p>Rest Start</p>
        </Col>
        <Col sm={2}>
          <p>Rest End</p>
        </Col>
        <Col sm={2}>
          <p>Total</p>
        </Col>
      </Row>
    </Col>
  );

  renderDutyRecords() {
    return (
      <Row>
        {this.renderHeader()}
        {this.renderAttributes()}
      </Row>
    );
  }

  render() {
    return (
      <Row>
        <Col sm={2}>
          <p>
            <strong>{this.props.title}</strong>
          </p>
        </Col>
        <Col sm={10}>{this.renderDutyRecords()}</Col>
      </Row>
    );
  }
}

export default BookingPilotDutyRecordFields;
