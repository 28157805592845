import memoize from 'lodash/memoize';

import { LocationsDistanceMemo } from './locations_distance_memo';

export const FlightSegmentDistanceDataMemo = memoize(
  ({ distanceAdj, locationIdA, locationIdB, locations }) => {
    if (locationIdA && locationIdB) {
      const distance = LocationsDistanceMemo({ locationIdA, locationIdB, locations });
      return {
        raw: distance,
        adjustment: distanceAdj,
        adjusted: distance + distanceAdj,
      };
      return distance + distanceAdj;
    } else {
      return {};
    }
  },
  ({ distanceAdj, locationIdA, locationIdB }) => {
    if (locationIdA && locationIdB) {
      return [locationIdA, locationIdB, distanceAdj].map((i) => String(i)).join('.');
    } else {
      return 'unknown';
    }
  }
);
