import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { authReset } from '../actions/auth_actions';
import { unsubscribeMutationObservable } from '../lib/process_mutation_subscription';

export default function Logout() {
  const apolloClient = useApolloClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    apolloClient.clearStore();
    dispatch(authReset(navigate)).then(() => {
      // console.log('logout unsubscribe');
      unsubscribeMutationObservable();
    });
  }, [apolloClient, dispatch, navigate]);

  return <p style={{ color: 'white' }}>Logging out...</p>;
}
