import { FormGroup, Col, Checkbox, HelpBlock } from 'react-bootstrap';

import { validationState, validationText } from '../form/helpers';

const LocationFormNamedPilotsOnlyField = (props) => {
  const { input, meta, children } = props;
  return (
    <FormGroup
      controlId="locationFormNamedPilotsOnly"
      validationState={validationState(meta)}
    >
      <Col sm={9} smOffset={3}>
        <Checkbox {...input} checked={input.value}>
          {children}
        </Checkbox>
        <HelpBlock>{validationText(meta)}</HelpBlock>
      </Col>
    </FormGroup>
  );
};

export default LocationFormNamedPilotsOnlyField;
