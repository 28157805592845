import {
  Row,
  Col,
  Panel,
  Button,
  ButtonToolbar,
  ButtonGroup,
  FormControl,
  SplitButton,
  MenuItem,
} from 'react-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import Toggle from 'react-bootstrap-toggle';
import DateTime from 'react-datetime';
import moment from 'moment';
import SunCalc from 'suncalc';

import get from 'lodash/get';
import debounce from 'lodash/debounce';
import intersection from 'lodash/intersection';
import difference from 'lodash/difference';
import chunk from 'lodash/chunk';
import clone from 'lodash/clone';
import compact from 'lodash/compact';
import trim from 'lodash/trim';
import replace from 'lodash/replace';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';

import { aircraftsData, contactsData } from '../selectors';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import { filterDo } from '../actions/filter_actions';

import { currentSettingsSet } from '../actions/current_setting_actions';

import Loader from '../components/loader';

import BookingManagerList from '../components/booking_manager/booking_manager_list';
import BookingManagerCalendar from '../components/booking_manager/booking_manager_calendar';

import { queryReady, queriesReady, queryJustReady, typeInput } from '../lib/utils';

import bookingUpdateMutation from '../mutations/booking_update_mutation';
import bookingDeleteMutation from '../mutations/booking_delete_mutation';
import bookingCloneMutation from '../mutations/booking_clone_mutation';

import bookingListQuery from '../queries/booking_list_query';
import aircraftListQuery from '../queries/aircraft_list_query';
import aircraftTypeListQuery from '../queries/aircraft_type_list_query';
import aircraftGroupListQuery from '../queries/aircraft_group_list_query';
import contactItemListQuery from '../queries/contact_item_list_query';

moment.updateLocale('en-nz');

class BookingManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingIds: {},
      contactOnly: false,
      startAtDate: moment(this.props.currentSettingsBookingCollectionStartDate).format(
        'MM/YYYY'
      ),
      isMd: this.props.containerWidth < 992,
      isXs: this.props.containerWidth < 480,
      showCalendarMd: false,
      nonEmployee: false,
    };

    this.handleDropdownNewSelect = this.handleDropdownNewSelect.bind(this);
    this.handleDropdownNewClick = this.handleDropdownNewClick.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.onListItemCalendarClicked = this.onListItemCalendarClicked.bind(this);
    this.onFlightCompleteClicked = this.onFlightCompleteClicked.bind(this);
    this.onDeleteClicked = this.onDeleteClicked.bind(this);
    this.onCloneClicked = this.onCloneClicked.bind(this);
    this.onCalendarEventClicked = this.onCalendarEventClicked.bind(this);
    this.onCalendarDisplayDateChanged = this.onCalendarDisplayDateChanged.bind(this);
    this.handleContactToggle = this.handleContactToggle.bind(this);
    this.onTodayCalendarClicked = this.onTodayCalendarClicked.bind(this);
    this.onPrevCalendarClicked = this.onPrevCalendarClicked.bind(this);
    this.onNextCalendarClicked = this.onNextCalendarClicked.bind(this);
    this.onDayCalendarClicked = this.onDayCalendarClicked.bind(this);
    this.onWeekCalendarClicked = this.onWeekCalendarClicked.bind(this);
    this.onMonthCalendarClicked = this.onMonthCalendarClicked.bind(this);
    this.handleStartAtDateChange = this.handleStartAtDateChange.bind(this);
    this.handleCalendarMdToggle = this.handleCalendarMdToggle.bind(this);
    this.handleAircraftGroupToggle = this.handleAircraftGroupToggle.bind(this);
    this.handleAircraftGroupEntered = this.handleAircraftGroupEntered.bind(this);
    this.handleAircraftGroupExited = this.handleAircraftGroupExited.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.location.pathname === '/') {
      this.props.navigate('/bookings');
    }
  }

  componentDidMount() {
    this.delayedHandleRefetch = debounce(() => {
      this.props.bookingListQuery.refetch();
    }, 250);

    this.delayedHandleFilter = debounce((e) => {
      if (e.target.value.length > 1 || e.target.value === '' || e.target.value === '*') {
        const q = trim(replace(e.target.value, '*', ''));
        this.props.filterDo({
          type: 'booking',
          q: q || (this.state.contactOnly && '*') || q,
          start_at: this.props.currentSettingsBookingCollectionStartDate,
          end_at: this.props.currentSettingsBookingCollectionEndDate,
          ...(this.state.contactOnly && {
            display_name: this.props.currentContact.display_name,
          }),
        });
      }
    }, 400);

    if (this.props.filter_q) {
      this.delayedHandleFilter({ target: { value: this.props.filter_q } });
    }

    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    if (this.isLoaded(this.props)) {
      this.setState({
        nonEmployee: !this.props.currentContact['employee?'],
        bookingIds: this.groupBookingIds(this.props),
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let bookingIds;

    if (this.isLoaded(nextProps)) {
      bookingIds = this.groupBookingIds(nextProps);
    }

    if (
      (nextProps.filter_q || this.state.contactOnly) &&
      (queryJustReady(this.props.bookingListQuery, nextProps.bookingListQuery) ||
        this.props.currentSettingsBookingCollectionStartDate !==
          nextProps.currentSettingsBookingCollectionStartDate ||
        this.props.currentSettingsBookingCollectionEndDate !==
          nextProps.currentSettingsBookingCollectionEndDate)
    ) {
      this.delayedHandleFilter({ target: { value: nextProps.filter_q } });
    }
    const state = {
      startAtDate: moment(nextProps.currentSettingsBookingCollectionStartDate).format(
        'MM/YYYY'
      ),
      isMd: this.props.containerWidth < 992,
      isXs: this.props.containerWidth < 480,
      nonEmployee: !nextProps.currentContact['employee?'],
    };

    if (bookingIds && !isEqual(bookingIds, this.state.bookingIds)) {
      state.bookingIds = bookingIds;
    }

    this.setState(state);
  }

  componentWillUnmount() {
    this.delayedHandleFilter.cancel();
    this.delayedHandleRefetch.cancel();
  }

  groupBookingIds = (props) => {
    const {
      bookingListQuery: { data: bookings },
      filter_ids: filterIds,
      currentContact,
      currentSettingsBookingStatusCancelled,
    } = props;

    const bookingIds = {
      all: [],
      cancelled: [],
      filtered: filterIds,
      nonEmployee: [],
      leave: [],
      flight: [],
      maintenance: [],
    };

    bookings.reduce((result, booking) => {
      const {
        id,
        calendar_type: type,
        status,
        pilot_id: pilotId,
        copilot_id: copilotId,
        aircraft_id: aircraftId,
        bookingChargeables,
      } = booking;

      result.all.push(id);

      if (type === 'flight') {
        result.flight.push(id);
      } else if (type === 'maintenance') {
        result.maintenance.push(id);
      } else if (type === 'leave') {
        result.leave.push(id);
      }
      if (!currentContact['employee?']) {
        const nonEmployeeRelatedAircraftIds = currentContact.related_aircraft_ids;
        const nonEmployeeRelatedChargeableIds = currentContact.related_chargeable_ids;
        const chargeableIds = bookingChargeables.map((bca) => bca.chargeable_id);
        if (
          includes(compact([pilotId, copilotId]), currentContact.id) ||
          (nonEmployeeRelatedAircraftIds &&
            includes(nonEmployeeRelatedAircraftIds, aircraftId)) ||
          (nonEmployeeRelatedChargeableIds &&
            intersection(nonEmployeeRelatedChargeableIds, chargeableIds).length > 0)
        ) {
          result.nonEmployee.push(id);
        }
      }
      if (status === currentSettingsBookingStatusCancelled) {
        result.cancelled.push(id);
      }
      return result;
    }, bookingIds);

    return bookingIds;
  };

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const newProps = props || this.props;
    return !queriesReady(
      newProps.bookingListQuery,
      newProps.aircraftListQuery,
      newProps.aircraftGroupListQuery,
      newProps.aircraftTypeListQuery,
      newProps.employeeListQuery,
      newProps.chargeableListQuery,
      newProps.pilotListQuery
    );
  }

  handleContactToggle() {
    this.setState((prevState) => ({
      contactOnly: !prevState.contactOnly,
    }));
    this.delayedHandleFilter({ target: { value: this.props.filter_q } });
  }

  handleCalendarMdToggle() {
    this.setState((prevState) => ({
      showCalendarMd: !prevState.showCalendarMd,
    }));
  }

  handleFilter(e) {
    e.persist();
    this.delayedHandleFilter(e);
  }

  handleStartAtDateChange(date) {
    if (this.isLoaded) {
      if (moment.isMoment(date)) {
        this.setState({
          startAtDate: date.format('MM/YYYY'),
        });
        this.onCalendarDisplayDateChanged(
          date.startOf('month').format(),
          date.endOf('month').format(),
          'dayGridMonth'
        );
      }
    }
  }

  handleAircraftGroupToggle(e) {
    const aircraftGroupId = parseInt(e.target.getAttribute('data-id'), 10);
    if (includes(this.props.currentSettingsBookingAircraftGroupIds, aircraftGroupId)) {
      this.props.currentSettingsSet({
        bookingAircraftGroupIds: this.props.currentSettingsBookingAircraftGroupIds.filter(
          (id) => id !== aircraftGroupId
        ),
      });
    } else {
      this.props.currentSettingsSet({
        bookingAircraftGroupIds: uniq([
          ...this.props.currentSettingsBookingAircraftGroupIds,
          aircraftGroupId,
        ]),
      });
    }
  }

  handleAircraftGroupEntered(el) {
    const aircraftGroupId = parseInt(el.getAttribute('data-id'), 10);
    this.props.currentSettingsSet({
      enteredAircraftGroupIds: uniq([
        ...this.props.currentSettingsEnteredAircraftGroupIds,
        aircraftGroupId,
      ]),
    });
  }

  handleAircraftGroupExited(el) {
    const aircraftGroupId = parseInt(el.getAttribute('data-id'), 10);
    this.props.currentSettingsSet({
      enteredAircraftGroupIds: this.props.currentSettingsEnteredAircraftGroupIds.filter(
        (id) => id !== aircraftGroupId
      ),
    });
  }

  handleDropdownNewSelect(key) {
    this.props.currentSettingsSet({ bookingRequestedCalendarType: key });
    this.onNewClicked(key === this.props.currentSettingsBookingCalendarTypeFlight);
  }

  handleDropdownNewClick() {
    this.handleDropdownNewSelect(this.props.currentSettingsBookingCalendarTypeFlight);
  }

  onEditClicked(id, isFlight) {
    if (isFlight) {
      this.props.navigate(`/flights/${id}/edit`);
    } else {
      this.props.navigate(`/nonflights/${id}/edit`);
    }
  }

  onNewClicked(isFlight) {
    if (isFlight) {
      this.props.navigate('/flights/new');
    } else {
      this.props.navigate('/nonflights/new');
    }
  }

  onDeleteClicked(bookingId) {
    this.props.mutationSet(true);
    this.props
      .bookingDeleteMutation({
        variables: {
          id: bookingId,
        },
      })
      .then(() => {
        this.props.mutationSuccess('Booking delete');
      })
      .catch((err) => {
        this.props.mutationFailure(err);
      });
  }

  onFlightCompleteClicked(bookingId) {
    const data = {
      id: bookingId,
      audit_created: true,
      audit_created_at: moment().format(),
      audit_created_by_admin_id: this.props.currentContact.id,
      updated_by_admin_id: this.props.currentContact.id,
    };
    this.props.mutationSet(true);
    return this.props
      .bookingUpdateMutation({
        variables: {
          id: bookingId,
          input: typeInput(data),
        },
      })
      .then(() => {
        this.props.mutationSuccess('Booking update');
        this.props.navigate(`/flights/${bookingId}/edit`);
      })
      .catch((err) => {
        this.props.mutationFailure(err);
      });
  }

  onCloneClicked(id, type) {
    this.props.mutationSet(true);
    this.props
      .bookingCloneMutation({
        variables: {
          input: {
            id,
            cloneType: type,
          },
        },
      })
      .then(() => {
        this.props.mutationSuccess('Booking clone');
      })
      .catch((err) => {
        this.props.mutationFailure(err);
      });
  }

  onCalendarEventClicked(id, adminComplete, isFlight) {
    if (!adminComplete) {
      this.onEditClicked(id, isFlight);
    }
  }

  onCalendarDisplayDateChanged(calendarStart, calendarEnd, viewName) {
    this.props.currentSettingsSet({
      bookingCollectionStartDate: calendarStart,
      bookingCollectionEndDate: calendarEnd,
      bookingCollectionView: viewName,
    });
  }

  onListItemCalendarClicked(id) {
    const startAt = this.props.bookingListQuery.data.find(
      (model) => model.id === id
    ).start_at;
    this.onCalendarDisplayDateChanged(
      moment(startAt).startOf('day').format(),
      moment(startAt).endOf('day').format(),
      'resourceTimelineDay'
    );
  }

  onTodayCalendarClicked() {
    this.onCalendarDisplayDateChanged(
      moment().startOf('day').format(),
      moment().endOf('day').format(),
      'resourceTimelineDay'
    );
  }

  onPrevCalendarClicked() {
    const {
      currentSettingsBookingCollectionStartDate: start,
      currentSettingsBookingCollectionEndDate: end,
      currentSettingsBookingCollectionView: view,
    } = this.props;
    switch (view) {
      case 'resourceTimelineDay':
        this.onCalendarDisplayDateChanged(
          moment(start).subtract(1, 'day').format(),
          moment(end).subtract(1, 'day').format(),
          view
        );
        break;
      case 'timeGridWeek':
        this.onCalendarDisplayDateChanged(
          moment(start).subtract(1, 'week').format(),
          moment(end).subtract(1, 'week').format(),
          view
        );
        break;
      case 'dayGridMonth':
        this.onCalendarDisplayDateChanged(
          moment(start).subtract(1, 'month').format(),
          moment(end).subtract(1, 'month').format(),
          view
        );
        break;
      default:
        break;
    }
  }

  onNextCalendarClicked() {
    const {
      currentSettingsBookingCollectionStartDate: start,
      currentSettingsBookingCollectionEndDate: end,
      currentSettingsBookingCollectionView: view,
    } = this.props;
    switch (view) {
      case 'resourceTimelineDay':
        this.onCalendarDisplayDateChanged(
          moment(start).add(1, 'day').format(),
          moment(end).add(1, 'day').format(),
          view
        );
        break;
      case 'timeGridWeek':
        this.onCalendarDisplayDateChanged(
          moment(start).add(1, 'week').format(),
          moment(end).add(1, 'week').format(),
          view
        );
        break;
      case 'dayGridMonth':
        this.onCalendarDisplayDateChanged(
          moment(start).add(1, 'month').format(),
          moment(end).add(1, 'month').format(),
          view
        );
        break;
      default:
        break;
    }
  }

  onDayCalendarClicked() {
    const {
      currentSettingsBookingCollectionStartDate: start,
      currentSettingsBookingCollectionView: view,
    } = this.props;
    switch (view) {
      case 'timeGridWeek':
      case 'dayGridMonth':
        this.onCalendarDisplayDateChanged(
          moment(start).startOf('day').format(),
          moment(start).endOf('day').format(),
          'resourceTimelineDay'
        );
        break;
      default:
        break;
    }
  }

  onWeekCalendarClicked() {
    const {
      currentSettingsBookingCollectionStartDate: start,
      currentSettingsBookingCollectionView: view,
    } = this.props;
    switch (view) {
      case 'resourceTimelineDay':
      case 'dayGridMonth':
        this.onCalendarDisplayDateChanged(
          moment(start).startOf('isoWeek').format(),
          moment(start).endOf('isoWeek').format(),
          'timeGridWeek'
        );
        break;
      default:
        break;
    }
  }

  onMonthCalendarClicked() {
    const {
      currentSettingsBookingCollectionStartDate: start,
      currentSettingsBookingCollectionView: view,
    } = this.props;
    switch (view) {
      case 'resourceTimelineDay':
      case 'timeGridWeek':
        this.onCalendarDisplayDateChanged(
          moment(start).startOf('month').format(),
          moment(start).endOf('month').format(),
          'dayGridMonth'
        );
        break;
      default:
        break;
    }
  }

  renderOverlay() {
    const isLoading = this.isLoading();
    if (this.props.filtering || this.props.currentSettingsMutating || isLoading) {
      return <Loader noSpinner={isLoading} />;
    }
    return undefined;
  }

  renderAircraftsStatus(aircraftGroup) {
    const groupAircraftIds = aircraftGroup.aircrafts.map((a) => a.id);
    const bookings = this.calendarBookings(aircraftGroup);
    const flightAircraftIds = [
      ...new Set(
        bookings
          .filter((booking) => booking.calendar_type === 'flight')
          .map((booking) => booking.aircraft_id)
      ),
    ];
    const maintenanceAircraftIds = [
      ...new Set(
        bookings
          .filter((booking) => booking.calendar_type === 'maintenance')
          .map((booking) => booking.aircraft_id)
      ),
    ];
    return chunk(
      this.props.aircraftListQuery.data.filter(
        (aircraft) =>
          includes(groupAircraftIds, aircraft.id) &&
          !includes(flightAircraftIds, aircraft.id) &&
          !includes(maintenanceAircraftIds, aircraft.id) &&
          !aircraft.registration_abbreviated.includes('OLD') &&
          !aircraft.registration_abbreviated.includes('OTHER')
      ),
      6
    ).map((aircrafts) => (
      <Row key={get(aircrafts, [0, 'registration_abbreviated'])}>
        {aircrafts.map((aircraft) => (
          <Col xs={2} key={aircraft.registration_abbreviated}>
            <p>{aircraft.registration_abbreviated}</p>
          </Col>
        ))}
      </Row>
    ));
  }

  renderMonthPicker() {
    return (
      <DateTime
        value={this.state.startAtDate}
        dateFormat="MM/YYYY"
        timeFormat={false}
        closeOnSelect
        initialViewMode="months"
        onChange={this.handleStartAtDateChange}
        inputProps={{ style: { height: '30px', width: '76px' } }}
      />
    );
  }

  renderHeaderDateString() {
    const {
      currentSettingsBookingCollectionStartDate: start,
      currentSettingsBookingCollectionEndDate: end,
      currentSettingsBookingCollectionView: view,
    } = this.props;
    switch (view) {
      case 'resourceTimelineDay':
        return moment(start).format('ddd, D MMM YYYY');
      case 'timeGridWeek':
        switch (moment(start).format('MMM')) {
          case moment(end).format('MMM'):
            return `${moment(start).format('D')} \u2013 ${moment(end).format(
              'D MMM YYYY'
            )}`;
          default:
            return `${moment(start).format('D MMM')} \u2013 ${moment(end).format(
              'D MMM YYYY'
            )}`;
        }
      case 'dayGridMonth':
        return moment(start).format('MMM YYYY');
      default:
        return '';
    }
  }

  renderHeader() {
    const dateString = this.renderHeaderDateString();
    return `Bookings: ${dateString}`;
  }

  sizeList() {
    const listHeight = this.state.isXs
      ? this.props.containerHeight - 165 - 15 - 88
      : this.props.containerHeight - 105 - 15 - 88;
    if (this.state.isMd && this.state.showCalendarMd) {
      return { height: `${listHeight}px`, overflowY: 'scroll', display: 'none' };
    }
    return { height: `${listHeight}px`, overflowY: 'scroll', display: 'block' };
  }

  sizeCalendar() {
    let calendarHeight = this.props.containerHeight - 15 - 78;
    if (this.state.isXs) {
      calendarHeight = this.props.containerHeight - 175 - 15 - 78;
    } else if (this.state.isMd) {
      calendarHeight = this.props.containerHeight - 115 - 15 - 78;
    }
    return { height: `${calendarHeight}px`, overflowY: 'scroll' };
  }

  sizeBookings() {
    return this.filterBookingIds(false, true, true).length;
  }

  filterBookingIds(withoutCancelled = false, isFiltered = false, scoped = false) {
    const {
      nonEmployee: isNonEmployee,
      bookingIds: { all, cancelled, filtered, nonEmployee },
    } = this.state;
    let ids = clone(all);
    if (withoutCancelled) {
      ids = difference(ids, cancelled);
    }
    if (isFiltered && this.props.filter_q) {
      ids = intersection(ids, filtered);
    }
    if (scoped && isNonEmployee) {
      ids = intersection(ids, nonEmployee);
    }
    return ids || [];
  }

  filterBookings(ids, aircraftGroup) {
    const groupPilotIds = aircraftGroup.groupPilots.map((gp) => gp.id);
    return this.props.bookingListQuery.data.filter((booking) => {
      const bookingEmployeeIds = booking.bookingEmployees.map((bea) => bea.employee_id);
      return (
        includes(ids, booking.id) &&
        (includes(groupPilotIds, booking.pilot_id) ||
          includes(groupPilotIds, booking.copilot_id) ||
          intersection(groupPilotIds, bookingEmployeeIds).length > 0 ||
          (booking.calendar_type !== 'flight' &&
            aircraftGroup.name === 'Advanced Flight'))
      );
    });
  }

  listBookings(aircraftGroup) {
    if (queryReady(this.props.bookingListQuery)) {
      const ids = this.filterBookingIds(false, true, true);
      return this.filterBookings(ids, aircraftGroup);
    }
    return [];
  }

  calendarBookings(aircraftGroup) {
    if (queryReady(this.props.bookingListQuery)) {
      const ids = this.filterBookingIds(true, true, true);
      return this.filterBookings(ids, aircraftGroup);
    }
    return [];
  }

  renderSunTimes() {
    const {
      currentSettingsBookingCollectionView,
      currentSettingsBookingCollectionStartDate,
    } = this.props;
    if (currentSettingsBookingCollectionView === 'resourceTimelineDay') {
      const times = SunCalc.getTimes(
        moment(currentSettingsBookingCollectionStartDate).add(12, 'hours').toDate(),
        -36.848461,
        174.763336
      );
      return (
        <div>
          <span
            className="glyphicons glyphicons-sun"
            style={{ verticalAlign: 'middle' }}
          />
          <span
            style={{
              display: 'inline-block',
              lineHeight: 1,
              verticalAlign: 'middle',
            }}
          >
            {`${moment(times.dawn).format('HH:mm')} / ${moment(times.dusk).format(
              'HH:mm'
            )}`}
          </span>
        </div>
      );
    }
    return undefined;
  }

  render() {
    return (
      <div style={{ position: 'relative', left: 0, top: 0 }}>
        {this.renderOverlay()}
        <Row>
          <Col md={5}>
            <Panel>
              <Panel.Body>
                <Row>
                  <Col md={12}>
                    <div className="clearfix">
                      <div className={this.state.isXs ? '' : 'pull-left'}>
                        <h3 style={{ marginTop: '0px', marginBottom: '0' }}>
                          {this.renderHeader()}
                        </h3>
                      </div>
                      <div
                        className={
                          this.state.isXs ? 'form-group-sm ' : 'form-group-sm pull-right'
                        }
                      >
                        <span
                          className="badge"
                          style={this.props.filter_q ? {} : { opacity: '0.5' }}
                        >
                          {this.sizeBookings()}
                        </span>
                        <FormControl
                          style={{
                            verticalAlign: 'middle',
                            display: 'inline-block',
                            width: '135px',
                            marginRight: '8px',
                            marginLeft: '8px',
                          }}
                          type="text"
                          defaultValue={trim(replace(this.props.filter_q, '*', ''))}
                          placeholder="filter..."
                          onChange={this.handleFilter}
                        />
                        {!this.state.nonEmployee && (
                          <Toggle
                            active={this.state.contactOnly}
                            onClick={this.handleContactToggle}
                            on="me"
                            onstyle="primary"
                            off="all"
                            offstyle="info"
                            handlestyle={this.state.contactOnly ? 'info' : 'primary'}
                            size="sm"
                          />
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: '8px', marginBottom: '8px' }}>
                  <Col md={12}>
                    <div className="clearfix">
                      <div className={this.state.isXs ? '' : 'pull-left'}>
                        <ButtonToolbar>
                          {this.state.isMd && (
                            <ButtonGroup>
                              <Button
                                bsSize="small"
                                bsStyle="info"
                                onClick={this.handleCalendarMdToggle}
                              >
                                <span
                                  className={
                                    this.state.showCalendarMd
                                      ? 'glyphicon glyphicon-list'
                                      : 'glyphicon glyphicon-calendar'
                                  }
                                />
                              </Button>
                            </ButtonGroup>
                          )}
                          <ButtonGroup>
                            <Button
                              bsSize="small"
                              bsStyle="info"
                              onClick={this.delayedHandleRefetch}
                            >
                              <span className="glyphicon glyphicon-repeat" />
                            </Button>
                          </ButtonGroup>
                          <ButtonGroup>
                            <Button
                              bsSize="small"
                              bsStyle="info"
                              onClick={this.onPrevCalendarClicked}
                            >
                              <span className="glyphicon glyphicon-chevron-left" />
                            </Button>
                            <Button
                              bsSize="small"
                              bsStyle="info"
                              onClick={this.onNextCalendarClicked}
                            >
                              <span className="glyphicon glyphicon-chevron-right" />
                            </Button>
                          </ButtonGroup>
                          <ButtonGroup>
                            <Button
                              bsSize="small"
                              bsStyle="info"
                              onClick={this.onTodayCalendarClicked}
                            >
                              today
                            </Button>
                          </ButtonGroup>
                          <ButtonGroup>{this.renderMonthPicker()}</ButtonGroup>
                        </ButtonToolbar>
                      </div>
                      <div className={this.state.isXs ? '' : 'pull-right'}>
                        <ButtonToolbar>
                          <ButtonGroup>
                            <Button
                              bsSize="small"
                              bsStyle="info"
                              onClick={this.onDayCalendarClicked}
                            >
                              day
                            </Button>
                            <Button
                              bsSize="small"
                              bsStyle="info"
                              onClick={this.onWeekCalendarClicked}
                            >
                              week
                            </Button>
                            <Button
                              bsSize="small"
                              bsStyle="info"
                              onClick={this.onMonthCalendarClicked}
                            >
                              month
                            </Button>
                          </ButtonGroup>
                          <ButtonGroup>
                            <SplitButton
                              id="booking-manager-add-new-booking"
                              bsStyle="primary"
                              bsSize="sm"
                              title="New"
                              onClick={this.handleDropdownNewClick}
                              onSelect={this.handleDropdownNewSelect}
                            >
                              <MenuItem eventKey="flight">Flight</MenuItem>
                              <MenuItem eventKey="meeting">Meeting</MenuItem>
                              <MenuItem eventKey="leave">Leave</MenuItem>
                              <MenuItem eventKey="rostered">Rostered Off</MenuItem>
                              <MenuItem eventKey="maintenance">Maintenance</MenuItem>
                              <MenuItem eventKey="banner">Banner</MenuItem>
                            </SplitButton>
                          </ButtonGroup>
                        </ButtonToolbar>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div
                    className="col-md-12"
                    style={{ paddingLeft: 0, paddingRight: 0, ...this.sizeList() }}
                  >
                    {this.isLoaded() &&
                      this.props.aircraftGroupListQuery.data.map((aircraftGroup) => {
                        const { id: aircraftGroupId, name: aircraftGroupName } =
                          aircraftGroup;
                        return (
                          <Panel
                            key={aircraftGroupId}
                            expanded={includes(
                              this.props.currentSettingsBookingAircraftGroupIds,
                              aircraftGroupId
                            )}
                            onToggle={() => {}}
                          >
                            <Panel.Heading>
                              <Row style={{ display: 'flex', alignItems: 'center' }}>
                                <Col xs={4}>
                                  <Panel.Title>
                                    <Button
                                      bsStyle="link"
                                      data-id={aircraftGroupId}
                                      onClick={this.handleAircraftGroupToggle}
                                    >
                                      {aircraftGroupName}
                                    </Button>
                                  </Panel.Title>
                                </Col>
                              </Row>
                            </Panel.Heading>
                            <Panel.Collapse
                              data-id={aircraftGroupId}
                              onEntered={this.handleAircraftGroupEntered}
                              onExited={this.handleAircraftGroupExited}
                            >
                              <Panel.Body>
                                {includes(
                                  this.props.currentSettingsEnteredAircraftGroupIds,
                                  aircraftGroupId
                                ) ? (
                                  <BookingManagerList
                                    bookings={this.listBookings(aircraftGroup)}
                                    currentContact={this.props.currentContact}
                                    onListItemCalendarClicked={
                                      this.onListItemCalendarClicked
                                    }
                                    onFlightCompleteClicked={this.onFlightCompleteClicked}
                                    onDeleteClicked={this.onDeleteClicked}
                                    onCloneClicked={this.onCloneClicked}
                                    currentSettingsBookingCalendarTypeBanner={
                                      this.props.currentSettingsBookingCalendarTypeBanner
                                    }
                                    currentSettingsBookingCalendarTypeFlight={
                                      this.props.currentSettingsBookingCalendarTypeFlight
                                    }
                                    currentSettingsBookingStatusCancelled={
                                      this.props.currentSettingsBookingStatusCancelled
                                    }
                                    aircraftsDataSelector={
                                      this.props.aircraftsDataSelector
                                    }
                                    contactsDataSelector={this.props.contactsDataSelector}
                                  />
                                ) : (
                                  <Loader
                                    fadeIn="none"
                                    wrapperStyle={{
                                      zIndex: 1000,
                                      height: '100%',
                                      marginTop: '20px',
                                    }}
                                  />
                                )}
                              </Panel.Body>
                            </Panel.Collapse>
                          </Panel>
                        );
                      })}
                  </div>
                </Row>
              </Panel.Body>
            </Panel>
          </Col>
          <Col md={7}>
            <Row>
              <div className="col-md-12" style={this.sizeCalendar()}>
                {this.isLoaded() &&
                  this.props.aircraftGroupListQuery.data.map((aircraftGroup) => {
                    const { id: aircraftGroupId, name: aircraftGroupName } =
                      aircraftGroup;
                    return (
                      <Panel
                        key={aircraftGroupId}
                        expanded={includes(
                          this.props.currentSettingsBookingAircraftGroupIds,
                          aircraftGroupId
                        )}
                        onToggle={() => {}}
                      >
                        <Panel.Heading>
                          <Row style={{ display: 'flex', alignItems: 'center' }}>
                            <Col xs={4}>
                              <Panel.Title>
                                <Button
                                  bsStyle="link"
                                  data-id={aircraftGroupId}
                                  onClick={this.handleAircraftGroupToggle}
                                >
                                  {aircraftGroupName}
                                </Button>
                              </Panel.Title>
                            </Col>
                            <Col xs={4}>
                              <Panel.Title className="text-center">
                                {this.renderHeaderDateString()}
                              </Panel.Title>
                            </Col>
                            <Col xs={4}>
                              {aircraftGroupName === 'Advanced Flight' && (
                                <Panel.Title className="text-right">
                                  {this.renderSunTimes()}
                                </Panel.Title>
                              )}
                            </Col>
                          </Row>
                        </Panel.Heading>
                        <Panel.Collapse
                          data-id={aircraftGroupId}
                          onEntered={this.handleAircraftGroupEntered}
                          onExited={this.handleAircraftGroupExited}
                        >
                          <Panel.Body>
                            {includes(
                              this.props.currentSettingsEnteredAircraftGroupIds,
                              aircraftGroupId
                            ) ? (
                              <BookingManagerCalendar
                                aircraftGroup={aircraftGroup}
                                bookings={this.calendarBookings(aircraftGroup)}
                                currentSettingsBookingCollectionStartDate={
                                  this.props.currentSettingsBookingCollectionStartDate
                                }
                                currentSettingsBookingCollectionEndDate={
                                  this.props.currentSettingsBookingCollectionEndDate
                                }
                                currentSettingsBookingCollectionView={
                                  this.props.currentSettingsBookingCollectionView
                                }
                                onEventClicked={this.onCalendarEventClicked}
                                onDisplayDateChanged={this.onCalendarDisplayDateChanged}
                                currentSettingsBookingCalendarTypeFlight={
                                  this.props.currentSettingsBookingCalendarTypeFlight
                                }
                              />
                            ) : (
                              <Loader
                                fadeIn="none"
                                wrapperStyle={{
                                  zIndex: 1000,
                                  height: '100%',
                                  marginTop: '20px',
                                }}
                              />
                            )}
                          </Panel.Body>
                          {this.props.currentSettingsBookingCollectionView ===
                            'resourceTimelineDay' &&
                            !this.state.nonEmployee && (
                              <Panel.Body style={{ paddingTop: 0 }}>
                                <Panel.Title style={{ marginBottom: '5px' }}>
                                  <strong>Available Aircraft</strong>
                                </Panel.Title>
                                {this.renderAircraftsStatus(aircraftGroup)}
                              </Panel.Body>
                            )}
                        </Panel.Collapse>
                      </Panel>
                    );
                  })}
                {/* {this.isLoaded() &&
                  this.props.currentSettingsBookingCollectionView ===
                    'resourceTimelineDay' &&
                  !this.state.nonEmployee && (
                    <Row style={{ marginTop: '0px' }}>
                      <Col md={6}>
                        <Panel>
                          <Panel.Heading>Aircraft</Panel.Heading>
                          <Panel.Body>{this.renderAircraftsStatus()}</Panel.Body>
                        </Panel>
                      </Col>
                      <Col md={6}>
                        <Panel>
                          <Panel.Heading>Pilots</Panel.Heading>
                          <Panel.Body>{this.renderPilotsStatus()}</Panel.Body>
                        </Panel>
                      </Col>
                    </Row>
                  )} */}
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const filter = state.filters.booking;
  return {
    filter_ids: filter.ids,
    filter_q: filter.q,
    filtering: filter.filtering,
    currentContact: state.currentContact,
    currentSettingsBookingCollectionStartDate:
      state.currentSettings.bookingCollectionStartDate,
    currentSettingsBookingCollectionEndDate:
      state.currentSettings.bookingCollectionEndDate,
    currentSettingsBookingCollectionView: state.currentSettings.bookingCollectionView,
    currentSettingsBookingStatusCancelled: state.currentSettings.booking_status_cancelled,
    currentSettingsBookingCalendarTypeFlight:
      state.currentSettings.booking_calendar_type_flight,
    currentSettingsBookingCalendarTypeBanner:
      state.currentSettings.booking_calendar_type_banner,
    currentSettingsMutating: state.currentSettings.mutating,
    aircraftsDataSelector: aircraftsData(props),
    contactsDataSelector: contactsData(props),
    containerWidth: state.currentSettings.containerWidth,
    containerHeight: state.currentSettings.containerHeight,
    currentSettingsBookingAircraftGroupIds: state.currentSettings.bookingAircraftGroupIds,
    currentSettingsEnteredAircraftGroupIds: state.currentSettings.enteredAircraftGroupIds,
  };
}

export default compose(
  graphql(bookingUpdateMutation, {
    name: 'bookingUpdateMutation',
  }),
  graphql(bookingDeleteMutation, {
    name: 'bookingDeleteMutation',
  }),
  graphql(bookingCloneMutation, {
    name: 'bookingCloneMutation',
  }),
  graphql(aircraftListQuery, {
    name: 'aircraftListQuery',
  }),
  graphql(aircraftGroupListQuery, {
    name: 'aircraftGroupListQuery',
  }),
  graphql(aircraftTypeListQuery, {
    name: 'aircraftTypeListQuery',
  }),
  graphql(contactItemListQuery, {
    name: 'pilotListQuery',
    options: { variables: { role: 'pilot' } },
  }),
  graphql(contactItemListQuery, {
    name: 'chargeableListQuery',
    options: { variables: { role: 'chargeable' } },
  }),
  graphql(contactItemListQuery, {
    name: 'employeeListQuery',
    options: { variables: { role: 'employee' } },
  }),
  connect(mapStateToProps, {
    filterDo,
    currentSettingsSet,
    mutationSet,
    mutationSuccess,
    mutationFailure,
  }),
  graphql(bookingListQuery, {
    name: 'bookingListQuery',
    options: (props) => ({
      variables: {
        start_at: props.currentSettingsBookingCollectionStartDate,
        end_at: props.currentSettingsBookingCollectionEndDate,
      },
    }),
  })
)(BookingManager);
