export function rehydrate(state, payload, overrides = {}) {
  return {
    ...state,
    ...payload,
    ...overrides,
  };
}

export function fetch(state) {
  return {
    ...state,
    isFetching: true,
  };
}

export function unfetch(state) {
  return {
    ...state,
    isFetching: false,
  };
}

export function reset(state) {
  return state;
}

export function loadSuccess(state, payload, toCollection = true) {
  if (toCollection) {
    payload = { collection: payload };
  }
  return {
    ...state,
    ...payload,
  };
}

export function loadFailure(state) {
  return reset(state);
}
