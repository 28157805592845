import { Table, Button } from 'react-bootstrap';
import { Component } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment';
import Confirm from '../confirm';

import { ContactFullNameMemo } from '../../memos';

moment.updateLocale('en-nz');

class SmsActionListTable extends Component {
  constructor(props) {
    super(props);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
  }

  handleDeleteClick(e) {
    if (this.props.handleDeleteClick) {
      const smsActionId = e.currentTarget.getAttribute('data-id');
      this.props.handleDeleteClick(smsActionId);
    }
  }

  render() {
    return (
      <div>
        <Table hover>
          <thead>
            <tr>
              <th>Id</th>
              <th>Description</th>
              <th>Status</th>
              <th>Saturation</th>
              <th>Due On</th>
              <th>Employee</th>
              <th>Manager</th>
            </tr>
          </thead>
          <tbody>
            {this.props.actions &&
              this.props.actions.map((model) => {
                const {
                  id,
                  description,
                  status,
                  saturation,
                  dueOn,
                  employeeId,
                  managerId,
                } = model;
                return (
                  <tr key={id}>
                    <td>{id}</td>
                    <td>{description}</td>
                    <td>{status}</td>
                    <td>{saturation}</td>
                    <td>{moment(dueOn).format('DD/MM/YYYY')}</td>
                    <td>{ContactFullNameMemo(employeeId, this.props.employees)}</td>
                    <td>{ContactFullNameMemo(managerId, this.props.managers)}</td>
                    <td>
                      {this.props.withEdit && (
                        <LinkContainer to={`/sms/actions/${id}/edit`}>
                          <Button bsStyle="link" bsSize="xsmall">
                            edit
                          </Button>
                        </LinkContainer>
                      )}
                      {this.props.withDelete && (
                        <Confirm
                          confirmId={`sms-action-delete-${id}`}
                          dataId={id}
                          onConfirm={this.handleDeleteClick}
                          title="Delete Action"
                          body="Are you sure you want to delete this action"
                          confirmText="Confirm"
                        >
                          <Button bsStyle="link" bsSize="xsmall">
                            delete
                          </Button>
                        </Confirm>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default SmsActionListTable;
