import memoize from 'lodash/memoize';

const PilotFlightLogTableHeader = memoize(
  (props) => {
    if (props.isHobbing) {
      return (
        <thead>
          <tr>
            <th colSpan={2} style={{ borderBottom: 'none' }}>
              &nbsp;
            </th>
            <th colSpan={4} style={{ textAlign: 'center' }}>
              Day
            </th>
            <th style={{ borderBottom: 'none' }}>&nbsp;</th>
            <th colSpan={4} style={{ textAlign: 'center' }}>
              Night
            </th>
            <th style={{ borderBottom: 'none' }}>&nbsp;</th>
          </tr>
          <tr>
            <th style={{ borderTop: 'none' }}>Pilot</th>
            <th style={{ borderTop: 'none', width: '7%' }} className="text-left">
              Multi Engine
            </th>
            <th style={{ borderTop: 'none', width: '7%' }} className="text-center">
              PIC
            </th>
            <th style={{ borderTop: 'none', width: '7%' }} className="text-center">
              Copilot
            </th>
            <th style={{ borderTop: 'none', width: '7%' }} className="text-center">
              Dual
            </th>
            <th style={{ borderTop: 'none', width: '7%' }} className="text-center">
              Command Practice
            </th>
            <th style={{ borderTop: 'none', width: '1%' }}>&nbsp;</th>
            <th style={{ borderTop: 'none', width: '7%' }} className="text-center">
              PIC
            </th>
            <th style={{ borderTop: 'none', width: '7%' }} className="text-center">
              Copilot
            </th>
            <th style={{ borderTop: 'none', width: '7%' }} className="text-center">
              Dual
            </th>
            <th style={{ borderTop: 'none', width: '7%' }} className="text-center">
              Command Practice
            </th>
            <th style={{ borderTop: 'none', width: '1%' }}>&nbsp;</th>
            <th style={{ borderTop: 'none', width: '200px' }} className="text-left">
              Other Flight Types
            </th>
            <th style={{ borderTop: 'none', width: '40px' }} className="text-right">
              &nbsp;
            </th>
          </tr>
        </thead>
      );
    } else {
      return null;
    }
  },
  (props) => [props.isHobbing].map((i) => String(i)).join('.')
);

export default PilotFlightLogTableHeader;
