import actionTypes from '../constants';
import Api from '../lib/api';
import { manageCatch } from '../lib/action_helpers';

export function typeaheadsFetch() {
  return {
    type: actionTypes.TYPEAHEAD_FETCH,
  };
}

export function typeaheadsReset() {
  return {
    type: actionTypes.TYPEAHEAD_RESET,
  };
}

export function typeaheadsFilterSuccess(payload, meta) {
  return {
    type: actionTypes.TYPEAHEAD_FILTER_SUCCESS,
    payload,
    meta,
  };
}

export function typeaheadsFilterFailure() {
  return {
    type: actionTypes.TYPEAHEAD_FILTER_FAILURE,
  };
}

export function typeaheadsFilterClear(meta) {
  return {
    type: actionTypes.TYPEAHEAD_FILTER_CLEAR,
    meta,
  };
}

export function typeaheadsFilter(
  args = {},
  meta = {},
  success = typeaheadsFilterSuccess,
  failure = typeaheadsFilterFailure,
  clear = typeaheadsFilterClear
) {
  return function typeaheadsFilterDispatch(dispatch) {
    if (args.str) {
      dispatch(typeaheadsFetch());
      Api.get('/api/search/typeahead', args)
        .then((res) => {
          dispatch(success(res.data, meta));
        })
        .catch((res) => {
          manageCatch(dispatch, res, failure);
        });
    } else {
      dispatch(clear(meta));
    }
  };
}
