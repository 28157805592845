import { Modal } from 'react-bootstrap';
import { Component } from 'react';

import SmsControlCreateFormModal from './sms_control_create_form_modal';

class SmsControlCreateModal extends Component {
  constructor(props) {
    super(props);
    this.handleCreateCancel = this.handleCreateCancel.bind(this);
    this.handleCreateSubmit = this.handleCreateSubmit.bind(this);
  }

  handleCreateSubmit(data) {
    if (this.props.handleCreateSubmit) {
      this.props.handleCreateSubmit(data);
    }
  }

  handleCreateCancel() {
    if (this.props.handleCreateCancel) {
      this.props.handleCreateCancel();
    }
  }

  render() {
    return (
      <Modal show={this.props.isCreating} onHide={this.handleCreateCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Control</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SmsControlCreateFormModal
            form="SmsControlCreateFormModal"
            enableReinitialize
            initialValues={{ description: '' }}
            handleCreateSubmit={this.handleCreateSubmit}
            handleCreateCancel={this.handleCreateCancel}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

export default SmsControlCreateModal;
