import actionTypes from '../constants';

export const initialState = {
  fetching: false,
  chargeables: [],
  employees: [],
  locations: [],
  passengers: [],
};

function reset() {
  return initialState;
}

function fetch(state) {
  return {
    ...state,
    fetching: true,
  };
}

function unfetch(state) {
  return {
    ...state,
    fetching: false,
  };
}

function filterClear(state, meta) {
  return {
    ...state,
    fetching: false,
    [meta.collection]: [],
  };
}

function filterSuccess(state, payload, meta) {
  return {
    ...state,
    fetching: false,
    [meta.collection]: payload,
  };
}

export default function typeaheadsReducer(state = initialState, action = {}) {
  const { type, payload, meta } = action;

  switch (type) {
    case actionTypes.TYPEAHEAD_FETCH:
      return fetch(state);
    case actionTypes.TYPEAHEAD_FILTER_FAILURE:
      return unfetch(state);
    case actionTypes.TYPEAHEAD_RESET:
      return reset();
    case actionTypes.TYPEAHEAD_FILTER_SUCCESS:
      return filterSuccess(state, payload, meta);
    case actionTypes.TYPEAHEAD_FILTER_CLEAR:
      return filterClear(state, meta);
    default:
      return state;
  }
}
