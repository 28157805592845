import authReducer, { initialState as authInitialState } from './auth_reducer';
import currentContactReducer, {
  initialState as currentContactInitialState,
} from './current_contact_reducer';
import currentSettingsReducer, {
  initialState as currentSettingsInitialState,
} from './current_settings_reducer';
import currentTenantReducer, {
  initialState as currentTenantInitialState,
} from './current_tenant_reducer';
import filtersReducer, { initialState as filtersInitialState } from './filters_reducer';
import suggestionsReducer, {
  initialState as suggestionsInitialState,
} from './suggestions_reducer';
import tagsReducer, { initialState as tagsInitialState } from './tags_reducer';
import typeaheadsReducer, {
  initialState as typeaheadsInitialState,
} from './typeaheads_reducer';

export const reducers = {
  auth: authReducer,
  currentContact: currentContactReducer,
  currentSettings: currentSettingsReducer,
  currentTenant: currentTenantReducer,
  filters: filtersReducer,
  suggestions: suggestionsReducer,
  tags: tagsReducer,
  typeaheads: typeaheadsReducer,
};

export const initialState = {
  auth: authInitialState,
  currentContact: currentContactInitialState,
  currentSettings: currentSettingsInitialState,
  currentTenant: currentTenantInitialState,
  filters: filtersInitialState,
  suggestions: suggestionsInitialState,
  tags: tagsInitialState,
  typeaheads: typeaheadsInitialState,
};
