import { Component } from 'react';
import { FormGroup, HelpBlock } from 'react-bootstrap';

import DateTime from 'react-datetime';
import moment from 'moment';
import { validationState, validationText } from './helpers';

moment.updateLocale('en-nz');

class ReactDateTimeFilter extends Component {
  constructor(props) {
    super(props);
    this.format = this.props.locked ? 'MM/YYYY' : 'DD/MM/YYYY';
    this.view = this.props.locked ? 'months' : 'days';
    this.period = this.props.locked ? 'month' : 'day';
    this.state = {
      start: moment(this.props.currentSettingsReportStart).format(this.format),
      startError: {},
      end: moment(this.props.currentSettingsReportEnd).format(this.format),
      endError: {},
    };
    this._handleStartChange = this._handleStartChange.bind(this);
    this._handleEndChange = this._handleEndChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    this.setState({
      start: moment(nextProps.currentSettingsReportStart).format(this.format),
      startError: {},
      end: moment(nextProps.currentSettingsReportEnd).format(this.format),
      endError: {},
    });
  }

  _handleStartChange(date) {
    if (moment.isMoment(date)) {
      this.setState({
        start: date.format(this.format),
        startError: {},
        end: date.clone().endOf('month').format(this.format),
        endError: {},
      });
      this.props.onChange({
        reportStart: date.clone().startOf(this.period).toISOString(),
        reportEnd: date.clone().endOf('month').toISOString(),
      });
    } else {
      this.setState({
        startError: { touched: true, invalid: true, error: this.format },
      });
    }
  }

  _handleEndChange(date) {
    if (moment.isMoment(date)) {
      this.setState({
        end: date.format(this.format),
        endError: {},
      });
      this.props.onChange({
        reportEnd: date.clone().endOf(this.period).toISOString(),
      });
    } else {
      this.setState({
        endError: { touched: true, invalid: true, error: this.format },
      });
    }
  }

  render() {
    return (
      <div className="form-group">
        <FormGroup
          style={{ marginLeft: '8px' }}
          bsSize="sm"
          controlId="filterStartAtDate"
          validationState={validationState(this.state.startError)}
        >
          <DateTime
            value={this.state.start}
            dateFormat={this.format}
            timeFormat={false}
            closeOnSelect
            initialViewMode={this.view}
            onChange={this._handleStartChange}
          />
          <HelpBlock>{validationText(this.state.startError)}</HelpBlock>
        </FormGroup>
        {this.props.locked || (
          <div className="form-group">
            <FormGroup
              style={{ margin: '0', padding: '0 0 12px 5px' }}
              bsSize="sm"
              controlId="filterTo"
            >
              <span style={{ paddingLeft: '5px', opacity: '0.8' }}>to...</span>
            </FormGroup>
            <FormGroup
              style={{ marginLeft: '12px' }}
              bsSize="sm"
              controlId="filterEndAtDate"
              validationState={validationState(this.state.endError)}
            >
              <DateTime
                value={this.state.end}
                dateFormat={this.format}
                timeFormat={false}
                closeOnSelect
                initialViewMode={this.view}
                onChange={this._handleEndChange}
              />
              <HelpBlock>{validationText(this.state.endError)}</HelpBlock>
            </FormGroup>
          </div>
        )}
      </div>
    );
  }
}

export default ReactDateTimeFilter;
