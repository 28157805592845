import { Row, Col, FormGroup, Button, HelpBlock } from 'react-bootstrap';
import { Component } from 'react';

import get from 'lodash/get';

import BlurOnlyFormControl from '../form/blur_only_form_control';
import { validationState, validationText } from '../form/helpers';

class BookingPilotFlightNoteFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this._handleShowClick = this._handleShowClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (!get(this.props, 'formValues.pilot_flight_note_attributes.pilot_id')) {
      const input = get(this.props, 'pilot_flight_note_attributes.pilot_id.input');
      input.onChange(get(this.props, 'formValues.pilot_id', ''));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      get(this.props, 'formValues.pilot_id') !== get(nextProps, 'formValues.pilot_id')
    ) {
      const input = get(nextProps, 'pilot_flight_note_attributes.pilot_id.input');
      input.onChange(get(nextProps, 'formValues.pilot_id', ''));
    }
  }

  _handleShowClick() {
    this.setState({
      show: !this.state.show,
    });
  }

  render() {
    const {
      notes: { input: notesInput, meta: notesMeta },
    } = get(this.props, 'pilot_flight_note_attributes');

    return (
      <Row>
        <Col sm={2}>
          <p>
            <strong>Aircraft Notes</strong>
          </p>
        </Col>
        <Col sm={10}>
          <Button
            style={{ padding: 0 }}
            bsStyle="link"
            tabIndex={-1}
            onClick={this._handleShowClick}
          >
            {this.state.show ? 'hide...' : 'show...'}
          </Button>
          <FormGroup
            style={{ display: this.state.show ? 'block' : 'none' }}
            bsSize="sm"
            controlId={notesInput.name}
            validationState={validationState(notesMeta)}
          >
            <BlurOnlyFormControl rows={6} componentClass="textarea" {...notesInput} />
            <HelpBlock>{validationText(notesMeta)}</HelpBlock>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default BookingPilotFlightNoteFields;
