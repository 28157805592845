import { Button } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { LinkContainer } from 'react-router-bootstrap';

const useStyles = createUseStyles({
  container: {
    minHeight: 'calc(100vh - 86px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    width: '400px',
    textAlign: 'center',
  },
  h2: {
    marginBottom: '16px',
  },
});

export default function Page404() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <h2 className={classes.h2}>Something went missing</h2>
        <LinkContainer to="/bookings">
          <Button bsStyle="primary">Home</Button>
        </LinkContainer>
      </div>
    </div>
  );
}
