import { Alert, Row, Col, Table, Button } from 'react-bootstrap';
import { Component } from 'react';
import { Fields } from 'redux-form';

import _compact from 'lodash/compact';
import _defaultTo from 'lodash/defaultTo';
import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import first from 'lodash/first';
import _get from 'lodash/get';
import has from 'lodash/has';

import PilotFlightLogTableHeader from './pilot_flight_log_table_header';
import BookingPilotFlightLogFields from './booking_pilot_flight_log_fields';

class PilotFlightLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flightPilotId: '',
      flightCopilotId: '',
      selectOptions: [],
      flightPilotIds: [],
      currentPilotIds: [],
      multiengine: false,
    };
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    this._getSelectOptions();
    if (
      has(this.props, 'formValues.pilot_flight_logs_attributes') &&
      _get(this.props, 'formValues.pilot_flight_logs_attributes', []).length === 0
    ) {
      const multiengine = _get(
        this.props.aircraftsDataSelector,
        [_get(this.props, 'formValues.aircraft_id'), 'aircraftTypeMultiengine'],
        false
      );
      const pilotFlightLogs = [];
      if (_get(this.props, 'formValues.pilot_id')) {
        pilotFlightLogs.push({
          pilot_id: _get(this.props, 'formValues.pilot_id'),
          pilot_in_charge_day: _get(
            this.props,
            'formValues.hobb_record_attributes.flight_time',
            ''
          ),
          multiengine,
          flight_types: {},
        });
      }
      if (_get(this.props, 'formValues.copilot_id')) {
        pilotFlightLogs.push({
          pilot_id: _get(this.props, 'formValues.copilot_id'),
          copilot_day: _get(
            this.props,
            'formValues.hobb_record_attributes.flight_time',
            ''
          ),
          multiengine,
          flight_types: {},
        });
      }
      this.props.change('pilot_flight_logs_attributes', pilotFlightLogs);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      _get(this.props, 'formValues.aircraft_id', '') !==
        _get(nextProps, 'formValues.aircraft_id', '') ||
      _get(this.props, 'formValues.pilot_id', '') !==
        _get(nextProps, 'formValues.pilot_id', '') ||
      _get(this.props, 'formValues.copilot_id', '') !==
        _get(nextProps, 'formValues.copilot_id', '') ||
      !isEqual(
        _get(this.props, 'formValues.pilot_flight_logs_attributes', []),
        _get(nextProps, 'formValues.pilot_flight_logs_attributes', [])
      )
    ) {
      this._getSelectOptions(nextProps);
    }
  }

  _getSelectOptions(props = this.props) {
    const pilotId = _defaultTo(parseInt(_get(props, 'formValues.pilot_id')), '');
    const coPilotId = _defaultTo(parseInt(_get(props, 'formValues.copilot_id')), '');
    const flightPilotIds = _compact([pilotId, coPilotId]);
    const currentPilotIds = _compact(
      _get(props, 'formValues.pilot_flight_logs_attributes', [])
        .filter((pfl) => !pfl._destroy)
        .map((pfl) => _defaultTo(parseInt(pfl.pilot_id), ''))
    );
    const selectOptions = difference(flightPilotIds, currentPilotIds);
    // console.log(flightPilotIds)
    // console.log(currentPilotIds)
    // console.log(selectOptions)
    const multiengine = _get(
      this.props.aircraftsDataSelector,
      [_get(this.props, 'formValues.aircraft_id'), 'aircraftTypeMultiengine'],
      false
    );
    this.setState({
      flightPilotId: pilotId,
      flightCopilotId: coPilotId,
      flightPilotIds,
      currentPilotIds,
      selectOptions,
      multiengine,
    });
  }

  handleAddButtonClick() {
    const pilotID = first(this.state.selectOptions);
    if (pilotID) {
      const fields = {
        multiengine: this.state.multiengine,
        pilot_id: pilotID,
        flight_types: {},
      };
      if (pilotID === _get(this.props, 'formValues.pilot_id')) {
        fields.pilot_in_charge_day = _get(
          this.props,
          'formValues.hobb_record_attributes.flight_time'
        );
      } else {
        fields.copilot_day = _get(
          this.props,
          'formValues.hobb_record_attributes.flight_time'
        );
      }
      this.props.pilotFlightLogs.fields.push(fields);
    }
  }

  handleDeleteButtonClick(index) {
    this.props.pilotFlightLogs.fields.remove(index);
  }

  _renderEngineWarning() {
    const currentMultis = _get(this.props, 'formValues.pilot_flight_logs_attributes', [])
      .filter((pfl) => !pfl._destroy)
      .map((pfl) => pfl.multiengine);
    if (currentMultis.some((multi) => multi !== this.state.multiengine)) {
      const bookingType = this.state.multiengine ? 'multi-engine' : 'single-engine';
      const logType = this.state.multiengine ? 'single-engine' : 'multi-engine';
      return (
        <Alert bsStyle="warning">
          <strong>Caution</strong>
          &nbsp;
          {`The booking aircraft is a ${bookingType} type but one or more log entries are for ${logType} types`}
        </Alert>
      );
    }
  }

  _renderPilotWarning() {
    const nonFlightPilots = difference(
      this.state.currentPilotIds,
      this.state.flightPilotIds
    );
    if (nonFlightPilots.length > 0) {
      return (
        <Alert bsStyle="warning">
          <strong>Caution</strong>
          &nbsp;
          {'One or more log entries are for non flight pilots.'}
        </Alert>
      );
    }
  }

  render() {
    return (
      <Col sm={12}>
        <Table striped condensed className="small">
          <PilotFlightLogTableHeader isHobbing={this.props.isHobbing} />
          <tbody>
            {this.props.pilotFlightLogs.fields.map((field, index) => {
              const values = _get(this.props, `formValues.${field}`, {});
              if (!values._destroy) {
                const fields = [
                  `${field}._destroy`,
                  `${field}.pilot_id`,
                  `${field}.multiengine`,
                  `${field}.command_practice_day`,
                  `${field}.command_practice_night`,
                  `${field}.dual_day`,
                  `${field}.dual_night`,
                  `${field}.copilot_day`,
                  `${field}.copilot_night`,
                  `${field}.pilot_in_charge_day`,
                  `${field}.pilot_in_charge_night`,
                  `${field}.flight_types`,
                ];
                return (
                  <Fields
                    key={`pfl-${index}`}
                    index={index}
                    parentField={field}
                    names={fields}
                    component={BookingPilotFlightLogFields}
                    formValues={values}
                    handleDeleteButtonClick={this.handleDeleteButtonClick}
                    contactsDataSelector={this.props.contactsDataSelector}
                    currentSettingsPilotFlightLogFlightTypes={
                      this.props.currentSettingsPilotFlightLogFlightTypes
                    }
                    hobbRecordFlightTime={_get(
                      this.props,
                      'formValues.hobb_record_attributes.flight_time',
                      ''
                    )}
                    flightPilotId={this.state.flightPilotId}
                    flightCopilotId={this.state.flightCopilotId}
                  />
                );
              }
            })}
          </tbody>
        </Table>
        <Row>
          <Col sm={12}>
            <Button
              style={{ paddingTop: 0, paddingLeft: 0 }}
              type="button"
              bsStyle="link"
              bsSize="small"
              onClick={this.handleAddButtonClick}
              tabIndex={-1}
              disabled={this.state.selectOptions.length === 0}
            >
              add...
            </Button>
          </Col>
        </Row>
        <Row>
          {this._renderEngineWarning()}
          {this._renderPilotWarning()}
        </Row>
      </Col>
    );
  }
}

export default PilotFlightLogs;
