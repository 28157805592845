import { createClient } from 'graphql-ws';
import { getIdToken } from './local_storage';

// const subscriptionClient = new SubscriptionClient(
//   `${process.env.EXPRESS_WS_URL}/subscriptions`,
//   {
//     reconnect: true,
//     connectionParams: () => ({
//       token: getIdToken() && `${getIdToken()}`,
//     }),
//     connectionCallback,
//   }
// );

// const subscriptionLink = new WebSocketLink(subscriptionClient);

// let restartRequestedBeforeConnected = false;
// let gracefullyRestart = () => {
//   console.log('running top graceful restart');
//   restartRequestedBeforeConnected = true;
// };

const subscriptionClient = createClient({
  url: `${process.env.EXPRESS_WS_URL}/subscriptions`,
  connectionParams: () => ({
    token: getIdToken() && `${getIdToken()}`,
  }),
  lazy: false,
  shouldRetry: () => true,
  retryAttempts: Infinity,
  retryWait: async (retries) => {
    const maxRetryDelay = 32000;
    const retryDelay = Math.min(maxRetryDelay, 1000 * 2 ** retries);
    const random = Math.floor(Math.random() * (3000 - 300) + 300);
    // console.log({ retries });
    // console.log({ retryDelay });
    // console.log({ random });
    await new Promise((resolve) => {
      setTimeout(resolve, retryDelay + random);
    });
  },
  // on: {
  //   connecting: () => console.log('connecting'),
  //   opened: (socket) => console.log('opened', socket),
  //   connected: (socket) => console.log('connected', socket),
  //   closed: (resp) => console.log('closed', resp),
  //   error: (resp) => console.log('error', resp),
  //   message: (resp) => console.log('message', resp),
  // },
});
// console.log(subscriptionClient);
// subscriptionClient.start();

//   client.closeWSConnection = (permanent = true) => {
//     // subscriptionClient.close(permanent);
//     // if (permanent) {
//     //   subscriptionClient.dispose();
//     // } else {
//     //   subscriptionClient.terminate();
//     // }
//   };

//   client.openWSConnection = () => {
//     // subscriptionClient.connect();
//     // subscriptionClient.subscribe();
//   };

//   client.resetWSConnection = () => {
//     // subscriptionClient.close(true);
//     // subscriptionClient.connect();
//     // subscriptionClient.terminate();
//   };

export default subscriptionClient;
