import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  // 'CLIENT_PUBLISH',
  'LOCAL_LOAD',
  'DATA_RESET',
  'DATA_FETCH',
  'DATA_UNFETCH',
  'DATA_LOAD_SUCCESS',
  'DATA_LOAD_FAILURE',
  'AUTH_FETCH',
  'AUTH_LOAD_SUCCESS',
  'AUTH_LOAD_FAILURE',
  'AUTH_RESET_SUCCESS',
  'AUTH_RESET_FAILURE',
  'CURRENT_SETTINGS_SET',
  'FILTER_RESET',
  'FILTER_START',
  'FILTER_STOP',
  'FILTER_SUCCESS',
  'FILTER_FAILURE',
  'FILTER_CLEAR',
  'MUTATION_CREATE',
  'MUTATION_UPDATE',
  'MUTATION_DESTROY',
  'SUGGESTION_RESET',
  'SUGGESTION_FILTER_SUCCESS',
  'SUGGESTION_FILTER_FAILURE',
  'SUGGESTION_FILTER_CLEAR',
  'TAG_CREATE',
  'TYPEAHEAD_FETCH',
  'TYPEAHEAD_RESET',
  'TYPEAHEAD_FILTER_SUCCESS',
  'TYPEAHEAD_FILTER_FAILURE',
  'TYPEAHEAD_FILTER_CLEAR',
]);

export default actionTypes;
