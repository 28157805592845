import { FormGroup, Col, Checkbox, HelpBlock } from 'react-bootstrap';

import { validationState, validationText } from '../form/helpers';

const ContactFormCompanyField = (props) => {
  const { input, meta } = props;
  return (
    <FormGroup controlId="contactCompany" validationState={validationState(meta)}>
      <Col sm={9} smOffset={3}>
        <Checkbox {...input} checked={input.value}>
          Is Company
        </Checkbox>
        <HelpBlock>{validationText(meta, 'Leave unchecked for person')}</HelpBlock>
      </Col>
    </FormGroup>
  );
};

export default ContactFormCompanyField;
