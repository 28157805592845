import { Col, FormGroup, FormControl, ControlLabel, HelpBlock } from 'react-bootstrap';
import { Component } from 'react';

import _compact from 'lodash/compact';
import _first from 'lodash/first';
import _get from 'lodash/get';
import { validationState, validationText } from '../form/helpers';

class AircraftInputField extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      _get(this.props, 'formValues.provider_id', '') !==
      _get(nextProps, 'formValues.provider_id', '')
    ) {
      if (_get(nextProps, 'formValues.provider_id')) {
        const provider = this.props.providers.find(
          (c) => c.id === parseInt(_get(nextProps, 'formValues.provider_id'), 10)
        );
        const defaultAircraftId =
          _first(
            _compact([
              _get(provider, 'default_aircraft_id'),
              _get(provider, 'use_first_if_no_default_aircraft')
                ? _get(provider, ['providerAircrafts', 0, 'id'])
                : '',
            ])
          ) || '';
        this.props.input.onChange(defaultAircraftId);
      } else {
        this.props.input.onChange('');
      }
    }
  }

  renderFormControl() {
    const {
      noTab,
      placeholder,
      type,
      input,
      inputStyle,
      componentClass,
      defaultSelectOption,
      selectOptions,
      rows,
    } = this.props;

    const commonProps = {
      type,
      value: input.value,
      ...(placeholder && { placeholder }),
      ...(componentClass && { componentClass }),
      ...(rows && { rows }),
      ...(noTab && { tabIndex: -1 }),
      ...(inputStyle && { style: inputStyle }),
    };

    if (selectOptions) {
      return (
        <FormControl {...commonProps} onChange={input.onChange}>
          {defaultSelectOption ? (
            <option key={0} value="">
              {}
            </option>
          ) : (
            ''
          )}
          {selectOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </FormControl>
      );
    }
    return undefined;
  }

  render() {
    const {
      input,
      meta,
      bsSize,
      groupStyle,
      labelWidth,
      inputWidth,
      helpText,
      helpBlock,
    } = this.props;

    return (
      <FormGroup
        controlId={input.name}
        validationState={validationState(meta)}
        {...(bsSize && { bsSize })}
        {...(groupStyle && { style: groupStyle })}
      >
        {this.props.labelWidth > 0 && (
          <Col componentClass={ControlLabel} sm={labelWidth}>
            {this.props.children}
          </Col>
        )}
        <Col sm={inputWidth}>
          {this.renderFormControl()}
          {helpBlock && <HelpBlock>{validationText(meta, helpText)}</HelpBlock>}
        </Col>
      </FormGroup>
    );
  }
}

AircraftInputField.defaultProps = {
  bsSize: 'sm',
  labelWidth: 4,
  inputWidth: 8,
  defaultSelectOption: true,
  helpText: '',
  helpBlock: true,
  noTab: true,
};

export default AircraftInputField;
