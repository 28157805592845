import {
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  FormControl,
  HelpBlock,
  Button,
  Panel,
} from 'react-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment';

import debounce from 'lodash/debounce';

import { currentSettingsSet } from '../actions/current_setting_actions';

import ReactDateTimeFilter from '../components/form/react_date_time_filter';
import Loader from '../components/loader';
import ReportHeader from '../components/report_header';

import { queriesReady } from '../lib/utils';

import bookingDetailForMonthByAircraftQuery from '../queries/booking_detail_for_month_by_aircraft_query';
import aircraftListQuery from '../queries/aircraft_list_query';

moment.updateLocale('en-nz');

class ReportAircraftFlightRecord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterAircraftId: this.props.currentSettingsReportAircraftId,
      filterAircraftIds: [],
    };
    this._handleDisplayAircraftIdChange = this._handleDisplayAircraftIdChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.startAtDate) {
      const date = moment(this.props.params.startAtDate, 'MM-YYYY');
      this.props.currentSettingsSet({
        reportStart: date.clone().startOf('month').toISOString(),
        reportEnd: date.clone().endOf('month').toISOString(),
      });
    }
    if (this.props.params.aircraftId) {
      this._handleDisplayAircraftIdChange({
        target: { value: this.props.params.aircraftId },
      });
    }
    if (
      !moment(this.props.currentSettingsReportStart).isSame(
        moment(this.props.currentSettingsReportStart).startOf('month')
      )
    ) {
      this.props.currentSettingsSet({
        reportStart: moment(this.props.currentSettingsReportStart)
          .startOf('month')
          .toISOString(),
      });
    }
    if (
      !moment(this.props.currentSettingsReportEnd).isSame(
        moment(this.props.currentSettingsReportStart).endOf('month')
      )
    ) {
      this.props.currentSettingsSet({
        reportEnd: moment(this.props.currentSettingsReportStart)
          .endOf('month')
          .toISOString(),
      });
    }
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    this.delayedHandleRefetch = debounce((e) => {
      this.props.bookingDetailForMonthByAircraftQuery.refetch();
    }, 250);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { filterAircraftIds } = this.state;
    if (this.isLoaded(nextProps)) {
      filterAircraftIds = nextProps.bookingDetailForMonthByAircraftQuery.data.map(
        (data) => data.aircraft_id
      );
    }
    this.setState({
      filterAircraftIds,
    });
    let filterAircraftId = nextProps.currentSettingsReportAircraftId;
    if (filterAircraftId && this.isLoaded(nextProps)) {
      if (
        filterAircraftIds.length > 0 &&
        filterAircraftIds.indexOf(filterAircraftId) === -1
      ) {
        filterAircraftId = '';
      }
    }
    if (filterAircraftId !== this.props.currentSettingsReportAircraftId) {
      this._handleDisplayAircraftIdChange({ target: { value: filterAircraftId } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleRefetch.cancel();
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(
      props.bookingDetailForMonthByAircraftQuery,
      props.aircraftListQuery
    );
  }

  _handleDisplayAircraftIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value);
    this.setState({
      filterAircraftId: value,
    });
    this.props.currentSettingsSet({
      reportAircraftId: value,
    });
  }

  _renderFixed(value) {
    return value ? value.toFixed(1) : '-';
  }

  _renderPilotFlightExpenses(pilot_expenses) {
    if (pilot_expenses) {
      return (
        <span>
          {pilot_expenses.map((pfe) => {
            const oncharge = pfe.oncharge ? 'Charge Client: Yes' : 'Charge Client: No';
            const fee = `$${pfe.fee}`;
            return (
              <span
                key={pfe.id}
                style={{ display: 'block' }}
              >{`Pilot Expense - ${pfe.note} - ${fee} - ${oncharge}`}</span>
            );
          })}
        </span>
      );
    }
  }

  _renderLandingFees(landing_fees) {
    if (landing_fees) {
      return (
        <span>
          {landing_fees.map((lf) => {
            const oncharge = lf.oncharge ? 'Charge Client: Yes' : 'Charge Client: No';
            const fee = `$${lf.fee}`;
            return (
              <span
                key={lf.id}
                style={{ display: 'block' }}
              >{`${lf.name} Landing Fee - ${fee} - ${oncharge}`}</span>
            );
          })}
        </span>
      );
    }
  }

  _styleHobb(serial_hobb) {
    if (!serial_hobb) {
      return { borderTop: '3px solid red' };
    }
  }

  _renderRowA(booking) {
    const {
      id,
      booking_id,
      reference,
      start_at_s,
      pilot_display_name,
      copilot_display_name,
      start_hobb,
      end_hobb,
      serial_hobb,
      landing_fees,
      pilot_expenses,
    } = booking;
    return (
      <tr>
        <td rowSpan={2} style={{ verticalAlign: 'middle' }}>
          <LinkContainer
            style={{ display: 'block', textAlign: 'left' }}
            to={`/flights/${booking_id}/edit`}
          >
            <Button bsStyle="link" bsSize="xsmall">
              {`#${reference}`}
            </Button>
          </LinkContainer>
        </td>
        <td>{start_at_s}</td>
        <td>
          {[pilot_display_name, copilot_display_name].filter((name) => name).join(',')}
        </td>
        <td style={this._styleHobb(serial_hobb)}>{`${this._renderFixed(
          start_hobb
        )} / ${this._renderFixed(end_hobb)}`}</td>
        <td rowSpan={2}>
          <span>
            {this._renderLandingFees(landing_fees)}
            {this._renderPilotFlightExpenses(pilot_expenses)}
          </span>
        </td>
      </tr>
    );
  }

  _renderRowB(booking) {
    const { flight_time, booking_summary, chargeable_full_names } = booking;
    return (
      <tr>
        <td>{chargeable_full_names}</td>
        <td>{booking_summary}</td>
        <td>{this._renderFixed(flight_time)}</td>
      </tr>
    );
  }

  _renderHeaderRow() {
    return (
      <thead>
        <tr>
          <th>Ref#</th>
          <th>
            Date /
            <br />
            Charge to
          </th>
          <th>
            Pilot /
            <br />
            Flight Summary
          </th>
          <th width="15%">
            Start/End /
            <br />
            Flight Time
          </th>
          <th width="40%">Expenses</th>
        </tr>
      </thead>
    );
  }

  _renderSummary(data, name) {
    const { start_hobb, end_hobb, next_start_hobb, prev_end_hobb, calc_hobb, sum_hobb } =
      data;

    const MM_currentSettingsReportStart = moment(this.props.currentSettingsReportStart);

    return (
      <Row>
        <Col xs={4}>
          <Panel>
            <Panel.Heading>{`Summary for ${name}`}</Panel.Heading>
            <Panel.Body>
              <dl className="dl-horizontal">
                <dt>{`${MM_currentSettingsReportStart.clone()
                  .subtract(1, 'month')
                  .format('MMMM')} End Hobb`}</dt>
                <dd>{this._renderFixed(prev_end_hobb)}</dd>
              </dl>
              <dl className="dl-horizontal">
                <dt>{`${MM_currentSettingsReportStart.format('MMMM')} Start Hobb`}</dt>
                <dd>{this._renderFixed(start_hobb)}</dd>
              </dl>
              <dl className="dl-horizontal">
                <dt>{`${MM_currentSettingsReportStart.format('MMMM')} End Hobb`}</dt>
                <dd>{this._renderFixed(end_hobb)}</dd>
              </dl>
              <dl className="dl-horizontal">
                <dt>{`${MM_currentSettingsReportStart.clone()
                  .add(1, 'month')
                  .format('MMMM')} Start Hobb`}</dt>
                <dd>{this._renderFixed(next_start_hobb)}</dd>
              </dl>
              <dl className="dl-horizontal">
                <dt>End - Prev End</dt>
                <dd>{this._renderFixed(calc_hobb)}</dd>
              </dl>
              <dl className="dl-horizontal">
                <dt>Sum of Flight Times</dt>
                <dd>{this._renderFixed(sum_hobb)}</dd>
              </dl>
            </Panel.Body>
          </Panel>
        </Col>
      </Row>
    );
  }

  _renderAircrafts() {
    return (
      <Row>
        <Col xs={12}>
          {this.props.bookingDetailForMonthByAircraftQuery.data
            .filter((data) => {
              if (
                this.state.filterAircraftId &&
                data.aircraft_id !== this.state.filterAircraftId
              ) {
                return false;
              }
              return true;
            })
            .map((data) => (
              <Row key={data.aircraft_id} style={{ marginBottom: '20px' }}>
                <h4>{data.aircraft_registration_abbreviated}</h4>
                <Col xs={12}>
                  <Table key={data.id}>
                    {this._renderHeaderRow()}
                    {data.bookings.map((booking, index) => (
                      <tbody
                        key={booking.id}
                        style={{
                          backgroundColor: index % 2 === 1 ? 'inherit' : '#f9f9f9',
                        }}
                      >
                        {this._renderRowA(booking)}
                        {this._renderRowB(booking)}
                      </tbody>
                    ))}
                  </Table>
                </Col>
                <Col xs={12}>
                  {this._renderSummary(data, data.aircraft_registration_abbreviated)}
                </Col>
              </Row>
            ))}
        </Col>
      </Row>
    );
  }

  _renderAircraftFilter() {
    const dataAircrafts = this.props.aircraftListQuery.data.filter(
      (model) => this.state.filterAircraftIds.indexOf(model.id) > -1
    );
    return (
      <FormControl
        componentClass="select"
        value={this.state.filterAircraftId}
        onChange={this._handleDisplayAircraftIdChange}
      >
        <option key={0} value="">
          All
        </option>
        {dataAircrafts.map((model) => {
          const { id, registration_abbreviated } = model;
          return (
            <option key={id} value={id}>
              {registration_abbreviated}
            </option>
          );
        })}
      </FormControl>
    );
  }

  render() {
    if (this.isLoading()) {
      return <Loader />;
    }
    return (
      <div>
        <ReportHeader
          title="Aircraft Flight Record Report"
          start={this.props.currentSettingsReportStart}
          end={this.props.currentSettingsReportEnd}
        />
        <Row style={{ marginTop: '10px' }}>
          <Col xs={12}>
            <Form inline>
              <Button
                style={{ margin: '0', padding: '0 0 8px 0' }}
                bsStyle="link"
                bsSize="xsmall"
                onClick={this.delayedHandleRefetch}
              >
                <span className="glyphicon glyphicon-repeat" />
              </Button>
              <ReactDateTimeFilter
                currentSettingsReportStart={this.props.currentSettingsReportStart}
                currentSettingsReportEnd={this.props.currentSettingsReportEnd}
                onChange={this.props.currentSettingsSet}
                locked
              />
              <FormGroup
                style={{ marginLeft: '16px', paddingBottom: '10px' }}
                bsSize="sm"
                controlId="filterId"
              >
                {this._renderAircraftFilter()}
              </FormGroup>
            </Form>
          </Col>
        </Row>
        {this._renderAircrafts()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsReportStart: state.currentSettings.reportStart,
    currentSettingsReportEnd: state.currentSettings.reportEnd,
    currentSettingsReportAircraftId: state.currentSettings.reportAircraftId,
  };
}

export default compose(
  connect(mapStateToProps, { currentSettingsSet }),
  graphql(aircraftListQuery, {
    name: 'aircraftListQuery',
  }),
  graphql(bookingDetailForMonthByAircraftQuery, {
    name: 'bookingDetailForMonthByAircraftQuery',
    options: (props) => ({
      variables: {
        startAt: props.currentSettingsReportStart,
        endAt: props.currentSettingsReportEnd,
      },
      fetchPolicy: 'cache-and-network',
    }),
  })
)(ReportAircraftFlightRecord);
