import {
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  FormControl,
  Button,
  Checkbox,
} from 'react-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { LinkContainer } from 'react-router-bootstrap';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import accounting from 'accounting';

import debounce from 'lodash/debounce';

import { currentSettingsSet } from '../actions/current_setting_actions';

import { mutationSet, mutationFailure } from '../actions/mutation_actions';

import ReactDateTimeFilter from '../components/form/react_date_time_filter';
import Loader from '../components/loader';
import ReportHeader from '../components/report_header';

import { queriesReady, getExport } from '../lib/utils';

import pilotFlightExpenseDetailForMonthByPilotQuery from '../queries/pilot_flight_expense_detail_for_month_by_pilot_query';
import contactItemListQuery from '../queries/contact_item_list_query';

const moment = extendMoment(Moment);
moment.updateLocale('en-nz');

class ReportPilotFlightExpense extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterPilotId: this.props.currentSettingsReportPilotId,
      filterPilotIds: [],
      overnight: false,
      onchargeable: false,
      reimbursable: false,
    };
    this.handleDisplayPilotIdChange = this.handleDisplayPilotIdChange.bind(this);
    this.handleOvernightFilterChecked = this.handleOvernightFilterChecked.bind(this);
    this.handleOnchargeableFilterChecked =
      this.handleOnchargeableFilterChecked.bind(this);
    this.handleReimbursableFilterChecked =
      this.handleReimbursableFilterChecked.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.startAtDate) {
      const date = moment(this.props.params.startAtDate, 'MM-YYYY');
      this.props.currentSettingsSet({
        reportStart: date.clone().startOf('day').toISOString(),
        reportEnd: date.clone().endOf('month').toISOString(),
      });
    }
    if (this.props.params.pilotId) {
      this.handleDisplayPilotIdChange({
        target: {
          value: parseInt(this.props.params.pilotId, 10),
        },
      });
    }
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    this.delayedHandleRefetch = debounce(() => {
      this.props.pilotFlightExpenseDetailForMonthByPilotQuery.refetch();
    }, 250);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { filterPilotIds } = this.state;
    if (this.isLoaded(nextProps)) {
      filterPilotIds = nextProps.pilotFlightExpenseDetailForMonthByPilotQuery.data.map(
        (data) => data.pilot_id
      );
    }
    this.setState({
      filterPilotIds,
    });
    let filterPilotId = nextProps.currentSettingsReportPilotId;
    if (filterPilotId && this.isLoaded(nextProps)) {
      if (filterPilotIds.length > 0 && filterPilotIds.indexOf(filterPilotId) === -1) {
        filterPilotId = '';
      }
    }
    if (filterPilotId !== this.props.currentSettingsReportPilotId) {
      this.handleDisplayPilotIdChange({ target: { value: filterPilotId } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleRefetch.cancel();
  }

  getExport = (e) => {
    this.props.mutationSet(true);
    const reportName = e.target.getAttribute('data-report-name');
    const args = {
      startAt: this.props.currentSettingsReportStart,
      endAt: this.props.currentSettingsReportEnd,
      pilotId: this.props.currentSettingsReportPilotId,
    };
    getExport(reportName, args)
      .then(() => {
        this.props.mutationSet(false);
      })
      .catch((err) => this.props.mutationFailure(err));
  };

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(
      testProps.pilotFlightExpenseDetailForMonthByPilotQuery,
      testProps.pilotListQuery
    );
  }

  handleDisplayPilotIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value, 10))
      ? ''
      : parseInt(e.target.value, 10);
    this.setState({
      filterPilotId: value,
    });
    this.props.currentSettingsSet({
      reportPilotId: value,
    });
  }

  handleOvernightFilterChecked() {
    this.setState((prevState) => ({ overnight: !prevState.overnight }));
  }

  handleOnchargeableFilterChecked() {
    this.setState((prevState) => ({ onchargeable: !prevState.onchargeable }));
  }

  handleReimbursableFilterChecked() {
    this.setState((prevState) => ({ reimbursable: !prevState.reimbursable }));
  }

  renderRow = (pilotFlightExpense) => {
    const {
      id,
      booking_id: bookingId,
      booking_reference: bookingReference,
      booking_start_at_s: bookingStartAtS,
      notes,
      reimbursable,
      onchargeable,
      chargeable_full_names: chargeableFullNames,
      total_excl_gst: totalExclGst,
    } = pilotFlightExpense;

    return (
      <tr key={id}>
        <td>
          <LinkContainer
            style={{ display: 'block', textAlign: 'left' }}
            to={`/flights/${bookingId}/edit`}
          >
            <Button bsStyle="link" bsSize="xsmall">
              {`#${bookingReference}`}
            </Button>
          </LinkContainer>
        </td>
        <td>{bookingStartAtS}</td>
        <td>{notes}</td>
        <td>{reimbursable ? 'yes' : 'no'}</td>
        <td>{onchargeable ? 'yes' : 'no'}</td>
        <td>{chargeableFullNames}</td>
        <td className="text-right">{accounting.formatMoney(totalExclGst)}</td>
      </tr>
    );
  };

  renderRows(pilotFlightExpenses) {
    return (
      <tbody>
        {pilotFlightExpenses.map((pilotFlightExpense) =>
          this.renderRow(pilotFlightExpense)
        )}
      </tbody>
    );
  }

  renderHeaderRow = () => (
    <thead>
      <tr>
        <th>Reference</th>
        <th>Date</th>
        <th>Expense</th>
        <th>Reimburse?</th>
        <th>Oncharge?</th>
        <th>Customer</th>
        <th className="text-right">Excl. Gst</th>
      </tr>
    </thead>
  );

  renderFooterRow = (pilotFlightExpenses, pilotFullName) => {
    const total = pilotFlightExpenses.reduce(
      (sum, expense) => sum + expense.total_excl_gst,
      0
    );
    return (
      <tfoot>
        <tr>
          <th colSpan={5} />
          <th className="text-right">{`Total expenses for ${pilotFullName}:`}</th>
          <th className="text-right">{accounting.formatMoney(total)}</th>
        </tr>
      </tfoot>
    );
  };

  renderPilots() {
    return (
      <Row>
        <Col xs={12}>
          {this.props.pilotFlightExpenseDetailForMonthByPilotQuery.data
            .filter((data) => {
              if (
                this.state.filterPilotId &&
                data.pilot_id !== this.state.filterPilotId
              ) {
                return false;
              }
              return true;
            })
            .map((data) => {
              const pilot = this.props.pilotListQuery.data.find(
                (model) => model.id === data.pilot_id
              );
              if (pilot) {
                const pilotFullName = pilot.fullName;
                const filteredExpenses = data.pilot_flight_expenses.filter((pfe) => {
                  if (this.state.overnight && !pfe.overnight) {
                    return false;
                  }
                  if (this.state.onchargeable && !pfe.onchargeable) {
                    return false;
                  }
                  if (this.state.reimbursable && !pfe.reimbursable) {
                    return false;
                  }
                  return true;
                });
                return (
                  <Row key={data.pilot_id} style={{ marginBottom: '20px' }}>
                    <Col xs={12}>
                      <h4>{pilotFullName}</h4>
                    </Col>
                    <Col xs={12}>
                      <Table striped>
                        {this.renderHeaderRow()}
                        {this.renderRows(filteredExpenses)}
                        {this.renderFooterRow(filteredExpenses, pilotFullName)}
                      </Table>
                    </Col>
                  </Row>
                );
              } else {
                return '';
              }
            })}
        </Col>
      </Row>
    );
  }

  renderPilotFilter() {
    const dataPilots = this.props.pilotListQuery.data.filter(
      (model) => this.state.filterPilotIds.indexOf(model.id) > -1
    );
    return (
      <FormControl
        componentClass="select"
        value={this.state.filterPilotId}
        onChange={this.handleDisplayPilotIdChange}
      >
        <option key={0} value="">
          All
        </option>
        {dataPilots.map((model) => {
          const { id, fullName } = model;
          return (
            <option key={id} value={id}>
              {fullName}
            </option>
          );
        })}
      </FormControl>
    );
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (this.isLoading()) {
      return undefined;
    }
    return (
      <div>
        <Row>
          <Col sm={12}>
            <div className="clearfix">
              <div className="pull-left">
                <ReportHeader
                  title="Flight Expense Report"
                  start={this.props.currentSettingsReportStart}
                  end={this.props.currentSettingsReportEnd}
                />
              </div>
              <div className="pull-right">
                <Button
                  bsStyle="primary"
                  data-report-name="pilot_flight_expense_detail_for_month_by_pilot"
                  onClick={this.getExport}
                >
                  PDF
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: '10px' }}>
          <Col xs={12}>
            <Form inline>
              <Button
                style={{ margin: '0', padding: '0 0 8px 0' }}
                bsStyle="link"
                bsSize="xsmall"
                onClick={this.delayedHandleRefetch}
              >
                <span className="glyphicon glyphicon-repeat" />
              </Button>
              <ReactDateTimeFilter
                currentSettingsReportStart={this.props.currentSettingsReportStart}
                currentSettingsReportEnd={this.props.currentSettingsReportEnd}
                onChange={this.props.currentSettingsSet}
              />
              <FormGroup
                style={{ marginLeft: '16px', paddingBottom: '10px' }}
                bsSize="sm"
                controlId="filterId"
              >
                {this.renderPilotFilter()}
              </FormGroup>
              <FormGroup
                style={{ marginLeft: '16px', paddingBottom: '10px' }}
                bsSize="sm"
                controlId="filterId"
              >
                <Checkbox
                  inline
                  checked={this.state.overnight}
                  onChange={this.handleOvernightFilterChecked}
                >
                  Only Overnight
                </Checkbox>
                <Checkbox
                  inline
                  checked={this.state.onchargeable}
                  onChange={this.handleOnchargeableFilterChecked}
                >
                  Only Onchargeable
                </Checkbox>
                <Checkbox
                  inline
                  checked={this.state.reimbursable}
                  onChange={this.handleReimbursableFilterChecked}
                >
                  Only Reimbursable
                </Checkbox>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        {this.renderPilots()}
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderOverlay()}
        {this.renderData()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsReportPilotId: state.currentSettings.reportPilotId,
    currentSettingsReportStart: state.currentSettings.reportStart,
    currentSettingsReportEnd: state.currentSettings.reportEnd,
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default compose(
  connect(mapStateToProps, {
    currentSettingsSet,
    mutationFailure,
    mutationSet,
  }),
  graphql(contactItemListQuery, {
    name: 'pilotListQuery',
    options: { variables: { role: 'pilot' } },
  }),
  graphql(pilotFlightExpenseDetailForMonthByPilotQuery, {
    name: 'pilotFlightExpenseDetailForMonthByPilotQuery',
    options: (props) => ({
      variables: {
        startAt: props.currentSettingsReportStart,
        endAt: props.currentSettingsReportEnd,
      },
      fetchPolicy: 'cache-and-network',
    }),
  })
)(ReportPilotFlightExpense);
