import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { Outlet } from 'react-router-dom';

const useStyles = createUseStyles({
  heightWrapper: {
    height: 'calc(100vh - 80px - 8px)', // - header - tiny footer
  },
  scrollWrapper: {
    height: '100%',
    overflowY: 'scroll',
  },
});

export default function FluidAndScrollLayout({ routes }) {
  const classes = useStyles();
  const { pathname } = useLocation();
  const scrollableDivRef = useRef(null);
  useEffect(() => {
    scrollableDivRef.current.scrollTo(0, 0);
  }, [pathname, scrollableDivRef]);

  return (
    <div className={classes.heightWrapper}>
      <div className={classes.scrollWrapper} ref={scrollableDivRef}>
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
