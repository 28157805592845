import get from 'lodash/get';
import memoize from 'lodash/memoize';

import { AircraftAirspeedDataMemo } from './aircraft_airspeed_data_memo';

export const BookingAirspeedDataMemo = memoize(
  ({ bookingCruiseAirspeedSlKnots, aircraftId, aircrafts, aircraftTypes }) => {
    if (aircraftId) {
      const aircraftAirspeedData = AircraftAirspeedDataMemo({
        aircraftId,
        aircrafts,
        aircraftTypes,
      });
      return Object.assign({}, aircraftAirspeedData, {
        bookingCruiseAirspeedSlKnots,
        bookingAirspeedKnots:
          bookingCruiseAirspeedSlKnots ||
          get(aircraftAirspeedData, 'aircraftTypeCruiseAirspeedSlKnots', 0) ||
          0,
      });
    } else {
      return {};
    }
  },
  ({ bookingCruiseAirspeedSlKnots, aircraftId }) => {
    if (aircraftId) {
      return [aircraftId, bookingCruiseAirspeedSlKnots].map((i) => String(i)).join('.');
    } else {
      return 'unknown';
    }
  }
);
