import {
  Col,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  InputGroup,
} from 'react-bootstrap';
import { Component } from 'react';
import BlurOnlyFormControl from './blur_only_form_control';
import { validationState, validationText } from './helpers';

class InputField extends Component {
  _renderFormControl() {
    const {
      blurOnly,
      noTab,
      placeholder,
      type,
      input,
      inputStyle,
      componentClass,
      defaultSelectOption,
      selectOptions,
      rows,
    } = this.props;

    const commonProps = {
      type,
      value: input.value,
      ...(placeholder && { placeholder }),
      ...(componentClass && { componentClass }),
      ...(rows && { rows }),
      ...(noTab && { tabIndex: -1 }),
      ...(inputStyle && { style: inputStyle }),
    };

    if (selectOptions) {
      return (
        <FormControl {...commonProps} onChange={input.onChange}>
          {defaultSelectOption ? (
            <option key={0} value="">
              {}
            </option>
          ) : (
            ''
          )}
          {selectOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </FormControl>
      );
    } else if (blurOnly) {
      return <BlurOnlyFormControl {...commonProps} onBlur={input.onBlur} />;
    } else {
      return (
        <FormControl {...commonProps} onBlur={input.onBlur} onChange={input.onChange} />
      );
    }
  }

  render() {
    const {
      input,
      meta,
      bsClass,
      bsSize,
      groupStyle,
      labelWidth,
      inputWidth,
      helpText,
      helpBlock,
      preAddon,
      postAddon,
    } = this.props;

    return (
      <FormGroup
        controlId={input.name}
        validationState={validationState(meta)}
        {...(bsClass && { bsClass })}
        {...(bsSize && { bsSize })}
        {...(groupStyle && { style: groupStyle })}
      >
        {this.props.labelWidth > 0 && (
          <Col componentClass={ControlLabel} sm={labelWidth}>
            {this.props.children}
          </Col>
        )}
        <Col sm={inputWidth}>
          {this.props.labelWidth === 0 && this.props.children && (
            <ControlLabel>{this.props.children}</ControlLabel>
          )}
          {preAddon || postAddon ? (
            <InputGroup>
              {preAddon && <InputGroup.Addon>{preAddon}</InputGroup.Addon>}
              {this._renderFormControl()}
              {postAddon && <InputGroup.Addon>{postAddon}</InputGroup.Addon>}
            </InputGroup>
          ) : (
            this._renderFormControl()
          )}
          {helpBlock && <HelpBlock>{validationText(meta, helpText)}</HelpBlock>}
        </Col>
      </FormGroup>
    );
  }
}

InputField.defaultProps = {
  labelWidth: 3,
  inputWidth: 9,
  defaultSelectOption: true,
  helpText: '',
  helpBlock: true,
};

export default InputField;
