import { Checkbox, HelpBlock, Button } from 'react-bootstrap';
import { Component } from 'react';

import _clone from 'lodash/clone';
import _defaultTo from 'lodash/defaultTo';
import _get from 'lodash/get';
import _has from 'lodash/has';

import BlurOnlyFormControl from '../form/blur_only_form_control';
import { validationText } from '../form/helpers';

class BookingPilotFlightLogFields extends Component {
  constructor(props) {
    super(props);
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
    this.handleFlightTypeChange = this.handleFlightTypeChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // console.log('------')
    // console.log('CWRP')
    // console.log('isPilot', _has(nextProps, 'formValues.pilot_in_charge_day'))
    // console.log('isCopilot', _has(nextProps, 'formValues.copilot_day'))
    if (this.props.hobbRecordFlightTime !== nextProps.hobbRecordFlightTime) {
      let newHobbValue = 0;
      if (nextProps.hobbRecordFlightTime && nextProps.hobbRecordFlightTime > 0) {
        newHobbValue = nextProps.hobbRecordFlightTime;
      }
      if (_has(nextProps, 'formValues.pilot_in_charge_day')) {
        const input = _get(
          this.props,
          `${this.props.parentField}pilot_in_charge_day.input`
        );
        input.onChange(newHobbValue);
      }
      if (_has(nextProps, 'formValues.copilot_day')) {
        const input = _get(this.props, `${this.props.parentField}copilot_day.input`);
        input.onChange(newHobbValue);
      }
    }

    const { flightPilotId, flightCopilotId } = nextProps;

    const { flightPilotId: prevFlightPilotId, flightCopilotId: prevFlightCopilotId } =
      this.props;

    const logPilotId = _defaultTo(
      parseInt(_get(nextProps, 'formValues.pilot_id', ''), 10),
      ''
    );

    // console.log('prev pilot', prevFlightPilotId)
    // console.log('pilot', flightPilotId)
    // console.log('prev copilot', prevFlightCopilotId)
    // console.log('copilot', flightCopilotId)
    // console.log('log pilot', logPilotId)

    if (prevFlightPilotId !== flightPilotId || prevFlightCopilotId !== flightCopilotId) {
      const input = _get(this.props, `${this.props.parentField}pilot_id.input`);
      // console.log('------')
      // console.log('Changed')
      // console.log(prevFlightPilotId)
      // console.log(flightPilotId)
      // console.log(logPilotId)
      if (logPilotId) {
        // console.log('has logPilotId')
        if (prevFlightPilotId !== flightPilotId && prevFlightPilotId === logPilotId) {
          // console.log('updating log with new pilot')
          input.onChange(flightPilotId);
        } else if (
          prevFlightCopilotId !== flightCopilotId &&
          prevFlightCopilotId === logPilotId
        ) {
          // console.log('updating log with new copilot')
          input.onChange(flightCopilotId);
        }
      } else {
        // console.log('has NO log pilot id')
        if (
          flightPilotId &&
          (_has(nextProps, 'formValues.pilot_in_charge_day') ||
            _has(nextProps, 'formValues.pilot_in_charge_night'))
        ) {
          // console.log('updating blank log with new pilot')
          input.onChange(flightPilotId);
        } else if (
          flightCopilotId &&
          (_has(nextProps, 'formValues.copilot_day') ||
            _has(nextProps, 'formValues.copilot_night'))
        ) {
          // console.log('updating blank log with new copilot')
          input.onChange(flightCopilotId);
          // } else {
          //   console.log('nothing can be done with no logPilotId')
        }
        // } else {
        //   console.log('nothing to do for changed flight pilot/copilot')
      }
      //   flightPilotId &&
      //   !logPilotId &&

      // ) {

      // }
      // } else {
      //   console.log('No Change ')
    }
  }

  handleDeleteButtonClick() {
    const id = _get(this.props, 'formValues.id', '');
    if (id) {
      const input = _get(this.props, `${this.props.parentField}._destroy.input`);
      input.onChange(true);
    } else if (this.props.handleDeleteButtonClick) {
      this.props.handleDeleteButtonClick(this.props.index);
    }
  }

  renderInputField = (input, meta) => {
    return (
      <td>
        <BlurOnlyFormControl type="text" {...input} />
        <HelpBlock style={{ color: '#d9230f' }}>{validationText(meta)}</HelpBlock>
      </td>
    );
  };

  handleFlightTypeChange(e) {
    const flightTypeName = e.target.getAttribute('data-type');
    const { value } = e.target;
    const newFlightTypes = _clone(_get(this.props, 'formValues.flight_types', {}));
    newFlightTypes[flightTypeName] = value;
    const input = _get(this.props, `${this.props.parentField}.flight_types.input`);
    input.onChange(newFlightTypes);
  }

  renderPilotName() {
    return _get(
      this.props.contactsDataSelector,
      [_get(this.props, 'formValues.pilot_id'), 'fullName'],
      'Unknown'
    );
  }

  renderFlightTypes() {
    return (
      <p>
        {this.props.currentSettingsPilotFlightLogFlightTypes.map((flightType) => {
          const value = _defaultTo(
            _get(this.props, ['formValues', 'flight_types', flightType], ''),
            ''
          );
          return (
            <span
              key={`pflft-${this.props.index}-${flightType}`}
              style={{ display: 'block' }}
            >
              <span style={{ display: 'inline-block', width: '70%' }}>{flightType}</span>
              <span>
                <input
                  data-type={flightType}
                  style={{ width: '30%' }}
                  type="text"
                  value={value}
                  onChange={this.handleFlightTypeChange}
                />
              </span>
            </span>
          );
        })}
      </p>
    );
  }

  render() {
    const {
      multiengine: { input: multiengineInput },
      command_practice_day: {
        input: commandPracticeDayInput,
        meta: commandPracticeDayMeta,
      },
      command_practice_night: {
        input: commandPracticeNightInput,
        meta: commandPracticeNightMeta,
      },
      dual_day: { input: dualDayInput, meta: dualDayMeta },
      dual_night: { input: dualNightInput, meta: dualNightMeta },
      copilot_day: { input: copilotDayInput, meta: copilotDayMeta },
      copilot_night: { input: copilotNightInput, meta: copilotNightMeta },
      pilot_in_charge_day: { input: pilotInChargeDayInput, meta: pilotInChargeDayMeta },
      pilot_in_charge_night: {
        input: pilotInChargeNightInput,
        meta: pilotInChargeNightMeta,
      },
    } = _get(this.props, this.props.parentField);
    return (
      <tr>
        <td>{this.renderPilotName()}</td>
        <td>
          <Checkbox
            style={{ marginTop: '0' }}
            {...multiengineInput}
            checked={multiengineInput.value}
            tabIndex={-1}
          />
        </td>
        {this.renderInputField(pilotInChargeDayInput, pilotInChargeDayMeta)}
        {this.renderInputField(copilotDayInput, copilotDayMeta)}
        {this.renderInputField(dualDayInput, dualDayMeta)}
        {this.renderInputField(commandPracticeDayInput, commandPracticeDayMeta)}
        <td />
        {this.renderInputField(pilotInChargeNightInput, pilotInChargeNightMeta)}
        {this.renderInputField(copilotNightInput, copilotNightMeta)}
        {this.renderInputField(dualNightInput, dualNightMeta)}
        {this.renderInputField(commandPracticeNightInput, commandPracticeNightMeta)}
        <td />
        <td>{this.renderFlightTypes()}</td>
        <td>
          <Button
            style={{ padding: 0 }}
            type="button"
            bsStyle="link"
            bsSize="small"
            onClick={this.handleDeleteButtonClick}
            tabIndex={-1}
          >
            del...
          </Button>
        </td>
      </tr>
    );
  }
}

export default BookingPilotFlightLogFields;
