import moment from 'moment';
moment.updateLocale('en-nz');

export function defaultNonFlightBooking(
  displayCollectionStartDate,
  requestedCalendarType
) {
  let startAt = moment(displayCollectionStartDate)
    .clone()
    .add(6, 'hours')
    .startOf('minute')
    .format();
  let endAt = moment(displayCollectionStartDate)
    .clone()
    .add(6, 'hours')
    .add(1, 'minutes')
    .startOf('minute')
    .format();
  if (requestedCalendarType === 'banner') {
    startAt = moment(displayCollectionStartDate).clone().startOf('day').format();
    endAt = moment(displayCollectionStartDate)
      .clone()
      .endOf('day')
      .startOf('minute')
      .format();
  }
  return {
    booking_employees_attributes: [],
    status: requestedCalendarType === 'leave' ? 'tentative' : 'confirmed',
    calendar_type: requestedCalendarType,
    start_at: startAt,
    end_at: endAt,
  };
}

export function defaultBooking(displayCollectionStartDate, fsCount = 1) {
  return {
    // chargeable_ids: [],
    booking_chargeables_attributes: [],
    //
    // TESTING
    // booking_chargeables_attributes: [{id:1, chargeable_id: 66, _destroy: false}],
    // provider_id: 139,
    // TESTING
    status: 'confirmed',
    calendar_type: 'flight',
    start_at: moment(displayCollectionStartDate)
      .clone()
      .add(6, 'hours')
      .startOf('minute')
      .format(),
    end_at: moment(displayCollectionStartDate)
      .clone()
      .add(6, 'hours')
      .add(1, 'minutes')
      .startOf('minute')
      .format(),
    flight_segments_attributes: Array(fsCount)
      .fill()
      .map((_, fsIndex) => {
        return Object.assign(
          {},
          defaultFlightSegment(displayCollectionStartDate, fsIndex)
        );
        // return Object.assign({}, defaultFlightSegment(displayCollectionStartDate), {
        //   position: fsIndex,
        //   collection_index: fsIndex
        // })
      }),
  };
}

export function defaultFlightSegment(displayCollectionStartDate, index) {
  return {
    // TESTING
    // start_location_id: index === 0 ? 5 : 378,
    // end_location_id: index === 0 ? 378 : 5,
    // TESTING
    // _destroy: false,
    // position: 0,
    // collection_index: 0,
    start_at: moment(displayCollectionStartDate)
      .clone()
      .add(6, 'hours')
      .startOf('minute')
      .format(),
    end_at: moment(displayCollectionStartDate)
      .clone()
      .add(6, 'hours')
      .add(1, 'minutes')
      .startOf('minute')
      .format(),
    start_critical: false,
    end_critical: false,
    distance_adj: 0,
    airspeed_adj: 0,
    start_fuel_adj: { auto: false, manual: false, adjustment: 0 },
    luggage_weight: 0,
    // passenger_weight: 0,
    // cabin_weight: 0,
    end_location_landing_fee: null,
    end_location_landing_fee_original: null,
    oncharge_end_location_landing_fee: false,
    seat_assignments_attributes: Array(9)
      .fill()
      .map((_, saIndex) => {
        return Object.assign({}, defaultSeatAssignment());
        // return Object.assign({}, defaultSeatAssignment(), {
        //   postion: saIndex
        // })
      }),
  };
}

export function defaultSeatAssignment() {
  return {
    // _destroy: true,
    pax_name: '',
    weight: '',
    passenger_id: '',
    // postion: 0
  };
}

export function defaultBookingDev(displayCollectionStartDate, fsCount = 1) {
  return {
    // chargeable_ids: [],
    booking_chargeables_attributes: [],
    //
    // TESTING
    // aircraft_id: 10,
    // booking_chargeables_attributes: [{id:1, chargeable_id: 66, _destroy: false}],
    // provider_id: 139,
    // TESTING
    status: 'confirmed',
    calendar_type: 'flight',
    start_at: moment(displayCollectionStartDate)
      .clone()
      .add(6, 'hours')
      .startOf('minute')
      .format(),
    end_at: moment(displayCollectionStartDate)
      .clone()
      .add(6, 'hours')
      .add(1, 'minutes')
      .startOf('minute')
      .format(),
    // flight_segments_attributes: Array(1).fill().map((_, fs_i) => {
    //   return Object.assign({}, defaultFlightSegment(displayCollectionStartDate), {
    //     position: fs_i,
    //     collection_index: fs_i
    //   })
    // })
    flight_segments_attributes: Array(fsCount)
      .fill()
      .map((_, fsIndex) => {
        return Object.assign(
          {},
          defaultFlightSegmentDev(displayCollectionStartDate, fsIndex)
        );
      }),
  };
}

export function defaultFlightSegmentDev(displayCollectionStartDate, index) {
  const startAt = moment(displayCollectionStartDate)
    .clone()
    .add(6 + index, 'hours')
    .startOf('minute')
    .format();
  const endAt = moment(displayCollectionStartDate)
    .clone()
    .add(6 + index, 'hours')
    .add(1, 'minutes')
    .startOf('minute')
    .format();
  return {
    // id:1,
    // _destroy: false,
    // position: 0,
    // collection_index: 0,
    // TESTING
    // start_location_id: index === 0 ? 5 : 378,
    // end_location_id: index === 0 ? 378 : 5,
    start_at: startAt,
    end_at: endAt,
    // TESTING
    start_critical: false,
    end_critical: false,
    distance_adj: 0,
    airspeed_adj: 0,
    start_fuel_adj: { auto: false, manual: false, adjustment: 0 },
    luggage_weight: 0,
    // passenger_weight: 0,
    // cabin_weight: 0,
    end_location_landing_fee: null,
    end_location_landing_fee_original: null,
    oncharge_end_location_landing_fee: false,
    seat_assignments_attributes: Array(9)
      .fill()
      .map((_, saIndex) => {
        return Object.assign({}, defaultSeatAssignmentDev());
        // return Object.assign({}, defaultSeatAssignment(), {
        //   postion: sa_i
        // })
      }),
  };
}

function defaultSeatAssignmentDev() {
  return {
    // _destroy: true,
    pax_name: '',
    weight: '',
    passenger_id: '',
    // postion: 0
  };
}
