import { Component } from 'react';
import ReactTags from 'react-tag-autocomplete';

import _find from 'lodash/find';
import _clone from 'lodash/clone';
import _snakeCase from 'lodash/snakeCase';

class TagList extends Component {
  constructor(props) {
    super(props);
    this.onTagDelete = this.onTagDelete.bind(this);
    this.onTagAdd = this.onTagAdd.bind(this);
  }

  onTagDelete(i) {
    const cloneTagCollection = _clone(this.props.tag_collection.value);
    const deletedTag = cloneTagCollection.splice(i, 1);
    const tagCollection = cloneTagCollection.map(({ text }) => ({
      id: _snakeCase(text),
      text,
    }));
    if (this.props.onTagDelete) {
      this.props.onTagDelete(deletedTag[0].text);
    }
    this.props.tag_collection.onChange(tagCollection);
  }

  onTagAdd(tag) {
    const { name: text } = tag;
    if (!_find(this.props.tag_collection.value, { text })) {
      const tagCollection = _clone(this.props.tag_collection.value);
      tagCollection.push({ id: _snakeCase(text), text });
      if (this.props.onTagCreate) {
        this.props.onTagCreate(text);
      }
      this.props.tag_collection.onChange(tagCollection);
    }
  }

  render() {
    const { suggestions, tag_collection: tagCollection } = this.props;
    return (
      <ReactTags
        placeholderText={
          this.props.placeholderText ? this.props.placeholderText : 'Add new tag'
        }
        suggestions={suggestions.map(({ id, text }) => ({ id, name: text }))}
        tags={tagCollection.value.map(({ id, text }) => ({ id, name: text }))}
        onDelete={this.onTagDelete}
        onAddition={this.onTagAdd}
        allowBackspace={false}
        allowNew={this.props.suggestionsOnly ? this.props.suggestionsOnly : true}
      />
    );
  }
}
export default TagList;
