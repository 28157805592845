import get from 'lodash/get';
import includes from 'lodash/includes';
import memoize from 'lodash/memoize';

export const LocationFuelDataMemo = memoize(
  ({ locationId, fuelBowsers, fuelTankers }) => {
    if (locationId) {
      const locationBowsers = fuelBowsers.filter(
        (fb) => fb.location_id === parseInt(locationId)
      );
      const locationTankers = fuelTankers.filter((ft) =>
        includes(ft.service_location_ids, parseInt(locationId))
      );
      return {
        locationBowsers,
        locationTankers,
      };
    } else {
      return {};
    }
  },
  ({ locationId }) => {
    if (locationId) {
      return [locationId].map((i) => String(i)).join('.');
    } else {
      return 'unknown';
    }
  }
);
