import {
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  ButtonToolbar,
  ButtonGroup,
} from 'react-bootstrap';
import { Component } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { reduxForm, Field, getFormValues } from 'redux-form';

import { LinkContainer } from 'react-router-bootstrap';

import Validator from 'validatorjs';

import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _indexOf from 'lodash/indexOf';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import _pick from 'lodash/pick';
import _omitBy from 'lodash/omitBy';
import _set from 'lodash/set';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import { currentSettingsSet } from '../actions/current_setting_actions';

import InputField from '../components/form/input_field';

import SmsActionCreateModal from '../components/sms/sms_action_create_modal';
import SmsActionListTable from '../components/sms/sms_action_list_table';

import smsControlQuery from '../queries/sms_control_query';
import smsControlListQuery from '../queries/sms_control_list_query';
import smsRiskListQuery from '../queries/sms_risk_list_query';

import contactItemListQuery from '../queries/contact_item_list_query';

import smsControlUpdateMutation from '../mutations/sms_control_update_mutation';
import smsControlCreateMutation from '../mutations/sms_control_create_mutation';

import smsActionCreateMutation from '../mutations/sms_action_create_mutation';
import smsActionDeleteMutation from '../mutations/sms_action_delete_mutation';

import { queryReady, queriesReady, typeInput } from '../lib/utils';
import Loader from '../components/loader';

let isInitialisedSmsControlForm = false;

class SmsControlForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: !!this.props.params.id,
      isActionCreating: false,
    };
    this._handleSubmit = this._handleSubmit.bind(this);

    this.handleCreateActionClicked = this.handleCreateActionClicked.bind(this);
    this.handleCreateActionCancel = this.handleCreateActionCancel.bind(this);
    this.handleCreateActionSubmit = this.handleCreateActionSubmit.bind(this);

    this.handleDeleteActionClicked = this.handleDeleteActionClicked.bind(this);
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRouteSmsAction: this.props.location.pathname });
  }

  componentWillUnmount() {
    isInitialisedSmsControlForm = false;
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(
      props.smsRiskListQuery,
      props.managerListQuery,
      props.employeeListQuery,
      [props.smsControlQuery, true] // ignore if undefined
    );
  }

  _handleSubmit(data) {
    const submitData = _cloneDeep(data);

    this.props.mutationSet(true);
    let mutation;
    let mutationMessage;
    const mutationData = {
      variables: {
        input: typeInput(submitData),
      },
      refetchQueries: () => [{ query: smsControlListQuery }, { query: smsRiskListQuery }],
    };
    if (this.state.updating) {
      mutation = this.props.smsControlUpdateMutation;
      mutationMessage = 'Control update';
      mutationData.variables.id = this.props.params.id;
    } else {
      mutation = this.props.smsControlCreateMutation;
      mutationMessage = 'Control create';
    }
    return mutation(mutationData)
      .then((res) => {
        this.props.mutationSuccess(mutationMessage);
        this.props.navigate(this.props.currentSettingsReturnRouteSmsControl);
      })
      .catch((err) => this.props.mutationFailure(err, true));
  }

  handleCreateActionClicked() {
    this.setState({ isActionCreating: true });
  }

  handleCreateActionCancel() {
    this.setState({ isActionCreating: false });
  }

  handleCreateActionSubmit(data) {
    this.setState({ isActionCreating: false });
    this.props.mutationSet(true);
    data.controlId = this.props.params.id;
    return this.props
      .smsActionCreateMutation({
        variables: { input: typeInput(data) },
      })
      .then((res) => {
        this.props.mutationSuccess('Action create');
        this.props.smsControlQuery.refetch();
      })
      .catch((err) => this.props.mutationFailure(err));
  }

  handleDeleteActionClicked(actionId) {
    this.props.mutationSet(true);
    return this.props
      .smsActionDeleteMutation({
        variables: { id: actionId },
      })
      .then((res) => {
        this.props.mutationSuccess('Action deleted');
        this.props.smsControlQuery.refetch();
      })
      .catch((err) => this.props.mutationFailure(err));
  }

  _renderTitle() {
    if (this.state.updating) {
      return 'Edit Control';
    } else {
      return 'New Control';
    }
  }

  _renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
  }

  _renderData() {
    if (
      isInitialisedSmsControlForm &&
      this.isLoaded() &&
      _get(this.props, 'formValues') &&
      (!this.state.updating || _get(this.props, 'formValues.id'))
    ) {
      const { handleSubmit, pristine, submitting, valid } = this.props;

      return (
        <div>
          <SmsActionCreateModal
            isCreating={this.state.isActionCreating}
            handleCreateCancel={this.handleCreateActionCancel}
            handleCreateSubmit={this.handleCreateActionSubmit}
            managers={this.props.managerListQuery.data}
            employees={this.props.employeeListQuery.data}
          />
          <Form horizontal onSubmit={handleSubmit(this._handleSubmit)}>
            <h2>{this._renderTitle()}</h2>
            <fieldset>
              <legend>Details</legend>
              <Field
                type="text"
                name="description"
                component={InputField}
                componentClass="textarea"
                rows={4}
              >
                Description
              </Field>
              <Field
                type="text"
                name="riskId"
                inputWidth={6}
                component={InputField}
                componentClass="select"
                selectOptions={this.props.smsRiskListQuery.data.map((c) => ({
                  id: c.id,
                  name: c.description,
                }))}
              >
                Related Risk
              </Field>
            </fieldset>
            {this.state.updating &&
              this.props.currentContact['manager?'] &&
              _includes(this.props.currentContact.statutory_role_ids, 2) && (
                <fieldset>
                  <legend>Actions</legend>
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={3}>
                      Assigned Actions
                    </Col>
                    <Col sm={9}>
                      <SmsActionListTable
                        managers={this.props.managerListQuery.data}
                        employees={this.props.employeeListQuery.data}
                        actions={this.props.smsControlQuery.data.actions}
                        handleDeleteClick={this.handleDeleteActionClicked}
                        withDelete
                        withEdit
                      />
                      <ButtonGroup>
                        <Button bsStyle="link" onClick={this.handleCreateActionClicked}>
                          Create a new action...
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </FormGroup>
                </fieldset>
              )}
            <FormGroup>
              <Col sm={12}>
                <div className="pull-right">
                  <ButtonToolbar>
                    <ButtonGroup>
                      <LinkContainer to={this.props.currentSettingsReturnRouteSmsControl}>
                        <Button type="reset" bsStyle="danger" disabled={submitting}>
                          Cancel
                        </Button>
                      </LinkContainer>
                    </ButtonGroup>
                    <ButtonGroup>
                      <Button
                        type="submit"
                        bsStyle="primary"
                        disabled={pristine || submitting || !valid}
                      >
                        {submitting ? (
                          <span className="glyphicon glyphicon-refresh glyphicon-spin" />
                        ) : (
                          ''
                        )}{' '}
                        {this.state.updating ? 'Update' : 'Create'}
                      </Button>
                    </ButtonGroup>
                  </ButtonToolbar>
                </div>
              </Col>
            </FormGroup>
          </Form>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        {this._renderOverlay()}
        {this._renderData()}
      </div>
    );
  }
}

const validationAliases = {};

const validationRules = {
  description: 'required',
  riskId: 'required',
};

const validationCustomMessages = {};

function validate(data, props) {
  const validator = new Validator(data, validationRules, validationCustomMessages);
  validator.setAttributeNames(validationAliases);
  validator.passes();
  const errors = validator.errors.all();
  const result = {};
  Object.keys(errors).forEach((key) => {
    _set(result, key, errors[key].join(' '));
  });
  return result;
}

const attributeWhiteList = ['id', 'description', 'riskId'];

function pickInitialValues(smsControlQuery, updating) {
  if (!isInitialisedSmsControlForm) {
    if (!updating) {
      isInitialisedSmsControlForm = true;
      return {};
    } else if (queryReady(smsControlQuery)) {
      isInitialisedSmsControlForm = true;
      const editSmsControl = _pick(
        _omitBy(_cloneDeep(smsControlQuery.data), _isNil),
        attributeWhiteList
      );
      return editSmsControl;
    }
  }
}

function mapStateToProps(state, props) {
  const initialValues = pickInitialValues(props.smsControlQuery, !!props.params.id);
  return {
    initialValues,
    currentContact: state.currentContact,
    currentSettingsReturnRouteSmsControl: state.currentSettings.returnRouteSmsControl,
    currentSettingsMutating: state.currentSettings.mutating,
    formValues: getFormValues('SmsControlForm')(state),
  };
}

export default compose(
  graphql(smsControlCreateMutation, {
    name: 'smsControlCreateMutation',
  }),
  graphql(smsControlUpdateMutation, {
    name: 'smsControlUpdateMutation',
  }),
  graphql(smsActionCreateMutation, {
    name: 'smsActionCreateMutation',
  }),
  graphql(smsActionDeleteMutation, {
    name: 'smsActionDeleteMutation',
  }),
  graphql(smsRiskListQuery, {
    name: 'smsRiskListQuery',
  }),
  graphql(contactItemListQuery, {
    name: 'managerListQuery',
    options: { variables: { role: 'manager' } },
  }),
  graphql(contactItemListQuery, {
    name: 'employeeListQuery',
    options: { variables: { role: 'employee' } },
  }),
  graphql(smsControlQuery, {
    name: 'smsControlQuery',
    skip: (props) => !props.params.id,
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'network-only',
    }),
  }),
  connect(mapStateToProps, {
    mutationSuccess,
    mutationFailure,
    mutationSet,
    currentSettingsSet,
  }),
  reduxForm({
    form: 'SmsControlForm',
    validate,
  })
)(SmsControlForm);
