import actionTypes from '../constants';

export const initialState = {
  isFetching: false,
  collection: [],
};

function reset() {
  return initialState;
}

function filterClear() {
  return initialState;
}

function filterSuccess(state, payload) {
  return {
    ...state,
    collection: payload,
  };
}

export default function suggestionsReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SUGGESTION_RESET:
      return reset();
    case actionTypes.SUGGESTION_FILTER_SUCCESS:
      return filterSuccess(state, payload);
    case actionTypes.SUGGESTION_FILTER_CLEAR:
      return filterClear();
    default:
      return state;
  }
}
