import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import {
  reduxForm,
  Field,
  formValueSelector,
  change as changeFieldValue,
} from 'redux-form';
import moment from 'moment';

import { LinkContainer } from 'react-router-bootstrap';
import {
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  ButtonToolbar,
  ButtonGroup,
} from 'react-bootstrap';

import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import merge from 'lodash/merge';
import ReactDateTimeField from '../components/form/react_date_time_field';
import InputField from '../components/form/input_field';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import fuelTankerListQuery from '../queries/fuel_tanker_list_query';
import fuelBowserListQuery from '../queries/fuel_bowser_list_query';
// This should be usable by an aircraft mutation as well, but its not made as of 29/5/17
// import aircraftUpdateMutation from '../mutations/aircraft_update_mutation'
import fuelTankerUpdateMutation from '../mutations/fuel_tanker_update_mutation';
import fuelBowserFillQuery from '../queries/fuel_bowser_fill_query';

import { queriesReady, queryReady, typeInput } from '../lib/utils';
import Loader from '../components/loader';

moment.updateLocale('en-nz');
const formSelector = formValueSelector('FuelTankerForm');

class FuelBowserFillForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: !!this.props.params.id,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(data) {
    this.props.mutationSet(true);
    let mutation;
    let message;
    let redirect;
    const mutation_data = { variables: { input: typeInput(data) } };
    if (this.props.params.fuelTankerId) {
      mutation = this.props.fuelTankerUpdateMutation;
      message = this.state.updating
        ? 'Fuel Tanker Fuel Bowser Fill update'
        : 'Fuel Tanker Fuel Bowser Fill create';
      redirect =
        this.props.currentSettingsReturnRoute ===
        '/reports/fuel_bowser_fill_for_fuel_tanker'
          ? this.props.currentSettingsReturnRoute
          : '/fuel_tankers';
      mutation_data.variables.id = this.props.params.fuelTankerId;
      mutation_data.variables.input.id = this.props.params.fuelTankerId;
    }
    // add the aircraft mutation creation here
    return mutation(mutation_data)
      .then((res) => {
        this.props.mutationSuccess(message);
        this.props.navigate(redirect);
      })
      .catch((err) => this.props.mutationFailure(err, true, true, true));
  }

  _renderTitle() {
    if (this.state.updating) {
      return 'Edit Fuel Bowser Fill';
    } else {
      return 'New Fuel Bowser Fill';
    }
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(props.fuelTankerListQuery, props.fuelBowserListQuery, [
      props.fuelBowserFillQuery,
      true,
    ]);
  }

  _renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
  }

  _renderFilling() {
    if (this.props.params.fuelTankerId) {
      return this.props.fuelTankerListQuery.data.find(
        (tanker) => tanker.id === parseInt(this.props.params.fuelTankerId)
      ).name;
      // } else if (this.props.params.aircraftId) {
      //   return this.props.aircraftListQuery.data.find(aircraft => aircraft.id === parseInt(this.props.params.aircraftId)).registration_abbreviated
    } else {
      return 'Unknown';
    }
  }

  _renderData() {
    if (this.isLoaded()) {
      const { handleSubmit, reset, pristine, submitting, formValues } = this.props;
      return (
        <Form horizontal onSubmit={handleSubmit(this.onSubmit)}>
          <h2>{this._renderTitle()}</h2>
          <fieldset>
            <legend>Details</legend>
            <FormGroup>
              <Col componentClass={ControlLabel} sm={3}>
                Filling
              </Col>
              <Col sm={3}>
                <FormControl.Static>{this._renderFilling()}</FormControl.Static>
              </Col>
            </FormGroup>
            <FormGroup>
              <Col componentClass={ControlLabel} sm={3}>
                Unit
              </Col>
              <Col sm={3}>
                <FormControl.Static>
                  {this.props.currentSettingsFuelBowserFillDefaultQuantityUnit}
                </FormControl.Static>
              </Col>
            </FormGroup>
            <Field
              type="text"
              blurOnly
              name="fuel_bowser_fills_attributes[0].quantity_value"
              inputWidth={3}
              component={InputField}
              componentClass="input"
            >
              Quantity
            </Field>
            <Field
              type="text"
              name="fuel_bowser_fills_attributes[0].fuel_bowser_id"
              inputWidth={3}
              component={InputField}
              componentClass="select"
              selectOptions={this.props.fuelBowserListQuery.data.map((model) => ({
                id: model.id,
                name: model.name,
              }))}
            >
              Fuel Bowser
            </Field>
            <Field
              name="fuel_bowser_fills_attributes[0].filled_on"
              inputWidth={3}
              component={ReactDateTimeField}
              helpText="DD/MM/YYYY"
              dateFormat="DD/MM/YYYY"
              timeFormat={false}
              closeOnSelect
            >
              Filled On
            </Field>
          </fieldset>
          <FormGroup>
            <Col sm={12}>
              <ButtonToolbar className="pull-right">
                <ButtonGroup>
                  <LinkContainer to="/fuel_tankers">
                    <Button type="reset" bsStyle="danger" disabled={submitting}>
                      Cancel
                    </Button>
                  </LinkContainer>
                  <Button
                    type="submit"
                    bsStyle="primary"
                    disabled={pristine || submitting}
                  >
                    {submitting ? (
                      <span className="glyphicon glyphicon-refresh glyphicon-spin" />
                    ) : (
                      ''
                    )}{' '}
                    {this.state.updating ? 'Update' : 'Create'}
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </Col>
          </FormGroup>
        </Form>
      );
    }
  }

  render() {
    return (
      <div>
        {this._renderOverlay()}
        {this._renderData()}
      </div>
    );
  }
}

function validate(values) {
  return {};
}

const attributeBlackList = [
  '__typename',
  // '__typename',
  // 'fuel_bowser_fill_id'
];

function pickInitialValues(fuelBowserFillQuery) {
  const baseValues = {
    fuel_bowser_fills_attributes: [{ quantity_value: 0, filled_on: moment().format() }],
  };
  if (queryReady(fuelBowserFillQuery)) {
    const attributes = omit(omitBy(fuelBowserFillQuery.data, isNil), attributeBlackList);
    const values = merge({}, baseValues, { fuel_bowser_fills_attributes: [attributes] });
    return values;
  } else {
    return baseValues;
  }
}

function mapStateToProps(state, props) {
  const initialValues = pickInitialValues(props.fuelBowserFillQuery);
  return {
    initialValues,
    currentSettingsReturnRoute: state.currentSettings.returnRoute,
    currentSettingsMutating: state.currentSettings.mutating,
    currentSettingsFuelBowserFillDefaultQuantityUnit:
      state.currentSettings.fuel_bowser_fill_default_quantity_unit,
  };
}

export default compose(
  graphql(fuelTankerUpdateMutation, {
    name: 'fuelTankerUpdateMutation',
  }),
  // graphql(aircraftUpdateMutation, {
  //   name: 'aircraftUpdateMutation',
  // }),
  graphql(fuelTankerListQuery, {
    name: 'fuelTankerListQuery',
  }),
  graphql(fuelBowserListQuery, {
    name: 'fuelBowserListQuery',
  }),
  graphql(fuelBowserFillQuery, {
    name: 'fuelBowserFillQuery',
    skip: (props) =>
      !(props.params.fuelTankerId || props.params.aircraftId) || !props.params.id,
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  connect(mapStateToProps, {
    changeFieldValue,
    mutationSuccess,
    mutationFailure,
    mutationSet,
  }),
  reduxForm({
    form: 'FuelBowserFillForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate,
  })
)(FuelBowserFillForm);
