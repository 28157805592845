import {
  Row,
  Col,
  FormGroup,
  ControlLabel,
  HelpBlock,
  Button,
  Checkbox,
  Glyphicon,
} from 'react-bootstrap';
import { Component } from 'react';

import get from 'lodash/get';

import Confirm from '../confirm';
import InputField from '../form/input_field';
import { validationState, validationText } from '../form/helpers';

class ContactFormPhoneNumberFields extends Component {
  constructor(props) {
    super(props);
    this._handleDefaultClick = this._handleDefaultClick.bind(this);
    this._handleDeleteClick = this._handleDeleteClick.bind(this);
  }

  _handleDefaultClick(e) {
    if (this.props.handleDefaultClick) {
      if (e.target.checked) {
        this.props.handleDefaultClick(this.props.index);
      } else {
        this.props.handleDefaultClick();
      }
    }
  }

  _handleDeleteClick() {
    if (this.props.handleDeleteClick) {
      this.props.handleDeleteClick(this.props.index);
    }
  }

  _renderDeleteMessage() {
    let msg = 'Are you sure you want to delete this phone number information?';
    if (get(this.props, 'formValues.id')) {
      msg +=
        ' Note that while the information will be removed from the list, it will not be permanently deleted until you save this Contact.';
    }
    return msg;
  }

  render() {
    const {
      phone_number_type: { input: phoneNumberTypeInput, meta: phoneNumberTypeMeta },
      description: { input: descriptionInput, meta: descriptionMeta },
      country_code: { input: countryCodeInput, meta: countryCodeMeta },
      area_code: { input: areaCodeInput, meta: areaCodeMeta },
      phone_number: { input: phoneNumberInput, meta: phoneNumberMeta },
      default_phone_number: {
        input: defaultPhoneNumberInput,
        meta: defaultPhoneNumberMeta,
      },
      extension: { input: extensionInput, meta: extensionMeta },
    } = get(this.props, this.props.parentField);

    const { phoneNumberTypes } = this.props;

    return (
      <fieldset>
        <Row>
          <Col xs={2}>
            <InputField
              type="text"
              bsClass="form-group form-horizontal-nested"
              inputStyle={{ marginTop: '5px' }}
              labelWidth={0}
              inputWidth={12}
              componentClass="select"
              selectOptions={phoneNumberTypes}
              input={phoneNumberTypeInput}
              meta={phoneNumberTypeMeta}
            >
              Type
            </InputField>
          </Col>
          <Col xs={8}>
            <InputField
              type="text"
              bsClass="form-group form-horizontal-nested"
              inputStyle={{ marginTop: '5px' }}
              labelWidth={0}
              inputWidth={12}
              input={descriptionInput}
              meta={descriptionMeta}
            >
              Description
            </InputField>
          </Col>
          <Col xs={2}>
            <FormGroup
              controlId={defaultPhoneNumberInput.name}
              validationState={validationState(defaultPhoneNumberMeta)}
            >
              <ControlLabel>&nbsp;</ControlLabel>
              <Checkbox
                {...defaultPhoneNumberInput}
                checked={defaultPhoneNumberInput.value}
                onChange={this._handleDefaultClick}
              >
                Default
              </Checkbox>
              <HelpBlock>{validationText(defaultPhoneNumberMeta)}</HelpBlock>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>
            <InputField
              type="text"
              bsClass="form-group form-horizontal-nested"
              inputStyle={{ marginTop: '5px' }}
              labelWidth={0}
              inputWidth={12}
              input={countryCodeInput}
              meta={countryCodeMeta}
            >
              Ctry Code
            </InputField>
          </Col>
          <Col xs={2}>
            <InputField
              type="text"
              bsClass="form-group form-horizontal-nested"
              inputStyle={{ marginTop: '5px' }}
              labelWidth={0}
              inputWidth={12}
              input={areaCodeInput}
              meta={areaCodeMeta}
            >
              Area Code
            </InputField>
          </Col>
          <Col xs={5}>
            <InputField
              type="text"
              bsClass="form-group form-horizontal-nested"
              inputStyle={{ marginTop: '5px' }}
              labelWidth={0}
              inputWidth={12}
              input={phoneNumberInput}
              meta={phoneNumberMeta}
            >
              Phone Number
            </InputField>
          </Col>
          <Col xs={2}>
            <InputField
              type="text"
              bsClass="form-group form-horizontal-nested"
              inputStyle={{ marginTop: '5px' }}
              labelWidth={0}
              inputWidth={12}
              input={extensionInput}
              meta={extensionMeta}
            >
              Extension
            </InputField>
          </Col>
          <Col sm={1}>
            <div style={{ marginTop: '33px' }}>
              <Confirm
                onConfirm={this._handleDeleteClick}
                title="Delete Phone Number"
                body={this._renderDeleteMessage()}
                confirmText="Confirm"
              >
                <Button type="button" bsStyle="default" bsSize="small">
                  <Glyphicon glyph="trash" />
                </Button>
              </Confirm>
            </div>
          </Col>
        </Row>
      </fieldset>
    );
  }
}

export default ContactFormPhoneNumberFields;
