import { Table, Button, Checkbox } from 'react-bootstrap';
import { Component } from 'react';
import { LinkContainer } from 'react-router-bootstrap';

import _includes from 'lodash/includes';
import Confirm from '../confirm';

class SmsRiskListTable extends Component {
  constructor(props) {
    super(props);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleSelectClick = this.handleSelectClick.bind(this);

    this.handleCreateControlClick = this.handleCreateControlClick.bind(this);
  }

  handleDeleteClick(e) {
    if (this.props.handleDeleteClick) {
      const smsRiskId = e.currentTarget.getAttribute('data-id');
      this.props.handleDeleteClick(smsRiskId);
    }
  }

  handleRemoveClick(e) {
    if (this.props.handleRemoveClick) {
      const smsRiskId = e.currentTarget.getAttribute('data-id');
      this.props.handleRemoveClick(smsRiskId);
    }
  }

  handleSelectClick(e) {
    if (this.props.handleSelectClick) {
      const smsRiskId = e.currentTarget.getAttribute('data-id');
      const { checked } = e.currentTarget;
      this.props.handleSelectClick(smsRiskId, checked);
    }
  }

  handleCreateControlClick(e) {
    const riskId = e.currentTarget.getAttribute('data-id');
    if (this.props.handleCreateControlClick) {
      this.props.handleCreateControlClick(riskId);
    }
  }

  render() {
    return (
      <div>
        <Table hover>
          <thead>
            <tr>
              <th>Id</th>
              <th>Description</th>
              <th>Controls</th>
              <th>Consequence</th>
              <th>Likelihood</th>
              <th>Risk Level</th>
              <th>Risk Group</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.props.risks &&
              this.props.risks.map((model) => {
                const {
                  id,
                  description,
                  consequence,
                  likelihood,
                  riskLevel,
                  riskGroup,
                  controls,
                } = model;
                return (
                  <tr key={id}>
                    <td>{id}</td>
                    <td>{description}</td>
                    <td>
                      {controls &&
                        controls.map((control) => (
                          <span key={control.id}>
                            {control.description}
                            <br />
                          </span>
                        ))}
                      {this.props.withCreateControl && (
                        <Button
                          data-id={id}
                          bsStyle="link"
                          bsSize="xsmall"
                          onClick={this.handleCreateControlClick}
                          style={{ paddingLeft: 0 }}
                        >
                          Add control...
                        </Button>
                      )}
                    </td>
                    <td>{consequence}</td>
                    <td>{likelihood}</td>
                    <td>{riskLevel}</td>
                    <td>{riskGroup}</td>
                    <td>
                      {this.props.withEdit && (
                        <LinkContainer to={`/sms/risks/${id}/edit`}>
                          <Button bsStyle="link" bsSize="xsmall">
                            edit
                          </Button>
                        </LinkContainer>
                      )}
                      {this.props.withDelete && (
                        <Confirm
                          confirmId={`sms-risk-delete-${id}`}
                          dataId={id}
                          onConfirm={this.handleDeleteClick}
                          title="Delete Risk"
                          body="Are you sure you want to delete this risk"
                          confirmText="Confirm"
                        >
                          <Button bsStyle="link" bsSize="xsmall">
                            delete
                          </Button>
                        </Confirm>
                      )}
                      {this.props.withRemove && (
                        <Confirm
                          confirmId={`sms-risk-remove-${id}`}
                          dataId={id}
                          onConfirm={this.handleRemoveClick}
                          title="Remove Risk"
                          body="Are you sure you want to remove this risk from the report"
                          confirmText="Confirm"
                        >
                          <Button bsStyle="link" bsSize="xsmall">
                            remove
                          </Button>
                        </Confirm>
                      )}
                      {this.props.withSelect && (
                        <Checkbox
                          data-id={id}
                          value={id}
                          checked={_includes(this.props.riskIds, id)}
                          onChange={this.handleSelectClick}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default SmsRiskListTable;
