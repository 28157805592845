import {
  Modal,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  Col,
} from 'react-bootstrap';
import { Component } from 'react';

class PassengerAddModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      weight: 0,
    };
    this._handleFirstNameChange = this._handleFirstNameChange.bind(this);
    this._handleLastNameChange = this._handleLastNameChange.bind(this);
    this._handleWeightChange = this._handleWeightChange.bind(this);

    this._handleAddCancel = this._handleAddCancel.bind(this);
    this._handleAddSubmit = this._handleAddSubmit.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.show && nextProps.show) {
      this.setState({
        firstName: nextProps.label.split(' ')[0],
        lastName: nextProps.label.split(' ')[1] || 'LastName',
        weight: nextProps.weight || 0,
      });
    }
  }

  _resetState() {
    this.setState({
      firstName: '',
      lastName: '',
      weight: 0,
    });
  }

  _handleAddCancel() {
    this._resetState();
    if (this.props.handleAddPassengerCancel) {
      this.props.handleAddPassengerCancel();
    }
  }

  _handleAddSubmit(e) {
    if (this.props.handleAddPassengerSubmit) {
      this.props.handleAddPassengerSubmit(this.state);
    }
    this._resetState();
  }

  _handleFirstNameChange(e) {
    this.setState({
      firstName: e.currentTarget.value,
    });
  }

  _handleLastNameChange(e) {
    this.setState({
      lastName: e.currentTarget.value,
    });
  }

  _handleWeightChange(e) {
    this.setState({
      weight: e.currentTarget.value,
    });
  }

  render() {
    const { show, index, flightSegmentIndex, label } = this.props;

    return (
      <Modal
        id={`booking-flight-segment-${flightSegmentIndex}-add-passenger-modal-${index}`}
        show={show}
        onHide={this._handleAddCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>{`Create new passenger ${label}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-horizontal">
            <FormGroup controlId="formInlineFirstName" bsSize="sm">
              <Col componentClass={ControlLabel} sm={3}>
                First Name
              </Col>
              <Col sm={4}>
                <FormControl
                  type="text"
                  placeholder="First name"
                  value={this.state.firstName}
                  onChange={this._handleFirstNameChange}
                />
              </Col>
            </FormGroup>
            <FormGroup controlId="formInlineLastName" bsSize="sm">
              <Col componentClass={ControlLabel} sm={3}>
                Last Name
              </Col>
              <Col sm={4}>
                <FormControl
                  type="text"
                  placeholder="Last name"
                  value={this.state.lastName}
                  onChange={this._handleLastNameChange}
                />
              </Col>
            </FormGroup>
            <FormGroup controlId="formInlineLastName" bsSize="sm">
              <Col componentClass={ControlLabel} sm={3}>
                Weight
              </Col>
              <Col sm={4}>
                <FormControl
                  type="text"
                  placeholder="weight"
                  value={this.state.weight}
                  onChange={this._handleWeightChange}
                />
              </Col>
            </FormGroup>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" bsStyle="danger" onClick={this._handleAddCancel}>
            Cancel
          </Button>
          <Button
            type="button"
            bsStyle="primary"
            disabled={!this.state.firstName}
            onClick={this._handleAddSubmit}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default PassengerAddModal;
