import { Col, FormGroup, FormControl, ControlLabel, HelpBlock } from 'react-bootstrap';
import { Component } from 'react';

import defaultTo from 'lodash/defaultTo';
import find from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import { validationState, validationText } from '../form/helpers';

class PilotInputField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectOptions: [],
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.aircraft_id && this.props.flight_type_id) {
      this.setState({
        selectOptions: this._getSelectOptions(),
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.aircraft_id !== nextProps.aircraft_id ||
      this.props.flight_type_id !== nextProps.flight_type_id
    ) {
      const pilotId = defaultTo(parseInt(nextProps.pilot_id), '');
      const aircraftId = defaultTo(parseInt(nextProps.aircraft_id), '');
      const flightTypeId = defaultTo(parseInt(nextProps.flight_type_id), '');
      let newPilotId = '';
      let selectOptions = [];
      if (aircraftId && flightTypeId) {
        selectOptions = this._getSelectOptions(nextProps);
        const pilotIds = map(
          get(
            find(
              get(
                find(nextProps.aircraftFlightTypePilotMap, ['aircraft_id', aircraftId]),
                'flight_types',
                {}
              ),
              ['flight_type_id', flightTypeId]
            ),
            'pilots',
            {}
          ),
          'pilot_id'
        );
        if (pilotId && includes(pilotIds, pilotId)) {
          newPilotId = pilotId;
        } else {
          const aircraft = this.props.aircrafts.find((c) => c.id === aircraftId);
          const aircraftDefaultPilotId = get(aircraft, 'default_pilot_id', '');
          if (aircraftDefaultPilotId && includes(pilotIds, aircraftDefaultPilotId)) {
            newPilotId = aircraftDefaultPilotId;
          } else {
            newPilotId = '';
          }
        }
      }
      this.setState({
        selectOptions,
      });
      if (newPilotId !== pilotId) {
        this.props.input.onChange(newPilotId);
      }
    }
  }

  _getSelectOptions(props = this.props) {
    const aircraftId = defaultTo(parseInt(props.aircraft_id), '');
    const flightTypeId = defaultTo(parseInt(props.flight_type_id), '');
    const pilotIds = map(
      get(
        find(
          get(
            find(props.aircraftFlightTypePilotMap, ['aircraft_id', aircraftId]),
            'flight_types',
            {}
          ),
          ['flight_type_id', flightTypeId]
        ),
        'pilots',
        {}
      ),
      'pilot_id'
    );
    return props.pilots
      .filter((p) => includes(pilotIds, p.id))
      .map((p) => ({ id: p.id, name: p.fullName }));
  }

  _renderFormControl() {
    const {
      noTab,
      placeholder,
      type,
      input,
      inputStyle,
      componentClass,
      defaultSelectOption,
      rows,
      copilot_id,
    } = this.props;

    const commonProps = {
      type,
      value: input.value,
      ...(placeholder && { placeholder }),
      ...(componentClass && { componentClass }),
      ...(rows && { rows }),
      ...(noTab && { tabIndex: -1 }),
      ...(inputStyle && { style: inputStyle }),
    };

    return (
      <FormControl {...commonProps} onChange={input.onChange}>
        {defaultSelectOption ? (
          <option key={0} value="">
            {}
          </option>
        ) : (
          ''
        )}
        {this.state.selectOptions.map((option) => (
          <option
            key={option.id}
            value={option.id}
            disabled={option.id === defaultTo(parseInt(copilot_id), '')}
          >
            {option.name}
          </option>
        ))}
      </FormControl>
    );
  }

  render() {
    const {
      input,
      meta,
      bsSize,
      groupStyle,
      labelWidth,
      inputWidth,
      helpText,
      helpBlock,
    } = this.props;

    return (
      <FormGroup
        controlId={input.name}
        validationState={validationState(meta)}
        {...(bsSize && { bsSize })}
        {...(groupStyle && { style: groupStyle })}
      >
        {this.props.labelWidth > 0 && (
          <Col componentClass={ControlLabel} sm={labelWidth}>
            {this.props.children}
          </Col>
        )}
        <Col sm={inputWidth}>
          {this._renderFormControl()}
          {helpBlock && <HelpBlock>{validationText(meta, helpText)}</HelpBlock>}
        </Col>
      </FormGroup>
    );
  }
}

PilotInputField.defaultProps = {
  bsSize: 'sm',
  labelWidth: 4,
  inputWidth: 8,
  defaultSelectOption: true,
  helpText: '',
  helpBlock: true,
  noTab: true,
};

export default PilotInputField;
