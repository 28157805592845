import { Component } from 'react';

import get from 'lodash/get';

import FlightPlan from './flight_plan';

class FlightPlans extends Component {
  render() {
    let activeIndex = -1;
    return (
      <tbody>
        {this.props.flightSegments.fields.map((field, index) => {
          const values = get(this.props, field, {});
          if (!values._destroy) {
            activeIndex += 1;
            return (
              <FlightPlan
                key={`fp-${index}`}
                index={index}
                change={this.props.change}
                field={field}
                formValues={values}
                fuelData={this.props.fuelData[activeIndex]}
                weightData={this.props.weightData[activeIndex]}
                distanceData={this.props.distanceData[activeIndex]}
                loadedFlighttime={get(this.props.flighttimeData, [
                  activeIndex,
                  'flightSegmentLoadedFlighttime',
                ])}
                locationsDataSelector={this.props.locationsDataSelector}
              />
            );
          }
        })}
      </tbody>
    );
  }
}

export default FlightPlans;
