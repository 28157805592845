import { Row, Col, FormGroup, FormControl, HelpBlock, Button } from 'react-bootstrap';
import { Component } from 'react';

import get from 'lodash/get';

import BlurOnlyFormControl from '../form/blur_only_form_control';
import { validationStates, validationText } from '../form/helpers';

class FuelBowserFillFields extends Component {
  constructor(props) {
    super(props);
    this._handleDeleteButtonClick = this._handleDeleteButtonClick.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.bookingAircraftId !== nextProps.bookingAircraftId) {
      const input = get(nextProps, `${nextProps.parentField}bowser_fillable_id.input`);
      if (nextProps.bookingAircraftId) {
        input.onChange(nextProps.bookingAircraftId);
      } else {
        input.onChange('');
      }
    }
  }

  _handleDeleteButtonClick() {
    const id = get(this.props, 'formValues.id', '');
    if (id) {
      const input = get(this.props, `${this.props.parentField}._destroy.input`);
      input.onChange(true);
    } else if (this.props.handleDeleteButtonClick) {
      this.props.handleDeleteButtonClick(this.props.index);
    }
  }

  render() {
    const {
      quantity_value: { input: quantityValueInput, meta: quantityValueMeta },
      fuel_bowser_id: { input: fuelBowserIDInput, meta: fuelBowserIDMeta },
      bowser_fillable_id: { meta: aircraftIDMeta },
    } = get(this.props, this.props.parentField);

    return (
      <Row className="form-horizontal">
        <Col sm={12}>
          <FormGroup
            bsSize="sm"
            controlId={quantityValueInput.name}
            validationState={validationStates([
              quantityValueMeta,
              fuelBowserIDMeta,
              aircraftIDMeta,
            ])}
          >
            <Col sm={2}>
              <FormControl.Static>
                {this.props.bookingAircraftId
                  ? this.props.aircrafts.find(
                      (aircraft) => aircraft.id === parseInt(this.props.bookingAircraftId)
                    ).registration_abbreviated
                  : 'pick aircraft...'}
              </FormControl.Static>
              <HelpBlock>{validationText(aircraftIDMeta)}</HelpBlock>
            </Col>
            <Col sm={2}>
              <BlurOnlyFormControl type="text" {...quantityValueInput} />
              <HelpBlock>{validationText(quantityValueMeta)}</HelpBlock>
            </Col>
            <Col sm={4}>
              <FormControl
                type="text"
                componentClass="select"
                tabIndex="-1"
                {...fuelBowserIDInput}
              >
                <option key={0} value="">
                  {}
                </option>
                {this.props.selectOptions.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </FormControl>
              <HelpBlock>{validationText(fuelBowserIDMeta)}</HelpBlock>
            </Col>
            <Col sm={2}>
              <Button
                style={{ padding: 0 }}
                type="button"
                bsStyle="link"
                bsSize="small"
                onClick={this._handleDeleteButtonClick}
                tabIndex={-1}
              >
                del...
              </Button>
            </Col>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default FuelBowserFillFields;
