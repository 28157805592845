import { Component } from 'react';
import * as React from 'react';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  Panel,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Thumbnail,
} from 'react-bootstrap';
import pickBy from 'lodash/pickBy';
import last from 'lodash/last';
import Confirm from '../components/confirm';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import fuelTypeListQuery from '../queries/fuel_type_list_query';
import fuelTankerQuery from '../queries/fuel_tanker_query';
import fuelTankerDeleteMutation from '../mutations/fuel_tanker_delete_mutation';

import { queriesReady } from '../lib/utils';
import Loader from '../components/loader';

class FuelTankerShow extends React.Component {
  constructor(props) {
    super(props);
    this._handleDeleteClick = this._handleDeleteClick.bind(this);
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(props.fuelTypeListQuery, props.fuelTankerQuery);
  }

  _handleDeleteClick(e) {
    this.props.mutationSet(true);
    const fuel_tanker_id = last(e.currentTarget.id.split('-'));
    this.props
      .fuelTankerDeleteMutation({
        variables: {
          id: fuel_tanker_id,
        },
      })
      .then((res) => {
        this.props.mutationSuccess('Fuel Tanker delete');
        this.props.navigate('/fuel_tankers');
      })
      .catch((err) => {
        this.props.mutationFailure(err);
      });
  }

  _renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
  }

  _renderData() {
    if (this.isLoaded()) {
      const { id, name, fuel_type_id } = this.props.fuelTankerQuery.data;
      const fuel_type = this.props.fuelTypeListQuery.data.find(
        (ft) => ft.id === fuel_type_id
      );
      return (
        <Row>
          <Col sm={12}>
            <Row>
              <Col sm={12}>
                <div className="clearfix">
                  <div className="pull-left">
                    <h3>{`Fuel Tanker Details for #${id}`}</h3>
                  </div>
                  <div className="pull-right">
                    <ButtonToolbar>
                      <ButtonGroup>
                        <Confirm
                          confirmId={`fuel-tanker-delete-${id}`}
                          onConfirm={this._handleDeleteClick}
                          title="Delete Fuel Tanker"
                          body="Are you sure you want to delete this Fuel Tanker?"
                          confirmText="Confirm"
                        >
                          <Button bsStyle="primary">Delete</Button>
                        </Confirm>
                      </ButtonGroup>
                      <ButtonGroup>
                        <LinkContainer to="/fuel_tankers">
                          <Button bsStyle="primary">All Fuel Tankers</Button>
                        </LinkContainer>
                      </ButtonGroup>
                    </ButtonToolbar>
                  </div>
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: '20px' }}>
              <Col sm={6}>
                <Row>
                  <Col xs={12}>
                    <Panel>
                      <Panel.Heading>Fuel Tanker Details</Panel.Heading>
                      <Panel.Body>
                        <dl className="dl-horizontal">
                          <dt>Name</dt>
                          <dd>{name}</dd>
                        </dl>
                        <dl className="dl-horizontal">
                          <dt>Fuel Type</dt>
                          <dd>{fuel_type.name}</dd>
                        </dl>
                      </Panel.Body>
                    </Panel>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }
  }

  render() {
    return (
      <div>
        {this._renderOverlay()}
        {this._renderData()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

FuelTankerShow = compose(
  graphql(fuelTankerDeleteMutation, {
    name: 'fuelTankerDeleteMutation',
  }),
  graphql(fuelTypeListQuery, {
    name: 'fuelTypeListQuery',
  }),
  graphql(fuelTankerQuery, {
    name: 'fuelTankerQuery',
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'cache-and-network',
    }),
  })
)(FuelTankerShow);

export default connect(mapStateToProps, {
  mutationSuccess,
  mutationFailure,
  mutationSet,
})(FuelTankerShow);
