import {
  Row,
  Col,
  Panel,
  FormGroup,
  FormControl,
  HelpBlock,
  ControlLabel,
} from 'react-bootstrap';
import { Component } from 'react';

import get from 'lodash/get';
import first from 'lodash/first';
import last from 'lodash/last';
import { validationState, validationText } from '../form/helpers';
import BlurOnlyFormControl from '../form/blur_only_form_control';

class BookingAdminFlightRecordFields extends Component {
  UNSAFE_componentWillMount() {
    this._setInitialValues();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      get(this.props, 'formValues.hobb_record_attributes.flight_time', '') !==
      get(nextProps, 'formValues.hobb_record_attributes.flight_time', '')
    ) {
      const input = get(nextProps, 'admin_flight_record_attributes.flight_time.input');
      input.onChange(get(nextProps, 'formValues.hobb_record_attributes.flight_time', ''));
    }
  }

  _setInitialValues(props = this.props) {
    if (!get(props, 'formValues.admin_flight_record_attributes.admin_id')) {
      const input = get(props, 'admin_flight_record_attributes.admin_id.input');
      input.onChange(get(props, 'currentContact.id', ''));
    }
    if (!get(props, 'formValues.admin_flight_record_attributes.flight_time')) {
      const input = get(props, 'admin_flight_record_attributes.flight_time.input');
      input.onChange(get(props, 'formValues.hobb_record_attributes.flight_time', ''));
    }
    if (!get(props, 'formValues.admin_flight_record_attributes.flight_summary')) {
      const input = get(props, 'admin_flight_record_attributes.flight_summary.input');
      input.onChange(this._getFlightSummary());
    }
  }

  _getFlightSummary() {
    const fss = get(this.props, 'formValues.flight_segments_attributes', []).filter(
      (fs) => !fs._destroy
    );
    if (fss.length > 0) {
      const newSummaries = fss.map((summary) => {
        const startLocationName =
          get(this.props.locationsDataSelector, [
            get(summary, 'start_location_id'),
            'shorthandName',
          ]) || 'Unknown';
        const pax = summary.seat_assignments_attributes
          .map((sa) => sa.pax_name)
          .filter((name) => name)
          .join(', ');
        return pax ? `${startLocationName} (${pax})` : startLocationName;
      });
      const endLocationName =
        get(this.props.locationsDataSelector, [
          get(last(fss), 'end_location_id'),
          'shorthandName',
        ]) || 'Unknown';
      newSummaries.push(endLocationName);
      return newSummaries.join(' - ');
    } else {
      return ' No flight summary';
    }
  }

  _renderPilotFlightExpense(pilotFlightExpense) {
    const pilotFullName =
      get(this.props.contactsDataSelector, [
        get(pilotFlightExpense, 'pilot_id'),
        'fullName',
      ]) || 'Unknown';
    const {
      notes,
      total,
      includes_gst: includesGst,
      onchargeable,
      reimbursable,
    } = pilotFlightExpense;

    const result = [notes, total];
    result.push(includesGst ? 'incl GST' : 'excl GST');
    result.push(onchargeable ? 'oncharge: yes' : 'oncharge: no');
    result.push(reimbursable ? 'reimburse: yes' : 'reimburse: no');
    return (
      <span>
        {`${pilotFullName} - ${result[0]} - ${result[1]}`}
        <br />
        {`${result[2]} - ${result[3]} - ${result[4]}`}
      </span>
    );
  }

  _renderPilotFlightExpenses() {
    if (get(this.props, 'formValues.pilot_flight_expenses_attributes')) {
      return (
        <dd>
          {get(this.props, 'formValues.pilot_flight_expenses_attributes').map(
            (pfe, index) => {
              if (!pfe._destroy && pfe.pilot_id) {
                return (
                  <p key={`afrpfe-${index}`}>{this._renderPilotFlightExpense(pfe)}</p>
                );
              }
            }
          )}
        </dd>
      );
    } else {
      return <dd />;
    }
  }

  _renderFuelBowserFill(fuelBowserFill) {
    const fuelBowserName = get(this.props.fuelBowsersDataSelector, [
      get(fuelBowserFill, 'fuel_bowser_id'),
      'name',
    ]);
    const value = get(fuelBowserFill, 'quantity_value');
    const quantityUnit = this.props.currentSettingsFuelBowserFillDefaultQuantityUnit;
    return (
      <span>
        {fuelBowserName}
        <br />
        {`${parseFloat(value).toFixed(2)} ${quantityUnit}`}
      </span>
    );
  }

  _renderFuelBowserFills() {
    if (get(this.props, 'formValues.fuel_bowser_fills_attributes')) {
      return (
        <dd>
          {get(this.props, 'formValues.fuel_bowser_fills_attributes').map(
            (fbf, index) => {
              if (!fbf._destroy && fbf.fuel_bowser_id) {
                return <p key={`afrfbf-${index}`}>{this._renderFuelBowserFill(fbf)}</p>;
              }
            }
          )}
        </dd>
      );
    } else {
      return <dd />;
    }
  }

  _renderFuelTankerFill(fuelTankerFill) {
    const fuelTankerName = get(this.props.fuelTankersDataSelector, [
      get(fuelTankerFill, 'fuel_tanker_id'),
      'name',
    ]);
    const value = get(fuelTankerFill, 'quantity_value');
    const quantityUnit = this.props.currentSettingsFuelBowserFillDefaultQuantityUnit;
    return (
      <span>
        {fuelTankerName}
        <br />
        {`${parseFloat(value).toFixed(2)} ${quantityUnit}`}
      </span>
    );
  }

  _renderFuelTankerFills() {
    if (get(this.props, 'formValues.fuel_tanker_fills_attributes')) {
      return (
        <dd>
          {get(this.props, 'formValues.fuel_tanker_fills_attributes').map(
            (ftf, index) => {
              if (!ftf._destroy && ftf.fuel_tanker_id) {
                return <p key={`afrftf-${index}`}>{this._renderFuelTankerFill(ftf)}</p>;
              }
            }
          )}
        </dd>
      );
    } else {
      return <dd />;
    }
  }

  _renderLanding(flightSegment) {
    const endLocationName =
      get(this.props.locationsDataSelector, [
        get(flightSegment, 'end_location_id'),
        'longName',
      ]) || 'Unknown';
    const result = [];
    if (endLocationName) {
      result.push(endLocationName);
      result.push(get(flightSegment, 'end_location_landing_fee', 0));
      result.push(
        get(flightSegment, 'oncharge_end_location_landing_fee')
          ? 'charged'
          : 'not charged'
      );
    } else {
      result.push('Select an end location...');
    }
    return result.join(' - ');
  }

  _renderLandings() {
    if (get(this.props, 'formValues.flight_segments_attributes')) {
      return (
        <dd>
          {this.props.formValues.flight_segments_attributes.map((fs, index) => {
            if (!fs._destroy) {
              return <p key={`afrl-${index}`}>{this._renderLanding(fs)}</p>;
            }
          })}
        </dd>
      );
    } else {
      return <dd />;
    }
  }

  _renderHobbs() {
    if (get(this.props, 'formValues.hobb_record_attributes')) {
      const {
        start_hobb: start,
        end_hobb: end,
        flight_time: time,
      } = get(this.props, 'formValues.hobb_record_attributes');
      return (
        <dd>
          <p>{`Start Hobb : ${start}`}</p>
          <p>{`Flight Time: ${time}`}</p>
          <p>{`End Hobb   : ${end}`}</p>
        </dd>
      );
    } else {
      return <dd />;
    }
  }

  _renderAdminFlightRecord() {
    const {
      flight_time: { input: flightTimeInput, meta: flightTimeMeta },
      flight_summary: { input: flightSummaryInput, meta: flightSummaryMeta },
    } = get(this.props, 'admin_flight_record_attributes');

    return (
      <Col xs={12} style={{ marginTop: '5px' }}>
        <FormGroup
          bsSize="sm"
          controlId={flightTimeInput.name}
          validationState={validationState(flightTimeMeta)}
        >
          <Col componentClass={ControlLabel} sm={3}>
            Flight Time
          </Col>
          <Col sm={9}>
            <BlurOnlyFormControl type="text" {...flightTimeInput} />
            <HelpBlock>{validationText(flightTimeMeta)}</HelpBlock>
          </Col>
        </FormGroup>
        <FormGroup
          bsSize="sm"
          controlId={flightSummaryInput.name}
          validationState={validationState(flightSummaryMeta)}
        >
          <Col componentClass={ControlLabel} sm={3}>
            Flight Summary
          </Col>
          <Col sm={9}>
            <BlurOnlyFormControl
              componentClass="textarea"
              rows={2}
              {...flightSummaryInput}
            />
            <HelpBlock>{validationText(flightSummaryMeta)}</HelpBlock>
          </Col>
        </FormGroup>
      </Col>
    );
  }

  render() {
    const { hobb_record_attributes } = this.props;

    return (
      <Row style={{ marginTop: '20px' }}>
        <Col sm={6}>
          <Row className="form-horizontal">{this._renderAdminFlightRecord()}</Row>
          <Row>
            <Col xs={12}>
              <Panel>
                <Panel.Heading>Details</Panel.Heading>
                <Panel.Body>
                  <dl className="dl-horizontal">
                    <dt>Landings</dt>
                    {this._renderLandings()}
                  </dl>
                  <dl className="dl-horizontal">
                    <dt>Hobbs</dt>
                    {this._renderHobbs()}
                  </dl>
                  <dl className="dl-horizontal">
                    <dt>Fuel Bowser Fills</dt>
                    {this._renderFuelBowserFills()}
                  </dl>
                  <dl className="dl-horizontal">
                    <dt>Fuel Tanker Fills</dt>
                    {this._renderFuelTankerFills()}
                  </dl>
                  <dl className="dl-horizontal">
                    <dt>Expenses</dt>
                    {this._renderPilotFlightExpenses()}
                  </dl>
                </Panel.Body>
              </Panel>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default BookingAdminFlightRecordFields;
