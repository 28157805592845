import { Col } from 'react-bootstrap';
import { Component } from 'react';

import { Fields } from 'redux-form';

import pick from 'lodash/pick';

import SeatAssignmentPassengerAndWeightInputFields from './seat_assignment_passenger_and_weight_input_fields';

class SeatAssignment extends Component {
  render() {
    const { index, flightSegmentIndex, field, isXs, width } = this.props;

    return (
      <Col xs={6} sm={2} {...(width && { style: { width } })}>
        <Fields
          index={index}
          flightSegmentIndex={flightSegmentIndex}
          field={field}
          names={[`${field}.pax_name`, `${field}.weight`]}
          component={SeatAssignmentPassengerAndWeightInputFields}
          addingPassenger={this.props.addingPassenger}
          typeaheadFetching={this.props.typeaheadFetching}
          passengersTypeaheadCollection={this.props.passengersTypeaheadCollection}
          handlePassengerSearch={this.props.handlePassengerSearch}
          handleAddPassengerModalClicked={this.props.handleAddPassengerModalClicked}
          handlePassengerChanged={this.props.handlePassengerChanged}
          isXs={isXs}
          {...pick(this.props.formValues, ['passenger_id', 'weight', 'pax_name'])}
        />
      </Col>
    );
  }
}

export default SeatAssignment;
