import { Col, FormGroup, FormControl, ControlLabel, HelpBlock } from 'react-bootstrap';
import { Component } from 'react';

import first from 'lodash/first';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import { validationState, validationText } from '../form/helpers';

class FlightTypeInputField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectOptions: [],
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.aircraft_id) {
      this.setState({
        selectOptions: this._getSelectOptions(),
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { selectOptions } = this.state;
    if (this.props.aircraft_id !== nextProps.aircraft_id) {
      if (nextProps.aircraft_id) {
        selectOptions = this._getSelectOptions(nextProps);
      } else {
        selectOptions = [];
      }
      const firstNextChargeableId = get(
        first(nextProps.booking_chargeables_attributes.filter((bca) => !bca._destroy)),
        'chargeable_id',
        ''
      );
      this._setDefaultFlightTypeId(firstNextChargeableId, selectOptions);
    }
    if (
      !isEqual(
        this.props.booking_chargeables_attributes,
        nextProps.booking_chargeables_attributes
      )
    ) {
      let firstPrevChargeableId = '';
      if (
        this.props.booking_chargeables_attributes &&
        this.props.booking_chargeables_attributes.length > 0
      ) {
        firstPrevChargeableId = get(
          first(this.props.booking_chargeables_attributes.filter((bca) => !bca._destroy)),
          'chargeable_id',
          ''
        );
      }
      const firstNextChargeableId = get(
        first(nextProps.booking_chargeables_attributes.filter((bca) => !bca._destroy)),
        'chargeable_id',
        ''
      );
      if (firstPrevChargeableId !== firstNextChargeableId) {
        this._setDefaultFlightTypeId(firstNextChargeableId, selectOptions);
      }
    }
    this.setState({
      selectOptions,
    });
  }

  _getSelectOptions(props = this.props) {
    const aircraft = props.aircrafts.find(
      (a) => a.id === parseInt(props.aircraft_id, 10)
    );
    const flightTypeIds = aircraft.flightTypes.map((ft) => ft.id);
    return props.flightTypes
      .filter((ft) => includes(flightTypeIds, ft.id))
      .map((ft) => ({ id: ft.id, name: ft.name }));
  }

  _setDefaultFlightTypeId(firstNextChargeableId, selectOptions) {
    if (firstNextChargeableId) {
      const chargeable = this.props.chargeables.find(
        (c) => c.id === firstNextChargeableId
      );
      const defaultFlightTypeId = get(chargeable, 'default_flight_type_id', '');
      if (
        includes(
          selectOptions.map((ft) => ft.id),
          defaultFlightTypeId
        )
      ) {
        this.props.input.onChange(defaultFlightTypeId);
      } else {
        this.props.input.onChange('');
      }
    } else {
      this.props.input.onChange('');
    }
  }

  _renderFormControl() {
    const {
      noTab,
      placeholder,
      type,
      input,
      inputStyle,
      componentClass,
      defaultSelectOption,
      rows,
    } = this.props;

    const commonProps = {
      type,
      value: input.value,
      ...(placeholder && { placeholder }),
      ...(componentClass && { componentClass }),
      ...(rows && { rows }),
      ...(noTab && { tabIndex: -1 }),
      ...(inputStyle && { style: inputStyle }),
    };

    return (
      <FormControl {...commonProps} onChange={input.onChange}>
        {defaultSelectOption ? (
          <option key={0} value="">
            {}
          </option>
        ) : (
          ''
        )}
        {this.state.selectOptions.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </FormControl>
    );
  }

  render() {
    const {
      input,
      meta,
      bsSize,
      groupStyle,
      labelWidth,
      inputWidth,
      helpText,
      helpBlock,
    } = this.props;

    return (
      <FormGroup
        controlId={input.name}
        validationState={validationState(meta)}
        {...(bsSize && { bsSize })}
        {...(groupStyle && { style: groupStyle })}
      >
        {this.props.labelWidth > 0 && (
          <Col componentClass={ControlLabel} sm={labelWidth}>
            {this.props.children}
          </Col>
        )}
        <Col sm={inputWidth}>
          {this._renderFormControl()}
          {helpBlock && <HelpBlock>{validationText(meta, helpText)}</HelpBlock>}
        </Col>
      </FormGroup>
    );
  }
}

FlightTypeInputField.defaultProps = {
  bsSize: 'sm',
  labelWidth: 4,
  inputWidth: 8,
  defaultSelectOption: true,
  helpText: '',
  helpBlock: true,
  noTab: true,
};

export default FlightTypeInputField;
