import {
  Row,
  Col,
  Table,
  Button,
  ButtonToolbar,
  ButtonGroup,
  FormControl,
} from 'react-bootstrap';
import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { graphql } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { LinkContainer } from 'react-router-bootstrap';

import debounce from 'lodash/debounce';
import last from 'lodash/last';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import { filterDo } from '../actions/filter_actions';

import Confirm from '../components/confirm';
import Loader from '../components/loader';

import { queriesReady } from '../lib/utils';

import fuelTankerListQuery from '../queries/fuel_tanker_list_query';
import fuelTankerDeleteMutation from '../mutations/fuel_tanker_delete_mutation';

moment.updateLocale('en-nz');

class FuelTankerList extends Component {
  constructor(props) {
    super(props);
    this._handleFilter = this._handleFilter.bind(this);
    this._handleDeleteClick = this._handleDeleteClick.bind(this);
  }

  componentDidMount() {
    this.delayedHandleFilter = debounce((e) => {
      if (e.target.value.length > 1 || e.target.value === '') {
        this.props.filterDo({ type: 'fuel_tanker', q: e.target.value });
      }
    }, 400);
    if (this.props.filter_q) {
      this.delayedHandleFilter({ target: { value: this.props.filter_q } });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.filter_q && this.isLoading(this.props) && this.isLoaded(nextProps)) {
      this.delayedHandleFilter({ target: { value: nextProps.filter_q } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleFilter.cancel();
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(props.fuelTankerListQuery);
  }

  _handleDeleteClick(e) {
    this.props.mutationSet(true);
    const fuelTankerId = last(e.currentTarget.id.split('-'));
    this.props
      .fuelTankerDeleteMutation({
        variables: {
          id: fuelTankerId,
        },
      })
      .then((res) => {
        this.props.mutationSuccess('Fuel Tanker delete');
      })
      .catch((err) => {
        this.props.mutationFailure(err);
      });
  }

  _handleFilter(e) {
    e.persist();
    this.delayedHandleFilter(e);
  }

  _renderOverlay() {
    if (this.props.filtering || this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
  }

  _renderData() {
    if (this.isLoaded()) {
      const filtered = this.props.filter_q;
      return (
        <div>
          <Row>
            <Col sm={12}>
              <div className="clearfix">
                <div className="pull-left">
                  <h3>Fuel Tankers</h3>
                </div>
                <div className="pull-right">
                  <FormControl
                    style={{
                      display: 'inline-block',
                      verticalAlign: 'middle',
                      width: '200px',
                      marginRight: '10px',
                    }}
                    type="text"
                    placeholder="filter..."
                    defaultValue={this.props.filter_q}
                    onChange={this._handleFilter}
                  />
                  <div className="pull-right">
                    <ButtonToolbar>
                      <ButtonGroup>
                        <LinkContainer to="/fuel_tankers/new">
                          <Button bsStyle="primary">Add a Fuel Tanker</Button>
                        </LinkContainer>
                      </ButtonGroup>
                    </ButtonToolbar>
                  </div>
                  <span
                    className="badge"
                    style={
                      filtered
                        ? { marginRight: '10px' }
                        : { marginRight: '10px', opacity: '0.5' }
                    }
                  >
                    {filtered
                      ? this.props.filter_ids.length
                      : this.props.fuelTankerListQuery.data.length}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Table hover className="vertical-align">
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Current Location</th>
                <th>Actions</th>
                <th>Reports</th>
              </tr>
            </thead>
            <tbody>
              {this.props.fuelTankerListQuery.data
                .filter((model) =>
                  filtered ? this.props.filter_ids.includes(model.id) : model
                )
                .map((model) => {
                  const { id, name, current_location: currentLocation } = model;
                  return (
                    <tr key={id}>
                      <td>{id}</td>
                      <td>{name}</td>
                      <td>{currentLocation}</td>
                      <td>
                        <Confirm
                          confirmId={`fuel-tanker-delete-${id}`}
                          onConfirm={this._handleDeleteClick}
                          title="Delete Fuel Tanker"
                          body="Are you sure you want to delete this Fuel Tanker?"
                          confirmText="Confirm"
                        >
                          <Button bsStyle="link" bsSize="xsmall">
                            delete
                          </Button>
                        </Confirm>
                        <LinkContainer to={`/fuel_tankers/${id}/edit`}>
                          <Button bsStyle="link" bsSize="xsmall">
                            edit
                          </Button>
                        </LinkContainer>
                        <LinkContainer to={`/fuel_tankers/${id}`}>
                          <Button bsStyle="link" bsSize="xsmall">
                            show
                          </Button>
                        </LinkContainer>
                        <LinkContainer to={`/fuel_tankers/${id}/fuel_bowser_fill/new`}>
                          <Button bsStyle="link" bsSize="xsmall">
                            add tanker fill
                          </Button>
                        </LinkContainer>
                      </td>
                      <td>
                        <LinkContainer
                          to={`reports/fuel_bowser_fill_for_fuel_tanker/${moment().format(
                            'MM-YYYY'
                          )}/${id}`}
                        >
                          <Button bsStyle="link" bsSize="xsmall">
                            tanker fills
                          </Button>
                        </LinkContainer>
                        <LinkContainer
                          to={`reports/fuel_tanker_fill_for_aircraft/${moment().format(
                            'MM-YYYY'
                          )}/${id}`}
                        >
                          <Button bsStyle="link" bsSize="xsmall">
                            aircraft fills
                          </Button>
                        </LinkContainer>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        {this._renderOverlay()}
        {this._renderData()}
      </div>
    );
  }
}

FuelTankerList.propTypes = {
  filter_ids: PropTypes.array.isRequired,
  filtering: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const filter = state.filters.fuel_tanker;
  return {
    filter_ids: filter.ids,
    filter_q: filter.q,
    filtering: filter.filtering,
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default compose(
  graphql(fuelTankerDeleteMutation, {
    name: 'fuelTankerDeleteMutation',
  }),
  graphql(fuelTankerListQuery, {
    name: 'fuelTankerListQuery',
  }),
  connect(mapStateToProps, {
    mutationSuccess,
    mutationFailure,
    mutationSet,
    filterDo,
  })
)(FuelTankerList);
