import pick from 'lodash/pick';
import actionTypes from '../constants';
import Api from '../lib/api';
import { manageCatch, toastSuccessDev } from '../lib/action_helpers';

export function filterReset() {
  return {
    type: actionTypes.FILTER_RESET,
  };
}

export function filterStart(payload) {
  return {
    type: actionTypes.FILTER_START,
    payload,
  };
}

export function filterStop(payload) {
  return {
    type: actionTypes.FILTER_STOP,
    payload,
  };
}

export function filterSuccess(payload, meta) {
  return {
    type: actionTypes.FILTER_SUCCESS,
    payload,
    meta,
  };
}

export function filterFailure(payload) {
  return {
    type: actionTypes.FILTER_FAILURE,
    payload,
  };
}

export function filterClear(payload) {
  return {
    type: actionTypes.FILTER_CLEAR,
    payload,
  };
}

export function filterDo(
  args = {},
  success = filterSuccess,
  failure = filterFailure,
  clear = filterClear
) {
  return function filterDoDispatch(dispatch) {
    if (args.type) {
      if (args.q) {
        dispatch(filterStart(args));
        Api.get(
          `/api/search/xhr/${args.type}/${args.q}`,
          pick(args, ['start_at', 'end_at', 'display_name', 'exclude_responsible'])
        )
          .then((res) => {
            dispatch(success(res.data, args));
            toastSuccessDev(`${args.type} filtered`);
          })
          .catch((res) => {
            manageCatch(dispatch, res, failure, args);
          })
          .then(() => {
            dispatch(filterStop(args));
          });
      } else {
        dispatch(clear(args));
        toastSuccessDev(`${args.type} filter cleared`);
      }
    }
  };
}
