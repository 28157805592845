import get from 'lodash/get';
import memoize from 'lodash/memoize';

export const ContactFullNameMemo = memoize(
  (contactId, contacts) => {
    // NB can pass in any collection derived from the contactItemListQuery, pilots, providers etc.
    if (contactId) {
      const contact = contacts.find((c) => c.id === parseInt(contactId, 10));
      return get(contact, 'fullName', '');
    } else {
      return '';
    }
  },
  (contactId) => {
    if (contactId) {
      return [contactId].map((i) => String(i)).join('.');
    } else {
      return 'unknown';
    }
  }
);
