import { renderToStaticMarkup } from 'react-dom/server';
import moment from 'moment';

const getContrastYIQ = (color) => {
  const hexcolor = color.substr(1, 6);
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
};

const renderTimeGridWeek = (event) => {
  const {
    startStr,
    title,
    allDay,
    extendedProps: { contactColor, contactFullName, start, end },
  } = event;
  const contrastContactColor = getContrastYIQ(contactColor);
  return (
    <div
      className="fc-custom-event-noscrollbars"
      style={{
        color: contrastContactColor,
        backgroundColor: contactColor,
        border: '1px solid #3a87ad',
        height: '100%',
        overflow: 'scroll',
      }}
    >
      <div className="fc-event-time">
        {allDay
          ? `${moment(start).format('D/M HH:mm')} - ${moment(end).format('D/M HH:mm')}`
          : moment(startStr).format('HH:mm')}
      </div>
      <div className="fc-event-title">{`${title} - ${contactFullName}`}</div>
    </div>
  );
};

const renderDayGridMonth = (event) => {
  const {
    startStr,
    title,
    allDay,
    extendedProps: { contactColor, contactFullName, start, end },
  } = event;
  const contrastContactColor = getContrastYIQ(contactColor);
  return (
    <div
      className="fc-custom-event-noscrollbars"
      style={{
        color: contrastContactColor,
        backgroundColor: contactColor,
        border: '1px solid #3a87ad',
        width: '100%',
        overflow: 'scroll',
      }}
    >
      <span className="fc-event-time">
        {allDay
          ? `${moment(start).format('D/M HH:mm')} - ${moment(end).format('D/M HH:mm')}`
          : moment(startStr).format('HH:mm')}
      </span>
      <span>&nbsp;</span>
      <span className="fc-event-title">{`${title} - ${contactFullName}`}</span>
    </div>
  );
};

const renderTiaTogProfile = (tiaTogProfile) => {
  let accum = 0;
  return tiaTogProfile.map((percent, index) => {
    const thisAccum = accum;
    accum = Math.round((accum + percent) * 100) / 100;
    if (percent > 0) {
      return (
        <div
          key={`tia-tog-${thisAccum}`}
          className={`fc-bg-event ${index % 2 === 0 ? 'tog' : 'tia'}`}
          style={{
            left: `${thisAccum}%`,
            width: `${percent}%`,
          }}
        />
      );
    }
    return undefined;
  });
};

const renderResourceTimelineDayFlight = (event) => {
  const {
    title,
    extendedProps: { tiaTogProfile },
  } = event;
  return (
    <>
      {tiaTogProfile && tiaTogProfile.length > 0 ? (
        renderTiaTogProfile(tiaTogProfile)
      ) : (
        <div className="fc-bg-event" />
      )}
      <div className="fc-event-title-container">
        <div className="fc-event-title fc-sticky profiled-title">{title}</div>
      </div>
    </>
  );
};

const renderResourceTimelineDay = (event) => {
  if (event.extendedProps.flightSegmentSummaries) {
    return renderResourceTimelineDayFlight(event);
  }
  return undefined;
};

const renderEventContent = (info) => {
  const {
    event,
    event: {
      extendedProps: { eventType },
    },
    view: { type: viewType },
  } = info;
  if (eventType === 'Solar') {
    return undefined;
  }
  if (viewType === 'resourceTimelineDay') {
    const renderedEvent = renderResourceTimelineDay(event);
    if (renderedEvent) {
      return { html: renderToStaticMarkup(renderedEvent) };
    }
    return undefined;
  }
  if (viewType === 'dayGridMonth') {
    const renderedEvent = renderDayGridMonth(event);
    if (renderedEvent) {
      return { html: renderToStaticMarkup(renderedEvent) };
    }
    return undefined;
  }
  if (viewType === 'timeGridWeek') {
    const renderedEvent = renderTimeGridWeek(event);
    if (renderedEvent) {
      return { html: renderToStaticMarkup(renderedEvent) };
    }
    return undefined;
  }
  return undefined;
};

export default renderEventContent;
