import {
  Row,
  Col,
  Panel,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Thumbnail,
  Table,
} from 'react-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import moment from 'moment';
import { LinkContainer } from 'react-router-bootstrap';

import last from 'lodash/last';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import Confirm from '../components/confirm';
import Loader from '../components/loader';

import contactDeleteMutation from '../mutations/contact_delete_mutation';

import {
  queriesReady,
  getExport,
  getTaggingsNameArray,
  getPhoneNumberToString,
  getAddressToString,
} from '../lib/utils';

import contactShowQuery from '../queries/contact_show_query';

moment.updateLocale('en-nz');

class ContactShow extends Component {
  constructor(props) {
    super(props);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
  }

  displayBoolean = (attribute) => {
    if (attribute) {
      return 'Yes';
    } else {
      return 'No';
    }
  };

  getExport = (e) => {
    this.props.mutationSet(true);
    const reportName = e.target.getAttribute('data-report-name');
    const exportType = e.target.getAttribute('data-export-type');
    const displayName = e.target.getAttribute('data-display-name');
    getExport(reportName, {}, null, exportType, displayName)
      .then(() => {
        this.props.mutationSet(false);
      })
      .catch((err) => this.props.mutationFailure(err));
  };

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(testProps.contactQuery);
  }

  handleDeleteClick(e) {
    this.props.mutationSet(true);
    const contactId = last(e.currentTarget.id.split('-'));
    this.props
      .contactDeleteMutation({
        variables: {
          id: contactId,
        },
      })
      .then(() => {
        this.props.mutationSuccess('Contact delete');
        this.props.navigate('/contacts');
      })
      .catch((err) => this.props.mutationFailure(err));
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (this.isLoaded()) {
      const contact = this.props.contactQuery.data;
      const {
        addresses,
        contact_number: contactNumber,
        company,
        company_name: companyName,
        company_legal_name: companyLegalName,
        company_contact_name: companyContactName,
        dob,
        defaultPhoneNumber,
        defaultFlightType,
        defaultProvider,
        display_name: displayName,
        documents,
        email,
        id,
        employer,
        first_name: firstName,
        flight_notes: flightNotes,
        fullName,
        last_name: lastName,
        other_notes: otherNotes,
        phoneNumbers,
        photoMediumUrl: { url: photoMediumUrlUrl },
        roles,
        roleEmployee,
        roleChargeable,
        salutation,
        statutoryRoles,
        taggings,
        weight,
      } = contact;

      return (
        <div>
          <Row>
            <Col sm={12}>
              <div className="clearfix">
                <div className="pull-left">
                  <h3>{`Contact Details for ${fullName}`}</h3>
                </div>
                <div className="pull-right">
                  <ButtonToolbar>
                    <ButtonGroup>
                      <Confirm
                        confirmId={`contact-delete-${id}`}
                        onConfirm={this.handleDeleteClick}
                        title="Delete Contact"
                        body="Are you sure you want to delete this Contact?"
                        confirmText="Confirm"
                      >
                        <Button bsStyle="primary">Delete</Button>
                      </Confirm>
                    </ButtonGroup>
                    <ButtonGroup>
                      <LinkContainer to={`/contacts/${id}/edit`}>
                        <Button bsStyle="primary">Edit</Button>
                      </LinkContainer>
                      <LinkContainer to="/contacts">
                        <Button bsStyle="primary">All Contacts</Button>
                      </LinkContainer>
                    </ButtonGroup>
                  </ButtonToolbar>
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col sm={6}>
              <Row>
                <Col xs={12}>
                  <Panel>
                    <Panel.Heading>Company/Individual</Panel.Heading>
                    <Panel.Body>
                      <dl className="dl-horizontal">
                        <dt>Contact Class</dt>
                        <dd>{company ? 'Company' : 'Individual'}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Tags</dt>
                        <dd>
                          {taggings &&
                            getTaggingsNameArray(taggings).map((name) => (
                              <span key={name}>
                                {name}
                                <br />
                              </span>
                            ))}
                        </dd>
                      </dl>
                      {roleEmployee && (
                        <dl className="dl-horizontal">
                          <dt>Employer</dt>
                          <dd>{employer && employer.fullName}</dd>
                        </dl>
                      )}
                      {roleChargeable && (
                        <dl className="dl-horizontal">
                          <dt>Default Provider</dt>
                          <dd>{defaultProvider && defaultProvider.fullName}</dd>
                        </dl>
                      )}
                      {roleChargeable && (
                        <dl className="dl-horizontal">
                          <dt>Default Flight Type</dt>
                          <dd>{defaultFlightType && defaultFlightType.name}</dd>
                        </dl>
                      )}
                    </Panel.Body>
                  </Panel>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Panel>
                    <Panel.Heading>Role Details</Panel.Heading>
                    <Panel.Body>
                      <dl className="dl-horizontal">
                        <dt>Roles</dt>
                        <dd>
                          {roles &&
                            roles.map((role) => (
                              <span key={role.id}>
                                {role.name}
                                <br />
                              </span>
                            ))}
                        </dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Statutory Roles</dt>
                        <dd>
                          {statutoryRoles &&
                            statutoryRoles.map((sr) => (
                              <span key={sr.id}>
                                {sr.name}
                                <br />
                              </span>
                            ))}
                        </dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Permissions</dt>
                        <dd>
                          {[
                            'Passworded',
                            'Employee',
                            'OfficeAdmin',
                            'Manager',
                            'Pilot',
                            'Passenger',
                            'Provider',
                            'Chargeable',
                          ].map((name) => (
                            <span key={name}>
                              {`${name}: ${this.displayBoolean(contact[`role${name}`])}`}
                              <br />
                            </span>
                          ))}
                        </dd>
                      </dl>
                    </Panel.Body>
                  </Panel>
                </Col>
              </Row>
              {company && (
                <Row>
                  <Col xs={12}>
                    <Panel>
                      <Panel.Heading>Company Details</Panel.Heading>
                      <Panel.Body>
                        <dl className="dl-horizontal">
                          <dt>Company Name</dt>
                          <dd>{companyName}</dd>
                        </dl>
                        <dl className="dl-horizontal">
                          <dt>Company Legal Name</dt>
                          <dd>{companyLegalName}</dd>
                        </dl>
                        <dl className="dl-horizontal">
                          <dt>Company Contact Name</dt>
                          <dd>{companyContactName}</dd>
                        </dl>
                      </Panel.Body>
                    </Panel>
                  </Col>
                </Row>
              )}
              {company || (
                <Row>
                  <Col xs={12}>
                    <Panel>
                      <Panel.Heading>Individual Details</Panel.Heading>
                      <Panel.Body>
                        <dl className="dl-horizontal">
                          <dt>Salutation</dt>
                          <dd>{salutation}</dd>
                        </dl>
                        <dl className="dl-horizontal">
                          <dt>First Name</dt>
                          <dd>{firstName}</dd>
                        </dl>
                        <dl className="dl-horizontal">
                          <dt>Last Name</dt>
                          <dd>{lastName}</dd>
                        </dl>
                        <dl className="dl-horizontal">
                          <dt>Display Name</dt>
                          <dd>{displayName}</dd>
                        </dl>
                        <dl className="dl-horizontal">
                          <dt>Weight</dt>
                          <dd>{weight}</dd>
                        </dl>
                        <dl className="dl-horizontal">
                          <dt>DOB</dt>
                          <dd>{dob}</dd>
                        </dl>
                      </Panel.Body>
                    </Panel>
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs={12}>
                  <Panel>
                    <Panel.Heading>Basic Contact Details</Panel.Heading>
                    <Panel.Body>
                      <dl className="dl-horizontal">
                        <dt>Contact Number (DEPRECATED)</dt>
                        <dd>{contactNumber}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Default Phone Number</dt>
                        <dd>
                          {defaultPhoneNumber &&
                            getPhoneNumberToString(defaultPhoneNumber)}
                        </dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Email</dt>
                        <dd>{email}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Flight Notes</dt>
                        <dd>{flightNotes}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Other Notes</dt>
                        <dd>{otherNotes}</dd>
                      </dl>
                    </Panel.Body>
                  </Panel>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Panel>
                    <Panel.Heading>Phone Details</Panel.Heading>
                    <Panel.Body>
                      <dl className="dl-horizontal">
                        <dt>Phone Details</dt>
                        <dd>
                          {phoneNumbers &&
                            phoneNumbers.map((phoneNumber) => (
                              <span key={phoneNumber.id}>
                                {getPhoneNumberToString(phoneNumber)}
                                <br />
                              </span>
                            ))}
                        </dd>
                      </dl>
                    </Panel.Body>
                  </Panel>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Panel>
                    <Panel.Heading>Address Details</Panel.Heading>
                    <Panel.Body>
                      <dl className="dl-horizontal">
                        <dt>Address Details</dt>
                        <dd>
                          {addresses &&
                            addresses.map((address) => (
                              <span key={address.id}>
                                {getAddressToString(address)}
                                <br />
                              </span>
                            ))}
                        </dd>
                      </dl>
                    </Panel.Body>
                  </Panel>
                </Col>
              </Row>
            </Col>
            <Col sm={6}>
              <Row>
                <Col xs={12}>
                  <Panel>
                    <Panel.Heading>Photo</Panel.Heading>
                    <Panel.Body>
                      <Thumbnail
                        src={
                          photoMediumUrlUrl ||
                          'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
                        }
                      />
                    </Panel.Body>
                  </Panel>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Panel header="Documents">
                    <Panel.Heading>Documents</Panel.Heading>
                    <Panel.Body>
                      <Table hover className="vertical-align">
                        <thead>
                          <tr>
                            <th>Document</th>
                            <th>Uploader</th>
                            <th>Date</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {documents &&
                            documents.map((document) => {
                              const {
                                id: documentId,
                                document_file_name: documentFileName,
                                uploaded_at: uploadedAt,
                                uploader,
                                description,
                              } = document;
                              const documentFileExtension = documentFileName
                                ? documentFileName.split('.').pop()
                                : 'unknown';
                              return (
                                <tr key={id}>
                                  <td>{description}</td>
                                  <td>{uploader ? uploader.display_name : '-'}</td>
                                  <td>
                                    {uploadedAt ? moment(uploadedAt).format('lll') : '-'}
                                  </td>
                                  <td>
                                    <Button
                                      bsStyle="link"
                                      bsSize="xsmall"
                                      data-report-name={`documents/${documentId}`}
                                      data-display-name={documentFileName}
                                      data-export-type={documentFileExtension}
                                      onClick={this.getExport}
                                    >
                                      {documentFileExtension === "pdf" ? "view" : "download"}
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </Panel.Body>
                  </Panel>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      );
    }
    return undefined;
  }

  render() {
    return (
      <div>
        {this.renderOverlay()}
        {this.renderData()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default compose(
  graphql(contactDeleteMutation, {
    name: 'contactDeleteMutation',
  }),
  graphql(contactShowQuery, {
    name: 'contactQuery',
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  connect(mapStateToProps, { mutationSuccess, mutationFailure, mutationSet })
)(ContactShow);
