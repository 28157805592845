/* eslint-disable no-bitwise  */
import {
  Row,
  Col,
  Panel,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Carousel,
} from 'react-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import moment from 'moment';
import { LinkContainer } from 'react-router-bootstrap';
import L from 'leaflet';

import _get from 'lodash/get';
import _last from 'lodash/last';
import _merge from 'lodash/merge';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import Confirm from '../components/confirm';
import Loader from '../components/loader';

import locationDeleteMutation from '../mutations/location_delete_mutation';

import { queriesReady, queryJustReady, getExport } from '../lib/utils';

import locationShowQuery from '../queries/location_show_query';

const MAPBOX_API_TOKEN =
  'pk.eyJ1IjoiZ29yZG9ua2luZzAyIiwiYSI6ImNpbnUxbWJ3NjExZ2x1aWtqaThraGM3dmsifQ.rKb2iQMCm5ZPhJGfsSg_mg';

const baseLayerTemplates = [
  {
    name: 'Mapbox - Satellite Streets',
    maptype: 'mapbox/satellite-streets-v11',
    url: `https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${MAPBOX_API_TOKEN}`,
    attribution:
      '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  },
  {
    name: 'Mapbox - Street',
    maptype: 'mapbox/streets-v11',
    url: `https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${MAPBOX_API_TOKEN}`,
    attribution:
      '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  },
  {
    name: 'Mapbox - Satellite',
    maptype: 'mapbox/satellite-v9',
    url: `https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${MAPBOX_API_TOKEN}`,
    attribution:
      '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  },
];

moment.updateLocale('en-nz');

class LocationShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: [0, 0],
      zoom: 15,
      label: 'loading',
    };
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.mountMap = this.mountMap.bind(this);

    this.map = null;
    this.marker = null;
    this.baseLayers = null;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (queryJustReady(this.props.locationQuery, nextProps.locationQuery)) {
      if (
        _get(nextProps, 'locationQuery.data.latitude') &&
        _get(nextProps, 'locationQuery.data.longitude')
      ) {
        this.setState({
          position: [
            _get(nextProps, 'locationQuery.data.latitude'),
            _get(nextProps, 'locationQuery.data.longitude'),
          ],
          label: _get(nextProps, 'locationQuery.data.long_name'),
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.map &&
      _get(prevProps, 'locationQuery.data.latitude') !==
        _get(this.props, 'locationQuery.data.latitude')
    ) {
      this.map.setView(this.state.position, this.state.zoom);
      this.marker.setLatLng(this.state.position).setPopupContent(_get(this.state.label));
    }
  }

  createBaseLayer = (baseLayer) => {
    const { maptype, url, attribution } = baseLayer;
    const element = L.tileLayer(url, {
      attribution,
      id: maptype,
      accessToken: MAPBOX_API_TOKEN,
      tileSize: 512,
      zoomOffset: -1,
    });
    return Object.assign({}, baseLayer, { element });
  };

  getExport = (e) => {
    this.props.mutationSet(true);
    const reportName = e.target.getAttribute('data-report-name');
    const exportType = e.target.getAttribute('data-export-type');
    getExport(reportName, {}, null, exportType)
      .then(() => {
        this.props.mutationSet(false);
      })
      .catch((err) => this.props.mutationFailure(err));
  };

  convertDdDmsCoordinate = (D) => {
    const degrees = 0 | D;
    // eslint-disable-next-line no-param-reassign
    const minutes = 0 | (((D < 0 ? (D = -D) : D) % 1) * 60);
    const seconds = 0 | (((D * 60) % 1) * 60);
    return [degrees, minutes, seconds].join(' ');
  };

  convertDdDmmCoordinate = (D) => {
    const degrees = 0 | D;
    // eslint-disable-next-line no-param-reassign
    const minutes = 0 | (((D < 0 ? (D = -D) : D) % 1) * 60);
    const seconds = 0 | (((D * 60) % 1) * 60);
    let decimalMinutes = minutes + seconds / 60;
    decimalMinutes = Math.round(decimalMinutes * 100000000000000) / 100000000000000;
    return [degrees, decimalMinutes].join(' ');
  };

  convertFromDd(coordinate, displayFormat) {
    switch (parseInt(displayFormat, 10)) {
      case 2: // decimal minutes -> dd
        return this.convertDdDmmCoordinate(coordinate);
      case 1: // degree minute seconds -> dd
        return this.convertDdDmsCoordinate(coordinate);
      default:
        return coordinate;
    }
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(testProps.locationQuery);
  }

  mountMap(ref) {
    if (ref) {
      this.map = L.map('location-show-map-new', {
        zoom: this.state.zoom,
        center: this.state.position,
      });
      this.baseLayers = baseLayerTemplates.map((baseLayer) =>
        this.createBaseLayer(baseLayer)
      );
      const baseLayersMenu = this.baseLayers.reduce(
        (result, layer) => _merge({}, result, { [layer.name]: layer.element }),
        {}
      );
      L.control.layers(baseLayersMenu, {}, { position: 'topright' }).addTo(this.map);
      baseLayersMenu[this.baseLayers[0].name].addTo(this.map);
      this.marker = L.marker(this.state.position)
        .addTo(this.map)
        .bindPopup(this.state.label)
        .openPopup();
    } else {
      this.map.remove();
      this.map = null;
      this.marker = null;
      this.baseLayers = null;
    }
  }

  handleDeleteClick(e) {
    this.props.mutationSet(true);
    const locationId = _last(e.currentTarget.id.split('-'));
    this.props
      .locationDeleteMutation({
        variables: {
          id: locationId,
        },
      })
      .then(() => {
        this.props.mutationSuccess('Location delete');
        this.props.navigate('/locations');
      })
      .catch((err) => this.props.mutationFailure(err));
  }

  renderBoolean = (attribute) => {
    if (attribute) {
      return 'Yes';
    } else {
      return 'No';
    }
  };

  renderDisplayFormat() {
    const displayFormatId = _get(
      this.props,
      'locationQuery.display_format',
      this.props.currentSettingsLocationDefaultDisplayFormatId
    );
    return _get(
      this.props.currentSettingsLocationDisplayFormats.find(
        (df) => df.id === displayFormatId
      ),
      'name'
    );
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (this.isLoaded()) {
      const location = this.props.locationQuery.data;
      const {
        approach_depart_procedures: approachDepartProcedures,
        airways_fee: airwaysFee,
        display_format: displayFormat,
        fuel_notes: fuelNotes,
        gps_waypoint_name: gpsWaypointName,
        hazards,
        landing_fee: landingFee,
        long_name: longName,
        id,
        latitude,
        longitude,
        pal_frequency: palFrequency,
        named_pilots_only: namedPilotsOnly,
        notes,
        sensitive_location: sensitiveLocation,
        sensitive_nature: sensitiveNature,
        short_name: shortName,
        shorthand_name: shorthandName,
        fuelBowsers,
        fullName,
        locationAvailability,
        locationImageLargeUrls,
        locationLighting,
        locationServices,
        locationStatus,
        locationSurface,
        locationType,
        manager,
        namedPilots,
      } = location;

      return (
        <div>
          <Row>
            <Col sm={12}>
              <div className="clearfix">
                <div className="pull-left">
                  <h3>{`Location Details for ${fullName}`}</h3>
                </div>
                <div className="pull-right">
                  <ButtonToolbar>
                    <ButtonGroup>
                      <Confirm
                        confirmId={`location-delete-${id}`}
                        onConfirm={this.handleDeleteClick}
                        title="Delete Location"
                        body="Are you sure you want to delete this Location?"
                        confirmText="Confirm"
                      >
                        <Button bsStyle="danger">Delete</Button>
                      </Confirm>
                    </ButtonGroup>
                    <ButtonGroup>
                      <LinkContainer to={`/locations/${id}/edit`}>
                        <Button bsStyle="primary">Edit</Button>
                      </LinkContainer>
                      <Button
                        bsStyle="primary"
                        data-report-name={`locations/${id}`}
                        data-export-type="html"
                        onClick={this.getExport}
                      >
                        Print
                      </Button>
                      <Button
                        bsStyle="primary"
                        data-report-name={`locations/${id}`}
                        data-export-type="pdf"
                        onClick={this.getExport}
                      >
                        PDF
                      </Button>
                      <Button
                        bsStyle="primary"
                        data-report-name={`locations/${id}`}
                        data-export-type="kml"
                        onClick={this.getExport}
                      >
                        KML
                      </Button>
                    </ButtonGroup>
                    <ButtonGroup>
                      <LinkContainer to="/locations">
                        <Button bsStyle="primary">All Locations</Button>
                      </LinkContainer>
                    </ButtonGroup>
                  </ButtonToolbar>
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col sm={6}>
              <Row>
                <Col xs={12}>
                  <Panel>
                    <Panel.Heading>General Details</Panel.Heading>
                    <Panel.Body>
                      <dl className="dl-horizontal">
                        <dt>Long Name</dt>
                        <dd>{longName}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Short Name</dt>
                        <dd>{shortName}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Shorthand Name</dt>
                        <dd>{shorthandName}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>GPS Waypoint Name</dt>
                        <dd>{gpsWaypointName}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Responsible Person Name</dt>
                        <dd>{manager && manager.fullName}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Location Status</dt>
                        <dd>{locationStatus && locationStatus.name}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Location Type</dt>
                        <dd>{locationType && locationType.name}</dd>
                      </dl>
                    </Panel.Body>
                  </Panel>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Panel>
                    <Panel.Heading>Mapping Details</Panel.Heading>
                    <Panel.Body>
                      <dl className="dl-horizontal">
                        <dt>Decimal Latitude</dt>
                        <dd>{latitude}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Decimal Longitude</dt>
                        <dd>{longitude}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Entered Display Format</dt>
                        <dd>{this.renderDisplayFormat()}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Entered Latitude</dt>
                        <dd>{this.convertFromDd(latitude, displayFormat)}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Entered Longitude</dt>
                        <dd>{this.convertFromDd(longitude, displayFormat)}</dd>
                      </dl>
                    </Panel.Body>
                  </Panel>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Panel>
                    <Panel.Heading>Landing Details</Panel.Heading>
                    <Panel.Body>
                      <dl className="dl-horizontal">
                        <dt>Location Availability</dt>
                        <dd>{locationAvailability && locationAvailability.name}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Location Surface</dt>
                        <dd>{locationSurface && locationSurface.name}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Location Lighting</dt>
                        <dd>{locationLighting && locationLighting.name}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>PAL Frequency</dt>
                        <dd>{palFrequency}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Location Services</dt>
                        <dd>{locationServices.map((ls) => ls.name).join(', ')}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Landing Fee</dt>
                        <dd>{landingFee}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Airways Fee</dt>
                        <dd>{airwaysFee}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Sensitive Location</dt>
                        <dd>{this.renderBoolean(sensitiveLocation)}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Sensitive Nature</dt>
                        <dd>{sensitiveNature}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Approach & Departure</dt>
                        <dd>{approachDepartProcedures}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Hazards</dt>
                        <dd>{hazards}</dd>
                      </dl>
                    </Panel.Body>
                  </Panel>
                </Col>
              </Row>
            </Col>
            <Col sm={6}>
              <Row>
                <Col xs={12}>
                  <Panel>
                    <Panel.Heading>Map</Panel.Heading>
                    <Panel.Body>
                      <div
                        id="location-show-map-new"
                        style={{ height: '400px' }}
                        ref={(input) => {
                          this.mountMap(input);
                        }}
                      />
                    </Panel.Body>
                  </Panel>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Panel>
                    <Panel.Heading>Other Details</Panel.Heading>
                    <Panel.Body>
                      <dl className="dl-horizontal">
                        <dt>Named Pilots Only</dt>
                        <dd>{this.renderBoolean(namedPilotsOnly)}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Named Pilot Names</dt>
                        <dd>{namedPilots.map((p) => p.fullName).join(', ')}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Notes</dt>
                        <dd>{notes}</dd>
                      </dl>
                    </Panel.Body>
                  </Panel>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Panel>
                    <Panel.Heading>Fuel Details</Panel.Heading>
                    <Panel.Body>
                      <dl className="dl-horizontal">
                        <dt>Fuel Notes</dt>
                        <dd>{fuelNotes}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Fuel Bowsers</dt>
                        <dd>{fuelBowsers.map((fb) => fb.name).join(', ')}</dd>
                      </dl>
                    </Panel.Body>
                  </Panel>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Panel>
                <Panel.Heading>Images</Panel.Heading>
                <Panel.Body>
                  {locationImageLargeUrls && locationImageLargeUrls.length > 0 ? (
                    <Carousel interval={0}>
                      {locationImageLargeUrls.map((image) => (
                        <Carousel.Item key={image.id}>
                          <img alt="" height={768} width={1024} src={image.url} />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  ) : (
                    <p>No images</p>
                  )}
                </Panel.Body>
              </Panel>
            </Col>
          </Row>
        </div>
      );
    }
    return undefined;
  }

  render() {
    return (
      <div>
        {this.renderOverlay()}
        {this.renderData()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsMutating: state.currentSettings.mutating,
    currentSettingsLocationDisplayFormats: state.currentSettings.location_display_formats,
    currentSettingsLocationDefaultDisplayFormatId:
      state.currentSettings.location_default_display_format_id,
  };
}

export default compose(
  graphql(locationDeleteMutation, {
    name: 'locationDeleteMutation',
  }),
  graphql(locationShowQuery, {
    name: 'locationQuery',
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  connect(mapStateToProps, { mutationSuccess, mutationFailure, mutationSet })
)(LocationShow);
