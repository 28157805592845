import {
  Row,
  Col,
  FormGroup,
  Button,
  Checkbox,
  ButtonToolbar,
  ButtonGroup,
} from 'react-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';

import { Field, FieldArray, reduxForm, getFormValues } from 'redux-form';

import pick from 'lodash/pick';

import FlightSignoffFormLandingCharges from './flight_signoff_form_landing_charges';
import FlightSignoffFormPilotFlightExpenses from './flight_signoff_form_pilot_flight_expenses';

import InputField from '../form/input_field';

moment.updateLocale('en-nz');

class FlightSignoffForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      completeAdmin: !!this.props.booking.admin_completed_at,
    };
    this._handleCompleteAdminChecked = this._handleCompleteAdminChecked.bind(this);
    this._renderLandingChargesFieldArray =
      this._renderLandingChargesFieldArray.bind(this);
    this._renderPilotFlightExpensesFieldArray =
      this._renderPilotFlightExpensesFieldArray.bind(this);
  }

  _handleCompleteAdminChecked(e) {
    const { checked } = e.target;
    this.setState({ completeAdmin: checked });
    if (checked) {
      this.props.change('admin_completed_at', moment().format());
      this.props.change('admin_completed_by_admin_id', this.props.currentContactId);
    } else {
      this.props.change('admin_completed_at', '');
      this.props.change('admin_completed_by_admin_id', '');
    }
  }

  _renderLandingChargesFieldArray(flightSegments) {
    const formKeys = ['flight_segments_attributes'];
    return (
      <FlightSignoffFormLandingCharges
        flightSegments={flightSegments}
        locationsDataSelector={this.props.locationsDataSelector}
        formValues={pick(this.props.formValues, formKeys)}
      />
    );
  }

  _renderPilotFlightExpensesFieldArray(pilotFlightExpenses) {
    const formKeys = ['pilot_flight_expenses_attributes'];
    return (
      <FlightSignoffFormPilotFlightExpenses
        pilotFlightExpenses={pilotFlightExpenses}
        formValues={pick(this.props.formValues, formKeys)}
        contactsDataSelector={this.props.contactsDataSelector}
        currentSettingsPilotFlightExpenseOvernightText={
          this.props.currentSettingsPilotFlightExpenseOvernightText
        }
      />
    );
  }

  render() {
    const {
      booking: { id },
      handleSubmit,
      pristine,
      reset,
      submitting,
    } = this.props;

    return (
      <Col xs={8}>
        <Row>
          <form onSubmit={handleSubmit} key={id} className="form-horizontal">
            <Field
              type="text"
              name="admin_flight_record_attributes.flight_time"
              component={InputField}
              blurOnly
              bsSize="sm"
              groupStyle={{ marginBottom: 0 }}
              inputStyle={{ fontWeight: 600 }}
            >
              Flight Time
            </Field>
            <Field
              type="text"
              name="admin_flight_record_attributes.flight_summary"
              blurOnly
              component={InputField}
              bsSize="sm"
              groupStyle={{ marginBottom: 0 }}
              componentClass="textarea"
              rows={4}
            >
              Flight Summary
            </Field>
            <FieldArray
              name="flight_segments_attributes"
              component={this._renderLandingChargesFieldArray}
            />
            <FieldArray
              name="pilot_flight_expenses_attributes"
              component={this._renderPilotFlightExpensesFieldArray}
            />
            <FormGroup bsSize="sm">
              <Col smOffset={3} sm={4}>
                <Checkbox
                  inline
                  checked={this.state.completeAdmin}
                  onChange={this._handleCompleteAdminChecked}
                >
                  Mark Admin Complete
                </Checkbox>
              </Col>
            </FormGroup>
            <FormGroup>
              <Col smOffset={3} sm={6}>
                <ButtonToolbar>
                  <ButtonGroup>
                    <Button
                      type="button"
                      bsStyle="danger"
                      disabled={pristine || submitting}
                      onClick={reset}
                    >
                      Reset
                    </Button>
                  </ButtonGroup>
                  <ButtonGroup>
                    <Button
                      type="submit"
                      bsStyle="primary"
                      disabled={pristine || submitting}
                    >
                      {submitting && (
                        <span className="glyphicon glyphicon-refresh glyphicon-spin" />
                      )}{' '}
                      Update
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </Col>
            </FormGroup>
          </form>
        </Row>
      </Col>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    formValues: getFormValues(props.form)(state),
  };
}

export default compose(connect(mapStateToProps, {}), reduxForm({}))(FlightSignoffForm);
