import { Modal, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { Component } from 'react';

class FlightSegmentLocationCriticalModal extends Component {
  constructor(props) {
    super(props);

    this._handleCriticalLocationCancel = this._handleCriticalLocationCancel.bind(this);
    this._handleCriticalStartLocationSubmit =
      this._handleCriticalStartLocationSubmit.bind(this);
    this._handleCriticalEndLocationSubmit =
      this._handleCriticalEndLocationSubmit.bind(this);
  }

  _handleCriticalLocationCancel() {
    if (this.props.handleCriticalLocationCancel) {
      this.props.handleCriticalLocationCancel();
    }
  }

  _handleCriticalStartLocationSubmit() {
    if (this.props.handleCriticalLocationSubmit) {
      this.props.handleCriticalLocationSubmit(true);
    }
  }

  _handleCriticalEndLocationSubmit() {
    if (this.props.handleCriticalLocationSubmit) {
      this.props.handleCriticalLocationSubmit(false);
    }
  }

  render() {
    const { show, index } = this.props;

    return (
      <Modal
        id={`booking-flight-segment-${index}-critical-location-modal`}
        show={show}
        onHide={this._handleCriticalLocationCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>Toggle Critical Start & End Times</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <ButtonToolbar className="pull-right">
            <ButtonGroup>
              <Button
                type="button"
                bsStyle="danger"
                onClick={this._handleCriticalLocationCancel}
              >
                Cancel
              </Button>
            </ButtonGroup>
            <ButtonGroup>
              <Button
                type="button"
                bsStyle="primary"
                onClick={this._handleCriticalStartLocationSubmit}
              >
                Toggle Start
              </Button>
              <Button
                type="button"
                bsStyle="primary"
                onClick={this._handleCriticalEndLocationSubmit}
              >
                Toggle End
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default FlightSegmentLocationCriticalModal;
