import {
  Row,
  Col,
  Table,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Form,
  FormGroup,
  FormControl,
} from 'react-bootstrap';
import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';

import { LinkContainer } from 'react-router-bootstrap';

import _get from 'lodash/get';
import _debounce from 'lodash/debounce';
import _includes from 'lodash/includes';
import _last from 'lodash/last';

import { currentSettingsSet } from '../actions/current_setting_actions';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import { filterDo } from '../actions/filter_actions';

import Confirm from '../components/confirm';
import Loader from '../components/loader';

import {
  queriesReady,
  getTaggingsNameString,
  getPhoneNumberToString,
} from '../lib/utils';

import contactDeleteMutation from '../mutations/contact_delete_mutation';

import contactItemListQuery from '../queries/contact_item_list_query';
import roleListQuery from '../queries/role_list_query';

moment.updateLocale('en-nz');

class ContactList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterRoleId: this.props.currentSettingsFilterRoleId,
    };
    this.handleFilter = this.handleFilter.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleFilterRoleIdChange = this.handleFilterRoleIdChange.bind(this);
  }

  componentDidMount() {
    this.delayedHandleFilter = _debounce((e) => {
      if (e.target.value.length > 1 || e.target.value === '') {
        this.props.filterDo({ type: 'contact', q: e.target.value });
      }
    }, 400);
    if (this.props.filterQ) {
      this.delayedHandleFilter({ target: { value: this.props.filterQ } });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.filterQ && this.isLoading(this.props) && this.isLoaded(nextProps)) {
      this.delayedHandleFilter({ target: { value: nextProps.filterQ } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleFilter.cancel();
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(testProps.contactItemListQuery, testProps.roleListQuery);
  }

  handleDeleteClick(e) {
    this.props.mutationSet(true);
    const contactId = _last(e.currentTarget.id.split('-'));
    this.props
      .contactDeleteMutation({
        variables: {
          id: contactId,
        },
      })
      .then(() => {
        this.props.mutationSuccess('Contact delete');
      })
      .catch((res) => {
        this.props.mutationFailure(res);
      });
  }

  handleFilter(e) {
    e.persist();
    this.delayedHandleFilter(e);
  }

  handleFilterRoleIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value, 10))
      ? ''
      : parseInt(e.target.value, 10);
    this.setState({
      filterRoleId: value,
    });
    this.props.currentSettingsSet({
      filterRoleId: value,
    });
  }

  renderRoleFilter() {
    return (
      <FormControl
        componentClass="select"
        value={this.state.filterRoleId}
        onChange={this.handleFilterRoleIdChange}
      >
        <option key={0} value="">
          All
        </option>
        {this.props.roleListQuery.data.map((role) => {
          const { id, name } = role;
          return (
            <option key={id} value={id}>
              {name}
            </option>
          );
        })}
      </FormControl>
    );
  }

  renderRoles = (roles, statutoryRoles) => {
    return (roles || [])
      .map((r) => r.name)
      .concat((statutoryRoles || []).map((r) => r.name))
      .join(', ');
  };

  renderOverlay() {
    if (this.props.filtering || this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (this.isLoaded()) {
      const filtered = this.props.filterQ;
      return (
        <div>
          <Row>
            <Col sm={12}>
              <h3>Contacts</h3>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="clearfix">
                <div className="pull-left">
                  <Form inline>
                    <FormGroup
                      style={{ marginLeft: '16px', paddingBottom: '10px' }}
                      bsSize="sm"
                      controlId="filterId"
                    >
                      {this.renderRoleFilter()}
                    </FormGroup>
                  </Form>
                </div>
                <div className="pull-right">
                  <FormControl
                    style={{
                      display: 'inline-block',
                      verticalAlign: 'middle',
                      width: '200px',
                      marginRight: '10px',
                    }}
                    type="text"
                    placeholder="filter..."
                    defaultValue={this.props.filterQ}
                    onChange={this.handleFilter}
                  />
                  <div className="pull-right">
                    <ButtonToolbar>
                      <ButtonGroup>
                        <LinkContainer to="/contacts/new">
                          <Button bsStyle="primary">Add a Contact</Button>
                        </LinkContainer>
                      </ButtonGroup>
                    </ButtonToolbar>
                  </div>
                  <span
                    className="badge"
                    style={
                      filtered
                        ? { marginRight: '10px' }
                        : { marginRight: '10px', opacity: '0.5' }
                    }
                  >
                    {filtered
                      ? this.props.filterIds.length
                      : this.props.contactItemListQuery.data.length}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Table hover className="vertical-align">
            <thead>
              <tr>
                <th />
                <th>Id</th>
                <th>Name</th>
                <th>Email</th>
                <th>Old Number</th>
                <th>Default Number</th>
                <th>Roles</th>
                <th>Tags</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.props.contactItemListQuery.data
                .filter((contact) => {
                  if (
                    this.state.filterRoleId &&
                    !_includes(
                      _get(contact, 'roles', []).map((r) => r.id),
                      this.state.filterRoleId
                    )
                  ) {
                    return false;
                  }
                  if (filtered && !_includes(this.props.filterIds, contact.id)) {
                    return false;
                  }
                  return true;
                })
                .map((contact) => {
                  const {
                    company,
                    contact_number: contactNumber,
                    defaultPhoneNumber,
                    email,
                    fullName,
                    id,
                    roles,
                    statutoryRoles,
                    taggings,
                  } = contact;
                  return (
                    <tr key={id}>
                      <td>
                        {company ? (
                          <span className="glyphicons glyphicons-building" />
                        ) : (
                          <span className="glyphicons glyphicons-user" />
                        )}
                      </td>
                      <td>{id}</td>
                      <td>{fullName}</td>
                      <td>{email}</td>
                      <td>{contactNumber}</td>
                      <td>
                        {defaultPhoneNumber && getPhoneNumberToString(defaultPhoneNumber)}
                      </td>
                      <td>{this.renderRoles(roles, statutoryRoles)}</td>
                      <td>{getTaggingsNameString(taggings)}</td>
                      <td>
                        <Confirm
                          confirmId={`contact-delete-${id}`}
                          onConfirm={this.handleDeleteClick}
                          title="Delete Contact"
                          body="Are you sure you want to delete this contact"
                          confirmText="Confirm"
                        >
                          <Button bsStyle="link" bsSize="xsmall">
                            delete
                          </Button>
                        </Confirm>
                        <LinkContainer to={`/contacts/${id}/edit`}>
                          <Button bsStyle="link" bsSize="xsmall">
                            edit
                          </Button>
                        </LinkContainer>
                        <LinkContainer to={`/contacts/${id}`}>
                          <Button bsStyle="link" bsSize="xsmall">
                            show
                          </Button>
                        </LinkContainer>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      );
    }
    return undefined;
  }

  render() {
    return (
      <div>
        {this.renderOverlay()}
        {this.renderData()}
      </div>
    );
  }
}

ContactList.propTypes = {
  filterIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  filtering: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const filter = state.filters.contact;
  return {
    filterIds: filter.ids,
    filterQ: filter.q,
    filtering: filter.filtering,
    currentSettingsMutating: state.currentSettings.mutating,
    currentSettingsFilterRoleId: state.currentSettings.filterRoleId,
  };
}

export default compose(
  graphql(contactDeleteMutation, {
    name: 'contactDeleteMutation',
  }),
  graphql(contactItemListQuery, {
    name: 'contactItemListQuery',
  }),
  graphql(roleListQuery, {
    name: 'roleListQuery',
  }),
  connect(mapStateToProps, {
    filterDo,
    mutationSuccess,
    mutationFailure,
    mutationSet,
    currentSettingsSet,
  })
)(ContactList);
