import actionTypes from '../constants';

import {
  fetch,
  unfetch,
  reset,
  loadSuccess,
  loadFailure,
  rehydrate,
} from '../lib/reducerHelpers';

const dataKey = 'tags';

export const initialState = {
  isFetching: false,
  collection: [],
};

function create(state, payload) {
  const { collection } = state;
  const index = collection.findIndex(
    (item) => item.text === payload.text && item.type === payload.type
  );
  if (index > -1) {
    return state;
  } else {
    return {
      ...state,
      collection: [...collection, payload],
    };
  }
}

export default function tagsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case 'persist/REHYDRATE':
      return rehydrate(initialState, (payload || {})[dataKey] || {});
    case actionTypes.TAG_CREATE:
      return create(state, payload);
    case actionTypes.DATA_FETCH:
      return fetch(state);
    case actionTypes.DATA_UNFETCH:
      return unfetch(state);
    case actionTypes.DATA_RESET:
      return reset(initialState);
    case actionTypes.DATA_LOAD_SUCCESS:
      return loadSuccess(initialState, payload[dataKey]);
    case actionTypes.DATA_LOAD_FAILURE:
      return loadFailure(initialState);
    default:
      return state;
  }
}
