import { Row, Col, Button, Glyphicon } from 'react-bootstrap';
import { Component } from 'react';

import get from 'lodash/get';

import Confirm from '../confirm';
import InputField from '../form/input_field';

class ContactFormAddressFields extends Component {
  constructor(props) {
    super(props);
    this._handleDeleteClick = this._handleDeleteClick.bind(this);
  }

  _handleDeleteClick() {
    if (this.props.handleDeleteClick) {
      this.props.handleDeleteClick(this.props.index);
    }
  }

  _renderDeleteMessage() {
    let msg = 'Are you sure you want to delete this address information?';
    if (get(this.props, 'formValues.id')) {
      msg +=
        ' Note that while the information will be removed from the list, it will not be permanently deleted until you save this Contact.';
    }
    return msg;
  }

  render() {
    const {
      address_type: { input: addressTypeInput, meta: addressTypeMeta },
      description: { input: descriptionInput, meta: descriptionMeta },
      prefix: { input: prefixInput, meta: prefixMeta },
      street_number: { input: streetNumberInput, meta: streetNumberMeta },
      suffix: { input: suffixInput, meta: suffixMeta },
      street: { input: streetInput, meta: streetMeta },
      suburb: { input: suburbInput, meta: suburbMeta },
      city: { input: cityInput, meta: cityMeta },
      region: { input: regionInput, meta: regionMeta },
      postcode: { input: postcodeInput, meta: postcodeMeta },
      post_office_box: { input: postOfficeBoxInput, meta: postOfficeBoxMeta },
    } = get(this.props, this.props.parentField);

    const { addressTypes } = this.props;

    return (
      <fieldset>
        <Row>
          <Col xs={4}>
            <InputField
              type="text"
              bsClass="form-group form-horizontal-nested"
              inputStyle={{ marginTop: '5px' }}
              labelWidth={0}
              inputWidth={12}
              componentClass="select"
              selectOptions={addressTypes}
              input={addressTypeInput}
              meta={addressTypeMeta}
            >
              Type
            </InputField>
          </Col>
          <Col xs={8}>
            <InputField
              type="text"
              bsClass="form-group form-horizontal-nested"
              inputStyle={{ marginTop: '5px' }}
              labelWidth={0}
              inputWidth={12}
              input={descriptionInput}
              meta={descriptionMeta}
            >
              Description
            </InputField>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>
            <InputField
              type="text"
              bsClass="form-group form-horizontal-nested"
              inputStyle={{ marginTop: '5px' }}
              labelWidth={0}
              inputWidth={12}
              input={postOfficeBoxInput}
              meta={postOfficeBoxMeta}
            >
              PO Box
            </InputField>
          </Col>
          <Col xs={2}>
            <InputField
              type="text"
              bsClass="form-group form-horizontal-nested"
              inputStyle={{ marginTop: '5px' }}
              labelWidth={0}
              inputWidth={12}
              input={prefixInput}
              meta={prefixMeta}
            >
              Prefix
            </InputField>
          </Col>
          <Col xs={2}>
            <InputField
              type="text"
              bsClass="form-group form-horizontal-nested"
              inputStyle={{ marginTop: '5px' }}
              labelWidth={0}
              inputWidth={12}
              input={streetNumberInput}
              meta={streetNumberMeta}
            >
              Number
            </InputField>
          </Col>
          <Col xs={2}>
            <InputField
              type="text"
              bsClass="form-group form-horizontal-nested"
              inputStyle={{ marginTop: '5px' }}
              labelWidth={0}
              inputWidth={12}
              input={suffixInput}
              meta={suffixMeta}
            >
              Suffix
            </InputField>
          </Col>
          <Col xs={4}>
            <InputField
              type="text"
              bsClass="form-group form-horizontal-nested"
              inputStyle={{ marginTop: '5px' }}
              labelWidth={0}
              inputWidth={12}
              input={streetInput}
              meta={streetMeta}
            >
              Street
            </InputField>
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <InputField
              type="text"
              bsClass="form-group form-horizontal-nested"
              inputStyle={{ marginTop: '5px' }}
              labelWidth={0}
              inputWidth={12}
              input={suburbInput}
              meta={suburbMeta}
            >
              Suburb
            </InputField>
          </Col>
          <Col xs={3}>
            <InputField
              type="text"
              bsClass="form-group form-horizontal-nested"
              inputStyle={{ marginTop: '5px' }}
              labelWidth={0}
              inputWidth={12}
              input={cityInput}
              meta={cityMeta}
            >
              City
            </InputField>
          </Col>
          <Col xs={3}>
            <InputField
              type="text"
              bsClass="form-group form-horizontal-nested"
              inputStyle={{ marginTop: '5px' }}
              labelWidth={0}
              inputWidth={12}
              input={regionInput}
              meta={regionMeta}
            >
              Region
            </InputField>
          </Col>
          <Col xs={2}>
            <InputField
              type="text"
              bsClass="form-group form-horizontal-nested"
              inputStyle={{ marginTop: '5px' }}
              labelWidth={0}
              inputWidth={12}
              input={postcodeInput}
              meta={postcodeMeta}
            >
              Postcode
            </InputField>
          </Col>
          <Col sm={1}>
            <div style={{ marginTop: '33px' }}>
              <Confirm
                onConfirm={this._handleDeleteClick}
                title="Delete Phone Number"
                body={this._renderDeleteMessage()}
                confirmText="Confirm"
              >
                <Button type="button" bsStyle="default" bsSize="small">
                  <Glyphicon glyph="trash" />
                </Button>
              </Confirm>
            </div>
          </Col>
        </Row>
      </fieldset>
    );
  }
}

export default ContactFormAddressFields;
