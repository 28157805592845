import { FormGroup, Col, HelpBlock, ControlLabel } from 'react-bootstrap';
import { SketchPicker } from 'react-color';

import { validationState, validationText } from '../form/helpers';

const ContactFormEmployeeColorField = (props) => {
  const {
    input,
    meta,
    formValues: { employee_color: employeeColor },
  } = props;

  function _handleColorChangeComplete(color, event) {
    input.onChange(color.hex);
  }

  return (
    <FormGroup controlId="contactEmployeeColor" validationState={validationState(meta)}>
      <Col componentClass={ControlLabel} sm={3}>
        Employee Color (because employee)
      </Col>
      <Col sm={6}>
        <SketchPicker
          color={employeeColor}
          onChangeComplete={_handleColorChangeComplete}
        />
        <HelpBlock>{validationText(meta)}</HelpBlock>
      </Col>
    </FormGroup>
  );
};

export default ContactFormEmployeeColorField;
