import { createUseStyles } from 'react-jss';
import { Outlet } from 'react-router-dom';

import LoginBackground from '../images/login_background.jpg';

const useStyles = createUseStyles({
  container: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url(${LoginBackground})`,
    backgroundColor: 'orange',
  },
  content: {
    width: '400px',
  },
});

export default function AuthLayout() {
  // note don't have to pass props in only noting option.
  // const classes = useStyles(props);
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Outlet />
      </div>
    </div>
  );
}
