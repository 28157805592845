import {
  Row,
  Col,
  Table,
  Tabs,
  Tab,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Glyphicon,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import moment from 'moment';
import { LinkContainer } from 'react-router-bootstrap';

import { reduxForm, Field, Fields, FieldArray, getFormValues } from 'redux-form';

import _compact from 'lodash/compact';
import _cloneDeep from 'lodash/cloneDeep';
import _defaults from 'lodash/defaults';
import _defaultTo from 'lodash/defaultTo';
import _findIndex from 'lodash/findIndex';
import _findLastIndex from 'lodash/findLastIndex';
import _first from 'lodash/first';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _isNumber from 'lodash/isNumber';
import _last from 'lodash/last';
import _merge from 'lodash/merge';
import _mergeWith from 'lodash/mergeWith';
import _omit from 'lodash/omit';
import _pick from 'lodash/pick';
import _reduce from 'lodash/reduce';
import _set from 'lodash/set';
import _slice from 'lodash/slice';
import _values from 'lodash/values';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import { typeaheadsFilter, typeaheadsFilterClear } from '../actions/typeahead_actions';

import {
  aircraftsData,
  aircraftCandidateHobbsData,
  contactsData,
  fuelBowsersData,
  fuelTankersData,
  locationsData,
} from '../selectors';

import {
  AircraftFuelDataMemo,
  AircraftAirspeedDataMemo,
  BookingPassengerSeatsMemo,
  BookingWeightDataMemo,
  FlightSegmentDistanceDataMemo,
  FlightSegmentFuelDataMemo,
  FlightSegmentFlighttimeDataMemo,
  FlightSegmentWeightDataMemo,
} from '../memos';

import Loader from '../components/loader';
import InputField from '../components/form/input_field';

import BookingCreators from '../components/flight_form/booking_creators';
import BookingSnapshot from '../components/flight_form/booking_snapshot';
import BookingTitle from '../components/flight_form/booking_title';
import FlightPlanTableHeader from '../components/flight_form/flight_plan_table_header';
import FlightPlanTableFooter from '../components/flight_form/flight_plan_table_footer';

import ChargeablePaxes from '../components/flight_form/chargeable_paxes';
import EngineEvents from '../components/flight_form/engine_events';
import FlightPlans from '../components/flight_form/flight_plans';
import FlightSegmentFieldArray from '../components/flight_form/flight_segment_field_array';
import FuelBowserFillFieldArray from '../components/flight_form/fuel_bowser_fill_field_array';
import FuelTankerFillFieldArray from '../components/flight_form/fuel_tanker_fill_field_array';
import LandingCharges from '../components/flight_form/landing_charges';
import OilFills from '../components/flight_form/oil_fills';
import PilotFlightExpenses from '../components/flight_form/pilot_flight_expenses';
import PilotFlightLogs from '../components/flight_form/pilot_flight_logs';
import PilotFlightLogSummary from '../components/flight_form/pilot_flight_log_summary';

import AircraftInputField from '../components/flight_form/aircraft_input_field';
import AircraftConfigurationInputField from '../components/flight_form/aircraft_configuration_input_field';
import ChargeableInputField from '../components/flight_form/chargeable_input_field';
import StartDateAndTimeInputFields from '../components/flight_form/start_date_and_time_input_fields';
import EndDateAndTimeInputFields from '../components/flight_form/end_date_and_time_input_fields';
import ProviderInputField from '../components/flight_form/provider_input_field';
import FlightTypeInputField from '../components/flight_form/flight_type_input_field';
import PilotInputField from '../components/flight_form/pilot_input_field';
import CopilotInputField from '../components/flight_form/copilot_input_field';

import BookingAdminFlightRecordFields from '../components/flight_form/booking_admin_flight_record_fields';
import BookingHobbRecordFields from '../components/flight_form/booking_hobb_record_fields';
import BookingPilotDutyRecordFields from '../components/flight_form/booking_pilot_duty_record_fields';
import BookingPilotFlightNoteFields from '../components/flight_form/booking_pilot_flight_note_fields';

import FlightSegmentAddModal from '../components/flight_form/flight_segment_add_modal';
import FlightSegmentLocationAddModal from '../components/flight_form/flight_segment_location_add_modal';
import FlightSegmentLocationCriticalModal from '../components/flight_form/flight_segment_location_critical_modal';
import PassengerAddModal from '../components/flight_form/passenger_add_modal';

import {
  // defaultBookingDev,
  defaultBooking,
  defaultFlightSegment,
  defaultSeatAssignment,
} from '../defaults';

import bookingUpdateMutation from '../mutations/booking_update_mutation';
import bookingCreateMutation from '../mutations/booking_create_mutation';
import locationCreateMutation from '../mutations/location_create_mutation';
import contactCreateMutation from '../mutations/contact_create_mutation';

import {
  queriesReady,
  queryReady,
  queryJustReady,
  typeInput,
  mapPickValues,
  pickValues,
  mapOmitValues,
  omitValues,
} from '../lib/utils';

import aircraftListQuery from '../queries/aircraft_list_query';
import aircraftCandidateHobbListQuery from '../queries/aircraft_candidate_hobb_list_query';
import aircraftConfigurationListQuery from '../queries/aircraft_configuration_list_query';
import aircraftEngineListQuery from '../queries/aircraft_engine_list_query';
import aircraftTypeListQuery from '../queries/aircraft_type_list_query';
import aircraftFlightTypePilotMapQuery from '../queries/aircraft_flight_type_pilot_map_query';
import bookingQuery from '../queries/booking_query';
import contactItemListQuery from '../queries/contact_item_list_query';
import flightTypeListQuery from '../queries/flight_type_list_query';
import fuelTypeListQuery from '../queries/fuel_type_list_query';
import fuelBowserListQuery from '../queries/fuel_bowser_list_query';
import fuelTankerListQuery from '../queries/fuel_tanker_list_query';
import locationListQuery from '../queries/location_min_list_query';
import roleListQuery from '../queries/role_list_query';

moment.updateLocale('en-nz');

let isInitialisedBookingForm = false;

class FlightForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: !!this.props.params.id,
      tabKey: 2,
      locationsTypeaheadIndex: ['', ''],
      passengersTypeaheadIndex: ['', ''],
      addPassenger: {
        show: false,
        index: '',
        flightSegmentIndex: '',
        label: '',
        weight: 0,
      },
      addFlightSegment: { show: false, index: '' },
      addLocation: {
        show: false,
        index: '',
        start: false,
        label: '',
      },
      addCritical: { show: false, index: '' },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTabClick = this.handleTabClick.bind(this);

    this.handleAddFlightSegmentClicked = this.handleAddFlightSegmentClicked.bind(this);
    this.handleAddFlightSegmentModalClicked =
      this.handleAddFlightSegmentModalClicked.bind(this);
    this.handleAddFlightSegmentModalCancel =
      this.handleAddFlightSegmentModalCancel.bind(this);
    this.handleAddFlightSegmentModalSubmit =
      this.handleAddFlightSegmentModalSubmit.bind(this);

    this.handleCriticalLocationModalClicked =
      this.handleCriticalLocationModalClicked.bind(this);
    this.handleCriticalLocationModalCancel =
      this.handleCriticalLocationModalCancel.bind(this);
    this.handleCriticalLocationModalSubmit =
      this.handleCriticalLocationModalSubmit.bind(this);

    this.handleAddLocationModalClicked = this.handleAddLocationModalClicked.bind(this);
    this.handleAddLocationModalCancel = this.handleAddLocationModalCancel.bind(this);
    this.handleAddLocationModalSubmit = this.handleAddLocationModalSubmit.bind(this);

    this.handleAddLocationClicked = this.handleAddLocationClicked.bind(this);
    this.handleStartLocationChanged = this.handleStartLocationChanged.bind(this);
    this.handleEndLocationChanged = this.handleEndLocationChanged.bind(this);

    this.handleAddPassengerModalClicked = this.handleAddPassengerModalClicked.bind(this);
    this.handleAddPassengerModalCancel = this.handleAddPassengerModalCancel.bind(this);
    this.handleAddPassengerModalSubmit = this.handleAddPassengerModalSubmit.bind(this);

    this.handleAddPassengerClicked = this.handleAddPassengerClicked.bind(this);
    this.handlePassengerChanged = this.handlePassengerChanged.bind(this);

    this.handleChargeableSearch = this.handleChargeableSearch.bind(this);
    this.handleChargeableSearchClear = this.handleChargeableSearchClear.bind(this);
    this.handleLocationSearch = this.handleLocationSearch.bind(this);
    this.handleLocationSearchClear = this.handleLocationSearchClear.bind(this);
    this.handlePassengerSearch = this.handlePassengerSearch.bind(this);
    this.handlePassengerSearchClear = this.handlePassengerSearchClear.bind(this);

    this.renderBookingTab = this.renderBookingTab.bind(this);
    this.renderFlightPlanTab = this.renderFlightPlanTab.bind(this);

    this.renderFlightSegmentFieldArray = this.renderFlightSegmentFieldArray.bind(this);
    this.renderFuelBowserFillFieldArray = this.renderFuelBowserFillFieldArray.bind(this);

    this.renderChargeablesFieldArray = this.renderChargeablesFieldArray.bind(this);
    this.renderChargeablesPaxFieldArray = this.renderChargeablesPaxFieldArray.bind(this);
    this.renderEngineEventsFieldArray = this.renderEngineEventsFieldArray.bind(this);

    this.renderFlightPlansFieldArray = this.renderFlightPlansFieldArray.bind(this);

    this.renderFuelTankerFillFieldArray = this.renderFuelTankerFillFieldArray.bind(this);
    this.renderLandingChargesFieldArray = this.renderLandingChargesFieldArray.bind(this);
    this.renderOilFillsFieldArray = this.renderOilFillsFieldArray.bind(this);
    this.renderPilotFlightExpensesFieldArray =
      this.renderPilotFlightExpensesFieldArray.bind(this);
    this.renderPilotFlightLogsFieldArray =
      this.renderPilotFlightLogsFieldArray.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.bookingQuery &&
      queryJustReady(this.props.bookingQuery, nextProps.bookingQuery)
    ) {
      if (
        _get(nextProps.bookingQuery, 'data.audit_created_at') &&
        this.state.tabKey === 2
      ) {
        this.setState({
          tabKey: 3,
        });
      }
    }
  }

  componentWillUnmount() {
    isInitialisedBookingForm = false;
  }

  getBookingPassengerSeats() {
    const values = _get(this.props, 'formValues', {});
    return BookingPassengerSeatsMemo({
      aircraftId: _get(values, 'aircraft_id', ''),
      aircraftConfigurationId: values.aircraft_configuration_id,
      copilotId: _get(values, 'copilot_id', ''),
      aircrafts: this.props.aircraftListQuery.data,
      aircraftConfigurations: this.props.aircraftConfigurationListQuery.data,
      aircraftTypes: this.props.aircraftTypeListQuery.data,
    });
  }

  getBookingWeightData() {
    const values = _get(this.props, 'formValues', {});
    return BookingWeightDataMemo({
      aircraftId: _get(values, 'aircraft_id', ''),
      aircrafts: this.props.aircraftListQuery.data,
      aircraftTypes: this.props.aircraftTypeListQuery.data,
      pilotId: _get(values, 'pilot_id', ''),
      copilotId: _get(values, 'copilot_id', ''),
      pilots: this.props.pilotListQuery.data,
    });
  }

  getAircraftAirspeedData() {
    const values = _get(this.props, 'formValues', {});
    return AircraftAirspeedDataMemo({
      aircraftId: _get(values, 'aircraft_id', ''),
      aircrafts: this.props.aircraftListQuery.data,
      aircraftTypes: this.props.aircraftTypeListQuery.data,
    });
  }

  getAircraftFuelData() {
    const values = _get(this.props, 'formValues', {});
    return AircraftFuelDataMemo({
      aircraftId: _get(values, 'aircraft_id', ''),
      aircrafts: this.props.aircraftListQuery.data,
      aircraftTypes: this.props.aircraftTypeListQuery.data,
      fuelTypes: this.props.fuelTypeListQuery.data,
    });
  }

  getBookingAircraftEngines() {
    return this.props.aircraftEngineListQuery.data.filter(
      (ae) =>
        ae.aircraft_id === parseInt(_get(this.props, 'formValues.aircraft_id', 0), 10)
    );
  }

  getActiveFlightSegments() {
    // eslint-disable-next-line no-underscore-dangle
    return _get(this.props, 'formValues.flight_segments_attributes', []).filter(
      (fs) => !fs._destroy
    );
  }

  getActiveBookingChargeables() {
    // eslint-disable-next-line no-underscore-dangle
    return _get(this.props, 'formValues.booking_chargeables_attributes', []).filter(
      (bc) => !bc._destroy
    );
  }

  getFlightSummary(submitData) {
    // eslint-disable-next-line no-underscore-dangle
    const fss = _get(submitData, 'flight_segments_attributes', []).filter(
      (fs) => !fs._destroy
    );
    if (fss.length > 0) {
      const newSummaries = fss.map((summary) => {
        const startLocationName =
          _get(this.props.locationsDataSelector, [
            _get(summary, 'start_location_id'),
            'shorthandName',
          ]) || 'Unknown';
        const pax = summary.seat_assignments_attributes
          .map((sa) => sa.pax_name)
          .filter((name) => name)
          .join(', ');
        return pax ? `${startLocationName} (${pax})` : startLocationName;
      });
      const endLocationName =
        _get(this.props.locationsDataSelector, [
          _get(_last(fss), 'end_location_id'),
          'shorthandName',
        ]) || 'Unknown';
      newSummaries.push(endLocationName);
      return newSummaries.join(' - ');
    } else {
      return ' No flight summary';
    }
  }

  getFlightSegmentCalculatedFuelData() {
    const values = _get(this.props, 'formValues', {});
    const flightSegments = this.getActiveFlightSegments();
    const fuelDatas = this.getFlightSegmentFuelData();
    const flighttimeDatas = this.getFlightSegmentFlighttimeData();
    const firstFuelData = fuelDatas.find((fd) => fd.locationId);
    let results = [];
    if (firstFuelData) {
      const fuelCapacity = _get(firstFuelData, 'aircraftTypeFuelCapacityKg', 0);
      const fuelSpecificGravity = _get(
        firstFuelData,
        'aircraftTypeFuelTypeSpecificGravity',
        0
      );
      const consumption =
        _get(values, 'cruise_fuel_consumption_sl_kg', 0) ||
        _get(firstFuelData, 'aircraftTypeCruiseFuelConsumptionSlKg', 0);
      const reserve =
        _get(values, 'company_planned_reserve', 0) ||
        _get(firstFuelData, 'aircraftTypeCompanyPlannedReserve', 0);
      const reserveFuelKg = (reserve / 60) * consumption;
      let prevEndFuelCalcKg = 0;

      results = flightSegments.map((fs, index) => {
        if (_get(fs, 'start_location_id') && _get(fs, 'end_location_id')) {
          const flighttimeData = flighttimeDatas[index];
          if (_get(flighttimeData, 'flightSegmentAdjustedFlighttime')) {
            const fuelData = fuelDatas[index];
            const {
              flightSegmentFuelTankers: tankers,
              flightSegmentFuelBowsers: bowsers,
            } = fuelData;
            const startFuelAdj = _get(fs, 'start_fuel_adj', {
              auto: false,
              manual: false,
              adjustment: 0,
            });
            const hasTankers = tankers.length > 0;
            const hasBowsers = bowsers.length > 0;
            const hasFuel = hasTankers || hasBowsers;
            const flightTimeCalcLoading = flighttimeData.flightSegmentLoadedFlighttime;
            const legFlightFuelKg = (flightTimeCalcLoading / 60) * consumption;
            const legFuelCalcKg = legFlightFuelKg + reserveFuelKg;
            const remainingFlightTime = _slice(
              flighttimeDatas,
              index,
              flighttimeDatas.length
            )
              .filter((ftd) => ftd.flightSegmentLoadedFlighttime)
              .reduce((total, time) => total + time.flightSegmentLoadedFlighttime, 0);
            let sumFlightFuelKg = 0;
            if (remainingFlightTime > 0) {
              sumFlightFuelKg = (remainingFlightTime / 60) * consumption;
            }
            let sumFuelCalcKg = sumFlightFuelKg + reserveFuelKg;
            if (index > 0 && prevEndFuelCalcKg !== sumFuelCalcKg) {
              sumFuelCalcKg = prevEndFuelCalcKg;
            }
            let startFuelCalcKg = 0;
            const currentAdjustment = startFuelAdj.adjustment;
            let candidateAdjustment = startFuelAdj.adjustment;
            const currentManual = startFuelAdj.manual;
            let candidateManual = startFuelAdj.manual;
            const currentAuto = startFuelAdj.auto;
            let candidateAuto = startFuelAdj.auto;

            if (hasFuel) {
              if (sumFuelCalcKg > fuelCapacity) {
                candidateAuto = true;
                candidateAdjustment = fuelCapacity - sumFuelCalcKg;
                if (candidateManual) {
                  if (currentAdjustment < candidateAdjustment) {
                    candidateAdjustment = currentAdjustment;
                  } else {
                    candidateManual = false;
                  }
                }
                startFuelCalcKg = sumFuelCalcKg + candidateAdjustment;
              } else if (sumFuelCalcKg < sumFlightFuelKg + reserveFuelKg) {
                candidateAuto = true;
                if (sumFuelCalcKg < fuelCapacity) {
                  const required = Math.min(
                    sumFlightFuelKg + reserveFuelKg,
                    fuelCapacity
                  );
                  candidateAdjustment = required - sumFuelCalcKg;
                  if (candidateManual) {
                    if (currentAdjustment > candidateAdjustment) {
                      candidateAdjustment = currentAdjustment;
                    } else {
                      candidateManual = false;
                    }
                  }
                  startFuelCalcKg = sumFuelCalcKg + candidateAdjustment;
                } else {
                  startFuelCalcKg = fuelCapacity;
                  candidateAdjustment = 0;
                  candidateManual = currentManual;
                }
              } else if (currentAuto) {
                startFuelCalcKg = sumFuelCalcKg;
                candidateAdjustment = 0;
                candidateManual = currentManual;
                candidateAuto = false;
              } else {
                startFuelCalcKg = sumFuelCalcKg + currentAdjustment;
                candidateAdjustment = currentAdjustment;
                candidateManual = currentManual;
                candidateAuto = currentAuto;
              }
            } else {
              startFuelCalcKg = sumFuelCalcKg;
              candidateAdjustment = 0;
              candidateManual = false;
              candidateAuto = false;
            }
            const endFuelCalcKg = startFuelCalcKg - legFlightFuelKg;
            prevEndFuelCalcKg = endFuelCalcKg;
            const startFuelCalcKgRnd = Math.round(100 * startFuelCalcKg) / 100;
            const alertFuelOver =
              startFuelCalcKgRnd > Math.round(100 * fuelCapacity) / 100;
            const alertFuelUnder =
              startFuelCalcKgRnd < Math.round(100 * legFuelCalcKg) / 100;
            return {
              start_location_id: _get(fs, 'start_location_id'),
              end_location_id: _get(fs, 'end_location_id'),
              hasFuel,
              tankers,
              bowsers,
              fuelCapacity,
              legFlightFuelKg,
              legFuelCalcKg,
              startFuelCalcKg,
              alertFuelOver,
              alertFuelUnder,
              legFlightFuelLt: legFlightFuelKg / fuelSpecificGravity,
              legFuelCalcLt: legFuelCalcKg / fuelSpecificGravity,
              startFuelCalcLt: startFuelCalcKg / fuelSpecificGravity,
              startFuelAdj: {
                adjustment: candidateAdjustment,
                manual: candidateManual,
                auto: candidateAuto,
              },
            };
          } else {
            return {};
          }
        } else {
          return {};
        }
      });
    }
    return results;
  }

  getFlightSegmentFuelData() {
    const values = _get(this.props, 'formValues', {});
    const flightSegments = this.getActiveFlightSegments();
    return flightSegments.map((fs) =>
      FlightSegmentFuelDataMemo({
        locationId: _get(fs, 'start_location_id', ''),
        fuelBowsers: this.props.fuelBowserListQuery.data,
        fuelTankers: this.props.fuelTankerListQuery.data,
        aircraftId: _get(values, 'aircraft_id', ''),
        aircrafts: this.props.aircraftListQuery.data,
        aircraftTypes: this.props.aircraftTypeListQuery.data,
        fuelTypes: this.props.fuelTypeListQuery.data,
      })
    );
  }

  getFlightSegmentWeightData() {
    const values = _get(this.props, 'formValues', {});
    const flightSegments = this.getActiveFlightSegments();
    return flightSegments.map((fs) => {
      const passengers = _slice(
        _get(fs, 'seat_assignments_attributes', []),
        0,
        this.getBookingPassengerSeats()
      );
      const paxWeight = passengers.reduce(
        (total, passenger) =>
          total + _defaultTo(parseInt(_get(passenger, 'weight', 0), 10), 0),
        0
      );
      return FlightSegmentWeightDataMemo({
        luggageWeight: _get(fs, 'luggage_weight', 0),
        paxWeight,
        aircraftId: _get(values, 'aircraft_id', ''),
        aircrafts: this.props.aircraftListQuery.data,
        aircraftTypes: this.props.aircraftTypeListQuery.data,
        pilotId: _get(values, 'pilot_id', ''),
        copilotId: _get(values, 'copilot_id', ''),
        pilots: this.props.pilotListQuery.data,
      });
    });
  }

  getFlightSegmentFlighttimeData() {
    const values = _get(this.props, 'formValues', {});
    const flightSegments = this.getActiveFlightSegments();
    return flightSegments.map((fs) => {
      const ids = _values(_pick(fs, ['start_location_id', 'end_location_id'])).sort();
      return FlightSegmentFlighttimeDataMemo({
        airspeedAdj: _get(fs, 'airspeed_adj', 0),
        bookingCruiseAirspeedSlKnots: _get(values, 'cruise_airspeed_sl_knots', 0),
        aircraftId: _get(values, 'aircraft_id', ''),
        aircrafts: this.props.aircraftListQuery.data,
        aircraftTypes: this.props.aircraftTypeListQuery.data,
        distanceAdj: _get(fs, 'distance_adj', 0),
        locations: this.props.locationListQuery.data,
        locationIdA: _first(ids),
        locationIdB: _last(ids),
      });
    });
  }

  getFlightSegmentDistanceData() {
    const flightSegments = this.getActiveFlightSegments();
    return flightSegments.map((fs) => {
      const ids = _values(_pick(fs, ['start_location_id', 'end_location_id'])).sort();
      return FlightSegmentDistanceDataMemo({
        distanceAdj: _get(fs, 'distance_adj', 0),
        locations: this.props.locationListQuery.data,
        locationIdA: _first(ids),
        locationIdB: _last(ids),
      });
    });
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(
      [testProps.bookingQuery, true], // ignore if undefined
      testProps.aircraftListQuery,
      testProps.aircraftConfigurationListQuery,
      testProps.aircraftEngineListQuery,
      testProps.aircraftTypeListQuery,
      testProps.aircraftCandidateHobbListQuery,
      testProps.aircraftFlightTypePilotMapQuery,
      testProps.chargeableListQuery,
      testProps.employeeListQuery,
      testProps.flightTypeListQuery,
      testProps.fuelBowserListQuery,
      testProps.fuelTankerListQuery,
      testProps.fuelTypeListQuery,
      testProps.locationListQuery,
      testProps.passengerListQuery,
      testProps.pilotListQuery,
      testProps.providerListQuery,
      testProps.roleListQuery
    );
  }

  handleSubmit(data) {
    const submitData = _cloneDeep(data);
    const maxPassengerSeats = this.getBookingPassengerSeats();
    const adminId = this.props.currentContact.id;
    if (this.state.updating) {
      submitData.updated_by_admin_id = adminId;
      if (this.isAudited()) {
        const undeletedChargeables = submitData.booking_chargeables_attributes.filter(
          (bca) =>
            // eslint-disable-next-line no-underscore-dangle
            !bca._destroy
        );
        if (undeletedChargeables.length === 1) {
          const newBookingChargeables = submitData.booking_chargeables_attributes.map(
            (bookingChargeable) => Object.assign({}, bookingChargeable, { pax: '' })
          );
          submitData.booking_chargeables_attributes = newBookingChargeables;
        }
      }
    } else {
      submitData.chargeable_ids = submitData.booking_chargeables_attributes.map(
        (bca) => bca.chargeable_id
      );
      delete submitData.booking_chargeables_attributes;
      submitData.updated_by_admin_id = adminId;
      submitData.created_by_admin_id = adminId;
    }
    const activeFlightSegments = this.getActiveFlightSegments();
    if (activeFlightSegments.length > 0) {
      const firstFlightSegment = _first(activeFlightSegments);
      const lastFlightSegment = _last(activeFlightSegments);
      submitData.start_at = submitData.start_at || firstFlightSegment.start_at;
      submitData.end_at = submitData.end_at || lastFlightSegment.end_at;
      submitData.start_location_id = firstFlightSegment.start_location_id;
      submitData.end_location_id = lastFlightSegment.end_location_id;
    } else {
      submitData.start_location_id = '';
      submitData.end_location_id = '';
    }
    let activeIndex = -1;
    let newFlightSegments = _cloneDeep(submitData.flight_segments_attributes);
    newFlightSegments = newFlightSegments.map((flightSegment) => {
      // eslint-disable-next-line no-underscore-dangle
      if (!flightSegment._destroy) {
        activeIndex += 1;
      }
      let activeSaIndex = -1;
      let wipSeatAssigments = _cloneDeep(flightSegment.seat_assignments_attributes);
      wipSeatAssigments = wipSeatAssigments.filter(
        (fs) => fs.id || fs.pax_name || fs.weight
      );
      wipSeatAssigments = wipSeatAssigments.map((fs) => {
        if (fs.id && !fs.pax_name && !fs.weight) {
          return _merge(fs, { _destroy: true });
        } else {
          return fs;
        }
      });
      const sizedSeatAssignments = _slice(wipSeatAssigments, 0, maxPassengerSeats);
      const overSizedSeatAssignments = _slice(wipSeatAssigments, maxPassengerSeats);
      // eslint-disable-next-line no-underscore-dangle
      const deleteableOverSizedSeatAssignments = overSizedSeatAssignments.filter(
        (fs) => fs._destroy
      );
      const newSeatAssigments = [
        ...sizedSeatAssignments,
        ...deleteableOverSizedSeatAssignments,
      ].map((seatAssignment) => {
        // eslint-disable-next-line no-underscore-dangle
        if (!seatAssignment._destroy) {
          activeSaIndex += 1;
        }
        return Object.assign(
          {},
          seatAssignment,
          { position: activeSaIndex },
          // eslint-disable-next-line no-underscore-dangle
          !seatAssignment._destroy && !seatAssignment.pax_name
            ? { pax_name: `Pax#${activeSaIndex + 1}` }
            : {},
          // eslint-disable-next-line no-underscore-dangle
          !seatAssignment._destroy && !seatAssignment.weight ? { weight: 0 } : {}
        );
      });
      return Object.assign({}, flightSegment, {
        manual_start_fuel_adj: flightSegment.start_fuel_adj.manual,
        start_fuel_adj: flightSegment.start_fuel_adj.adjustment,
        position: activeIndex,
        seat_assignments_attributes: newSeatAssigments,
      });
    });

    submitData.flight_segments_attributes = newFlightSegments;

    if (_get(submitData, 'pilot_flight_logs_attributes')) {
      submitData.pilot_flight_logs_attributes =
        submitData.pilot_flight_logs_attributes.map((pfl) => {
          const newFlightTypes = _reduce(
            pfl.flight_types,
            (result, value, key) => {
              const newResult = { ...result };
              if (value && _isNumber(parseFloat(value))) {
                newResult[key] = parseFloat(value);
              }
              return newResult;
            },
            {}
          );
          return _mergeWith(
            pfl,
            { flight_types: newFlightTypes },
            (objValue, srcValue, key) => {
              if (key === 'flight_types') {
                return srcValue;
              }
              return undefined;
            }
          );
        });
    }

    if (!this.state.updating || (this.state.updating && !this.isAudited())) {
      [
        'oil_fills_attributes',
        'engine_events_attributes',
        'fuel_bowser_fills_attributes',
        'fuel_tanker_fills_attributes',
        'pilot_flight_expenses_attributes',
        'pilot_flight_logs_attributes',
        'pilot_duty_record_attributes',
        'copilot_duty_record_attributes',
        'hobb_record_attributes',
        'admin_flight_record_attributes',
        'pilot_flight_note_attributes',
      ].forEach((attributes) => {
        delete submitData[attributes];
      });
    }

    if (this.isAudited()) {
      // manage admin flight record for non office admins
      const adminFlightRecord = _get(submitData, 'admin_flight_record_attributes');
      const hobbRecordFlightTime = _get(submitData, 'hobb_record_attributes.flight_time');
      if (hobbRecordFlightTime) {
        if (!_get(adminFlightRecord, 'flight_time')) {
          _set(
            submitData,
            'admin_flight_record_attributes.flight_time',
            hobbRecordFlightTime
          );
          _set(submitData, 'admin_flight_record_attributes.admin_id', adminId);
        }
        if (!_get(adminFlightRecord, 'flight_summary')) {
          _set(
            submitData,
            'admin_flight_record_attributes.flight_summary',
            this.getFlightSummary(submitData)
          );
          _set(submitData, 'admin_flight_record_attributes.admin_id', adminId);
        }
      }
    }
    // console.log('submit')
    // console.log(submitData)
    // return

    this.props.mutationSet(true);
    let mutation;
    let mutationMessage;
    const mutationData = { variables: { input: typeInput(submitData) } };
    if (this.state.updating) {
      mutation = this.props.bookingUpdateMutation;
      mutationMessage = 'Booking update';
      mutationData.variables.id = this.props.params.id;
    } else {
      mutation = this.props.bookingCreateMutation;
      mutationMessage = 'Booking create';
    }
    return mutation(mutationData)
      .then(() => {
        this.props.mutationSuccess(mutationMessage);
        this.props.navigate(this.props.currentSettingsReturnRoute);
      })
      .catch((err) => this.props.mutationFailure(err, true));
  }

  handleTabClick(tabKey) {
    // if (isNaN(parseInt(tabKey))) {
    //   tabKey.preventDefault()
    // } else {
    //   this.setState({tabKey})
    // }
    this.setState({ tabKey });
  }

  handlePassengerSearch(query, index, flightSegmentIndex) {
    this.props.typeaheadsFilter(
      {
        str: query,
        class: 'contacts',
        role: 'passenger',
        fields: [
          'first_name',
          'last_name',
          'display_name',
          'company_name',
          'company_legal_name',
          'company_contact_name',
        ],
      },
      {
        collection: 'passengers',
      }
    );
    this.setState({
      passengersTypeaheadIndex: [index, flightSegmentIndex],
    });
  }

  handlePassengerSearchClear() {
    this.props.typeaheadsFilterClear({ collection: 'passsengers' });
    this.setState({
      passengersTypeaheadIndex: ['', ''],
    });
  }

  handleAddPassengerModalClicked(index, flightSegmentIndex, label, weight) {
    this.setState({
      addPassenger: {
        show: true,
        index,
        flightSegmentIndex,
        label,
        weight,
      },
    });
  }

  handleAddPassengerModalCancel() {
    this.setState({
      addPassenger: {
        show: false,
        index: '',
        flightSegmentIndex: '',
        label: '',
        weight: 0,
      },
    });
  }

  handleAddPassengerModalSubmit({ firstName, lastName, weight }) {
    const { index, flightSegmentIndex } = this.state.addPassenger;
    const passengerRoleId = _get(
      this.props.roleListQuery.data.find((r) => r.passenger && !r.chargeable),
      'id'
    );
    const args = {
      display_name: _compact([firstName, lastName]).join(' '),
      first_name: firstName,
      last_name: lastName || 'Unknown',
      weight: weight || 0,
      role_ids: [passengerRoleId],
    };
    this.handleAddPassengerClicked(args, index, flightSegmentIndex);
    this.handleAddPassengerModalCancel();
  }

  handleAddPassengerClicked(args, index, flightSegmentIndex) {
    this.props.mutationSet(true);
    const mutationData = { variables: { input: typeInput(args) } };
    return this.props
      .contactCreateMutation(mutationData)
      .then((res) => {
        this.props.mutationSuccess('Passenger create');
        this.handlePassengerChanged(
          index,
          flightSegmentIndex,
          _get(res, 'data.contactCreate.id'),
          args.display_name,
          args.weight
        );
      })
      .catch((err) => this.props.mutationFailure(err, true));
  }

  handlePassengerChanged(index, flightSegmentIndex, passengerId, paxName, weight) {
    const flightSegment = _get(this.props.formValues, [
      'flight_segments_attributes',
      flightSegmentIndex,
    ]);
    const seatAssignment = _get(flightSegment, ['seat_assignments_attributes', index]);
    const currentPassengerId = _defaultTo(parseInt(seatAssignment.passenger_id, 10), '');
    const newPassengerId = _defaultTo(parseInt(passengerId, 10), '');
    const currentPaxName = _defaultTo(seatAssignment.pax_name, '');
    const newPaxName = _defaultTo(paxName, '');
    const currentWeight = _defaultTo(parseInt(seatAssignment.weight, 10), 0);
    let newWeight = _defaultTo(parseInt(weight, 10), 0);
    const field = [
      'flight_segments_attributes',
      flightSegmentIndex,
      'seat_assignments_attributes',
      index,
    ].join('.');
    if (currentPassengerId !== newPassengerId || currentPaxName !== newPaxName) {
      if (currentPassengerId !== newPassengerId) {
        this.props.change(`${field}.passenger_id`, newPassengerId);
      }
      if (currentPaxName !== newPaxName) {
        this.props.change(`${field}.pax_name`, newPaxName);
      }
      if (newPassengerId) {
        const passenger = this.props.passengerListQuery.data.find(
          (p) => p.id === newPassengerId
        );
        newWeight = _get(passenger, 'weight', newWeight);
      }
      if (currentWeight !== newWeight) {
        this.props.change(`${field}.weight`, newWeight);
      }
    }
    this.handlePassengerSearchClear(index);
  }

  handleChargeableSearch(query) {
    this.props.typeaheadsFilter(
      {
        str: query,
        class: 'contacts',
        role: 'chargeable',
        fields: [
          'first_name',
          'last_name',
          'display_name',
          'company_name',
          'company_legal_name',
          'company_contact_name',
        ],
      },
      {
        collection: 'chargeables',
      }
    );
  }

  handleChargeableSearchClear() {
    this.props.typeaheadsFilterClear({ collection: 'chargeables' });
  }

  handleAddFlightSegmentClicked() {
    this.handleAddFlightSegmentModalClicked('last');
  }

  handleAddFlightSegmentModalClicked(index) {
    this.setState({
      addFlightSegment: {
        show: true,
        index,
      },
    });
  }

  handleAddFlightSegmentModalCancel() {
    this.setState({
      addFlightSegment: {
        show: false,
        index: '',
      },
    });
  }

  handleAddFlightSegmentModalSubmit(addType, includePax) {
    const { index } = this.state.addFlightSegment;
    let collectionIndex = index;
    if (collectionIndex === 'last') {
      const values = this.props.formValues.flight_segments_attributes;
      // eslint-disable-next-line no-underscore-dangle
      collectionIndex = _findLastIndex(values, (fs) => !fs._destroy);
    }
    this.handleAddFlightSegmentModalCancel();
    this.handleAddFlightSegment(collectionIndex, addType, includePax);
  }

  handleAddFlightSegment(index, addType, includePax) {
    const values = this.props.formValues.flight_segments_attributes;
    const baseFlightSegment = defaultFlightSegment(
      this.props.currentSettingsBookingCollectionStartDate
    );
    let newIndex = index;
    let newFlightSegment = {};
    if (index > -1) {
      let afterFlightSegment = {};
      let beforeFlightSegment = {};
      let endLocation;
      switch (addType) {
        case 'new':
        // fall through to new below no break
        case 'newBelow':
          newIndex += 1;
          afterFlightSegment = values[index];
          newFlightSegment = Object.assign(
            {},
            baseFlightSegment,
            {
              start_at: afterFlightSegment.end_at,
              end_at: afterFlightSegment.end_at,
              start_location_id: afterFlightSegment.end_location_id,
              end_location_id: '',
            },
            includePax
              ? {
                  seat_assignments_attributes: _cloneDeep(
                    afterFlightSegment.seat_assignments_attributes
                  ).map((sa) => _omit(sa, ['id'])),
                }
              : {}
          );
          break;
        case 'return':
          newIndex += 1;
          afterFlightSegment = values[index];
          // eslint-disable-next-line no-underscore-dangle
          beforeFlightSegment = _first(values.filter((fs) => !fs._destroy));
          newFlightSegment = Object.assign(
            {},
            baseFlightSegment,
            {
              start_at: afterFlightSegment.end_at,
              end_at: afterFlightSegment.end_at,
              start_location_id: afterFlightSegment.end_location_id,
              end_location_id: beforeFlightSegment.start_location_id,
            },
            includePax
              ? {
                  seat_assignments_attributes: _cloneDeep(
                    afterFlightSegment.seat_assignments_attributes
                  ).map((sa) => _omit(sa, ['id'])),
                }
              : {}
          );
          endLocation = this.props.locationListQuery.data.find(
            (l) => l.id === beforeFlightSegment.start_location_id
          );
          break;
        case 'newAbove':
          afterFlightSegment = values[index];
          newFlightSegment = Object.assign(
            {},
            baseFlightSegment,
            {
              start_at: afterFlightSegment.start_at,
              end_at: afterFlightSegment.start_at,
              start_location_id: '',
              end_location_id: afterFlightSegment.start_location_id,
            },
            includePax
              ? {
                  seat_assignments_attributes: _cloneDeep(
                    afterFlightSegment.seat_assignments_attributes
                  ).map((sa) => _omit(sa, ['id'])),
                }
              : {}
          );
          endLocation = this.props.locationListQuery.data.find(
            (l) => l.id === afterFlightSegment.start_location_id
          );
          break;
        default:
          break;
      }
      if (endLocation) {
        const locationLandingFee = _get(endLocation, 'landing_fee');
        newFlightSegment.end_location_landing_fee = locationLandingFee;
        newFlightSegment.end_location_landing_fee_original = locationLandingFee;
        newFlightSegment.oncharge_end_location_landing_fee = !!(
          locationLandingFee && locationLandingFee > 0
        );
      }
    } else {
      newIndex = 0;
      newFlightSegment = baseFlightSegment;
    }
    this.props.array.insert('flight_segments_attributes', newIndex, newFlightSegment);
  }

  handleCriticalLocationModalClicked(index) {
    this.setState({
      addCritical: {
        show: true,
        index,
      },
    });
  }

  handleCriticalLocationModalCancel() {
    this.setState({
      addCritical: {
        show: false,
        index: '',
      },
    });
  }

  handleCriticalLocationModalSubmit(start) {
    const { index } = this.state.addCritical;
    if (start) {
      const currentValue = _get(
        this.props.formValues,
        ['flight_segments_attributes', index, 'start_critical'],
        false
      );
      this.props.change(
        ['flight_segments_attributes', index, 'start_critical'].join('.'),
        !currentValue
      );
    } else {
      const currentValue = _get(
        this.props.formValues,
        ['flight_segments_attributes', index, 'end_critical'],
        false
      );
      this.props.change(
        ['flight_segments_attributes', index, 'end_critical'].join('.'),
        !currentValue
      );
    }
    this.handleCriticalLocationModalCancel();
  }

  handleLocationSearch(query, index, start) {
    this.props.typeaheadsFilter(
      {
        str: query,
        class: 'location',
      },
      {
        collection: 'locations',
      }
    );
    this.setState({
      locationsTypeaheadIndex: [index, start ? 'start' : 'end'],
    });
  }

  handleLocationSearchClear() {
    this.props.typeaheadsFilterClear({ collection: 'locations' });
    this.setState({
      locationsTypeaheadIndex: ['', ''],
    });
  }

  handleAddLocationModalClicked(index, start, label) {
    this.setState({
      addLocation: {
        show: true,
        index,
        start,
        label,
      },
    });
  }

  handleAddLocationModalCancel() {
    this.setState({
      addLocation: {
        show: false,
        index: '',
        start: true,
        label: '',
      },
    });
  }

  handleAddLocationModalSubmit(latitude, longitude) {
    const { start, index, label } = this.state.addLocation;
    const args = {
      long_name: label,
      short_name: label,
      shorthand_name: label.replace(/\s+/g, '').toUpperCase(),
      latitude,
      longitude,
      location_status_id: 1,
    };
    this.handleAddLocationClicked(args, index, start);
    this.handleAddLocationModalCancel();
  }

  handleAddLocationClicked(args, index, start) {
    this.props.mutationSet(true);
    return this.props
      .locationCreateMutation({
        variables: {
          input: typeInput(args),
        },
        update: (store, { data: { locationCreate } }) => {
          const collection = store.readQuery({ query: locationListQuery });
          const newLocation = _merge(
            {
              landing_fee: 0,
              latitude: args.latitude || null,
              longitude: args.longitude || null,
              long_name: args.long_name,
              fullName: args.short_name,
              short_name: args.short_name,
              shorthand_name: args.shorthand_name,
            },
            locationCreate
          );
          const newCollection = [...collection.data, newLocation];
          store.writeQuery({ query: locationListQuery, data: { data: newCollection } });
        },
      })
      .then((res) => {
        this.props.mutationSuccess('Location create');
        if (start) {
          this.handleStartLocationChanged(index, _get(res, 'data.locationCreate.id'));
        } else {
          this.handleEndLocationChanged(index, _get(res, 'data.locationCreate.id'));
        }
      })
      .catch((err) => this.props.mutationFailure(err, true));
  }

  handleStartLocationChanged(index, startLocationId) {
    const values = this.props.formValues.flight_segments_attributes;
    const flightSegment = values[index];
    const currentStartLocationId = _defaultTo(
      parseInt(flightSegment.start_location_id, 10),
      ''
    );
    const newStartLocationId = _defaultTo(parseInt(startLocationId, 10), '');
    if (currentStartLocationId !== newStartLocationId) {
      this.props.change(
        ['flight_segments_attributes', index, 'start_location_id'].join('.'),
        newStartLocationId
      );
      if (newStartLocationId) {
        // eslint-disable-next-line no-underscore-dangle
        const prevFlightSegmentIndex = _findLastIndex(
          _slice(values, 0, index),
          (fs) => !fs._destroy
        );
        if (prevFlightSegmentIndex > -1) {
          this.props.change(
            [
              'flight_segments_attributes',
              prevFlightSegmentIndex,
              'end_location_id',
            ].join('.'),
            newStartLocationId
          );
          const location = this.props.locationListQuery.data.find(
            (l) => l.id === newStartLocationId
          );
          const locationLandingFee = _get(location, 'landing_fee', '');
          this.props.change(
            [
              'flight_segments_attributes',
              prevFlightSegmentIndex,
              'end_location_landing_fee',
            ].join('.'),
            locationLandingFee
          );
          this.props.change(
            [
              'flight_segments_attributes',
              prevFlightSegmentIndex,
              'end_location_landing_fee_original',
            ].join('.'),
            locationLandingFee
          );
          this.props.change(
            [
              'flight_segments_attributes',
              prevFlightSegmentIndex,
              'oncharge_end_location_landing_fee',
            ].join('.'),
            !!(locationLandingFee && locationLandingFee > 0)
          );
        }
      }
    }
    this.handleLocationSearchClear();
  }

  handleEndLocationChanged(index, endLocationId) {
    const values = this.props.formValues.flight_segments_attributes;
    const flightSegment = values[index];
    const currentEndLocationId = _defaultTo(
      parseInt(flightSegment.end_location_id, 10),
      ''
    );
    const newEndLocationId = _defaultTo(parseInt(endLocationId, 10), '');
    if (currentEndLocationId !== newEndLocationId) {
      this.props.change(
        ['flight_segments_attributes', index, 'end_location_id'].join('.'),
        newEndLocationId
      );
      if (newEndLocationId) {
        const location = this.props.locationListQuery.data.find(
          (l) => l.id === newEndLocationId
        );
        const locationLandingFee = _get(location, 'landing_fee', '');
        this.props.change(
          ['flight_segments_attributes', index, 'end_location_landing_fee'].join('.'),
          locationLandingFee
        );
        this.props.change(
          ['flight_segments_attributes', index, 'end_location_landing_fee_original'].join(
            '.'
          ),
          locationLandingFee
        );
        this.props.change(
          ['flight_segments_attributes', index, 'oncharge_end_location_landing_fee'].join(
            '.'
          ),
          !!(locationLandingFee && locationLandingFee > 0)
        );
        // eslint-disable-next-line no-underscore-dangle
        let nextFlightSegmentIndex = _findIndex(
          _slice(values, index + 1),
          (fs) => !fs._destroy
        );
        if (nextFlightSegmentIndex > -1) {
          nextFlightSegmentIndex = nextFlightSegmentIndex + index + 1;
          this.props.change(
            [
              'flight_segments_attributes',
              nextFlightSegmentIndex,
              'start_location_id',
            ].join('.'),
            newEndLocationId
          );
        }
      }
    }
    this.handleLocationSearchClear();
  }

  isOfficeAdmin() {
    return _get(this.props, 'currentContact.office_admin?', false);
  }

  isHobbing() {
    return !!_get(this.props.formValues, 'hobb_record_attributes.flight_time');
  }

  isHobbed() {
    return _get(this.props.bookingQuery, 'data.hobbRecord.id');
  }

  isAudited() {
    return _get(this.props.bookingQuery, 'data.audit_created_at');
  }

  hasAircraft() {
    return _get(this.props.formValues, 'aircraft_id');
  }

  renderFlightSegmentFieldArray(flightSegments) {
    const formKeys = [
      'aircraft_id',
      'cruise_airspeed_sl_knots',
      'flight_segments_attributes',
      'pilot_id',
      'copilot_id',
      'start_at',
    ];
    return (
      <FlightSegmentFieldArray
        containerWidth={this.props.containerWidth}
        updating={this.state.updating}
        change={this.props.change}
        form={this.props.form}
        flightSegments={flightSegments}
        addingPassenger={this.state.addPassenger.show}
        typeaheadFetching={this.props.typeaheadFetching}
        passengersTypeaheadIndex={this.state.passengersTypeaheadIndex}
        passengersTypeaheadCollection={this.props.passengersTypeaheadCollection}
        handlePassengerSearch={this.handlePassengerSearch}
        handleAddPassengerModalClicked={this.handleAddPassengerModalClicked}
        handlePassengerChanged={this.handlePassengerChanged}
        addingLocation={this.state.addLocation.show}
        locationsTypeaheadIndex={this.state.locationsTypeaheadIndex}
        locationsTypeaheadCollection={this.props.locationsTypeaheadCollection}
        handleLocationSearch={this.handleLocationSearch}
        handleAddLocationModalClicked={this.handleAddLocationModalClicked}
        handleAddLocationClicked={this.handleAddLocationClicked}
        handleStartLocationChanged={this.handleStartLocationChanged}
        handleEndLocationChanged={this.handleEndLocationChanged}
        handleCriticalLocationModalClicked={this.handleCriticalLocationModalClicked}
        handleAddFlightSegmentModalClicked={this.handleAddFlightSegmentModalClicked}
        aircrafts={this.props.aircraftListQuery.data}
        aircraftTypes={this.props.aircraftTypeListQuery.data}
        pilots={this.props.pilotListQuery.data}
        bookingPassengerSeats={this.getBookingPassengerSeats()}
        distanceData={this.getFlightSegmentDistanceData()}
        weightData={this.getFlightSegmentWeightData()}
        flighttimeData={this.getFlightSegmentFlighttimeData()}
        fuelData={this.getFlightSegmentCalculatedFuelData()}
        aircraftsDataSelector={this.props.aircraftsDataSelector}
        contactsDataSelector={this.props.contactsDataSelector}
        locationsDataSelector={this.props.locationsDataSelector}
        {..._pick(this.props.formValues, formKeys)}
      />
    );
  }

  renderFlightPlansFieldArray(flightSegments) {
    const formKeys = ['aircraft_id', 'flight_segments_attributes'];
    return (
      <FlightPlans
        change={this.props.change}
        flightSegments={flightSegments}
        distanceData={this.getFlightSegmentDistanceData()}
        weightData={this.getFlightSegmentWeightData()}
        fuelData={this.getFlightSegmentCalculatedFuelData()}
        flighttimeData={this.getFlightSegmentFlighttimeData()}
        locationsDataSelector={this.props.locationsDataSelector}
        {..._pick(this.props.formValues, formKeys)}
      />
    );
  }

  renderPilotFlightLogsFieldArray(pilotFlightLogs) {
    const formKeys = [
      'aircraft_id',
      'pilot_id',
      'copilot_id',
      'pilot_flight_logs_attributes',
      'hobb_record_attributes',
    ];
    return (
      <PilotFlightLogs
        change={this.props.change}
        pilotFlightLogs={pilotFlightLogs}
        formValues={_pick(this.props.formValues, formKeys)}
        isHobbing={this.isHobbing()}
        currentSettingsPilotFlightLogFlightTypes={
          this.props.currentSettingsPilotFlightLogFlightTypes
        }
        aircraftsDataSelector={this.props.aircraftsDataSelector}
        contactsDataSelector={this.props.contactsDataSelector}
      />
    );
  }

  renderChargeablesFieldArray(bookingChargeables) {
    return (
      <ChargeableInputField
        updating={this.state.updating}
        change={this.props.change}
        form={this.props.form}
        chargeables={this.props.chargeableListQuery.data}
        typeaheadFetching={this.props.typeaheadFetching}
        chargeablesTypeaheadCollection={this.props.chargeablesTypeaheadCollection}
        bookingChargeables={bookingChargeables}
        handleChargeableSearch={this.handleChargeableSearch}
        handleChargeableSearchClear={this.handleChargeableSearchClear}
        {..._pick(this.props.formValues, ['booking_chargeables_attributes'])}
      />
    );
  }

  renderChargeablesPaxFieldArray(bookingChargeables) {
    const formKeys = ['booking_chargeables_attributes'];
    return (
      <ChargeablePaxes
        bookingChargeables={bookingChargeables}
        formValues={_pick(this.props.formValues, formKeys)}
        contactsDataSelector={this.props.contactsDataSelector}
      />
    );
  }

  renderFuelBowserFillFieldArray(fuelBowserFills) {
    const formKeys = ['start_at', 'aircraft_id', 'fuel_bowser_fills_attributes'];
    return (
      <FuelBowserFillFieldArray
        change={this.props.change}
        fuelBowserFills={fuelBowserFills}
        fuelDatas={this.getFlightSegmentFuelData()}
        formValues={_pick(this.props.formValues, formKeys)}
        aircrafts={this.props.aircraftListQuery.data}
        currentSettingsFuelBowserFillDefaultQuantityUnit={
          this.props.currentSettingsFuelBowserFillDefaultQuantityUnit
        }
      />
    );
  }

  renderFuelTankerFillFieldArray(fuelTankerFills) {
    const formKeys = ['start_at', 'aircraft_id', 'fuel_tanker_fills_attributes'];
    return (
      <FuelTankerFillFieldArray
        change={this.props.change}
        fuelTankerFills={fuelTankerFills}
        fuelDatas={this.getFlightSegmentFuelData()}
        formValues={_pick(this.props.formValues, formKeys)}
        currentSettingsFuelBowserFillDefaultQuantityUnit={
          this.props.currentSettingsFuelBowserFillDefaultQuantityUnit
        }
      />
    );
  }

  renderLandingChargesFieldArray(flightSegments) {
    const formKeys = ['flight_segments_attributes'];
    return (
      <LandingCharges
        flightSegments={flightSegments}
        locationsDataSelector={this.props.locationsDataSelector}
        formValues={_pick(this.props.formValues, formKeys)}
      />
    );
  }

  renderOilFillsFieldArray(oilFills) {
    const formKeys = ['aircraft_id', 'oil_fills_attributes'];
    return (
      <OilFills
        change={this.props.change}
        oilFills={oilFills}
        aircraftEngines={this.getBookingAircraftEngines()}
        formValues={_pick(this.props.formValues, formKeys)}
        currentSettingsFuelBowserFillDefaultQuantityUnit={
          this.props.currentSettingsFuelBowserFillDefaultQuantityUnit
        }
      />
    );
  }

  renderEngineEventsFieldArray(engineEvents) {
    const formKeys = ['aircraft_id', 'engine_events_attributes'];
    return (
      <EngineEvents
        change={this.props.change}
        engineEvents={engineEvents}
        formValues={_pick(this.props.formValues, formKeys)}
        aircrafts={this.props.aircraftListQuery.data}
        currentSettingsEngineEventTypes={this.props.currentSettingsEngineEventTypes}
      />
    );
  }

  renderPilotFlightExpensesFieldArray(pilotFlightExpenses) {
    const formKeys = ['pilot_id', 'copilot_id', 'pilot_flight_expenses_attributes'];
    return (
      <PilotFlightExpenses
        change={this.props.change}
        pilotFlightExpenses={pilotFlightExpenses}
        formValues={_pick(this.props.formValues, formKeys)}
        contactsDataSelector={this.props.contactsDataSelector}
        currentSettingsPilotFlightExpenseOvernightText={
          this.props.currentSettingsPilotFlightExpenseOvernightText
        }
        currentSettingsPilotFlightExpenseOvernightRate={
          this.props.currentSettingsPilotFlightExpenseOvernightRate
        }
      />
    );
  }

  renderAuditSnapshotTab() {
    return <BookingSnapshot {...this.props.bookingQuery.data.audit_snapshot} />;
  }

  renderPilotFlightLogTab() {
    return (
      <Col sm={12}>
        <Row
          style={{ marginTop: '10px' }}
          className={this.isHobbing() ? 'form-horizontal' : 'hide form-horizontal'}
        >
          <Col sm={4}>
            {this.renderFlightPlanStaticInput(
              'Start At',
              moment(_get(this.props, 'formValues.start_at', '')).format('HH:mm')
            )}
            {this.renderFlightPlanStaticInput(
              'End At',
              moment(_get(this.props, 'formValues.end_at', '')).format('HH:mm')
            )}
            {this.renderFlightPlanStaticInput(
              'Flight Time',
              _get(this.props, 'formValues.hobb_record_attributes.flight_time', '')
            )}
          </Col>
        </Row>
        <Row className={this.isHobbing() ? '' : 'hide'}>
          <FieldArray
            name="pilot_flight_logs_attributes"
            component={this.renderPilotFlightLogsFieldArray}
          />
        </Row>
        <Row style={{ marginTop: '10px' }} className={this.isHobbing() ? 'hide' : ''}>
          <p>Complete the Hobb Report...</p>
        </Row>
      </Col>
    );
  }

  renderAdminFlightRecordTab() {
    const formKeys = [
      'hobb_record_attributes',
      'admin_flight_record_attributes',
      'flight_segments_attributes',
      'fuel_bowser_fills_attributes',
      'fuel_tanker_fills_attributes',
      'pilot_flight_expenses_attributes',
    ];
    const fields = [
      'admin_flight_record_attributes.admin_id',
      'admin_flight_record_attributes.flight_time',
      'admin_flight_record_attributes.flight_summary',
    ];
    return (
      <Fields
        names={fields}
        component={BookingAdminFlightRecordFields}
        formValues={_pick(this.props.formValues, formKeys)}
        fuelBowsersDataSelector={this.props.fuelBowsersDataSelector}
        fuelTankersDataSelector={this.props.fuelTankersDataSelector}
        currentSettingsFuelBowserFillDefaultQuantityUnit={
          this.props.currentSettingsFuelBowserFillDefaultQuantityUnit
        }
        currentContact={this.props.currentContact}
        contactsDataSelector={this.props.contactsDataSelector}
        locationsDataSelector={this.props.locationsDataSelector}
      />
    );
  }

  renderCompanyReserve = (aircraftFuelData) => {
    if (
      _get(aircraftFuelData, 'aircraftTypeCompanyPlannedReserve') &&
      _get(aircraftFuelData, 'aircraftTypeCruiseFuelConsumptionSlKg')
    ) {
      return (
        <span>
          {`Company Reserve (${_get(
            aircraftFuelData,
            'aircraftTypeCompanyPlannedReserve'
          )}mins/${(
            (_get(aircraftFuelData, 'aircraftTypeCruiseFuelConsumptionSlKg') / 60) *
            _get(aircraftFuelData, 'aircraftTypeCompanyPlannedReserve')
          ).toFixed(0)}kg)`}
        </span>
      );
    } else {
      return <span>Compant Reserve</span>;
    }
  };

  renderFlightPlanStaticInput = (label, value) => (
    <FormGroup bsSize="sm">
      <Col componentClass={ControlLabel} sm={8} style={{ paddingTop: '6px' }}>
        {label}
      </Col>
      <Col sm={4}>
        <FormControl.Static>{value}</FormControl.Static>
      </Col>
    </FormGroup>
  );

  renderFlightPlanTab() {
    const bookingWeightData = this.getBookingWeightData();
    const aircraftFuelData = this.getAircraftFuelData();
    const aircraftAirspeedData = this.getAircraftAirspeedData();
    return (
      <Col sm={12}>
        <Row
          style={{ marginTop: '10px' }}
          className={this.hasAircraft() ? 'form-horizontal' : 'hide form-horizontal'}
        >
          <Col sm={4}>
            {this.renderFlightPlanStaticInput(
              'Fuel Capacity',
              `${_get(aircraftFuelData, 'aircraftTypeFuelCapacityKg', '?')}kg`
            )}
            <Field
              type="text"
              name="cruise_airspeed_sl_knots"
              component={InputField}
              blurOnly
              bsSize="sm"
              labelWidth={8}
              inputWidth={4}
              noTab
            >
              {`Cruise Airspeed (${_get(
                aircraftAirspeedData,
                'aircraftTypeCruiseAirspeedSlKnots',
                '?'
              )} knots)`}
            </Field>
            <Field
              type="text"
              name="cruise_fuel_consumption_sl_kg"
              component={InputField}
              blurOnly
              bsSize="sm"
              labelWidth={8}
              inputWidth={4}
              noTab
            >
              {`Fuel Consumption (${_get(
                aircraftFuelData,
                'aircraftTypeCruiseFuelConsumptionSlKg',
                '?'
              )} kg/hr)`}
            </Field>
            <Field
              type="text"
              name="company_planned_reserve"
              component={InputField}
              blurOnly
              bsSize="sm"
              labelWidth={8}
              inputWidth={4}
              noTab
            >
              {this.renderCompanyReserve(aircraftFuelData)}
            </Field>
            {this.renderFlightPlanStaticInput(
              'Dep/Arr Loading',
              `${_get(
                aircraftAirspeedData,
                'aircraftTypeDepartureAndArrivalLoading',
                '?'
              )}mins`
            )}
          </Col>
          <Col sm={4}>
            {this.renderFlightPlanStaticInput(
              'Aircraft Maximum Weight',
              `${_get(bookingWeightData, 'aircraftTypeMaximumAllUpWeight', '?')}kg`
            )}
            {this.renderFlightPlanStaticInput(
              'Aircraft Weight',
              `${_get(bookingWeightData, 'aircraftWeight', '?')}kg`
            )}
            {this.renderFlightPlanStaticInput(
              'Pilot Weight',
              `${_get(bookingWeightData, 'pilotWeight', '?')}kg`
            )}
            {this.renderFlightPlanStaticInput(
              'Copilot Weight',
              `${_get(bookingWeightData, 'copilotWeight', '?')}kg`
            )}
            {this.renderFlightPlanStaticInput(
              'Weight for Payload',
              `${_get(bookingWeightData, 'aircraftGrossWeight', '?')}kg`
            )}
          </Col>
        </Row>
        <Row className={this.hasAircraft() ? '' : 'hide'}>
          <Col sm={12}>
            <Table striped condensed className="small">
              <FlightPlanTableHeader hasAircraft={this.hasAircraft()} />
              <FieldArray
                name="flight_segments_attributes"
                component={this.renderFlightPlansFieldArray}
              />
              <FlightPlanTableFooter
                distanceData={this.getFlightSegmentDistanceData()}
                flighttimeData={this.getFlightSegmentFlighttimeData()}
              />
            </Table>
          </Col>
        </Row>
        <Row style={{ marginTop: '10px' }} className={this.hasAircraft() ? 'hide' : ''}>
          <p>Select an aircraft...</p>
        </Row>
      </Col>
    );
  }

  renderBookingTab() {
    return (
      <Col sm={12}>
        <FlightSegmentAddModal
          {...this.state.addFlightSegment}
          handleAddFlightSegmentSubmit={this.handleAddFlightSegmentModalSubmit}
          handleAddFlightSegmentCancel={this.handleAddFlightSegmentModalCancel}
        />
        <FlightSegmentLocationAddModal
          {...this.state.addLocation}
          handleAddLocationSubmit={this.handleAddLocationModalSubmit}
          handleAddLocationCancel={this.handleAddLocationModalCancel}
        />
        <FlightSegmentLocationCriticalModal
          {...this.state.addCritical}
          handleCriticalLocationSubmit={this.handleCriticalLocationModalSubmit}
          handleCriticalLocationCancel={this.handleCriticalLocationModalCancel}
        />
        <PassengerAddModal
          {...this.state.addPassenger}
          handleAddPassengerSubmit={this.handleAddPassengerModalSubmit}
          handleAddPassengerCancel={this.handleAddPassengerModalCancel}
        />
        <Row style={{ marginTop: '10px' }}>
          <Col sm={4} className="form-horizontal">
            <Row>
              <FieldArray
                name="booking_chargeables_attributes"
                component={this.renderChargeablesFieldArray}
              />
            </Row>
            <Field
              updating={this.state.updating}
              type="text"
              name="provider_id"
              component={ProviderInputField}
              componentClass="select"
              selectOptions={this.props.providerListQuery.data.map((provider) => ({
                id: provider.id,
                name: provider.fullName,
              }))}
              chargeables={this.props.chargeableListQuery.data}
              {..._pick(this.props.formValues, ['booking_chargeables_attributes'])}
            >
              Allocate
            </Field>
            <Field
              type="text"
              name="status"
              bsSize="sm"
              labelWidth={4}
              inputWidth={8}
              noTab
              component={InputField}
              componentClass="select"
              selectOptions={this.props.currentSettingsBookingStatuses.map((status) => ({
                id: status,
                name: status,
              }))}
            >
              Status
            </Field>
            <Field
              updating={this.state.updating}
              type="text"
              name="aircraft_id"
              component={AircraftInputField}
              componentClass="select"
              selectOptions={this.props.aircraftListQuery.data.map((aircraft) => ({
                id: aircraft.id,
                name: aircraft.registration_abbreviated,
              }))}
              providers={this.props.providerListQuery.data}
              formValues={_pick(this.props.formValues, ['provider_id', 'aircraft_id'])}
              noTab={false}
            >
              Aircraft
            </Field>
            <Field
              updating={this.state.updating}
              type="text"
              name="flight_type_id"
              component={FlightTypeInputField}
              componentClass="select"
              chargeables={this.props.chargeableListQuery.data}
              aircrafts={this.props.aircraftListQuery.data}
              flightTypes={this.props.flightTypeListQuery.data}
              {..._pick(this.props.formValues, [
                'booking_chargeables_attributes',
                'aircraft_id',
              ])}
            >
              Flight Type
            </Field>
            <Field
              updating={this.state.updating}
              type="text"
              name="pilot_id"
              component={PilotInputField}
              componentClass="select"
              pilots={this.props.pilotListQuery.data}
              aircrafts={this.props.aircraftListQuery.data}
              aircraftFlightTypePilotMap={this.props.aircraftFlightTypePilotMapQuery.data}
              {..._pick(this.props.formValues, [
                'aircraft_id',
                'flight_type_id',
                'pilot_id',
                'copilot_id',
              ])}
              noTab={false}
            >
              Pilot
            </Field>
            <Field
              updating={this.state.updating}
              type="text"
              name="copilot_id"
              component={CopilotInputField}
              componentClass="select"
              pilots={this.props.pilotListQuery.data}
              aircraftFlightTypePilotMap={this.props.aircraftFlightTypePilotMapQuery.data}
              {..._pick(this.props.formValues, [
                'aircraft_id',
                'flight_type_id',
                'pilot_id',
                'copilot_id',
              ])}
            >
              Copilot/Student
            </Field>
            <Field
              change={this.props.change}
              updating={this.state.updating}
              type="text"
              name="aircraft_configuration_id"
              component={AircraftConfigurationInputField}
              componentClass="select"
              aircrafts={this.props.aircraftListQuery.data}
              aircraftTypes={this.props.aircraftTypeListQuery.data}
              aircraftConfigurations={this.props.aircraftConfigurationListQuery.data}
              {..._pick(this.props.formValues, [
                'aircraft_id',
                'aircraft_configuration_id',
              ])}
            >
              Configuration
            </Field>
          </Col>
          <Col sm={8}>
            <Row className="form-horizontal">
              <Col sm={6}>
                <Field
                  type="text"
                  name="requested_by"
                  component={InputField}
                  bsSize="sm"
                  labelWidth={4}
                  inputWidth={8}
                  noTab
                >
                  Requested By
                </Field>
              </Col>
              <Col sm={6}>
                <Field
                  type="text"
                  name="customer_reference"
                  component={InputField}
                  bsSize="sm"
                  labelWidth={4}
                  inputWidth={8}
                  noTab
                >
                  Cust Reference
                </Field>
              </Col>
            </Row>
            <Row className="form-horizontal">
              <Col sm={8}>
                <Field
                  type="text"
                  name="job_notes"
                  component={InputField}
                  bsSize="sm"
                  labelWidth={3}
                  inputWidth={9}
                  componentClass="textarea"
                  rows={3}
                  noTab
                >
                  Job/
                  <br />
                  Contact Notes
                </Field>
              </Col>
              <Col sm={4}>
                <Field
                  type="text"
                  name="contact_flight_notes"
                  component={InputField}
                  bsSize="sm"
                  labelWidth={0}
                  inputWidth={12}
                  componentClass="textarea"
                  rows={3}
                  noTab
                >
                  Contact Notes
                </Field>
              </Col>
              <Col sm={12}>
                <Field
                  type="text"
                  name="pilot_notes"
                  component={InputField}
                  bsSize="sm"
                  labelWidth={2}
                  inputWidth={10}
                  componentClass="textarea"
                  rows={6}
                  noTab
                >
                  Pilot Notes
                </Field>
              </Col>
              <Col sm={12}>
                <Field
                  type="text"
                  name="public_notes"
                  component={InputField}
                  bsSize="sm"
                  labelWidth={2}
                  inputWidth={10}
                  componentClass="textarea"
                  rows={2}
                  noTab
                >
                  Public Notes
                </Field>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <hr style={{ marginTop: 0 }} />
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Row>
              <Col xs={8}>
                <Field
                  type="text"
                  name="start_at"
                  component={StartDateAndTimeInputFields}
                  {..._pick(this.props.formValues, ['start_at'])}
                >
                  Start Date
                </Field>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <FieldArray
            name="flight_segments_attributes"
            component={this.renderFlightSegmentFieldArray}
          />
        </Row>
        <Row>
          <Col sm={4}>
            <Row>
              <Col xs={8}>
                <Field
                  type="text"
                  name="end_at"
                  component={EndDateAndTimeInputFields}
                  {..._pick(this.props.formValues, ['end_at', 'start_at'])}
                  lastFlightSegmentEndAt={_get(
                    _last(this.getActiveFlightSegments()),
                    'end_at'
                  )}
                >
                  End Date
                </Field>
              </Col>
              <Col xs={4}>
                <FormGroup bsSize="sm" controlId="booking-add-segment">
                  <ControlLabel>&nbsp;</ControlLabel>
                  <FormControl.Static style={{ padding: '0' }}>
                    <OverlayTrigger
                      key="0"
                      placement="top"
                      overlay={<Tooltip id="tooltip-add-leg-booking">Add leg</Tooltip>}
                    >
                      <Button
                        bsStyle="link"
                        onClick={this.handleAddFlightSegmentClicked}
                        tabIndex={-1}
                        style={{ padding: '0' }}
                      >
                        <Glyphicon glyph="plus" />
                      </Button>
                    </OverlayTrigger>
                  </FormControl.Static>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <hr />
          </Col>
        </Row>
        <Row>{this.renderFlightData()}</Row>
      </Col>
    );
  }

  renderFlightData() {
    if (this.isAudited()) {
      return (
        <Col sm={12}>
          {this.renderHobbsReport()}
          {this.renderEngineEvents()}
          {this.renderLandingCharges()}
          {this.renderChargeablePax()}
          {this.renderFuelBowserFills()}
          {this.renderFuelTankerFills()}
          {this.renderOilFills()}
          {this.renderPilotDutyRecords()}
          {this.renderCopilotDutyRecord()}
          {this.renderPilotFlightLogSummary()}
          {this.renderPilotFlightExpenses()}
          {this.renderPilotFlightNote()}
        </Col>
      );
    }
    return undefined;
  }

  renderHobbsReport() {
    if (_has(this.props, 'formValues.aircraft_id')) {
      const fields = [
        'hobb_record_attributes.start_hobb',
        'hobb_record_attributes.end_hobb',
        'hobb_record_attributes.flight_time',
        'hobb_record_attributes.aircraft_id',
      ];
      return (
        <div>
          <Fields
            names={fields}
            component={BookingHobbRecordFields}
            formValues={_pick(this.props.formValues, [
              'aircraft_id',
              'hobb_record_attributes',
            ])}
            aircraftCandidateHobbsDataSelector={
              this.props.aircraftCandidateHobbsDataSelector
            }
          />
          <hr />
        </div>
      );
    }
    return undefined;
  }

  renderLandingCharges() {
    return (
      <div>
        <FieldArray
          name="flight_segments_attributes"
          component={this.renderLandingChargesFieldArray}
        />
        <hr />
      </div>
    );
  }

  renderChargeablePax() {
    if (this.getActiveBookingChargeables().length > 1) {
      return (
        <div>
          <FieldArray
            name="booking_chargeables_attributes"
            component={this.renderChargeablesPaxFieldArray}
          />
          <hr />
        </div>
      );
    }
    return undefined;
  }

  renderFuelBowserFills() {
    return (
      <div>
        <FieldArray
          name="fuel_bowser_fills_attributes"
          component={this.renderFuelBowserFillFieldArray}
        />
        <hr />
      </div>
    );
  }

  renderFuelTankerFills() {
    return (
      <div>
        <FieldArray
          name="fuel_tanker_fills_attributes"
          component={this.renderFuelTankerFillFieldArray}
        />
        <hr />
      </div>
    );
  }

  renderOilFills() {
    if (this.getBookingAircraftEngines().length > 0) {
      return (
        <div>
          <FieldArray
            name="oil_fills_attributes"
            component={this.renderOilFillsFieldArray}
          />
          <hr />
        </div>
      );
    }
    return undefined;
  }

  renderEngineEvents() {
    return (
      <div>
        <FieldArray
          name="engine_events_attributes"
          component={this.renderEngineEventsFieldArray}
        />
        <hr />
      </div>
    );
  }

  renderPilotDutyRecords() {
    if (_get(this.props, 'formValues.pilot_duty_record_attributes.id')) {
      const fields = [
        'pilot_duty_record_attributes.duty_start_at',
        'pilot_duty_record_attributes.duty_end_at',
        'pilot_duty_record_attributes.rest_start_at',
        'pilot_duty_record_attributes.rest_end_at',
      ];
      return (
        <div>
          <Fields
            names={fields}
            parentField="pilot_duty_record_attributes"
            component={BookingPilotDutyRecordFields}
            formValues={_pick(this.props.formValues, [
              'pilot_id',
              'pilot_duty_record_attributes',
            ])}
            contactsDataSelector={this.props.contactsDataSelector}
            title="Pilot Duty Record"
          />
          <hr />
        </div>
      );
    }
    return undefined;
  }

  renderCopilotDutyRecord() {
    if (_get(this.props, 'formValues.copilot_duty_record_attributes.id')) {
      const fields = [
        'copilot_duty_record_attributes.duty_start_at',
        'copilot_duty_record_attributes.duty_end_at',
        'copilot_duty_record_attributes.rest_start_at',
        'copilot_duty_record_attributes.rest_end_at',
      ];
      return (
        <div>
          <Fields
            names={fields}
            parentField="copilot_duty_record_attributes"
            component={BookingPilotDutyRecordFields}
            formValues={_pick(this.props.formValues, [
              'copilot_id',
              'copilot_duty_record_attributes',
            ])}
            contactsDataSelector={this.props.contactsDataSelector}
            title="Copilot Duty Record"
          />
          <hr />
        </div>
      );
    }
    return undefined;
  }

  renderPilotFlightLogSummary() {
    const formKeys = [
      'start_at',
      'end_at',
      'end_location_id',
      'pilot_flight_logs_attributes',
    ];
    return (
      <div>
        <PilotFlightLogSummary
          handleTabClick={this.handleTabClick}
          formValues={_pick(this.props.formValues, formKeys)}
          contactsDataSelector={this.props.contactsDataSelector}
          locationsDataSelector={this.props.locationsDataSelector}
        />
        <hr />
      </div>
    );
  }

  renderPilotFlightExpenses() {
    return (
      <div>
        <FieldArray
          name="pilot_flight_expenses_attributes"
          component={this.renderPilotFlightExpensesFieldArray}
        />
        <hr />
      </div>
    );
  }

  renderPilotFlightNote() {
    if (_has(this.props, 'formValues.pilot_id')) {
      const fields = [
        'pilot_flight_note_attributes.pilot_id',
        'pilot_flight_note_attributes.notes',
      ];
      return (
        <div>
          <Fields
            names={fields}
            component={BookingPilotFlightNoteFields}
            formValues={_pick(this.props.formValues, [
              'pilot_id',
              'pilot_flight_note_attributes',
            ])}
          />
        </div>
      );
    }
    return undefined;
  }

  renderTab1() {
    if (this.isAudited()) {
      return (
        <Tab eventKey={1} title="Original Booking">
          {this.renderAuditSnapshotTab()}
        </Tab>
      );
    }
    return undefined;
  }

  renderTab2() {
    let tab;
    let title;
    if (this.isHobbed()) {
      if (this.isOfficeAdmin()) {
        title = 'Flight Plan';
        tab = this.renderFlightPlanTab;
      } else {
        title = 'Flight Plan';
        tab = () => (
          <Col sm={12} style={{ marginTop: '10px' }}>
            <p>Booking hobb report recorded</p>
          </Col>
        );
      }
    } else if (this.isAudited()) {
      title = 'Flight Plan';
      tab = this.renderFlightPlanTab;
    } else {
      title = 'Booking';
      tab = this.renderBookingTab;
    }

    return (
      <Tab eventKey={2} title={title}>
        {tab()}
      </Tab>
    );
  }

  renderTab3() {
    let tab;
    let title;
    if (this.isHobbed()) {
      if (this.isOfficeAdmin()) {
        title = 'Flight Log';
        tab = this.renderBookingTab;
      } else {
        title = 'Flight Log';
        tab = () => (
          <Col sm={12} style={{ marginTop: '10px' }}>
            <p>Booking hobb report recorded</p>
          </Col>
        );
      }
    } else if (this.isAudited()) {
      title = 'Flight Log';
      tab = this.renderBookingTab;
    } else {
      title = 'Flight Plan';
      tab = this.renderFlightPlanTab;
    }

    return (
      <Tab eventKey={3} title={title}>
        {tab()}
      </Tab>
    );
  }

  renderTab4() {
    if (this.isAudited()) {
      return (
        <Tab eventKey={4} title="Pilot Logs">
          {this.renderPilotFlightLogTab()}
        </Tab>
      );
    }
    return undefined;
  }

  renderTab5() {
    if (this.isAudited() && this.isOfficeAdmin()) {
      return (
        <Tab eventKey={5} title="Invoice">
          {this.renderAdminFlightRecordTab()}
        </Tab>
      );
    }
    return undefined;
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (
      isInitialisedBookingForm &&
      this.isLoaded() &&
      (!this.state.updating || _get(this.props, 'formValues.id'))
    ) {
      const { handleSubmit, submitting, error } = this.props;

      return (
        <Form className="form form-booking" onSubmit={handleSubmit(this.handleSubmit)}>
          <BookingTitle
            updating={this.state.updating}
            reference={_get(this.props, 'bookingQuery.data.reference')}
            startAt={_get(this.props, 'bookingQuery.data.start_at')}
            calendarType={_get(this.props, 'formValues.calendar_type')}
          />
          {error && <strong>{error}</strong>}
          <Tabs
            id="new-booking-booking"
            activeKey={this.state.tabKey}
            onSelect={this.handleTabClick}
          >
            {this.renderTab1()}
            {this.renderTab2()}
            {this.renderTab3()}
            {this.renderTab4()}
            {this.renderTab5()}
          </Tabs>
          <Row>
            <Col sm={12}>
              <hr />
            </Col>
          </Row>
          <FormGroup>
            <Col sm={12}>
              {this.state.updating && (
                <div className="pull-left">
                  <BookingCreators
                    contactsDataSelector={this.props.contactsDataSelector}
                    {..._pick(_get(this.props.bookingQuery, 'data', {}), [
                      'created_by_admin_id',
                      'created_at',
                      'updated_by_admin_id',
                      'updated_at',
                    ])}
                  />
                </div>
              )}
              <div className="pull-right">
                <ButtonToolbar>
                  <ButtonGroup>
                    <LinkContainer to={this.props.currentSettingsReturnRoute}>
                      <Button type="reset" bsStyle="danger" disabled={submitting}>
                        Cancel
                      </Button>
                    </LinkContainer>
                  </ButtonGroup>
                  <ButtonGroup>
                    <Button type="submit" bsStyle="primary" disabled={submitting}>
                      {submitting && (
                        <span className="glyphicon glyphicon-refresh glyphicon-spin" />
                      )}{' '}
                      {this.state.updating ? 'Update' : 'Create'}
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </div>
            </Col>
          </FormGroup>
        </Form>
      );
    }
    return undefined;
  }

  render() {
    return (
      <div>
        {this.renderOverlay()}
        {this.renderData()}
      </div>
    );
  }
}

const bookingWhiteList = [
  'admin_completed_at',
  'admin_completed_by_admin_id',
  'aircraft_configuration_id',
  'aircraft_id',
  'aircraft_provider_id',
  'audit_booking_id',
  'audit_created_by_admin_id',
  'calendar_type',
  'company_planned_reserve',
  'consumption_adj',
  'contact_flight_notes',
  'copilot_duty_record_id',
  'copilot_id',
  'cruise_adj',
  'cruise_airspeed_sl_knots',
  'cruise_fuel_consumption_sl_kg',
  'customer_reference',
  'end_at',
  'end_location_id',
  'flight_type_id',
  'id',
  'job_notes',
  'pilot_duty_record_id',
  'pilot_id',
  'pilot_notes',
  'provider_id',
  'public_notes',
  'requested_by',
  'reserve_adj',
  'start_at',
  'start_location_id',
  'status',
];

const flightSegmentWhiteList = [
  'airspeed_adj',
  'booking_id',
  'distance_adj',
  'end_at',
  'end_critical',
  'end_location_id',
  'end_location_landing_fee',
  'end_location_landing_fee_original',
  'id',
  'luggage_weight',
  'manual_start_fuel_adj',
  'oncharge_end_location_landing_fee',
  'start_at',
  'start_critical',
  'start_fuel_adj',
  'start_location_id',
];

const seatAssignmentWhiteList = [
  'flight_segment_id',
  'id',
  'passenger_id',
  'pax_name',
  'weight',
];

function validate() {
  return {};
}

function pickInitialValues(
  bookingQueryInitial,
  currentSettingsBookingCollectionStartDate,
  updating
) {
  if (!isInitialisedBookingForm) {
    if (!updating) {
      isInitialisedBookingForm = true;
      return defaultBooking(currentSettingsBookingCollectionStartDate);
    } else if (queryReady(bookingQueryInitial)) {
      isInitialisedBookingForm = true;

      const bookingQueryInitialData = _cloneDeep(bookingQueryInitial.data);
      const booking = pickValues(bookingQueryInitialData, bookingWhiteList);

      booking.flight_segments_attributes = _get(
        bookingQueryInitialData,
        'flightSegments'
      ).map((flightSegment) => {
        const newFlightSegment = pickValues(flightSegment, flightSegmentWhiteList);
        let newSeatAssignments = mapPickValues(
          _cloneDeep(flightSegment.seatAssignments),
          seatAssignmentWhiteList
        );
        newSeatAssignments = _defaults(
          newSeatAssignments,
          Array(9).fill(defaultSeatAssignment())
        );
        return Object.assign({}, newFlightSegment, {
          start_fuel_adj: {
            adjustment: flightSegment.start_fuel_adj,
            manual: flightSegment.manual_start_fuel_adj,
            auto: false,
          },
          seat_assignments_attributes: newSeatAssignments,
        });
      });

      booking.pilot_flight_logs_attributes = _get(
        bookingQueryInitialData,
        'pilotFlightLogs'
      ).map((pilotFlightLog) =>
        _mergeWith(
          omitValues(pilotFlightLog),
          { flight_types: omitValues(pilotFlightLog.flight_types, ['id', '__typename']) },
          (objValue, srcValue, key) => {
            if (key === 'flight_types') {
              return srcValue;
            }
            return undefined;
          }
        )
      );

      booking.admin_flight_record_attributes = omitValues(
        _get(bookingQueryInitialData, 'adminFlightRecord')
      );
      booking.booking_chargeables_attributes = mapOmitValues(
        _get(bookingQueryInitialData, 'bookingChargeables')
      );
      booking.copilot_duty_record_attributes = omitValues(
        _get(bookingQueryInitialData, 'copilotDutyRecord')
      );
      booking.engine_events_attributes = mapOmitValues(
        _get(bookingQueryInitialData, 'engineEvents')
      );
      booking.fuel_bowser_fills_attributes = mapOmitValues(
        _get(bookingQueryInitialData, 'fuelBowserFills')
      );
      booking.fuel_tanker_fills_attributes = mapOmitValues(
        _get(bookingQueryInitialData, 'fuelTankerFills')
      );
      booking.hobb_record_attributes = omitValues(
        _get(bookingQueryInitialData, 'hobbRecord')
      );
      booking.oil_fills_attributes = mapOmitValues(
        _get(bookingQueryInitialData, 'oilFills')
      );
      booking.pilot_duty_record_attributes = omitValues(
        _get(bookingQueryInitialData, 'pilotDutyRecord')
      );
      booking.pilot_flight_expenses_attributes = mapOmitValues(
        _get(bookingQueryInitialData, 'pilotFlightExpenses')
      );
      booking.pilot_flight_note_attributes = omitValues(
        _get(bookingQueryInitialData, 'pilotFlightNote')
      );

      return booking;
    }
  }
  return undefined;
}

function mapStateToProps(state, props) {
  const initialValues = pickInitialValues(
    props.bookingQuery,
    state.currentSettings.bookingCollectionStartDate,
    !!props.params.id
  );
  return {
    initialValues,
    currentSettingsBookingCollectionStartDate:
      state.currentSettings.bookingCollectionStartDate,
    currentContact: state.currentContact,
    typeaheadFetching: state.typeaheads.fetching,
    chargeablesTypeaheadCollection: state.typeaheads.chargeables,
    locationsTypeaheadCollection: state.typeaheads.locations,
    passengersTypeaheadCollection: state.typeaheads.passengers,
    currentSettingsBookingStatuses: state.currentSettings.booking_statuses,
    currentSettingsMutating: state.currentSettings.mutating,
    currentSettingsReturnRoute: state.currentSettings.returnRoute,
    currentSettingsFuelBowserFillDefaultQuantityUnit:
      state.currentSettings.fuel_bowser_fill_default_quantity_unit,
    currentSettingsPilotFlightExpenseOvernightText:
      state.currentSettings.pilot_flight_expense_overnight_text,
    currentSettingsPilotFlightExpenseOvernightRate:
      state.currentSettings.pilot_flight_expense_overnight_rate,
    currentSettingsEngineEventTypes: state.currentSettings.engine_event_types,
    currentSettingsPilotFlightLogFlightTypes:
      state.currentSettings.pilot_flight_log_flight_types,
    aircraftsDataSelector: aircraftsData(props),
    aircraftCandidateHobbsDataSelector: aircraftCandidateHobbsData(props),
    fuelBowsersDataSelector: fuelBowsersData(props),
    fuelTankersDataSelector: fuelTankersData(props),
    locationsDataSelector: locationsData(props),
    contactsDataSelector: contactsData(props),
    formValues: getFormValues('FlightForm')(state),
    containerWidth: state.currentSettings.containerWidth,
  };
}

export default compose(
  graphql(bookingCreateMutation, {
    name: 'bookingCreateMutation',
  }),
  graphql(bookingUpdateMutation, {
    name: 'bookingUpdateMutation',
  }),
  graphql(locationCreateMutation, {
    name: 'locationCreateMutation',
  }),
  graphql(contactCreateMutation, {
    name: 'contactCreateMutation',
  }),
  graphql(aircraftListQuery, {
    name: 'aircraftListQuery',
  }),
  graphql(aircraftConfigurationListQuery, {
    name: 'aircraftConfigurationListQuery',
  }),
  graphql(aircraftEngineListQuery, {
    name: 'aircraftEngineListQuery',
  }),
  graphql(aircraftTypeListQuery, {
    name: 'aircraftTypeListQuery',
  }),
  graphql(aircraftCandidateHobbListQuery, {
    name: 'aircraftCandidateHobbListQuery',
  }),
  graphql(aircraftFlightTypePilotMapQuery, {
    name: 'aircraftFlightTypePilotMapQuery',
  }),
  graphql(contactItemListQuery, {
    name: 'chargeableListQuery',
    options: { variables: { role: 'chargeable' } },
  }),
  graphql(contactItemListQuery, {
    name: 'providerListQuery',
    options: { variables: { role: 'provider' } },
  }),
  graphql(contactItemListQuery, {
    name: 'pilotListQuery',
    options: { variables: { role: 'pilot' } },
  }),
  graphql(contactItemListQuery, {
    name: 'passengerListQuery',
    options: { variables: { role: 'passenger' } },
  }),
  graphql(contactItemListQuery, {
    name: 'employeeListQuery',
    options: { variables: { role: 'employee' } },
  }),
  graphql(flightTypeListQuery, {
    name: 'flightTypeListQuery',
  }),
  graphql(fuelTankerListQuery, {
    name: 'fuelTankerListQuery',
  }),
  graphql(fuelBowserListQuery, {
    name: 'fuelBowserListQuery',
  }),
  graphql(fuelTypeListQuery, {
    name: 'fuelTypeListQuery',
  }),
  graphql(locationListQuery, {
    name: 'locationListQuery',
  }),
  graphql(roleListQuery, {
    name: 'roleListQuery',
  }),
  graphql(bookingQuery, {
    name: 'bookingQuery',
    skip: (props) => !props.params.id,
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'network-only',
    }),
  }),
  connect(mapStateToProps, {
    mutationSuccess,
    mutationFailure,
    mutationSet,
    typeaheadsFilter,
    typeaheadsFilterClear,
  }),
  reduxForm({
    form: 'FlightForm',
    validate,
  })
)(FlightForm);
