import { Component } from 'react';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { Row, Col, Table, Button } from 'react-bootstrap';
import debounce from 'lodash/debounce';

import pilotFlightLogExceptionListQuery from '../queries/pilot_flight_log_exception_list_query';

import { currentSettingsSet } from '../actions/current_setting_actions';

import { queriesReady } from '../lib/utils';
import Loader from '../components/loader';
import ReportHeader from '../components/report_header';

class ReportPilotFlightLogException extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    this.delayedHandleRefetch = debounce((e) => {
      this.props.pilotFlightLogExceptionListQuery.refetch();
    }, 250);
  }

  componentWillUnmount() {
    this.delayedHandleRefetch.cancel();
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(props.pilotFlightLogExceptionListQuery);
  }

  _renderHeaderRow() {
    return (
      <thead>
        <tr>
          <th>Reference</th>
          <th>Date</th>
          <th>Exception</th>
        </tr>
      </thead>
    );
  }

  _renderRow(pilot_flight_log) {
    const { id, booking_id, booking_start_at_s, booking_reference, exception } =
      pilot_flight_log;

    return (
      <tr key={id}>
        <td>
          <LinkContainer
            style={{ display: 'block', textAlign: 'left' }}
            to={`/flights/${booking_id}/edit`}
          >
            <Button bsStyle="link" bsSize="xsmall">
              {`#${booking_reference}`}
            </Button>
          </LinkContainer>
        </td>
        <td>{booking_start_at_s}</td>
        <td>{exception}</td>
      </tr>
    );
  }

  _renderExceptions() {
    return (
      <Row>
        <Col xs={12}>
          <Table striped>
            {this._renderHeaderRow()}
            <tbody>
              {this.props.pilotFlightLogExceptionListQuery.data.map(
                (pilot_flight_log, index) => this._renderRow(pilot_flight_log)
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  }

  render() {
    if (this.isLoading()) {
      return <Loader />;
    }
    return (
      <div>
        <ReportHeader title="Flight Log Exception Report" />
        {this._renderExceptions()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

ReportPilotFlightLogException = compose(
  graphql(pilotFlightLogExceptionListQuery, {
    name: 'pilotFlightLogExceptionListQuery',
    options: { fetchPolicy: 'cache-and-network' },
  })
)(ReportPilotFlightLogException);

export default connect(mapStateToProps, { currentSettingsSet })(
  ReportPilotFlightLogException
);
