import {
  Row,
  Col,
  FormGroup,
  FormControl,
  HelpBlock,
  Button,
  Checkbox,
} from 'react-bootstrap';
import { Component } from 'react';

import defaultTo from 'lodash/defaultTo';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';

import BlurOnlyFormControl from '../form/blur_only_form_control';
import { validationStates, validationText } from '../form/helpers';

class BookingPilotFlightExpenseFields extends Component {
  constructor(props) {
    super(props);
    this._handleDeleteButtonClick = this._handleDeleteButtonClick.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.pilotIds, nextProps.pilotIds)) {
      const pilotId = defaultTo(parseInt(get(nextProps, 'formValues.pilot_id')), '');
      if (pilotId && !includes(nextProps.pilotIds, pilotId)) {
        const input = get(nextProps, `${nextProps.parentField}pilot_id.input`);
        input.onChange('');
      }
    }
  }

  _handleDeleteButtonClick() {
    const id = get(this.props, 'formValues.id', '');
    if (id) {
      const input = get(this.props, `${this.props.parentField}._destroy.input`);
      input.onChange(true);
    } else if (this.props.handleDeleteButtonClick) {
      this.props.handleDeleteButtonClick(this.props.index);
    }
  }

  render() {
    const {
      pilot_id: { input: pilotIDInput, meta: pilotIDMeta },
      notes: { input: notesInput, meta: notesMeta },
      total: { input: totalInput, meta: totalMeta },
      includes_gst: { input: includesGstInput, meta: includesGstMeta },
      onchargeable: { input: onchargeableInput, meta: onchargeableMeta },
      reimbursable: { input: reimbursableInput, meta: reimbursableMeta },
    } = get(this.props, this.props.parentField);

    return (
      <Row className="form-horizontal">
        <Col sm={12}>
          <FormGroup
            bsSize="sm"
            controlId={pilotIDInput.name}
            validationState={validationStates([pilotIDMeta, notesMeta, totalMeta])}
          >
            <Col sm={2}>
              <FormControl
                type="text"
                componentClass="select"
                tabIndex="-1"
                {...pilotIDInput}
              >
                <option key={0} value="">
                  {}
                </option>
                {this.props.selectOptions.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </FormControl>
              <HelpBlock>{validationText(pilotIDMeta)}</HelpBlock>
            </Col>
            <Col sm={2}>
              <BlurOnlyFormControl
                type="text"
                {...notesInput}
                disabled={this.props.overNight}
              />
              <HelpBlock>{validationText(notesMeta)}</HelpBlock>
            </Col>
            <Col sm={2}>
              <BlurOnlyFormControl type="text" {...totalInput} />
              <HelpBlock>{validationText(totalMeta)}</HelpBlock>
            </Col>
            <Col sm={1}>
              <Checkbox
                style={{ paddingTop: 0 }}
                {...includesGstInput}
                checked={includesGstInput.value}
                tabIndex={-1}
              />
              <HelpBlock>{validationText(includesGstMeta)}</HelpBlock>
            </Col>
            <Col sm={1}>
              <Checkbox
                style={{ paddingTop: 0 }}
                {...onchargeableInput}
                checked={onchargeableInput.value}
                tabIndex={-1}
              />
              <HelpBlock>{validationText(onchargeableMeta)}</HelpBlock>
            </Col>
            <Col sm={1}>
              <Checkbox
                style={{ paddingTop: 0 }}
                {...reimbursableInput}
                checked={reimbursableInput.value}
                tabIndex={-1}
              />
              <HelpBlock>{validationText(reimbursableMeta)}</HelpBlock>
            </Col>
            <Col sm={1}>
              <Button
                style={{ padding: 0 }}
                type="button"
                bsStyle="link"
                bsSize="small"
                onClick={this._handleDeleteButtonClick}
                tabIndex={-1}
              >
                del...
              </Button>
            </Col>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default BookingPilotFlightExpenseFields;
