import { Row, Col, Table, Form, FormGroup, FormControl, Button } from 'react-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { LinkContainer } from 'react-router-bootstrap';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import accounting from 'accounting';

import _compact from 'lodash/compact';
import _debounce from 'lodash/debounce';
import _groupBy from 'lodash/groupBy';

import { currentSettingsSet } from '../actions/current_setting_actions';

import ReactDateTimeFilter from '../components/form/react_date_time_filter';
import Loader from '../components/loader';
import ReportHeader from '../components/report_header';

import { queriesReady } from '../lib/utils';

import contactItemListQuery from '../queries/contact_item_list_query';
import pilotDutyRecordListQuery from '../queries/pilot_duty_record_list_query';

const moment = extendMoment(Moment);
moment.updateLocale('en-nz');

class ReportPilotDutyRecord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterPilotId: this.props.currentSettingsReportPilotId,
      filterPilotIds: [],
      // overnight: false,
      // onchargeable: false,
      // reimbursable: false,
    };
    this.handleDisplayPilotIdChange = this.handleDisplayPilotIdChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.startAtDate) {
      const date = moment(this.props.params.startAtDate, 'MM-YYYY');
      this.props.currentSettingsSet({
        reportStart: date.clone().startOf('day').toISOString(),
        reportEnd: date.clone().endOf('month').toISOString(),
      });
    }
    if (this.props.params.pilotId) {
      this.handleDisplayPilotIdChange({
        target: {
          value: parseInt(this.props.params.pilotId, 10),
        },
      });
    }
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    this.delayedHandleRefetch = _debounce(() => {
      this.props.pilotDutyRecordListQuery.refetch();
    }, 250);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { filterPilotIds } = this.state;
    if (this.isLoaded(nextProps)) {
      filterPilotIds = nextProps.pilotDutyRecordListQuery.data.map(
        (data) => data.pilot_id
      );
    }
    this.setState({
      filterPilotIds,
    });
    let filterPilotId = nextProps.currentSettingsReportPilotId;
    if (filterPilotId && this.isLoaded(nextProps)) {
      if (filterPilotIds.length > 0 && filterPilotIds.indexOf(filterPilotId) === -1) {
        filterPilotId = '';
      }
    }
    if (filterPilotId !== this.props.currentSettingsReportPilotId) {
      this.handleDisplayPilotIdChange({ target: { value: filterPilotId } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleRefetch.cancel();
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(testProps.pilotDutyRecordListQuery, testProps.pilotListQuery);
  }

  handleDisplayPilotIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value, 10))
      ? ''
      : parseInt(e.target.value, 10);
    this.setState({
      filterPilotId: value,
    });
    this.props.currentSettingsSet({
      reportPilotId: value,
    });
  }

  renderBookingCell = (bookings) => (
    // console.log(bookings)
    <span style={{ display: 'block' }}>
      {bookings.map((booking) => {
        const { id, flightSegmentSummaries } = booking;
        // console.log(flightSegmentSummaries)
        return flightSegmentSummaries.map((flightSegmentSummary) => {
          const { id, start_at: startAt } = flightSegmentSummary;
          // console.log(startAt)
          return <span key={id}>{startAt}</span>;
        });
      })}
    </span>
  );

  renderRow = (dutyRecord) => {
    // console.log(dutyRecord)
    const {
      id,
      duty_start_at: dutyStartAt,
      duty_end_at: dutyEndAt,
      rest_start_at: restStartAt,
      rest_end_at: restEndAt,
      pilotBookings,
      copilotBookings,
    } = dutyRecord;
    // console.log(pilotBookings)
    // console.log(copilotBookings)

    return (
      <tr key={id}>
        <td>
          <LinkContainer
            style={{ display: 'block', textAlign: 'left' }}
            to={`/flights/${id}/edit`}
          >
            <Button bsStyle="link" bsSize="xsmall">
              {`#${id}`}
            </Button>
          </LinkContainer>
        </td>
        <td>{moment(dutyStartAt).format('DD/MM/YY')}</td>
        <td>{moment(dutyStartAt).format('HH:mm')}</td>
        <td>{restStartAt ? moment(restStartAt).format('HH:mm') : '-'}</td>
        <td>{restEndAt ? moment(restEndAt).format('HH:mm') : '-'}</td>
        <td>{moment(dutyEndAt).format('HH:mm')}</td>
        <td>
          {this.renderBookingCell(_compact([...pilotBookings, ...copilotBookings]))}
        </td>
      </tr>
    );
  };

  renderRows(dutyRecords) {
    return <tbody>{dutyRecords.map((dutyRecord) => this.renderRow(dutyRecord))}</tbody>;
  }

  renderHeaderRow = () => (
    <thead>
      <tr>
        <th>Id</th>
        <th>Duty Date</th>
        <th>Duty Start</th>
        <th>Rest Start</th>
        <th>Rest End</th>
        <th>Duty End</th>
        <th width="50%">Bookings</th>
      </tr>
    </thead>
  );

  renderFooterRow = (pilotFlightExpenses, pilotFullName) => {
    const total = pilotFlightExpenses.reduce(
      (sum, expense) => sum + expense.total_excl_gst,
      0
    );
    return (
      <tfoot>
        <tr>
          <th colSpan={5} />
          <th className="text-right">{`Total expenses for ${pilotFullName}:`}</th>
          <th className="text-right">{accounting.formatMoney(total)}</th>
        </tr>
      </tfoot>
    );
  };

  renderPilots() {
    const pilotDutyRecords = _groupBy(
      this.props.pilotDutyRecordListQuery.data.filter((data) => {
        if (this.state.filterPilotId && data.pilot_id !== this.state.filterPilotId) {
          return false;
        }
        return true;
      }),
      'pilot_id'
    );
    return (
      <Row>
        <Col xs={12}>
          {Object.keys(pilotDutyRecords).map((pilotId) => {
            const pilot = this.props.pilotListQuery.data.find(
              (model) => model.id === parseInt(pilotId, 10)
            );
            if (pilot) {
              const pilotFullName = pilot.fullName;
              const records = pilotDutyRecords[pilotId];
              return (
                <Row key={pilotId} style={{ marginBottom: '20px' }}>
                  <Col xs={12}>
                    <h4>{pilotFullName}</h4>
                  </Col>
                  <Col xs={12}>
                    <Table striped>
                      {this.renderHeaderRow()}
                      {this.renderRows(records)}
                      {/* this.renderFooterRow(filteredExpenses, pilotFullName) */}
                    </Table>
                  </Col>
                </Row>
              );
            } else {
              return '';
            }
          })}
        </Col>
      </Row>
    );
  }

  renderPilotFilter() {
    const dataPilots = this.props.pilotListQuery.data.filter(
      (model) => this.state.filterPilotIds.indexOf(model.id) > -1
    );
    return (
      <FormControl
        componentClass="select"
        value={this.state.filterPilotId}
        onChange={this.handleDisplayPilotIdChange}
      >
        <option key={0} value="">
          All
        </option>
        {dataPilots.map((model) => {
          const { id, fullName } = model;
          return (
            <option key={id} value={id}>
              {fullName}
            </option>
          );
        })}
      </FormControl>
    );
  }

  render() {
    if (this.isLoading()) {
      return <Loader />;
    }
    return (
      <div>
        <Row>
          <Col sm={12}>
            <div className="clearfix">
              <div className="pull-left">
                <ReportHeader
                  title="Pilot Duty Records Audit"
                  start={this.props.currentSettingsReportStart}
                  end={this.props.currentSettingsReportEnd}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: '10px' }}>
          <Col xs={12}>
            <Form inline>
              <Button
                style={{ margin: '0', padding: '0 0 8px 0' }}
                bsStyle="link"
                bsSize="xsmall"
                onClick={this.delayedHandleRefetch}
              >
                <span className="glyphicon glyphicon-repeat" />
              </Button>
              <ReactDateTimeFilter
                currentSettingsReportStart={this.props.currentSettingsReportStart}
                currentSettingsReportEnd={this.props.currentSettingsReportEnd}
                onChange={this.props.currentSettingsSet}
              />
              <FormGroup
                style={{ marginLeft: '16px', paddingBottom: '10px' }}
                bsSize="sm"
                controlId="filterId"
              >
                {this.renderPilotFilter()}
              </FormGroup>
            </Form>
          </Col>
        </Row>
        {this.renderPilots()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsReportPilotId: state.currentSettings.reportPilotId,
    currentSettingsReportStart: state.currentSettings.reportStart,
    currentSettingsReportEnd: state.currentSettings.reportEnd,
  };
}

export default compose(
  connect(mapStateToProps, { currentSettingsSet }),
  graphql(contactItemListQuery, {
    name: 'pilotListQuery',
    options: { variables: { role: 'pilot' } },
  }),
  graphql(pilotDutyRecordListQuery, {
    name: 'pilotDutyRecordListQuery',
    options: (props) => ({
      variables: {
        startAt: props.currentSettingsReportStart,
        endAt: props.currentSettingsReportEnd,
      },
      fetchPolicy: 'cache-and-network',
    }),
  })
)(ReportPilotDutyRecord);
