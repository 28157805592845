import actionTypes from '../constants';

export const initialState = {
  isAuthenticated: false,
  isAuthenticating: false,
  isEmployee: false,
  token: '',
  contact_id: '',
};

function resetSuccess() {
  return initialState;
}

function resetFailure() {
  return initialState;
}

function fetch(state) {
  return {
    ...state,
    isAuthenticating: true,
  };
}

function loadSuccess(state, payload) {
  return {
    ...state,
    isAuthenticating: false,
    isAuthenticated: true,
    isEmployee: payload.isEmployee,
    token: payload.token,
    contact_id: payload.contact_id,
    tenant: payload.tenant,
  };
}

function loadFailure() {
  return initialState;
}

export default function authReducer(state = initialState, action) {
  const { type, payload, error } = action;
  if (error) {
    return state;
  }
  switch (type) {
    case actionTypes.AUTH_FETCH:
      return fetch(state);
    case actionTypes.AUTH_LOAD_SUCCESS:
      return loadSuccess(state, payload);
    case actionTypes.AUTH_LOAD_FAILURE:
      return loadFailure();
    case actionTypes.AUTH_RESET_SUCCESS:
      return resetSuccess();
    case actionTypes.AUTH_RESET_FAILURE:
      return resetFailure();
    default:
      return state;
  }
}
