import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import moment from 'moment';
import { LinkContainer } from 'react-router-bootstrap';

import {
  Row,
  Col,
  Panel,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Table,
} from 'react-bootstrap';
import last from 'lodash/last';
import Confirm from '../components/confirm';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import checkQuery from '../queries/check_query';
import checkDeleteMutation from '../mutations/check_delete_mutation';

import { queriesReady, getExport } from '../lib/utils';
import Loader from '../components/loader';

moment.updateLocale('en-nz');

class CheckShow extends Component {
  constructor(props) {
    super(props);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
  }

  getStatus = (model) => {
    const {
      check_start_on: checkStartOn,
      check_end_on: checkEndOn,
      check_complete_on: checkCompleteOn,
    } = model;
    if (checkCompleteOn) {
      return 'Completed';
    } else if (moment(checkEndOn).isBefore(moment())) {
      return 'Overdue';
    } else if (moment(checkStartOn).isBefore(moment())) {
      return 'Due now';
    } else {
      return 'Not due';
    }
  };

  displayBoolean = (attribute) => {
    if (attribute) {
      return 'Yes';
    } else {
      return 'No';
    }
  };

  getExport = (e) => {
    this.props.mutationSet(true);
    const reportName = e.target.getAttribute('data-report-name');
    const exportType = e.target.getAttribute('data-export-type');
    const displayName = e.target.getAttribute('data-display-name');
    getExport(reportName, {}, null, exportType, displayName)
      .then(() => {
        this.props.mutationSet(false);
      })
      .catch((err) => this.props.mutationFailure(err));
  };

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(testProps.checkQuery);
  }

  handleDeleteClick(e) {
    this.props.mutationSet(true);
    const checkId = last(e.currentTarget.id.split('-'));
    this.props
      .checkDeleteMutation({
        variables: {
          id: checkId,
        },
      })
      .then(() => {
        this.props.mutationSuccess('Check delete');
        this.props.navigate('/checks');
      })
      .catch((err) => this.props.mutationFailure(err));
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (this.isLoaded()) {
      const model = this.props.checkQuery.data;
      const {
        id,
        checkType: {
          name: checkTypeName,
          critical: checkTypeCritical,
          lead_period_days: checkTypeLeadPeriodDays,
          rollover_period_months: checkTypeRolloverPeriodMonths,
        },
        checkCategory: { name: checkCategoryName },
        checkable: { fullName: checkableFullName },
        manager: { fullName: managerFullName },
        check_start_on: checkStartOn,
        check_end_on: checkEndOn,
        check_complete_on: checkCompleteOn,
        documents,
      } = model;

      const status = this.getStatus(model);

      return (
        <div>
          <Row>
            <Col sm={12}>
              <div className="clearfix">
                <div className="pull-left">
                  <h3>{`Check Details for #${id}`}</h3>
                </div>
                <div className="pull-right">
                  <ButtonToolbar>
                    <ButtonGroup>
                      {this.props.currentContact['manager?'] && (
                        <Confirm
                          confirmId={`check-delete-${id}`}
                          disabled={status === 'Completed'}
                          onConfirm={this.handleDeleteClick}
                          title="Delete Contact"
                          body="Are you sure you want to delete this Check?"
                          confirmText="Confirm"
                        >
                          <Button bsStyle="primary">Delete</Button>
                        </Confirm>
                      )}
                      <LinkContainer to="/checks">
                        <Button bsStyle="primary">All Checks</Button>
                      </LinkContainer>
                    </ButtonGroup>
                  </ButtonToolbar>
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col sm={6}>
              <Row>
                <Col xs={12}>
                  <Panel>
                    <Panel.Heading>Check Details</Panel.Heading>
                    <Panel.Body>
                      <dl className="dl-horizontal">
                        <dt>Category</dt>
                        <dd>{checkCategoryName}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Type</dt>
                        <dd>{checkTypeName}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Checkable</dt>
                        <dd>{checkableFullName}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Responsible</dt>
                        <dd>{managerFullName}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Critical</dt>
                        <dd>{this.displayBoolean(checkTypeCritical)}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Check Start On</dt>
                        <dd>{moment(checkStartOn).format('DD/MM/YYYY')}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Check End On</dt>
                        <dd>{moment(checkEndOn).format('DD/MM/YYYY')}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Check Complete On</dt>
                        <dd>{checkCompleteOn}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Status</dt>
                        <dd>{status}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Rollover Period (months)</dt>
                        <dd>{checkTypeRolloverPeriodMonths}</dd>
                      </dl>
                      <dl className="dl-horizontal">
                        <dt>Lead Period (days)</dt>
                        <dd>{checkTypeLeadPeriodDays}</dd>
                      </dl>
                    </Panel.Body>
                  </Panel>
                </Col>
              </Row>
            </Col>
            <Col sm={6}>
              <Row>
                <Col xs={12}>
                  <Panel header="Documents">
                    <Panel.Heading>Documents</Panel.Heading>
                    <Panel.Body>
                      <Table hover className="vertical-align">
                        <thead>
                          <tr>
                            <th>Document</th>
                            <th>Uploader</th>
                            <th>Date</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {documents &&
                            documents.map((document) => {
                              const {
                                id: documentId,
                                document_file_name: documentFileName,
                                uploaded_at: uploadedAt,
                                uploader,
                                description,
                              } = document;
                              const documentFileExtension = documentFileName
                                ? documentFileName.split('.').pop()
                                : 'unknown';
                              return (
                                <tr key={id}>
                                  <td>{description}</td>
                                  <td>{uploader ? uploader.display_name : '-'}</td>
                                  <td>
                                    {uploadedAt ? moment(uploadedAt).format('lll') : '-'}
                                  </td>
                                  <td>
                                    <Button
                                      bsStyle="link"
                                      bsSize="xsmall"
                                      data-report-name={`documents/${documentId}`}
                                      data-display-name={documentFileName}
                                      data-export-type={documentFileExtension}
                                      onClick={this.getExport}
                                    >
                                      {documentFileExtension === 'pdf'
                                        ? 'view'
                                        : 'download'}
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </Panel.Body>
                  </Panel>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      );
    }
    return undefined;
  }

  render() {
    return (
      <div>
        {this.renderOverlay()}
        {this.renderData()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentContact: state.currentContact,
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default compose(
  graphql(checkDeleteMutation, {
    name: 'checkDeleteMutation',
  }),
  graphql(checkQuery, {
    name: 'checkQuery',
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  connect(mapStateToProps, { mutationSuccess, mutationFailure, mutationSet })
)(CheckShow);
