import { Row, Col, Button } from 'react-bootstrap';
import { Component } from 'react';
import { Fields } from 'redux-form';

import get from 'lodash/get';
import has from 'lodash/has';
import pick from 'lodash/pick';

import BookingEngineEventFields from './booking_engine_event_fields';

class EngineEvents extends Component {
  constructor(props) {
    super(props);
    this._handleAddButtonClick = this._handleAddButtonClick.bind(this);
    this._handleDeleteButtonClick = this._handleDeleteButtonClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (
      has(this.props, 'formValues.engine_events_attributes') &&
      get(this.props, 'formValues.engine_events_attributes', []).length === 0
    ) {
      const engineEvents = [];
      const defaultEngineEvents = ['start', 'landing'];
      defaultEngineEvents.forEach((event) => {
        engineEvents.push({
          aircraft_id: this.props.formValues.aircraft_id,
          event_type: event,
          event_value: 0,
        });
      });
      this.props.change('engine_events_attributes', engineEvents);
    }
  }

  _handleAddButtonClick() {
    this.props.engineEvents.fields.push({
      aircraft_id: this.props.formValues.aircraft_id,
      event_value: 0,
    });
  }

  _handleDeleteButtonClick(index) {
    this.props.engineEvents.fields.remove(index);
  }

  _renderEngineEventsList() {
    return (
      <Col sm={12}>
        {this.props.engineEvents.fields.map((field, index) => {
          const values = get(this.props, `formValues.${field}`, {});
          if (!values._destroy) {
            const fields = [
              `${field}._destroy`,
              `${field}.aircraft_id`,
              `${field}.event_value`,
              `${field}.event_type`,
            ];
            return (
              <Fields
                key={`of-${index}`}
                index={index}
                parentField={field}
                names={fields}
                component={BookingEngineEventFields}
                formValues={pick(values, [
                  'id',
                  'event_value',
                  'event_type',
                  'aircraft_id',
                ])}
                handleDeleteButtonClick={this._handleDeleteButtonClick}
                bookingAircraftId={get(this.props, 'formValues.aircraft_id', '')}
                aircrafts={this.props.aircrafts}
                currentSettingsEngineEventTypes={
                  this.props.currentSettingsEngineEventTypes
                }
              />
            );
          }
        })}
      </Col>
    );
  }

  _renderHeader() {
    return (
      <Col sm={12}>
        <Row>
          <Col sm={2}>
            <label>Aircraft</label>
          </Col>
          <Col sm={2}>
            <label>Event Quantity</label>
          </Col>
          <Col sm={2}>
            <label>Event Type</label>
          </Col>
          <Col sm={2} />
        </Row>
      </Col>
    );
  }

  _renderEngineEvents() {
    return (
      <Row>
        {this._renderHeader()}
        {this._renderEngineEventsList()}
      </Row>
    );
  }

  render() {
    const eventsLength = get(
      this.props,
      'formValues.engine_events_attributes',
      []
    ).filter((ff) => !ff._destroy).length;
    return (
      <Row>
        <Col sm={2}>
          <p>
            <strong>Engine Events</strong>
          </p>
        </Col>
        <Col sm={10}>
          {eventsLength > 0 && this._renderEngineEvents()}
          <Row>
            <Col sm={12}>
              <Button
                style={{ paddingTop: 0, paddingLeft: 0 }}
                type="button"
                bsStyle="link"
                bsSize="small"
                onClick={this._handleAddButtonClick}
                tabIndex={-1}
                disabled={!this.props.formValues.aircraft_id}
              >
                add...
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default EngineEvents;
