import { Col, FormGroup, Checkbox, ControlLabel, HelpBlock } from 'react-bootstrap';
import { Component } from 'react';

import get from 'lodash/get';

import BlurOnlyFormControl from '../form/blur_only_form_control';
import { validationState, validationText } from '../form/helpers';

class FlightSignoffFormLandingChargeFields extends Component {
  _renderControlLabel() {
    return get(
      this.props.locationsDataSelector,
      [this.props.formValues.end_location_id, 'longName'],
      'Select an end location'
    );
  }

  render() {
    const {
      end_location_landing_fee: {
        input: endLocationLandingFeeInput,
        meta: endLocationLandingFeeMeta,
      },
      oncharge_end_location_landing_fee: {
        input: onchargeEndLocationLandingFeeInput,
        meta: onchargeEndLocationLandingFeeMeta,
      },
    } = get(this.props, this.props.parentField);

    return (
      <Col sm={12} className="form-horizontal">
        <FormGroup
          style={{ marginBottom: '5px' }}
          bsSize="sm"
          controlId={endLocationLandingFeeInput.name}
          validationState={validationState(endLocationLandingFeeMeta)}
        >
          <Col componentClass={ControlLabel} sm={3}>
            {this._renderControlLabel()}
          </Col>
          <Col sm={3}>
            <BlurOnlyFormControl type="text" {...endLocationLandingFeeInput} />
            <HelpBlock>{validationText(endLocationLandingFeeMeta)}</HelpBlock>
          </Col>
          <Col sm={3}>
            <Checkbox
              inline
              {...onchargeEndLocationLandingFeeInput}
              checked={onchargeEndLocationLandingFeeInput.value}
            >
              Oncharge
            </Checkbox>
            <HelpBlock>{validationText(onchargeEndLocationLandingFeeMeta)}</HelpBlock>
          </Col>
        </FormGroup>
      </Col>
    );
  }
}

export default FlightSignoffFormLandingChargeFields;
