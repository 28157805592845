import {
  Col,
  Form,
  FormGroup,
  Button,
  ButtonToolbar,
  ButtonGroup,
} from 'react-bootstrap';
import { Component } from 'react';

import { compose } from 'redux';

import { reduxForm, Field } from 'redux-form';

import Validator from 'validatorjs';

import _set from 'lodash/set';

import InputField from '../form/input_field';

class SmsControlCreateFormModal extends Component {
  render() {
    const {
      handleCreateSubmit,
      handleCreateCancel,
      handleSubmit,
      pristine,
      submitting,
      valid,
    } = this.props;

    return (
      <Form horizontal onSubmit={handleSubmit(handleCreateSubmit)}>
        <Field type="text" name="description" component={InputField}>
          Description
        </Field>
        <FormGroup>
          <Col sm={12}>
            <div className="pull-right">
              <ButtonToolbar>
                <ButtonGroup>
                  <Button
                    type="reset"
                    bsStyle="danger"
                    disabled={submitting}
                    onClick={handleCreateCancel}
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
                <ButtonGroup>
                  <Button
                    type="submit"
                    bsStyle="primary"
                    disabled={pristine || submitting || !valid}
                  >
                    Create
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </div>
          </Col>
        </FormGroup>
      </Form>
    );
  }
}

const validationAliases = {
  // 'description': 'Description',
};

const validationRules = {
  description: 'required',
};

const validationCustomMessages = {};

function validate(data, props) {
  const validator = new Validator(data, validationRules, validationCustomMessages);
  validator.setAttributeNames(validationAliases);
  validator.passes();
  const errors = validator.errors.all();
  const result = {};
  Object.keys(errors).forEach((key) => {
    _set(result, key, errors[key].join(' '));
  });
  return result;
}

export default compose(
  reduxForm({
    validate,
  })
)(SmsControlCreateFormModal);
