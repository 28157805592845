import { Tooltip, OverlayTrigger, Button, Glyphicon } from 'react-bootstrap';
import { Component } from 'react';
import moment from 'moment';

import compact from 'lodash/compact';
import get from 'lodash/get';
import map from 'lodash/map';
import slice from 'lodash/slice';

import Confirm from '../confirm';

moment.updateLocale('en-nz');

class FlightSegmentPanelHeader extends Component {
  constructor(props) {
    super(props);
    this._handleToggleClick = this._handleToggleClick.bind(this);
    this._handleCriticalClick = this._handleCriticalClick.bind(this);
    this._handleDeleteClick = this._handleDeleteClick.bind(this);
    this._handleAddClick = this._handleAddClick.bind(this);
  }

  _handleToggleClick() {
    if (this.props.handleToggleClicked) {
      this.props.handleToggleClicked();
    }
  }

  _handleCriticalClick(e) {
    e.stopPropagation();
    if (this.props.handleCriticalLocationModalClicked) {
      this.props.handleCriticalLocationModalClicked(this.props.index);
    }
  }

  _handleDeleteClick(e) {
    e.stopPropagation();
    if (this.props.handleDeleteClicked) {
      this.props.handleDeleteClicked(this.props.index);
    }
  }

  _handleAddClick(e) {
    e.stopPropagation();
    if (this.props.handleAddFlightSegmentModalClicked) {
      this.props.handleAddFlightSegmentModalClicked(this.props.index);
    }
  }

  _styleAlertFuel() {
    const style = {};
    if (this.props.alertFuelOver) {
      style.color = 'blue';
    } else if (this.props.alertFuelUnder) {
      style.color = 'red';
    } else {
      style.color = '#777777';
    }
    return style;
  }

  _renderPanelTools() {
    const { index } = this.props;

    const fuelTip = (
      <Tooltip id={`tooltip-fuel-${index}`}>
        Fuel alert - blue over & red - under{' '}
      </Tooltip>
    );
    const criticalTip = (
      <Tooltip id={`tooltip-critical-${index}`}>Set critical start or end</Tooltip>
    );
    const deleteTip = <Tooltip id={`tooltip-delete-${index}`}>Delete leg</Tooltip>;
    const addTip = <Tooltip id={`tooltip-add-${index}`}>Add leg</Tooltip>;
    return (
      <span>
        <OverlayTrigger key="0" placement="top" overlay={fuelTip}>
          <Button
            bsStyle="link"
            style={{ color: '#777777', padding: '0 0 0 3px' }}
            tabIndex={-1}
          >
            <Glyphicon style={this._styleAlertFuel()} glyph="tint" />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger key="1" placement="top" overlay={criticalTip}>
          <Button
            bsStyle="link"
            style={{ color: '#777777', padding: '0 0 0 3px' }}
            onClick={this._handleCriticalClick}
            tabIndex={-1}
          >
            <Glyphicon glyph="exclamation-sign" />
          </Button>
        </OverlayTrigger>
        <Confirm
          title="Delete Flight Segment"
          onConfirm={this._handleDeleteClick}
          body="Delete this flight segment?"
          confirmText="Confirm"
          style={{ color: '#777777', padding: '0 0 0 3px' }}
          tip={deleteTip}
          stopPropagation
        >
          <Button bsStyle="link" tabIndex={-1}>
            <Glyphicon glyph="trash" />
          </Button>
        </Confirm>
        <OverlayTrigger key="3" placement="top" overlay={addTip}>
          <Button
            bsStyle="link"
            style={{ color: '#777777', padding: '0 0 0 3px' }}
            onClick={this._handleAddClick}
            tabIndex={-1}
          >
            <Glyphicon glyph="plus" />
          </Button>
        </OverlayTrigger>
      </span>
    );
  }

  _renderPanelSeats() {
    const { seat_assignments_attributes: seatAssignmentsAttributes } = this.props;
    const passengerNames = compact(
      slice(
        map(seatAssignmentsAttributes, 'pax_name'),
        0,
        this.props.bookingPassengerSeats
      )
    ).join(', ');
    if (passengerNames) {
      return (
        <span style={{ marginLeft: '20px' }}>
          <small>
            <Glyphicon style={{ paddingRight: '5px' }} glyph="user" />
            {passengerNames}
          </small>
        </span>
      );
    }
  }

  _renderPanelPilot() {
    const { bookingPilotId, bookingAircraftId, aircraftsDataSelector } = this.props;

    let bookingPilotDisplayName = '';
    let bookingAircraftRegistrationAbbreviated = '';

    if (bookingPilotId) {
      bookingPilotDisplayName = get(this.props.contactsDataSelector, [
        bookingPilotId,
        'fullName',
      ]);
    }
    if (bookingAircraftId) {
      bookingAircraftRegistrationAbbreviated = get(this.props.aircraftsDataSelector, [
        bookingAircraftId,
        'registrationAbbreviated',
      ]);
    }
    if (bookingPilotDisplayName && bookingAircraftRegistrationAbbreviated) {
      return (
        <span>{`(${bookingAircraftRegistrationAbbreviated} - ${bookingPilotDisplayName})`}</span>
      );
    } else if (bookingPilotDisplayName || bookingAircraftRegistrationAbbreviated) {
      return (
        <span>
          {'('}
          {bookingPilotDisplayName || bookingAircraftRegistrationAbbreviated}
          {')'}
        </span>
      );
    }
  }

  _renderPanelLocations() {
    const { start_location_id: startLocationId, end_location_id: endLocationId } =
      this.props;

    let startLocationLongName = '';
    if (startLocationId) {
      startLocationLongName = get(this.props.locationsDataSelector, [
        startLocationId,
        'longName',
      ]);
    }
    let endLocationLongName = '';
    if (endLocationId) {
      endLocationLongName = get(this.props.locationsDataSelector, [
        endLocationId,
        'longName',
      ]);
    }
    if (startLocationLongName || endLocationLongName) {
      return (
        <span>
          {startLocationLongName || '?'}
          {' - '}
          {endLocationLongName || '?'}
        </span>
      );
    }
  }

  render() {
    const {
      activeIndex,
      isXs,
      isMd,
      start_at: startAt,
      start_critical: startCritical,
    } = this.props;

    return (
      <div {...(!isMd && { onClick: this._handleToggleClick })}>
        <span>
          <span style={{ float: 'left' }}>
            {isXs && <span>{`Leg${parseInt(activeIndex + 1)}: `}</span>}
            <span style={{ backgroundColor: startCritical ? 'yellow' : '' }}>
              {startAt && (
                <span>
                  {moment(startAt).format('HHmm')}
                  &nbsp;
                </span>
              )}
            </span>
            {this._renderPanelLocations()}
            {this._renderPanelSeats()}
          </span>
          <span style={{ float: 'right' }}>
            {this._renderPanelPilot()}
            {this._renderPanelTools()}
          </span>
        </span>
        <div className="clearfix" />
      </div>
    );
  }
}

export default FlightSegmentPanelHeader;
