import {
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  ButtonToolbar,
  ButtonGroup,
} from 'react-bootstrap';
import { Component } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { reduxForm, Field, getFormValues } from 'redux-form';

import { LinkContainer } from 'react-router-bootstrap';

import Validator from 'validatorjs';

import _cloneDeep from 'lodash/cloneDeep';
import _forOwn from 'lodash/forOwn';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _indexOf from 'lodash/indexOf';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import _nth from 'lodash/nth';
import _pick from 'lodash/pick';
import _omitBy from 'lodash/omitBy';
import _set from 'lodash/set';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import { currentSettingsSet } from '../actions/current_setting_actions';

import InputField from '../components/form/input_field';

import SmsControlCreateModal from '../components/sms/sms_control_create_modal';
import SmsControlListTable from '../components/sms/sms_control_list_table';
import SmsActionCreateModal from '../components/sms/sms_action_create_modal';

import smsRiskQuery from '../queries/sms_risk_query';
import smsRiskListQuery from '../queries/sms_risk_list_query';

import contactItemListQuery from '../queries/contact_item_list_query';

import smsRiskUpdateMutation from '../mutations/sms_risk_update_mutation';
import smsRiskCreateMutation from '../mutations/sms_risk_create_mutation';

import smsControlCreateMutation from '../mutations/sms_control_create_mutation';
import smsControlDeleteMutation from '../mutations/sms_control_delete_mutation';

import smsActionCreateMutation from '../mutations/sms_action_create_mutation';

import { queryReady, queriesReady, typeInput } from '../lib/utils';
import Loader from '../components/loader';

let isInitialisedSmsRiskForm = false;

class SmsRiskForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: !!this.props.params.id,
      isControlCreating: false,
      isActionCreating: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleCreateControlClicked = this.handleCreateControlClicked.bind(this);
    this.handleCreateControlCancel = this.handleCreateControlCancel.bind(this);
    this.handleCreateControlSubmit = this.handleCreateControlSubmit.bind(this);

    this.handleDeleteControlClicked = this.handleDeleteControlClicked.bind(this);

    this.handleCreateActionClick = this.handleCreateActionClick.bind(this);
    this.handleCreateActionCancel = this.handleCreateActionCancel.bind(this);
    this.handleCreateActionSubmit = this.handleCreateActionSubmit.bind(this);
  }

  componentDidMount() {
    this.props.currentSettingsSet({
      returnRouteSmsControl: this.props.location.pathname,
    });
  }

  componentDidUpdate(prevProps) {
    if (!_isEqual(prevProps.formValues, this.props.formValues)) {
      let riskLevel = '';
      let riskGroup = '';
      if (
        this.props.formValues &&
        this.props.formValues.consequence &&
        this.props.formValues.likelihood
      ) {
        const likelihoodIndex = _indexOf(
          this.props.currentSettingsSmsRiskLikelihoods,
          this.props.formValues.likelihood
        );
        const consequenceIndex = _indexOf(
          this.props.currentSettingsSmsRiskConsequences,
          this.props.formValues.consequence
        );
        riskLevel = `${likelihoodIndex + 1}${_nth(
          ['E', 'D', 'C', 'B', 'A'],
          consequenceIndex
        )}`;
        _forOwn(this.props.currentSettingsSmsRiskMatrix, (values, key) => {
          if (riskGroup) {
            return false;
          }
          if (_indexOf(values, riskLevel) > -1) {
            riskGroup = key;
          }
          return true;
        });
      }
      this.props.change('riskLevel', riskLevel);
      this.props.change('riskGroup', riskGroup);
    }
  }

  componentWillUnmount() {
    isInitialisedSmsRiskForm = false;
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(testProps.managerListQuery, testProps.employeeListQuery, [
      testProps.smsRiskQuery,
      true,
    ]);
  }

  handleSubmit(data) {
    const submitData = _cloneDeep(data);

    this.props.mutationSet(true);
    let mutation;
    let mutationMessage;
    const mutationData = {
      variables: {
        input: typeInput(submitData),
      },
      refetchQueries: () => [{ query: smsRiskListQuery }],
    };
    if (this.state.updating) {
      mutation = this.props.smsRiskUpdateMutation;
      mutationMessage = 'Risk update';
      mutationData.variables.id = this.props.params.id;
    } else {
      mutation = this.props.smsRiskCreateMutation;
      mutationMessage = 'Risk create';
    }
    return mutation(mutationData)
      .then(() => {
        this.props.mutationSuccess(mutationMessage);
        this.props.navigate(this.props.currentSettingsReturnRouteSmsRisk);
      })
      .catch((err) => this.props.mutationFailure(err, true));
  }

  handleCreateControlClicked() {
    this.setState({ isControlCreating: true });
  }

  handleCreateControlCancel() {
    this.setState({ isControlCreating: false });
  }

  handleCreateControlSubmit(data) {
    const submitData = _cloneDeep(data);
    this.setState({ isControlCreating: false });
    this.props.mutationSet(true);
    submitData.riskId = this.props.params.id;
    return this.props
      .smsControlCreateMutation({
        variables: { input: typeInput(submitData) },
      })
      .then(() => {
        this.props.mutationSuccess('Control create');
        this.props.smsRiskQuery.refetch();
      })
      .catch((err) => this.props.mutationFailure(err));
  }

  handleDeleteControlClicked(controlId) {
    this.props.mutationSet(true);
    return this.props
      .smsControlDeleteMutation({
        variables: { id: controlId },
      })
      .then(() => {
        this.props.mutationSuccess('Control deleted');
        this.props.smsRiskQuery.refetch();
      })
      .catch((err) => this.props.mutationFailure(err));
  }

  handleCreateActionClick(controlId) {
    this.setState({ isActionCreating: controlId });
  }

  handleCreateActionCancel() {
    this.setState({ isActionCreating: false });
  }

  handleCreateActionSubmit(data) {
    const submitData = _cloneDeep(data);
    submitData.controlId = this.state.isActionCreating;
    this.setState({ isActionCreating: false });
    this.props.mutationSet(true);
    return this.props
      .smsActionCreateMutation({
        variables: { input: typeInput(submitData) },
      })
      .then(() => {
        this.props.mutationSuccess('Action create');
        this.props.smsRiskQuery.refetch();
      })
      .catch((err) => this.props.mutationFailure(err));
  }

  renderTitle() {
    if (this.state.updating) {
      return 'Edit Risk';
    } else {
      return 'New Risk';
    }
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (
      isInitialisedSmsRiskForm &&
      this.isLoaded() &&
      _get(this.props, 'formValues') &&
      (!this.state.updating || _get(this.props, 'formValues.id'))
    ) {
      const { handleSubmit, pristine, submitting, valid } = this.props;

      return (
        <div>
          <SmsControlCreateModal
            isCreating={this.state.isControlCreating}
            handleCreateCancel={this.handleCreateControlCancel}
            handleCreateSubmit={this.handleCreateControlSubmit}
          />
          <SmsActionCreateModal
            isCreating={!!this.state.isActionCreating}
            handleCreateCancel={this.handleCreateActionCancel}
            handleCreateSubmit={this.handleCreateActionSubmit}
            managers={this.props.managerListQuery.data}
            employees={this.props.employeeListQuery.data}
          />
          <Form horizontal onSubmit={handleSubmit(this.handleSubmit)}>
            <h2>{this.renderTitle()}</h2>
            <fieldset>
              <legend>Details</legend>
              <Field
                type="text"
                name="description"
                component={InputField}
                componentClass="textarea"
                rows={4}
              >
                Description
              </Field>
              <Field
                type="text"
                name="consequence"
                inputWidth={3}
                noTab
                component={InputField}
                componentClass="select"
                defaultSelectOption={false}
                selectOptions={this.props.currentSettingsSmsRiskConsequences.map((c) => ({
                  id: c,
                  name: c,
                }))}
              >
                Consequence
              </Field>
              <Field
                type="text"
                name="likelihood"
                inputWidth={3}
                noTab
                component={InputField}
                componentClass="select"
                defaultSelectOption={false}
                selectOptions={this.props.currentSettingsSmsRiskLikelihoods.map((l) => ({
                  id: l,
                  name: l,
                }))}
              >
                Likelihood
              </Field>
              <FormGroup>
                <Col componentClass={ControlLabel} sm={3}>
                  Risk Level
                </Col>
                <Col sm={9}>
                  <FormControl.Static>
                    {this.props.formValues.riskLevel || '-'}
                  </FormControl.Static>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col componentClass={ControlLabel} sm={3}>
                  Risk Group
                </Col>
                <Col sm={3}>
                  <FormControl.Static>
                    {this.props.formValues ? this.props.formValues.riskGroup || '-' : '-'}
                  </FormControl.Static>
                </Col>
              </FormGroup>
            </fieldset>
            {this.state.updating &&
              this.props.currentContact['manager?'] &&
              _includes(this.props.currentContact.statutory_role_ids, 2) && (
                <fieldset>
                  <legend>Controls</legend>
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={3}>
                      Assigned Controls
                    </Col>
                    <Col sm={9}>
                      <SmsControlListTable
                        controls={this.props.smsRiskQuery.data.controls}
                        handleDeleteClick={this.handleDeleteControlClicked}
                        handleCreateActionClick={this.handleCreateActionClick}
                        withDelete
                        withEdit
                        withCreateAction
                      />
                      <ButtonGroup>
                        <Button
                          // style={{margin: '0', padding: '0 0 8px 0'}}
                          bsStyle="link"
                          // bsSize='xsmall'
                          onClick={this.handleCreateControlClicked}
                        >
                          Create a new control...
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </FormGroup>
                </fieldset>
              )}
            <FormGroup>
              <Col sm={12}>
                <div className="pull-right">
                  <ButtonToolbar>
                    <ButtonGroup>
                      <LinkContainer to={this.props.currentSettingsReturnRouteSmsRisk}>
                        <Button type="reset" bsStyle="danger" disabled={submitting}>
                          Cancel
                        </Button>
                      </LinkContainer>
                    </ButtonGroup>
                    <ButtonGroup>
                      <Button
                        type="submit"
                        bsStyle="primary"
                        disabled={pristine || submitting || !valid}
                      >
                        {submitting ? (
                          <span className="glyphicon glyphicon-refresh glyphicon-spin" />
                        ) : (
                          ''
                        )}{' '}
                        {this.state.updating ? 'Update' : 'Create'}
                      </Button>
                    </ButtonGroup>
                  </ButtonToolbar>
                </div>
              </Col>
            </FormGroup>
          </Form>
        </div>
      );
    }
    return undefined;
  }

  render() {
    return (
      <div>
        {this.renderOverlay()}
        {this.renderData()}
      </div>
    );
  }
}

const validationAliases = {};

const validationRules = {
  description: 'required',
  consequence: 'required',
  likelihood: 'required',
};

const validationCustomMessages = {};

function validate(data) {
  const validator = new Validator(data, validationRules, validationCustomMessages);
  validator.setAttributeNames(validationAliases);
  validator.passes();
  const errors = validator.errors.all();
  const result = {};
  Object.keys(errors).forEach((key) => {
    _set(result, key, errors[key].join(' '));
  });
  return result;
}

const attributeWhiteList = [
  'id',
  'description',
  'consequence',
  'likelihood',
  'riskLevel',
  'riskGroup',
];

function pickInitialValues(smsRiskQueryInitial, updating) {
  if (!isInitialisedSmsRiskForm) {
    if (!updating) {
      isInitialisedSmsRiskForm = true;
      return {
        description: '',
        consequence: 'moderate',
        likelihood: 'remote',
        riskGroup: 'medium',
        riskLevel: '3C',
      };
    } else if (queryReady(smsRiskQueryInitial)) {
      isInitialisedSmsRiskForm = true;
      const editSmsRisk = _pick(
        _omitBy(_cloneDeep(smsRiskQueryInitial.data), _isNil),
        attributeWhiteList
      );
      return editSmsRisk;
    }
  }
  return undefined;
}

function mapStateToProps(state, props) {
  const initialValues = pickInitialValues(props.smsRiskQuery, !!props.params.id);
  return {
    initialValues,
    currentContact: state.currentContact,
    currentSettingsReturnRouteSmsRisk: state.currentSettings.returnRouteSmsRisk,
    currentSettingsMutating: state.currentSettings.mutating,
    currentSettingsSmsRiskConsequences: state.currentSettings.sms_risk_consequences,
    currentSettingsSmsRiskLikelihoods: state.currentSettings.sms_risk_likelihoods,
    currentSettingsSmsRiskMatrix: state.currentSettings.sms_risk_matrix,
    formValues: getFormValues('SmsRiskForm')(state),
  };
}

export default compose(
  graphql(smsRiskCreateMutation, {
    name: 'smsRiskCreateMutation',
  }),
  graphql(smsRiskUpdateMutation, {
    name: 'smsRiskUpdateMutation',
  }),
  graphql(smsControlCreateMutation, {
    name: 'smsControlCreateMutation',
  }),
  graphql(smsControlDeleteMutation, {
    name: 'smsControlDeleteMutation',
  }),
  graphql(smsActionCreateMutation, {
    name: 'smsActionCreateMutation',
  }),
  graphql(contactItemListQuery, {
    name: 'managerListQuery',
    options: { variables: { role: 'manager' } },
  }),
  graphql(contactItemListQuery, {
    name: 'employeeListQuery',
    options: { variables: { role: 'employee' } },
  }),
  graphql(smsRiskQuery, {
    name: 'smsRiskQuery',
    skip: (props) => !props.params.id,
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'network-only',
    }),
  }),
  connect(mapStateToProps, {
    mutationSuccess,
    mutationFailure,
    mutationSet,
    currentSettingsSet,
  }),
  reduxForm({
    form: 'SmsRiskForm',
    validate,
  })
)(SmsRiskForm);
