import {
  Row,
  Col,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
} from 'react-bootstrap';
import { Component } from 'react';
import moment from 'moment';
import DateTime from 'react-datetime';

import { validationState, validationText } from '../form/helpers';

moment.updateLocale('en-nz');

class StartDateAndTimeInputFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: '',
      time: '',
      dateError: {},
      timeError: {},
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.start_at) {
      this.receiveStartAt();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.start_at !== nextProps.start_at) {
      this.receiveStartAt(nextProps);
    }
  }

  handleDateChange(date) {
    if (moment.isMoment(date)) {
      date = date.format('DD/MM/YYYY');
      this.setState({
        date,
        dateError: {},
      });
      const { time, timeError } = this.state;
      if (!timeError.invalid) {
        this.props.input.onChange(moment(`${date} ${time}`, 'DD/MM/YYYY HHmm').format());
      }
    } else {
      this.setState({
        date,
        dateError: { touched: true, invalid: true, error: 'DD/MM/YYYY' },
      });
    }
  }

  handleTimeChange(e) {
    let time = e.target.value;
    time = time === '2400' ? '2359' : time;
    if (time.match(/^(0[0-9]|1[0-9]|2[0-3])[0-5][0-9]$/)) {
      this.setState({
        time,
        timeError: {},
      });
      const { date, dateError } = this.state;
      if (!dateError.invalid) {
        this.props.input.onChange(moment(`${date} ${time}`, 'DD/MM/YYYY HHmm').format());
      }
    } else {
      this.setState({
        time,
        timeError: { touched: true, invalid: true, error: 'HHMM' },
      });
    }
  }

  receiveStartAt(props = this.props) {
    this.setState({
      date: moment(props.start_at).format('DD/MM/YYYY'),
      time: moment(props.start_at).format('HHmm'),
    });
  }

  render() {
    const { input, bsSize } = this.props;

    return (
      <Row>
        <Col xs={6}>
          <FormGroup
            controlId={`${input.name}-date`}
            validationState={validationState(this.state.dateError)}
            {...(bsSize && { bsSize })}
          >
            <ControlLabel>{this.props.children}</ControlLabel>
            <DateTime
              value={this.state.date}
              dateFormat="DD/MM/YYYY"
              timeFormat={false}
              closeOnSelect
              onChange={this.handleDateChange}
              inputProps={{ tabIndex: '-1' }}
            />
            <HelpBlock>{validationText(this.state.dateError)}</HelpBlock>
          </FormGroup>
        </Col>
        {this.props.withoutTime || (
          <Col xs={6}>
            <FormGroup
              controlId={`${input.name}-time`}
              validationState={validationState(this.state.timeError)}
              {...(bsSize && { bsSize })}
            >
              <ControlLabel>Time</ControlLabel>
              <FormControl
                type="text"
                value={this.state.time}
                onChange={this.handleTimeChange}
              />
              <HelpBlock>{validationText(this.state.timeError)}</HelpBlock>
            </FormGroup>
          </Col>
        )}
      </Row>
    );
  }
}

StartDateAndTimeInputFields.defaultProps = {
  bsSize: 'sm',
};

export default StartDateAndTimeInputFields;
