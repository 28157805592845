import { Row, Col } from 'react-bootstrap';
import { Component } from 'react';

import _get from 'lodash/get';
import _last from 'lodash/last';

import SeatAssignment from './seat_assignment';

class SeatAssignments extends Component {
  isSearching(index) {
    const [seatAssignmentIndex, flightSegmentIndex] = this.props.passengersTypeaheadIndex;
    return (
      this.props.flightSegmentIndex === flightSegmentIndex &&
      index === seatAssignmentIndex
    );
  }

  render() {
    if (this.props.bookingPassengerSeats > 0) {
      let width;
      if (!this.props.isXs && this.props.bookingPassengerSeats > 6) {
        width = `${Math.round((99.9 / this.props.bookingPassengerSeats) * 1000) / 1000}%`;
      }
      return (
        <Row>
          {this.props.seatAssignments.fields.map((field, index) => {
            if (index < this.props.bookingPassengerSeats) {
              const seatAssignmentField = _last(field.split('.'));
              const values = _get(this.props, seatAssignmentField, {});
              return (
                <SeatAssignment
                  formValues={values}
                  key={`sa-${index}`}
                  index={index}
                  flightSegmentIndex={this.props.flightSegmentIndex}
                  field={field}
                  updating={this.props.updating}
                  isXs={this.props.isXs}
                  width={width}
                  addingPassenger={this.props.addingPassenger}
                  passengersTypeaheadCollection={
                    this.isSearching(index)
                      ? this.props.passengersTypeaheadCollection
                      : []
                  }
                  typeaheadFetching={
                    this.isSearching(index) ? this.props.typeaheadFetching : false
                  }
                  handlePassengerSearch={this.props.handlePassengerSearch}
                  handleAddPassengerModalClicked={
                    this.props.handleAddPassengerModalClicked
                  }
                  handlePassengerChanged={this.props.handlePassengerChanged}
                />
              );
            }
          })}
        </Row>
      );
    } else {
      return (
        <Row>
          <Col sm={12}>
            <div className="center-block" style={{ padding: '20px 0 20px 0' }}>
              <p className="text-center">
                {this.props.bookingAircraftId
                  ? 'No seats available'
                  : 'Select an aircraft...'}
              </p>
            </div>
          </Col>
        </Row>
      );
    }
  }
}

export default SeatAssignments;
