import { Row, Col, FormGroup, Button, ControlLabel, FormControl } from 'react-bootstrap';
import { Component } from 'react';
import SunCalc from 'suncalc';
import moment from 'moment';

import isEqual from 'lodash/isEqual';
import get from 'lodash/get';

moment.updateLocale('en-nz');

class PilotFlightLogSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasNight: false,
    };
    this._handleEditClick = this._handleEditClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    this._getState();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      get(this.props, 'formValues.start_at', '') !==
        get(nextProps, 'formValues.start_at', '') ||
      get(this.props, 'formValues.end_at', '') !==
        get(nextProps, 'formValues.end_at', '') ||
      get(this.props, 'formValues.end_location_id', '') !==
        get(nextProps, 'formValues.end_location_id', '') ||
      !isEqual(
        get(this.props, 'formValues.pilot_flight_logs_attributes', []),
        get(nextProps, 'formValues.pilot_flight_logs_attributes', [])
      )
    ) {
      this._getState(nextProps);
    }
  }

  _getState(props = this.props) {
    let hasNight = false;
    const bookingStartAt = moment(get(props, 'formValues.start_at', ''));
    const bookingEndAt = moment(get(props, 'formValues.end_at', ''));
    if (bookingStartAt.isValid() && bookingEndAt.isValid()) {
      const { latitude: locationLatitude, longitude: locationLongitude } = get(
        this.props.locationsDataSelector,
        get(props, 'formValues.end_location_id'),
        {}
      );
      if (locationLatitude && locationLongitude) {
        const times = SunCalc.getTimes(
          bookingStartAt.toDate(),
          locationLatitude,
          locationLongitude
        );
        const dawn = moment(times.dawn);
        const dusk = moment(times.dusk);
        hasNight = bookingStartAt.isBefore(dawn) || bookingEndAt.isAfter(dusk);
      }
    }
    this.setState({
      hasNight,
    });
  }

  _handleEditClick() {
    if (this.props.handleTabClick) {
      this.props.handleTabClick(4);
    }
  }

  _renderPilotName(pilotId) {
    return get(this.props.contactsDataSelector, [pilotId, 'fullName'], 'Unknown');
  }

  _renderCommandValue({
    pilot_in_charge_day,
    pilot_in_charge_night,
    copilot_day,
    copilot_night,
  }) {
    if (pilot_in_charge_day || pilot_in_charge_night) {
      return (
        <span>
          {(
            parseFloat(pilot_in_charge_day || 0) + parseFloat(pilot_in_charge_night || 0)
          ).toFixed(2)}
        </span>
      );
    } else if (copilot_day || copilot_night) {
      return (
        <span>
          {(parseFloat(copilot_day || 0) + parseFloat(copilot_night || 0)).toFixed(2)}
        </span>
      );
    } else {
      return <span>Check tab...</span>;
    }
  }

  _renderCommandType({
    pilot_in_charge_day,
    pilot_in_charge_night,
    copilot_day,
    copilot_night,
  }) {
    if (pilot_in_charge_day || pilot_in_charge_night) {
      return <span>PIC</span>;
    } else if (copilot_day || copilot_night) {
      return <span>Copilot</span>;
    } else {
      return <span>Check tab...</span>;
    }
  }

  render() {
    return (
      <Row>
        <Col sm={2}>
          <p>
            <strong>Pilot Flight Log</strong>
          </p>
        </Col>
        <Col sm={10}>
          <Row>
            {get(this.props, 'formValues.pilot_flight_logs_attributes', []).map(
              (model, index) => {
                if (!model._destroy) {
                  return (
                    <Col key={index} sm={12} className="form-inline">
                      <FormGroup
                        style={{ marginTop: '-5px', marginRight: '20px' }}
                        bsSize="sm"
                        controlId="flight_log_pilot"
                      >
                        <ControlLabel>Pilot:</ControlLabel>
                        &nbsp;&nbsp;
                        <FormControl.Static>
                          <strong>{this._renderPilotName(model.pilot_id)}</strong>
                        </FormControl.Static>
                      </FormGroup>
                      <FormGroup
                        style={{ marginTop: '-5px', marginRight: '20px' }}
                        bsSize="sm"
                        controlId="flight_log_command_type"
                      >
                        <ControlLabel>Command Type:</ControlLabel>
                        &nbsp;&nbsp;
                        <FormControl.Static>
                          <strong>{this._renderCommandType(model)}</strong>
                        </FormControl.Static>
                      </FormGroup>
                      <FormGroup
                        style={{ marginTop: '-5px', marginRight: '20px' }}
                        bsSize="sm"
                        controlId="flight_log_command_value"
                      >
                        <ControlLabel>Command Value:</ControlLabel>
                        &nbsp;&nbsp;
                        <FormControl.Static>
                          <strong>{this._renderCommandValue(model)}</strong>
                        </FormControl.Static>
                      </FormGroup>
                      <FormGroup
                        style={{ marginTop: '-8px', marginRight: '20px' }}
                        bsSize="sm"
                        controlId="flight_log_edit"
                      >
                        <Button
                          style={{ padding: 0 }}
                          bsStyle="link"
                          onClick={this._handleEditClick}
                          tabIndex={-1}
                        >
                          {this.state.hasNight ? 'edit for night flying...' : 'edit...'}
                        </Button>
                      </FormGroup>
                    </Col>
                  );
                }
              }
            )}
          </Row>
        </Col>
      </Row>
    );
  }
}

export default PilotFlightLogSummary;
