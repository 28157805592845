import {
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  FormControl,
  HelpBlock,
  Button,
} from 'react-bootstrap';
import { Component } from 'react';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { connect } from 'react-redux';

import debounce from 'lodash/debounce';

import { currentSettingsSet } from '../actions/current_setting_actions';

import { mutationSet, mutationFailure } from '../actions/mutation_actions';

import ReactDateTimeFilter from '../components/form/react_date_time_filter';
import Loader from '../components/loader';
import ReportHeader from '../components/report_header';

import { queriesReady, getExport } from '../lib/utils';

import aircraftListQuery from '../queries/aircraft_list_query';
import hobbRecordMonthlyByAircraftDayQuery from '../queries/hobb_record_monthly_by_aircraft_day_query';

const moment = extendMoment(Moment);
moment.updateLocale('en-nz');

class ReportAircraftFlightTimeEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterAircraftId: this.props.currentSettingsReportAircraftId,
      filterAircraftIds: [],
    };
    this._handleDisplayAircraftIdChange = this._handleDisplayAircraftIdChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.startAtDate) {
      const date = moment(this.props.params.startAtDate, 'MM-YYYY');
      this.props.currentSettingsSet({
        reportStart: date.clone().startOf('day').toISOString(),
        reportEnd: date.clone().endOf('month').toISOString(),
      });
    }
    if (this.props.params.aircraftId) {
      this._handleDisplayAircraftIdChange({
        target: { value: parseInt(this.props.params.aircraftId) },
      });
    }
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    this.delayedHandleRefetch = debounce((e) => {
      this.props.hobbRecordMonthlyByAircraftDayQuery.refetch();
    }, 250);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { filterAircraftIds } = this.state;
    if (this.isLoaded(nextProps)) {
      filterAircraftIds = nextProps.hobbRecordMonthlyByAircraftDayQuery.data.map(
        (data) => data.aircraft_id
      );
    }
    this.setState({
      filterAircraftIds,
    });
    let filterAircraftId = nextProps.currentSettingsReportAircraftId;
    if (filterAircraftId && this.isLoaded(nextProps)) {
      if (
        filterAircraftIds.length > 0 &&
        filterAircraftIds.indexOf(filterAircraftId) === -1
      ) {
        filterAircraftId = '';
      }
    }
    if (filterAircraftId !== this.props.currentSettingsReportAircraftId) {
      this._handleDisplayAircraftIdChange({ target: { value: filterAircraftId } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleRefetch.cancel();
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(
      props.hobbRecordMonthlyByAircraftDayQuery,
      props.aircraftListQuery
    );
  }

  getExport = (e) => {
    this.props.mutationSet(true);
    const reportName = e.target.getAttribute('data-report-name');
    const args = {
      startAt: this.props.currentSettingsReportStart,
      endAt: this.props.currentSettingsReportEnd,
      aircraftId: this.props.currentSettingsReportAircraftId,
    };
    getExport(reportName, args)
      .then(() => {
        this.props.mutationSet(false);
      })
      .catch((err) => this.props.mutationFailure(err));
  };

  _handleDisplayAircraftIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value);
    this.setState({
      filterAircraftId: value,
    });
    this.props.currentSettingsSet({
      reportAircraftId: value,
    });
  }

  _renderRow(hobb_record) {
    const { id, start_at, total, count, starts, landings } = hobb_record;
    return (
      <tr key={id}>
        <td>{start_at}</td>
        <td className="text-right">{total.toFixed(2)}</td>
        <td className="text-right">{count}</td>
        <td className="text-right">{starts}</td>
        <td className="text-right">{landings}</td>
      </tr>
    );
  }

  _renderHeaderRow(index) {
    return (
      <thead>
        <tr>
          <th className="text-left">Date</th>
          <th className="text-right">Flight Time</th>
          <th className="text-right">Flight Count</th>
          <th className="text-right">Starts</th>
          <th className="text-right">Landings</th>
        </tr>
      </thead>
    );
  }

  _renderFooterRow(aircraft_registration_abbreviated, hobb_records, hobb_min, hobb_max) {
    return (
      <tfoot>
        <tr>
          <th className="text-right">
            {`${aircraft_registration_abbreviated} Min Hobb: ${hobb_min.toFixed(
              2
            )}, Max Hobb: ${hobb_max.toFixed(2)}`}
          </th>
          <th className="text-right">
            {hobb_records.reduce((sum, value) => sum + value.total, 0).toFixed(2)}
          </th>
          <th className="text-right">
            {hobb_records.reduce((sum, value) => sum + value.count, 0)}
          </th>
          <th className="text-right">
            {hobb_records.reduce((sum, value) => sum + value.starts, 0)}
          </th>
          <th className="text-right">
            {hobb_records.reduce((sum, value) => sum + value.landings, 0)}
          </th>
        </tr>
      </tfoot>
    );
  }

  _renderAircrafts() {
    return (
      <Row>
        <Col xs={12}>
          {this.props.hobbRecordMonthlyByAircraftDayQuery.data
            .filter((data) => {
              if (
                this.state.filterAircraftId &&
                data.aircraft_id !== this.state.filterAircraftId
              ) {
                return false;
              }
              return true;
            })
            .map((data, index) => {
              const aircraft = this.props.aircraftListQuery.data.find(
                (model) => model.id === data.aircraft_id
              );
              const aircraft_registration_abbreviated = aircraft.registration_abbreviated;
              return (
                <Row key={data.aircraft_id} style={{ marginBottom: '20px' }}>
                  <Col xs={12}>
                    <h4>{aircraft_registration_abbreviated}</h4>
                  </Col>
                  <Col xs={12}>
                    <Table>
                      {this._renderHeaderRow()}
                      <tbody>
                        {data.hobb_records.map((hobb_record) =>
                          this._renderRow(hobb_record)
                        )}
                      </tbody>
                      {this._renderFooterRow(
                        aircraft_registration_abbreviated,
                        data.hobb_records,
                        data.hobb_min,
                        data.hobb_max
                      )}
                    </Table>
                  </Col>
                </Row>
              );
            })}
        </Col>
      </Row>
    );
  }

  _renderTotalRow() {
    const totals = this.props.hobbRecordMonthlyByAircraftDayQuery.data
      .filter((data) => {
        if (
          this.state.filterAircraftId &&
          data.aircraft_id !== this.state.filterAircraftId
        ) {
          return false;
        }
        return true;
      })
      .map((data) => ({ count: data.count, total: data.total }));
    return (
      <Row>
        <Col xs={12}>
          <Table>
            <tfoot>
              <tr>
                <th colSpan={3} className="text-right">
                  Totals for Report
                </th>
                <th className="text-right" style={{ width: '20%' }}>
                  {totals.reduce((sum, value) => sum + value.total, 0).toFixed(2)}
                </th>
                <th className="text-right" style={{ width: '20%' }}>
                  {totals.reduce((sum, value) => sum + value.count, 0)}
                </th>
              </tr>
            </tfoot>
          </Table>
        </Col>
      </Row>
    );
  }

  _renderAircraftFilter() {
    const dataAircrafts = this.props.aircraftListQuery.data.filter(
      (model) => this.state.filterAircraftIds.indexOf(model.id) > -1
    );
    return (
      <FormControl
        componentClass="select"
        value={this.state.filterAircraftId}
        onChange={this._handleDisplayAircraftIdChange}
      >
        <option key={0} value="">
          All
        </option>
        {dataAircrafts.map((model) => {
          const { id, registration_abbreviated } = model;
          return (
            <option key={id} value={id}>
              {registration_abbreviated}
            </option>
          );
        })}
      </FormControl>
    );
  }

  render() {
    return (
      <div>
        {this.renderOverlay()}
        {this.renderData()}
      </div>
    );
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (this.isLoading()) {
      return undefined;
    }
    return (
      <div>
        <Row>
          <Col sm={12}>
            <div className="clearfix">
              <div className="pull-left">
                <ReportHeader
                  title="Aircraft Daily Hours and Cycles"
                  start={this.props.currentSettingsReportStart}
                  end={this.props.currentSettingsReportEnd}
                />
              </div>
              <div className="pull-right">
                <Button
                  bsStyle="primary"
                  data-report-name="hobb_record_monthly_by_aircraft_day"
                  onClick={this.getExport}
                >
                  PDF
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: '10px' }}>
          <Col xs={12}>
            <Form inline>
              <Button
                style={{ margin: '0', padding: '0 0 8px 0' }}
                bsStyle="link"
                bsSize="xsmall"
                onClick={this.delayedHandleRefetch}
              >
                <span className="glyphicon glyphicon-repeat" />
              </Button>
              <ReactDateTimeFilter
                currentSettingsReportStart={this.props.currentSettingsReportStart}
                currentSettingsReportEnd={this.props.currentSettingsReportEnd}
                onChange={this.props.currentSettingsSet}
              />
              <FormGroup
                style={{ marginLeft: '16px', paddingBottom: '10px' }}
                bsSize="sm"
                controlId="filterId"
              >
                {this._renderAircraftFilter()}
              </FormGroup>
            </Form>
          </Col>
        </Row>
        {this._renderAircrafts()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsReportAircraftId: state.currentSettings.reportAircraftId,
    currentSettingsReportStart: state.currentSettings.reportStart,
    currentSettingsReportEnd: state.currentSettings.reportEnd,
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

ReportAircraftFlightTimeEvent = compose(
  graphql(aircraftListQuery, {
    name: 'aircraftListQuery',
  }),
  graphql(hobbRecordMonthlyByAircraftDayQuery, {
    name: 'hobbRecordMonthlyByAircraftDayQuery',
    options: (props) => ({
      variables: {
        startAt: props.currentSettingsReportStart,
        endAt: props.currentSettingsReportEnd,
      },
      fetchPolicy: 'cache-and-network',
    }),
  })
)(ReportAircraftFlightTimeEvent);

export default connect(mapStateToProps, {
  mutationFailure,
  mutationSet,
  currentSettingsSet,
})(ReportAircraftFlightTimeEvent);
