import {
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  FormControl,
  HelpBlock,
  Button,
} from 'react-bootstrap';
import { Component } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import moment from 'moment';
import { connect } from 'react-redux';

import debounce from 'lodash/debounce';
import uniq from 'lodash/uniq';
import first from 'lodash/first';

import { currentSettingsSet } from '../actions/current_setting_actions';

import { mutationSet, mutationFailure } from '../actions/mutation_actions';

import ReactDateTimeFilter from '../components/form/react_date_time_filter';
import Loader from '../components/loader';
import ReportHeader from '../components/report_header';

import { queriesReady, getExport } from '../lib/utils';

import bookingSwapsForMonthByProviderQuery from '../queries/booking_swaps_for_month_by_provider_query';
import contactItemListQuery from '../queries/contact_item_list_query';

moment.updateLocale('en-nz');

class ReportProviderSwapDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterProviderId: this.props.currentSettingsReportProviderId,
      filterProviderIds: [],
    };
    this._handleDisplayProviderIdChange = this._handleDisplayProviderIdChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.startAtDate) {
      const date = moment(this.props.params.startAtDate, 'MM-YYYY');
    }
    if (this.props.params.provider_id) {
      this._handleDisplayProviderIdChange({
        target: { value: this.props.params.provider_id },
      });
    }
    if (
      !moment(this.props.currentSettingsReportStart).isSame(
        moment(this.props.currentSettingsReportStart).startOf('month')
      )
    ) {
      this.props.currentSettingsSet({
        reportStart: moment(this.props.currentSettingsReportStart)
          .startOf('month')
          .toISOString(),
      });
    }
    if (
      !moment(this.props.currentSettingsReportEnd).isSame(
        moment(this.props.currentSettingsReportStart).endOf('month')
      )
    ) {
      this.props.currentSettingsSet({
        reportEnd: moment(this.props.currentSettingsReportStart)
          .endOf('month')
          .toISOString(),
      });
    }
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    this.delayedHandleRefetch = debounce((e) => {
      this.props.bookingSwapsForMonthByProviderQuery.refetch();
    }, 250);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { filterProviderIds } = this.state;
    if (this.isLoaded(nextProps)) {
      filterProviderIds = nextProps.bookingSwapsForMonthByProviderQuery.data
        .filter((data) => data.bookings_length > 0)
        .map((data) => data.provider_id);
    }
    this.setState({
      filterProviderIds,
    });
    let filterProviderId = nextProps.currentSettingsReportProviderId;
    if (filterProviderId && this.isLoaded(nextProps)) {
      if (
        filterProviderIds.length > 0 &&
        filterProviderIds.indexOf(filterProviderId) === -1
      ) {
        filterProviderId = '';
      }
    }
    if (filterProviderId !== this.props.currentSettingsReportProviderId) {
      this._handleDisplayProviderIdChange({ target: { value: filterProviderId } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleRefetch.cancel();
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(
      props.bookingSwapsForMonthByProviderQuery,
      props.providerListQuery
    );
  }

  getExport = (e) => {
    this.props.mutationSet(true);
    const reportName = e.target.getAttribute('data-report-name');
    const args = {
      startAt: this.props.currentSettingsReportStart,
      endAt: this.props.currentSettingsReportEnd,
      providerId: this.props.currentSettingsReportProviderId,
    };
    getExport(reportName, args)
      .then(() => {
        this.props.mutationSet(false);
      })
      .catch((err) => this.props.mutationFailure(err));
  };

  _handleDisplayProviderIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value);
    this.setState({
      filterProviderId: value,
    });
    this.props.currentSettingsSet({
      reportProviderId: value,
    });
  }

  _renderFixed(value, symbol = '') {
    return value ? symbol + value.toFixed(2) : '-';
  }

  _renderRow(booking) {
    const {
      id,
      booking_id,
      aircraft_registration_abbreviated,
      reference,
      customer_reference,
      start_at_s,
      pilot_display_name,
      copilot_display_name,
      booking_summary,
      hobb_record_flight_time_credit,
      hobb_record_flight_time_debit,
      swap_type,
      counterparty_full_name,
    } = booking;

    return (
      <tr key={id}>
        <td>
          <LinkContainer
            style={{ display: 'block', textAlign: 'left' }}
            to={`/flights/${booking_id}/edit`}
          >
            <Button bsStyle="link" bsSize="xsmall">
              {`#${reference}`}
            </Button>
          </LinkContainer>
        </td>
        <td>{start_at_s}</td>
        <td>{swap_type}</td>
        <td>{counterparty_full_name}</td>
        <td>{aircraft_registration_abbreviated}</td>
        <td>
          {[pilot_display_name, copilot_display_name].filter((name) => name).join(', ')}
        </td>
        <td>{booking_summary}</td>
        <td>{customer_reference}</td>
        <td className="text-right">
          {this._renderFixed(hobb_record_flight_time_credit)}
        </td>
        <td className="text-right">{this._renderFixed(hobb_record_flight_time_debit)}</td>
      </tr>
    );
  }

  _renderRows(bookings) {
    return <tbody>{bookings.map((booking) => this._renderRow(booking))}</tbody>;
  }

  _renderOpeningBalanceRow(balance) {
    balance = parseFloat(balance);
    const type = balance === 0 ? '' : balance > 0 ? 'Credit ' : 'Debit ';
    return (
      <tr>
        <th
          className="text-right"
          colSpan={10}
        >{`${type}Balance Brought Forward ${balance}`}</th>
      </tr>
    );
  }

  _renderHeaderRow() {
    return (
      <tr>
        <th>Ref#</th>
        <th>Date</th>
        <th>Type</th>
        <th>Counterparty</th>
        <th>Aircraft</th>
        <th>Pilot</th>
        <th>Route Details</th>
        <th>Job Ref</th>
        <th className="text-right" style={{ width: '12%' }}>
          Credit Time
        </th>
        <th className="text-right" style={{ width: '12%' }}>
          Debit Time
        </th>
      </tr>
    );
  }

  _renderFooterRow(bookings, adjustment) {
    const credit_total = bookings.reduce(
      (sum, booking) => sum + (booking.hobb_record_flight_time_credit || 0),
      0
    );
    const debit_total = bookings.reduce(
      (sum, booking) => sum + (booking.hobb_record_flight_time_debit || 0),
      0
    );

    return (
      <tr>
        {adjustment ? (
          <th colSpan={8} className="text-right">{`Total Adjustments: ${adjustment}`}</th>
        ) : (
          <th colSpan={8} />
        )}
        <th className="text-right">{`Total Credit: ${credit_total}`}</th>
        <th className="text-right">{`Total Debit: ${debit_total}`}</th>
      </tr>
    );
  }

  _renderClosingBalanceRow(balance) {
    balance = parseFloat(balance);
    const type = balance === 0 ? '' : balance > 0 ? 'Credit ' : 'Debit ';
    return (
      <tr>
        <th className="text-right" colSpan={10}>{`Closing ${type}Balance ${balance}`}</th>
      </tr>
    );
  }

  _renderProviders(bookings) {
    return (
      <Row>
        <Col xs={12}>
          {bookings
            .filter((data) => {
              if (
                this.state.filterProviderId &&
                data.provider_id !== this.state.filterProviderId
              ) {
                return false;
              }
              return true;
            })
            .map((data, index) => {
              const { id, provider_full_name } = data;
              return (
                <Row key={id} style={{ marginBottom: '10px' }}>
                  <Col xs={12}>
                    <h4 style={{ paddingLeft: '10px' }}>{provider_full_name}</h4>
                  </Col>
                  <Col xs={12}>
                    <Table striped>
                      <thead>
                        {this._renderOpeningBalanceRow(data.start_balance)}
                        {this._renderHeaderRow()}
                      </thead>
                      {this._renderRows(data.bookings)}
                      <tfoot>
                        {this._renderFooterRow(data.bookings, data.adjustment)}
                        {this._renderClosingBalanceRow(data.end_balance)}
                      </tfoot>
                    </Table>
                  </Col>
                </Row>
              );
            })}
        </Col>
      </Row>
    );
  }

  _renderSwapGroups() {
    const swap_group_ids = uniq(
      this.props.bookingSwapsForMonthByProviderQuery.data
        .filter((data) => data.bookings_length > 0 || data.adjustment)
        .map((b) => b.swap_group_id)
    );
    return (
      <Row style={{ marginBottom: '20px' }}>
        <Col xs={12}>
          {swap_group_ids.map((swap_group_id) => {
            const bookings = this.props.bookingSwapsForMonthByProviderQuery.data
              .filter((data) => data.bookings_length > 0 || data.adjustment)
              .filter((b) => b.swap_group_id === swap_group_id);
            const { swap_group_name } = first(bookings);
            return (
              <Row key={swap_group_id}>
                <Col xs={12}>
                  <h4>{`Swap Group - ${swap_group_name}`}</h4>
                  <hr style={{ marginTop: 0 }} />
                </Col>
                <Col xs={12}>{this._renderProviders(bookings)}</Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    );
  }

  _renderProviderFilter() {
    const dataProviders = this.props.providerListQuery.data.filter(
      (model) => this.state.filterProviderIds.indexOf(model.id) > -1
    );
    return (
      <FormControl
        componentClass="select"
        value={this.state.filterProviderId}
        onChange={this._handleDisplayProviderIdChange}
      >
        <option key={0} value="">
          All
        </option>
        {dataProviders.map((model) => {
          const { id, fullName } = model;
          return (
            <option key={id} value={id}>
              {fullName}
            </option>
          );
        })}
      </FormControl>
    );
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (this.isLoading()) {
      return undefined;
    }
    return (
      <div>
        <Row>
          <Col sm={12}>
            <div className="clearfix">
              <div className="pull-left">
                <ReportHeader
                  title="Provider Swap Detail Report"
                  start={this.props.currentSettingsReportStart}
                  end={this.props.currentSettingsReportEnd}
                />
              </div>
              <div className="pull-right">
                <Button
                  bsStyle="primary"
                  data-report-name="booking_swaps_for_month_by_provider"
                  onClick={this.getExport}
                >
                  PDF
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: '10px' }}>
          <Col xs={12}>
            <Form inline>
              <Button
                style={{ margin: '0', padding: '0 0 8px 0' }}
                bsStyle="link"
                bsSize="xsmall"
                onClick={this.delayedHandleRefetch}
              >
                <span className="glyphicon glyphicon-repeat" />
              </Button>
              <ReactDateTimeFilter
                currentSettingsReportStart={this.props.currentSettingsReportStart}
                currentSettingsReportEnd={this.props.currentSettingsReportEnd}
                onChange={this.props.currentSettingsSet}
                locked
              />
              <FormGroup
                style={{ marginLeft: '16px', paddingBottom: '10px' }}
                bsSize="sm"
                controlId="filterId"
              >
                {this._renderProviderFilter()}
              </FormGroup>
            </Form>
          </Col>
        </Row>
        {this._renderSwapGroups()}
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderOverlay()}
        {this.renderData()}
      </div>
    );
  }
}

ReportProviderSwapDetail = compose(
  graphql(contactItemListQuery, {
    name: 'providerListQuery',
    options: { variables: { role: 'provider' } },
  }),
  graphql(bookingSwapsForMonthByProviderQuery, {
    name: 'bookingSwapsForMonthByProviderQuery',
    options: (props) => ({
      variables: {
        startAt: props.currentSettingsReportStart,
        endAt: props.currentSettingsReportEnd,
      },
      fetchPolicy: 'cache-and-network',
    }),
  })
)(ReportProviderSwapDetail);

function mapStateToProps(state) {
  return {
    currentSettingsReportProviderId: state.currentSettings.reportProviderId,
    currentSettingsReportStart: state.currentSettings.reportStart,
    currentSettingsReportEnd: state.currentSettings.reportEnd,
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default connect(mapStateToProps, {
  currentSettingsSet,
  mutationFailure,
  mutationSet,
})(ReportProviderSwapDetail);
