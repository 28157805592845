import { FormGroup, Col, Checkbox, HelpBlock, ControlLabel } from 'react-bootstrap';

import clone from 'lodash/clone';
import upperFirst from 'lodash/upperFirst';

import { validationState, validationText } from '../form/helpers';

const ContactFormRoleIdsField = (props) => {
  const {
    input,
    meta,
    roles,
    formValues: { company, role_ids: roleIds },
  } = props;

  function _humanize(string) {
    return upperFirst(string.split('_').join(' '));
  }

  function _handleCheckboxChange(e) {
    const ids = clone(roleIds);
    let id = null;
    if (isNaN(e.target.getAttribute('data-id'))) {
      id = e.target.getAttribute('data-id');
    } else {
      id = parseInt(e.target.getAttribute('data-id'));
    }
    if (id) {
      const idIndex = ids.indexOf(id);
      if (e.target.checked) {
        if (idIndex === -1) {
          input.onChange([id, ...ids]);
        }
      } else if (idIndex > -1) {
        ids.splice(idIndex, 1);
        input.onChange(ids);
      }
    }
  }

  return (
    <FormGroup controlId="contactRoleIds" validationState={validationState(meta)}>
      <Col componentClass={ControlLabel} sm={3}>
        Roles
      </Col>
      <Col sm={9}>
        {roles
          .filter((role) => (company ? role.chargeable || role.provider : role))
          .map((role) => {
            const { id, name } = role;
            return (
              <Col sm={3} key={id}>
                <Checkbox
                  data-id={id}
                  checked={roleIds.indexOf(id) > -1}
                  value={id}
                  onChange={_handleCheckboxChange}
                >
                  {_humanize(name)}
                </Checkbox>
              </Col>
            );
          })}
      </Col>
      <Col smOffset={3} sm={9}>
        <HelpBlock>{validationText(meta)}</HelpBlock>
      </Col>
    </FormGroup>
  );
};

export default ContactFormRoleIdsField;
