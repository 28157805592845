import { Row, Col, Button } from 'react-bootstrap';
import { Component } from 'react';
import { Fields } from 'redux-form';

import get from 'lodash/get';
import has from 'lodash/has';
import pick from 'lodash/pick';

import BookingOilFillFields from './booking_oil_fill_fields';

class OilFills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectOptions: [],
    };
    this._handleAddButtonClick = this._handleAddButtonClick.bind(this);
    this._handleDeleteButtonClick = this._handleDeleteButtonClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    this._getSelectOptions();
    if (
      has(this.props, 'formValues.oil_fills_attributes') &&
      get(this.props, 'formValues.oil_fills_attributes', []).length === 0
    ) {
      const oilFills = [];
      this.props.aircraftEngines.forEach((engine) => {
        oilFills.push({
          aircraft_engine_id: engine.id,
          quantity_value: 0,
        });
      });
      this.props.change('oil_fills_attributes', oilFills);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.formValues.aircraft_id !== nextProps.formValues.aircraft_id) {
      this._getSelectOptions(nextProps);
    }
  }

  _getSelectOptions(props = this.props) {
    this.setState({
      selectOptions: props.aircraftEngines,
    });
  }

  _handleAddButtonClick() {
    this.props.oilFills.fields.push({
      quantity_value: 0,
    });
  }

  _handleDeleteButtonClick(index) {
    this.props.oilFills.fields.remove(index);
  }

  _renderOilFillsList() {
    return (
      <Col sm={12}>
        {this.props.oilFills.fields.map((field, index) => {
          const values = get(this.props, `formValues.${field}`, {});
          if (!values._destroy) {
            const fields = [
              `${field}._destroy`,
              `${field}.aircraft_engine_id`,
              `${field}.quantity_value`,
            ];
            return (
              <Fields
                key={`of-${index}`}
                index={index}
                parentField={field}
                names={fields}
                component={BookingOilFillFields}
                formValues={pick(values, ['id', 'quantity_value', 'aircraft_engine_id'])}
                handleDeleteButtonClick={this._handleDeleteButtonClick}
                selectOptions={this.state.selectOptions}
                bookingAircraftId={get(this.props, 'formValues.aircraft_id', '')}
              />
            );
          }
        })}
      </Col>
    );
  }

  _renderHeader() {
    return (
      <Col sm={12}>
        <Row>
          <Col sm={2}>
            <label>{`Quantity (${this.props.currentSettingsFuelBowserFillDefaultQuantityUnit})`}</label>
          </Col>
          <Col sm={4}>
            <label>Engine</label>
          </Col>
          <Col sm={2} />
        </Row>
      </Col>
    );
  }

  _renderOilFills() {
    return (
      <Row>
        {this._renderHeader()}
        {this._renderOilFillsList()}
      </Row>
    );
  }

  render() {
    const fillsLength = get(this.props, 'formValues.oil_fills_attributes', []).filter(
      (ff) => !ff._destroy
    ).length;
    return (
      <Row>
        <Col sm={2}>
          <p>
            <strong>Oil Fills</strong>
          </p>
        </Col>
        <Col sm={10}>
          {fillsLength > 0 && this._renderOilFills()}
          <Row>
            <Col sm={12}>
              <Button
                style={{ paddingTop: 0, paddingLeft: 0 }}
                type="button"
                bsStyle="link"
                bsSize="small"
                onClick={this._handleAddButtonClick}
                tabIndex={-1}
                disabled={!this.props.formValues.aircraft_id}
              >
                add...
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default OilFills;
