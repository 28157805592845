import { Component } from 'react';

import get from 'lodash/get';

class FlightPlanTableHeader extends Component {
  _calculateDistanceNm(type = 'raw') {
    const distance = this.props.distanceData.reduce(
      (total, data) => total + get(data, type, 0),
      0
    );
    if (distance) {
      const kms = Math.round(distance / 1000);
      const nms = Math.round(kms * 0.539957);
      return nms;
    } else {
      return '-';
    }
  }

  _calculateFlightTimeMin() {
    const flighttime = this.props.flighttimeData.reduce(
      (total, data) => total + get(data, 'flightSegmentLoadedFlighttime', 0),
      0
    );
    if (flighttime) {
      return flighttime.toFixed(2);
    } else {
      return '-';
    }
  }

  render() {
    return (
      <tfoot>
        <tr>
          <td />
          <td className="text-center">{this._calculateDistanceNm()}</td>
          <td className="text-center">{this._calculateDistanceNm('adjusted')}</td>
          <td />
          <td className="text-center">{this._calculateFlightTimeMin()}</td>
          <td colSpan={8} />
        </tr>
      </tfoot>
    );
  }
}

export default FlightPlanTableHeader;
