import { Row, Col, Button, Glyphicon } from 'react-bootstrap';
import { Component } from 'react';

import get from 'lodash/get';

import Confirm from '../confirm';
import InputField from '../form/input_field';

class LocationFormFuelBowserFields extends Component {
  constructor(props) {
    super(props);
    this._handleDeleteClick = this._handleDeleteClick.bind(this);
  }

  _handleDeleteClick() {
    if (this.props.handleDeleteClick) {
      this.props.handleDeleteClick(this.props.index);
    }
  }

  _renderDeleteMessage() {
    let msg = 'Are you sure you want to delete this fuel bowser information?';
    if (get(this.props, 'formValues.id')) {
      msg +=
        ' Note that while the information will be removed from the list, it will not be permanently deleted until you save this Location.';
    }
    return msg;
  }

  render() {
    const {
      fuel_availability_id: {
        input: fuelAvailabilityIdInput,
        meta: fuelAvailabilityIdMeta,
      },
      fuel_company_id: { input: fuelCompanyIdInput, meta: fuelCompanyIdMeta },
      fuel_pump_access_id: { input: fuelPumpAccessIdInput, meta: fuelPumpAccessIdMeta },
      fuel_type_id: { input: fuelTypeIdInput, meta: fuelTypeIdMeta },
      price: { input: priceInput, meta: priceMeta },
    } = get(this.props, this.props.parentField);

    const { fuelAvailabilities, fuelCompanies, fuelPumpAccesses, fuelTypes } = this.props;

    return (
      <fieldset>
        <Row>
          <Col xs={4}>
            <InputField
              type="text"
              bsClass="form-group form-horizontal-nested"
              inputStyle={{ marginTop: '5px' }}
              labelWidth={0}
              inputWidth={12}
              componentClass="select"
              selectOptions={fuelTypes}
              input={fuelTypeIdInput}
              meta={fuelTypeIdMeta}
            >
              Fuel Type
            </InputField>
          </Col>
          <Col xs={4}>
            <InputField
              type="text"
              bsClass="form-group form-horizontal-nested"
              inputStyle={{ marginTop: '5px' }}
              labelWidth={0}
              inputWidth={12}
              componentClass="select"
              selectOptions={fuelCompanies}
              input={fuelCompanyIdInput}
              meta={fuelCompanyIdMeta}
            >
              Fuel Company
            </InputField>
          </Col>
          <Col xs={4}>
            <InputField
              type="text"
              bsClass="form-group form-horizontal-nested"
              inputStyle={{ marginTop: '5px' }}
              labelWidth={0}
              inputWidth={12}
              componentClass="select"
              selectOptions={fuelAvailabilities}
              input={fuelAvailabilityIdInput}
              meta={fuelAvailabilityIdMeta}
            >
              Fuel Availability
            </InputField>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <InputField
              type="text"
              bsClass="form-group form-horizontal-nested"
              inputStyle={{ marginTop: '5px' }}
              labelWidth={0}
              inputWidth={12}
              componentClass="select"
              selectOptions={fuelPumpAccesses}
              input={fuelPumpAccessIdInput}
              meta={fuelPumpAccessIdMeta}
            >
              Fuel Pump Access
            </InputField>
          </Col>
          <Col xs={4}>
            <InputField
              type="text"
              bsClass="form-group form-horizontal-nested"
              inputStyle={{ marginTop: '5px' }}
              labelWidth={0}
              inputWidth={12}
              input={priceInput}
              meta={priceMeta}
              helpText="required"
            >
              Price
            </InputField>
          </Col>
          <Col sm={1}>
            <div style={{ marginTop: '33px' }}>
              <Confirm
                onConfirm={this._handleDeleteClick}
                title="Delete Phone Number"
                body={this._renderDeleteMessage()}
                confirmText="Confirm"
              >
                <Button type="button" bsStyle="default" bsSize="small">
                  <Glyphicon glyph="trash" />
                </Button>
              </Confirm>
            </div>
          </Col>
        </Row>
      </fieldset>
    );
  }
}

export default LocationFormFuelBowserFields;
