import { Col, FormGroup, FormControl, ControlLabel, HelpBlock } from 'react-bootstrap';
import { Component } from 'react';

import first from 'lodash/first';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { validationState, validationText } from '../form/helpers';

class ProviderInputField extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !isEqual(
        this.props.booking_chargeables_attributes,
        nextProps.booking_chargeables_attributes
      )
    ) {
      let firstPrevChargeableId = '';
      if (
        this.props.booking_chargeables_attributes &&
        this.props.booking_chargeables_attributes.length > 0
      ) {
        firstPrevChargeableId = get(
          first(this.props.booking_chargeables_attributes.filter((bca) => !bca._destroy)),
          'chargeable_id',
          ''
        );
      }
      const firstNextChargeableId = get(
        first(nextProps.booking_chargeables_attributes.filter((bca) => !bca._destroy)),
        'chargeable_id',
        ''
      );
      if (firstPrevChargeableId !== firstNextChargeableId) {
        if (firstNextChargeableId) {
          const chargeable = this.props.chargeables.find(
            (c) => c.id === firstNextChargeableId
          );
          const defaultProviderId = get(chargeable, 'default_provider_id', '');
          this.props.input.onChange(defaultProviderId);
        } else {
          this.props.input.onChange('');
        }
      }
    }
  }

  _renderFormControl() {
    const {
      noTab,
      placeholder,
      type,
      input,
      inputStyle,
      componentClass,
      defaultSelectOption,
      selectOptions,
      rows,
    } = this.props;

    const commonProps = {
      type,
      value: input.value,
      ...(placeholder && { placeholder }),
      ...(componentClass && { componentClass }),
      ...(rows && { rows }),
      ...(noTab && { tabIndex: -1 }),
      ...(inputStyle && { style: inputStyle }),
    };

    if (selectOptions) {
      return (
        <FormControl {...commonProps} onChange={input.onChange}>
          {defaultSelectOption ? (
            <option key={0} value="">
              {}
            </option>
          ) : (
            ''
          )}
          {selectOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </FormControl>
      );
    }
  }

  render() {
    const {
      input,
      meta,
      bsSize,
      groupStyle,
      labelWidth,
      inputWidth,
      helpText,
      helpBlock,
    } = this.props;

    return (
      <FormGroup
        controlId={input.name}
        validationState={validationState(meta)}
        {...(bsSize && { bsSize })}
        {...(groupStyle && { style: groupStyle })}
      >
        {this.props.labelWidth > 0 && (
          <Col componentClass={ControlLabel} sm={labelWidth}>
            {this.props.children}
          </Col>
        )}
        <Col sm={inputWidth}>
          {this._renderFormControl()}
          {helpBlock && <HelpBlock>{validationText(meta, helpText)}</HelpBlock>}
        </Col>
      </FormGroup>
    );
  }
}

ProviderInputField.defaultProps = {
  bsSize: 'sm',
  labelWidth: 4,
  inputWidth: 8,
  defaultSelectOption: true,
  helpText: '',
  helpBlock: true,
  noTab: true,
};

export default ProviderInputField;
