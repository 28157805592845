import { Row, Col } from 'react-bootstrap';
import { Component } from 'react';
import { Fields } from 'redux-form';

import get from 'lodash/get';
import pick from 'lodash/pick';

import FlightSegmentLandingChargeFields from './flight_segment_landing_charge_fields';

class LandingCharges extends Component {
  render() {
    return (
      <Row>
        <Col sm={2}>
          <p>
            <strong>Landing Fees</strong>
          </p>
        </Col>
        <Col sm={10}>
          <Row>
            {this.props.flightSegments.fields.map((field, index) => {
              const values = get(this.props, `formValues.${field}`, {});
              if (!values._destroy) {
                const listLandingFee = get(this.props.locationsDataSelector, [
                  values.end_location_id,
                  'landingFee',
                ]);
                if (
                  values.end_location_landing_fee ||
                  values.end_location_landing_fee_original ||
                  listLandingFee
                ) {
                  const fields = [
                    `${field}.end_location_landing_fee`,
                    `${field}.oncharge_end_location_landing_fee`,
                  ];
                  return (
                    <Fields
                      key={`lc-${index}`}
                      parentField={field}
                      names={fields}
                      component={FlightSegmentLandingChargeFields}
                      locationsDataSelector={this.props.locationsDataSelector}
                      formValues={pick(values, [
                        'end_location_id',
                        'end_location_landing_fee',
                        'oncharge_end_location_landing_fee',
                      ])}
                    />
                  );
                }
              }
            })}
          </Row>
        </Col>
      </Row>
    );
  }
}

export default LandingCharges;
