import actionTypes from '../constants';

import { filterReset } from './filter_actions';
import { datasLoad, datasReset } from './data_actions';
import { suggestionsReset } from './suggestion_actions';
import { typeaheadsReset } from './typeahead_actions';

export function storeReset() {
  return function storeResetDispatch(dispatch) {
    dispatch(filterReset());
    dispatch(datasReset());
    dispatch(suggestionsReset());
    dispatch(typeaheadsReset());
  };
}

export function storeLoad() {
  return function storeLoadDispatch(dispatch) {
    dispatch(datasLoad());
  };
}

export function storeReload() {
  return function storeReloadDispatch(dispatch) {
    dispatch(storeReset());
    dispatch(storeLoad());
  };
}

export function storeLocalLoad() {
  return {
    type: actionTypes.LOCAL_LOAD,
  };
}
