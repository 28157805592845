import { Col, FormGroup, Checkbox, ControlLabel, HelpBlock } from 'react-bootstrap';
import { Component } from 'react';

import get from 'lodash/get';

import BlurOnlyFormControl from '../form/blur_only_form_control';
import { validationState, validationText } from '../form/helpers';

class FlightSegmentLandingChargeFields extends Component {
  _renderControlLabel() {
    return get(
      this.props.locationsDataSelector,
      [this.props.formValues.end_location_id, 'longName'],
      'Select an end location'
    );
  }

  render() {
    const {
      end_location_landing_fee: {
        input: endLocationLandingFeeInput,
        meta: endLocationLandingFeeMeta,
      },
      oncharge_end_location_landing_fee: {
        input: onchargeEndLocationLandingFeeInput,
        meta: onchargeEndLocationLandingFeeMeta,
      },
    } = get(this.props, this.props.parentField);

    return (
      <Col sm={12} className="form-inline">
        <FormGroup
          style={{ marginRight: '20px' }}
          bsSize="sm"
          controlId={endLocationLandingFeeInput.name}
          validationState={validationState(endLocationLandingFeeMeta)}
        >
          <ControlLabel style={{ minWidth: '120px' }}>
            {this._renderControlLabel()}
          </ControlLabel>
          &nbsp;&nbsp;
          <BlurOnlyFormControl type="text" {...endLocationLandingFeeInput} />
          <HelpBlock>{validationText(endLocationLandingFeeMeta)}</HelpBlock>
        </FormGroup>
        <FormGroup
          bsSize="sm"
          controlId={onchargeEndLocationLandingFeeInput.name}
          validationState={validationState(onchargeEndLocationLandingFeeMeta)}
        >
          <Checkbox
            inline
            {...onchargeEndLocationLandingFeeInput}
            checked={onchargeEndLocationLandingFeeInput.value}
            tabIndex={-1}
          >
            Oncharge
          </Checkbox>
          <HelpBlock>{validationText(onchargeEndLocationLandingFeeMeta)}</HelpBlock>
        </FormGroup>
      </Col>
    );
  }
}

export default FlightSegmentLandingChargeFields;
