/* eslint-disable react-hooks/rules-of-hooks */
import { cloneElement } from 'react';
import { Navigate, useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AuthWrapper = ({ children, skipEmployee }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const authIsAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const authIsAuthenticating = useSelector((state) => state.auth.isAuthenticating);
  const authIsEmployee = useSelector((state) => state.auth.isEmployee);
  return authIsAuthenticated &&
    !authIsAuthenticating &&
    (skipEmployee || authIsEmployee) ? (
    cloneElement(children, { navigate, location, params })
  ) : (
    <Navigate to="/auth/logout" />
  );
};

export default AuthWrapper;
