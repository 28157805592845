import { Modal, Button } from 'react-bootstrap';
import { Component } from 'react';

import SmsRiskListTable from './sms_risk_list_table';

class SmsReportRiskAlterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      riskIds: [],
    };
    this.handleSelectClick = this.handleSelectClick.bind(this);
    this.handleAlterCancel = this.handleAlterCancel.bind(this);
    this.handleAlterSubmit = this.handleAlterSubmit.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ riskIds: nextProps.riskIds });
  }

  handleAlterSubmit() {
    if (this.props.handleAlterSubmit) {
      this.props.handleAlterSubmit(this.state.riskIds);
    }
  }

  handleAlterCancel() {
    if (this.props.handleAlterCancel) {
      this.props.handleAlterCancel();
    }
  }

  handleSelectClick(riskId, checked) {
    const ids = [...this.state.riskIds];
    const id = parseInt(riskId);
    if (id && ids) {
      const index = ids.indexOf(id);
      if (checked) {
        if (index === -1) {
          this.setState({
            riskIds: [id, ...ids],
          });
        }
      } else if (index > -1) {
        ids.splice(index, 1);
        this.setState({
          riskIds: ids,
        });
      }
    }
  }

  render() {
    return (
      <Modal show={this.props.isAltering} onHide={this.handleAlterCancel} bsSize="large">
        <Modal.Header closeButton>
          <Modal.Title>Alter Existing Risks</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ maxHeight: '400px', overflow: 'scroll' }}>
            <SmsRiskListTable
              risks={this.props.risks}
              riskIds={this.state.riskIds}
              handleSelectClick={this.handleSelectClick}
              withSelect
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" bsStyle="danger" onClick={this.handleAlterCancel}>
            Cancel
          </Button>
          <Button type="button" bsStyle="primary" onClick={this.handleAlterSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SmsReportRiskAlterModal;
