import {
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  ButtonToolbar,
  ButtonGroup,
} from 'react-bootstrap';
import { Component } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';

import { reduxForm, Field, getFormValues } from 'redux-form';

import Validator from 'validatorjs';

import _forOwn from 'lodash/forOwn';
import _indexOf from 'lodash/indexOf';
import _isEqual from 'lodash/isEqual';
import _nth from 'lodash/nth';
import _set from 'lodash/set';

import InputField from '../form/input_field';

class SmsRiskCreateFormModal extends Component {
  componentDidUpdate(prevProps) {
    if (!_isEqual(prevProps.formValues, this.props.formValues)) {
      let riskLevel = '';
      let riskGroup = '';
      if (
        this.props.formValues &&
        this.props.formValues.consequence &&
        this.props.formValues.likelihood
      ) {
        const likelihoodIndex = _indexOf(
          this.props.currentSettingsSmsRiskLikelihoods,
          this.props.formValues.likelihood
        );
        const consequenceIndex = _indexOf(
          this.props.currentSettingsSmsRiskConsequences,
          this.props.formValues.consequence
        );
        riskLevel = `${likelihoodIndex + 1}${_nth(
          ['E', 'D', 'C', 'B', 'A'],
          consequenceIndex
        )}`;
        _forOwn(this.props.currentSettingsSmsRiskMatrix, (values, key) => {
          if (riskGroup) {
            return false;
          }
          if (_indexOf(values, riskLevel) > -1) {
            riskGroup = key;
          }
          return true;
        });
      }
      this.props.change('riskLevel', riskLevel);
      this.props.change('riskGroup', riskGroup);
    }
  }

  render() {
    const {
      handleCreateSubmit,
      handleCreateCancel,
      handleSubmit,
      pristine,
      submitting,
      valid,
    } = this.props;

    return (
      <Form horizontal onSubmit={handleSubmit(handleCreateSubmit)}>
        <Field
          type="text"
          name="description"
          component={InputField}
          componentClass="textarea"
          rows={4}
          inputWidth={6}
        >
          Description
        </Field>
        <Field
          type="text"
          name="consequence"
          inputWidth={3}
          noTab
          component={InputField}
          componentClass="select"
          defaultSelectOption={false}
          selectOptions={this.props.currentSettingsSmsRiskConsequences.map((c) => ({
            id: c,
            name: c,
          }))}
        >
          Consequence
        </Field>
        <Field
          type="text"
          name="likelihood"
          inputWidth={3}
          noTab
          component={InputField}
          componentClass="select"
          defaultSelectOption={false}
          selectOptions={this.props.currentSettingsSmsRiskLikelihoods.map((l) => ({
            id: l,
            name: l,
          }))}
        >
          Likelihood
        </Field>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Risk Level
          </Col>
          <Col sm={3}>
            <FormControl.Static>
              {this.props.formValues ? this.props.formValues.riskLevel || '-' : '-'}
            </FormControl.Static>
          </Col>
        </FormGroup>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Risk Group
          </Col>
          <Col sm={3}>
            <FormControl.Static>
              {this.props.formValues ? this.props.formValues.riskGroup || '-' : '-'}
            </FormControl.Static>
          </Col>
        </FormGroup>
        <FormGroup>
          <Col sm={12}>
            <div className="pull-right">
              <ButtonToolbar>
                <ButtonGroup>
                  <Button
                    type="reset"
                    bsStyle="danger"
                    disabled={submitting}
                    onClick={handleCreateCancel}
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
                <ButtonGroup>
                  <Button
                    type="submit"
                    bsStyle="primary"
                    disabled={pristine || submitting || !valid}
                  >
                    Create
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </div>
          </Col>
        </FormGroup>
      </Form>
    );
  }
}

const validationAliases = {
  // 'description': 'Description',
};

const validationRules = {
  description: 'required',
  consequence: 'required',
  likelihood: 'required',
};

const validationCustomMessages = {};

function validate(data) {
  const validator = new Validator(data, validationRules, validationCustomMessages);
  validator.setAttributeNames(validationAliases);
  validator.passes();
  const errors = validator.errors.all();
  const result = {};
  Object.keys(errors).forEach((key) => {
    _set(result, key, errors[key].join(' '));
  });
  return result;
}

function mapStateToProps(state) {
  return {
    currentSettingsMutating: state.currentSettings.mutating,
    currentSettingsSmsRiskConsequences: state.currentSettings.sms_risk_consequences,
    currentSettingsSmsRiskLikelihoods: state.currentSettings.sms_risk_likelihoods,
    currentSettingsSmsRiskMatrix: state.currentSettings.sms_risk_matrix,
    formValues: getFormValues('SmsRiskCreateFormModal')(state),
  };
}

export default compose(
  connect(mapStateToProps, {}),
  reduxForm({
    validate,
  })
)(SmsRiskCreateFormModal);
