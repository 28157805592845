import { FormGroup, Col, Checkbox, HelpBlock } from 'react-bootstrap';

import { validationState, validationText } from '../form/helpers';

const ContactFormUseFirstField = (props) => {
  const { input, meta } = props;
  return (
    <FormGroup controlId="contactUseFirst" validationState={validationState(meta)}>
      <Col sm={9} smOffset={3}>
        <Checkbox {...input} checked={input.value}>
          Use first aircraft if no default aircraft selected
        </Checkbox>
        <HelpBlock>
          {validationText(meta, 'Leave unchecked for blank aircraft selector in forms')}
        </HelpBlock>
      </Col>
    </FormGroup>
  );
};

export default ContactFormUseFirstField;
