import Spinner from 'react-spinkit';

function Loader(props) {
  const defaultWrapperStyle = {
    zIndex: 1000,
    position: 'absolute',
    left: 0,
    top: 0,
    backgroundColor: 'rgba(252,252,252,0.1)',
    width: '100%',
    height: '100%',
  };

  return (
    <div style={props.wrapperStyle || defaultWrapperStyle}>
      <div
        style={{
          position: 'relative',
          top: '50%',
          left: '50%',
          width: '32px',
          height: '32px',
        }}
      >
        {props.noSpinner || (
          <Spinner
            fadeIn={props.fadeIn || 'half'}
            name={props.name || 'circle'}
            color={props.color || '#bcbcbc'}
          />
        )}
      </div>
    </div>
  );
}

export default Loader;
