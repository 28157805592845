import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { reduxForm, Field, change as changeFieldValue, getFormValues } from 'redux-form';
import moment from 'moment';

import { LinkContainer } from 'react-router-bootstrap';
import {
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  Button,
  ButtonToolbar,
  ButtonGroup,
} from 'react-bootstrap';

import _clone from 'lodash/clone';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _omitBy from 'lodash/omitBy';
import _isNil from 'lodash/isNil';
import _snakeCase from 'lodash/snakeCase';
import { tagsCreate } from '../actions/tag_actions';
import TagList from '../components/tag_list';
import { validationState, validationText } from '../components/form/helpers';
import InputField from '../components/form/input_field';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import documentQuery from '../queries/document_query';
import documentCategoryListQuery from '../queries/document_category_list_query';
import documentUpdateMutation from '../mutations/document_update_mutation';
import documentCreateMutation from '../mutations/document_create_mutation';

import { queryReady, queriesReady, typeInput, getTaggingsNameArray } from '../lib/utils';
import Loader from '../components/loader';

moment.updateLocale('en-nz');
let isInitialisedDocumentForm = false;

class DocumentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: !!this.props.params.id,
      document_file_extension: 'unknown',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTagCreate = this.handleTagCreate.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.renderTagField = this.renderTagField.bind(this);
    this.renderFileField = this.renderFileField.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.documentQuery && this.isLoaded(nextProps) && nextProps.pristine) {
      this.setState({
        document_file_extension: nextProps.documentQuery.data.document_file_name
          .split('.')
          .pop(),
      });
    }
  }

  componentWillUnmount() {
    isInitialisedDocumentForm = false;
  }

  getDocumentCategoryAncestryName(documentCategory) {
    const { rgt, lft } = documentCategory;
    return this.props.documentCategoryListQuery.data
      .filter((dc) => dc.lft <= lft && dc.rgt >= rgt)
      .sort((a, b) => a.lft - b.lft)
      .map((dc) => dc.name)
      .join(' | ');
  }

  getDocumentCategoryOptions() {
    return this.props.documentCategoryListQuery.data
      .map((documentCategory) => ({
        id: documentCategory.id,
        name: this.getDocumentCategoryAncestryName(documentCategory),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(
      testProps.documentCategoryListQuery,
      [testProps.documentQuery, true] // ignore if undefined
    );
  }

  handleSubmit(data) {
    const submitData = _cloneDeep(data);
    submitData.tag_list = submitData.tag_collection.map((tag) => tag.text).join(',');
    if (submitData.document) {
      submitData.uploader_id = this.props.currentContact.id;
      // eslint-disable-next-line no-param-reassign
      submitData.uploaded_at = moment().format();
    }
    this.props.mutationSet(true);
    let mutation;
    let mutationMessage;
    const mutationData = {
      variables: { input: typeInput(_omit(submitData, ['tag_collection'])) },
      context: { hasUpload: true },
    };
    // return
    if (this.state.updating) {
      mutation = this.props.documentUpdateMutation;
      mutationMessage = 'Document update';
      mutationData.variables.id = this.props.params.id;
    } else {
      mutation = this.props.documentCreateMutation;
      mutationMessage = 'Document create';
    }
    return mutation(mutationData)
      .then(() => {
        this.props.mutationSuccess(mutationMessage);
        this.props.navigate('/documents');
      })
      .catch((err) => this.props.mutationFailure(err, true));
  }

  handleTagCreate(tag) {
    this.props.tagsCreate({ id: _snakeCase(tag), text: tag, type: 'Document' });
  }

  handleFileChange(e) {
    const file = e.target.files[0];
    let fileExtension = 'unknown';
    const fileParts = file.name.split('.');
    if (fileParts.length > 1) {
      fileExtension = fileParts[fileParts.length - 1];
    }
    this.setState({
      document_file_extension: fileExtension.toLowerCase(),
    });
    this.props.changeFieldValue('DocumentForm', 'document', file);
  }

  renderTitle() {
    if (this.state.updating) {
      return 'Edit Document';
    } else {
      return 'New Document';
    }
  }

  renderTagField(field) {
    const { input } = field;
    return (
      <FormGroup>
        <Col componentClass={ControlLabel} sm={3}>
          Tags
        </Col>
        <Col sm={9}>
          <TagList
            suggestions={this.props.tagsCollection.filter(
              (model) => model && model.type === 'Document'
            )}
            tag_collection={input}
            onTagCreate={this.handleTagCreate}
          />
        </Col>
      </FormGroup>
    );
  }

  renderFileField(document) {
    return (
      <FormGroup validationState={validationState(document.meta)}>
        <Col componentClass={ControlLabel} sm={3}>
          {document.children}
        </Col>
        <Col sm={3} style={{ paddingTop: '8px' }}>
          <FormControl type="file" value={undefined} onChange={this.handleFileChange} />
          <HelpBlock>{validationText(document.meta)}</HelpBlock>
        </Col>
      </FormGroup>
    );
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (
      isInitialisedDocumentForm &&
      this.isLoaded() &&
      _get(this.props, 'formValues') &&
      (!this.state.updating || _get(this.props, 'formValues.id'))
    ) {
      const { handleSubmit, pristine, submitting } = this.props;

      return (
        <Form horizontal onSubmit={handleSubmit(this.handleSubmit)}>
          <h2>{this.renderTitle()}</h2>
          <fieldset>
            <legend>Details</legend>
            {this.state.updating ? (
              <FormGroup>
                <Col componentClass={ControlLabel} sm={3}>
                  Current File Name
                </Col>
                <Col sm={9}>
                  <FormControl.Static>
                    {this.props.documentQuery.data.document_file_name}
                  </FormControl.Static>
                </Col>
              </FormGroup>
            ) : (
              ''
            )}
            <Field
              type="text"
              name="description"
              component={InputField}
              componentClass="textarea"
              rows={3}
            >
              Description
            </Field>
            <Field type="text" name="tag_collection" component={this.renderTagField} />
            <Field
              type="text"
              name="document_category_id"
              bsSize="sm"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
              componentClass="select"
              selectOptions={this.getDocumentCategoryOptions()}
            >
              Category
            </Field>
            <Field name="document" component={this.renderFileField}>
              {this.state.updating ? 'Update Document' : 'Add Document'}
            </Field>
            <Col sm={3} smOffset={3}>
              <span
                className={`filetypes x2 filetypes-${this.state.document_file_extension}`}
              />
            </Col>
          </fieldset>
          <FormGroup>
            <Col sm={12}>
              <div className="pull-right">
                <ButtonToolbar>
                  <ButtonGroup>
                    <LinkContainer to="/documents">
                      <Button type="reset" bsStyle="danger" disabled={submitting}>
                        Cancel
                      </Button>
                    </LinkContainer>
                  </ButtonGroup>
                  <ButtonGroup>
                    <Button
                      type="submit"
                      bsStyle="primary"
                      disabled={pristine || submitting}
                    >
                      {submitting ? (
                        <span className="glyphicon glyphicon-refresh glyphicon-spin" />
                      ) : (
                        ''
                      )}{' '}
                      {this.state.updating ? 'Update' : 'Create'}
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </div>
            </Col>
          </FormGroup>
        </Form>
      );
    }
    return undefined;
  }

  render() {
    return (
      <div>
        {this.renderOverlay()}
        {this.renderData()}
      </div>
    );
  }
}

DocumentForm.propTypes = {
  tagsCollection: PropTypes.array.isRequired,
};

const attributeBlackList = [
  '__typename',
  'uploader',
  'document_file_name',
  'document_content_type',
  'taggings',
];

function validate() {
  return {};
}

function pickInitialValues(documentQueryInitial, updating) {
  if (!isInitialisedDocumentForm) {
    if (!updating) {
      isInitialisedDocumentForm = true;
      return {
        tag_collection: [],
      };
    } else if (queryReady(documentQueryInitial)) {
      isInitialisedDocumentForm = true;
      const editDocument = _omit(
        _omitBy(_cloneDeep(documentQueryInitial.data), _isNil),
        attributeBlackList
      );
      editDocument.tag_collection = getTaggingsNameArray(
        _clone(_get(documentQueryInitial, 'data.taggings', []))
      ).map((name) => ({ id: _snakeCase(name), text: name }));
      return editDocument;
    }
  }
  return undefined;
}

function mapStateToProps(state, props) {
  const initialValues = pickInitialValues(props.documentQuery, !!props.params.id);
  return {
    initialValues,
    currentContact: state.currentContact,
    currentSettingsMutating: state.currentSettings.mutating,
    tagsCollection: state.tags.collection,
    formValues: getFormValues('DocumentForm')(state),
  };
}

export default compose(
  graphql(documentCreateMutation, {
    name: 'documentCreateMutation',
  }),
  graphql(documentUpdateMutation, {
    name: 'documentUpdateMutation',
  }),
  graphql(documentCategoryListQuery, {
    name: 'documentCategoryListQuery',
  }),
  graphql(documentQuery, {
    name: 'documentQuery',
    skip: (props) => !props.params.id,
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'network-only',
    }),
  }),
  connect(mapStateToProps, {
    tagsCreate,
    changeFieldValue,
    mutationSuccess,
    mutationFailure,
    mutationSet,
  }),
  reduxForm({
    form: 'DocumentForm',
    // enableReinitialize: true,
    // keepDirtyOnReinitialize: true,
    validate,
  })
)(DocumentForm);
