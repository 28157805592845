import {
  Col,
  Modal,
  Button,
  Form,
  FormGroup,
  ControlLabel,
  Checkbox,
} from 'react-bootstrap';
import { Component } from 'react';

import _get from 'lodash/get';

class AircraftFlightTypePilot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pilotIds: [],
    };
    this._handleEditCancel = this._handleEditCancel.bind(this);
    this._handleEditSubmit = this._handleEditSubmit.bind(this);
    this._handleMultipleCheckboxChange = this._handleMultipleCheckboxChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ pilotIds: nextProps.pilotIds });
  }

  _handleEditSubmit() {
    if (this.props.handleEditSubmit) {
      this.props.handleEditSubmit(this.state.pilotIds);
    }
  }

  _handleEditCancel() {
    if (this.props.handleEditCancel) {
      this.props.handleEditCancel();
    }
  }

  _handleMultipleCheckboxChange(e) {
    const ids = [...this.state.pilotIds];
    const id = parseInt(e.target.value);
    if (id && ids) {
      const index = ids.indexOf(id);
      if (e.target.checked) {
        if (index === -1) {
          this.setState({
            pilotIds: [id, ...ids],
          });
        }
      } else if (index > -1) {
        ids.splice(index, 1);
        this.setState({
          pilotIds: ids,
        });
      }
    }
  }

  render() {
    const namedPilotsOnly = this.props.aircraft && this.props.aircraft.named_pilots_only;
    const namedPilotIds = _get(this.props, 'aircraft.namedPilots', []).map((np) => np.id);
    const form = (
      <div className="clearfix">
        <Form onSubmit={this._handleEditSubmit}>
          <FormGroup controlId="pilot_ids">
            <ControlLabel>
              {namedPilotsOnly
                ? 'Authorized Pilots (select from aircraft named list only)'
                : 'Authorized Pilots (select from all pilots)'}
            </ControlLabel>
            <Col sm={12}>
              {this.props.pilots
                .filter((model) =>
                  namedPilotsOnly ? namedPilotIds.includes(model.id) : model
                )
                .map((model) => {
                  const { id, display_name: displayName } = model;
                  return (
                    <Col sm={4} key={id}>
                      <Checkbox
                        value={id}
                        checked={this.state.pilotIds.indexOf(id) > -1}
                        onChange={this._handleMultipleCheckboxChange}
                      >
                        {displayName}
                      </Checkbox>
                    </Col>
                  );
                })}
            </Col>
          </FormGroup>
        </Form>
      </div>
    );
    return (
      <Modal show={this.props.isEditing} onHide={this._handleEditCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.editTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{form}</Modal.Body>
        <Modal.Footer>
          <Button type="button" bsStyle="danger" onClick={this._handleEditCancel}>
            Cancel
          </Button>
          <Button type="button" bsStyle="primary" onClick={this._handleEditSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AircraftFlightTypePilot;
