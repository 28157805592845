const ID_TOKEN_KEY = 'id_token';

export function setIdToken(idToken) {
  localStorage.setItem(ID_TOKEN_KEY, idToken);
}

export function clearIdToken() {
  localStorage.removeItem(ID_TOKEN_KEY);
}

export function getIdToken() {
  return localStorage.getItem(ID_TOKEN_KEY);
}
