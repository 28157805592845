import { Col, FormGroup, ControlLabel } from 'react-bootstrap';
import { Component } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
// import { validationState, validationText } from '../form/helpers'

import difference from 'lodash/difference';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import without from 'lodash/without';

class EmployeeInputField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employees: [],
      employeeOptions: [],
    };
    this._handleEmployeeSearch = this._handleEmployeeSearch.bind(this);
    this._handleEmployeeIdChange = this._handleEmployeeIdChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (get(this.props, 'formValues.booking_employees_attributes')) {
      this.setState({
        employees: this._getEmployees(this.props),
      });
    }
  }

  componentDidMount() {
    this.inputRef.focus();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { employees } = this.state;
    let { employeeOptions } = this.state;
    if (
      !isEqual(
        get(this.props, 'formValues.booking_employees_attributes'),
        get(nextProps, 'formValues.booking_employees_attributes')
      )
    ) {
      employees = this._getEmployees(nextProps);
    }
    if (
      !isEqual(
        this.props.employeesTypeaheadCollection,
        nextProps.employeesTypeaheadCollection
      )
    ) {
      employeeOptions = this._getEmployeeOptions(nextProps, employees);
    }
    this.setState({
      employees,
      employeeOptions,
    });
  }

  _getEmployees(props) {
    if (get(props, 'formValues.booking_employees_attributes')) {
      return get(props, 'formValues.booking_employees_attributes')
        .filter((bookingEmployee) => !bookingEmployee._destroy)
        .map((bookingEmployee) => {
          const employee = props.employees.find(
            (employee) => employee.id === bookingEmployee.employee_id
          );
          return {
            id: bookingEmployee.employee_id,
            label: get(employee, 'fullName', '404'),
            flightNotes: get(employee, 'flight_notes'),
          };
        });
    } else {
      return [];
    }
  }

  _getEmployeeOptions(props, employees = this.state.employees) {
    const rejectIds = [...employees.map((c) => c.id), 0];
    return props.employeesTypeaheadCollection.filter(
      (typeahead) => !includes(rejectIds, typeahead.id)
    );
  }

  _handleEmployeeSearch(value) {
    if (this.props.handleEmployeeSearch) {
      this.props.handleEmployeeSearch(value);
    }
  }

  _handleEmployeeIdChange(employees) {
    const currentIds = get(this.props, 'formValues.booking_employees_attributes')
      .filter((bookingEmployee) => !bookingEmployee._destroy)
      .map((bc) => bc.employee_id);
    const newIds = employees.map((c) => c.id);
    const deleteIds = difference(currentIds, newIds);
    let addIds = difference(newIds, currentIds);

    this.props.bookingEmployees.fields.forEach((name, index, fields) => {
      const {
        id,
        employee_id: employeeId,
        _destroy,
      } = get(this.props, `formValues.${name}`);
      if (includes(deleteIds, employeeId)) {
        if (id) {
          this.props.change(`${name}._destroy`, true);
        } else {
          fields.remove(index);
        }
      } else if (includes(addIds, employeeId) && _destroy) {
        addIds = without(addIds, employeeId);
        this.props.change(`${name}._destroy`, false);
      }
    });
    if (addIds.length > 0) {
      addIds.forEach((addId) => {
        this.props.bookingEmployees.fields.push({ employee_id: addId });
      });
    }
    if (this.props.handleEmployeeSearchClear) {
      this.props.handleEmployeeSearchClear();
    }
  }

  render() {
    return (
      <FormGroup bsSize="sm" controlId="employee_ids">
        <Col sm={12}>
          <AsyncTypeahead
            id="typeahead-employeeids"
            ref={(el) => {
              this.inputRef = el;
            }}
            isLoading={this.props.typeaheadFetching}
            multiple
            useCache={false}
            onSearch={this._handleEmployeeSearch}
            onChange={this._handleEmployeeIdChange}
            options={this.state.employeeOptions}
            selected={this.state.employees}
            filterBy={['searchable_label']}
          />
        </Col>
      </FormGroup>
    );
  }
}

export default EmployeeInputField;
