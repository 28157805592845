import { renderToStaticMarkup } from 'react-dom/server';
import $ from 'jquery';
import 'fullcalendar-popover';

const renderFlightSegmentSummaryRow1 = (flightSegmentSummary) => {
  const {
    start_at: startAt,
    start_location: startLocation,
    end_location: endLocation,
  } = flightSegmentSummary;
  return (
    <tr>
      <td
        rowSpan={2}
        style={{
          border: 'none',
          padding: '0 10px 0 0',
          width: '46px',
          whiteSpace: 'nowrap',
        }}
        className="text-right"
      >
        <small>{startAt}</small>
      </td>
      <td style={{ border: 'none', padding: '0' }} className="text-left">
        <small>{`${startLocation} - ${endLocation}`}</small>
      </td>
    </tr>
  );
};

const renderFlightSegmentSummaryRow2 = (flightSegmentSummary) => {
  const { pax } = flightSegmentSummary;
  return (
    <tr>
      <td style={{ border: 'none', padding: '0' }} className="text-left">
        <small>
          {pax ? (
            <span className="glyphicon glyphicon-user" style={{ paddingRight: '5px' }} />
          ) : (
            ''
          )}
          {pax}
        </small>
      </td>
    </tr>
  );
};

const renderFlight = (flightSegmentSummaries) => (
  <table className="table table-condensed" style={{ marginBottom: '0' }}>
    {flightSegmentSummaries.map((flightSegmentSummary) => (
      <tbody key={flightSegmentSummary.id}>
        {renderFlightSegmentSummaryRow1(flightSegmentSummary)}
        {renderFlightSegmentSummaryRow2(flightSegmentSummary)}
      </tbody>
    ))}
  </table>
);

const renderNonFlight = (title, jobNotes) => <span>{jobNotes || title}</span>;

const renderEventPopover = (info) => {
  const {
    event: {
      title,
      extendedProps: {
        eventType,
        isFlight,
        chargeableFullNames,
        employeeFullNames,
        jobNotes,
        flightSegmentSummaries,
      },
    },
    el,
  } = info;

  if (eventType === 'Solar') {
    return;
  }

  const content = isFlight
    ? renderFlight(flightSegmentSummaries)
    : renderNonFlight(title, jobNotes);

  $(el).popover({
    html: true,
    title: isFlight ? chargeableFullNames : employeeFullNames,
    placement: 'bottom',
    content: renderToStaticMarkup(content),
    container: '#bookingCalendar',
    trigger: 'hover',
  });
};

export default renderEventPopover;
