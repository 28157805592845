import { Row, Col, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { Component } from 'react';

import { graphql } from '@apollo/client/react/hoc';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import { currentSettingsSet } from '../actions/current_setting_actions';

import SmsActionListTable from '../components/sms/sms_action_list_table';

import contactItemListQuery from '../queries/contact_item_list_query';

import smsActionListQuery from '../queries/sms_action_list_query';
import smsActionDeleteMutation from '../mutations/sms_action_delete_mutation';

import { queriesReady } from '../lib/utils';
import Loader from '../components/loader';

class SmsActionList extends Component {
  constructor(props) {
    super(props);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRouteSmsAction: this.props.location.pathname });
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const nextProps = props || this.props;
    return !queriesReady(
      nextProps.smsActionListQuery,
      nextProps.managerListQuery,
      nextProps.employeeListQuery
    );
  }

  handleDeleteClick(smsActionId) {
    this.props.mutationSet(true);
    this.props
      .smsActionDeleteMutation({
        variables: {
          id: smsActionId,
        },
        refetchQueries: () => [{ query: smsActionListQuery }],
      })
      .then(() => {
        this.props.mutationSuccess('SMS Action delete');
      })
      .catch((err) => {
        this.props.mutationFailure(err);
      });
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return null;
  }

  renderData() {
    if (this.isLoaded()) {
      return (
        <div>
          <Row>
            <Col sm={12}>
              <div className="clearfix">
                <div className="pull-left">
                  <h3>SMS Actions</h3>
                </div>
                <div className="pull-right">
                  <div className="pull-right">
                    <ButtonToolbar>
                      <ButtonGroup>
                        <LinkContainer to="/sms/actions/new">
                          <Button bsStyle="primary">Add a SMS Action</Button>
                        </LinkContainer>
                      </ButtonGroup>
                    </ButtonToolbar>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <SmsActionListTable
            actions={this.props.smsActionListQuery.data}
            handleDeleteClick={this.handleDeleteClick}
            managers={this.props.managerListQuery.data}
            employees={this.props.employeeListQuery.data}
            withDelete
            withEdit
          />
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div>
        {this.renderOverlay()}
        {this.renderData()}
      </div>
    );
  }
}

SmsActionList.propTypes = {
  // filter_ids: PropTypes.array.isRequired,
  // filtering: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default compose(
  graphql(smsActionDeleteMutation, {
    name: 'smsActionDeleteMutation',
  }),
  graphql(smsActionListQuery, {
    name: 'smsActionListQuery',
  }),
  graphql(contactItemListQuery, {
    name: 'managerListQuery',
    options: { variables: { role: 'manager' } },
  }),
  graphql(contactItemListQuery, {
    name: 'employeeListQuery',
    options: { variables: { role: 'employee' } },
  }),
  connect(mapStateToProps, {
    mutationSuccess,
    mutationFailure,
    mutationSet,
    currentSettingsSet,
  })
)(SmsActionList);
