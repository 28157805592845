import { Table, Button } from 'react-bootstrap';
import { Component } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import Confirm from '../confirm';

class SmsControlListTable extends Component {
  constructor(props) {
    super(props);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleCreateActionClick = this.handleCreateActionClick.bind(this);
  }

  handleDeleteClick(e) {
    if (this.props.handleDeleteClick) {
      const smsControlId = e.currentTarget.getAttribute('data-id');
      this.props.handleDeleteClick(smsControlId);
    }
  }

  handleCreateActionClick(e) {
    const controlId = e.currentTarget.getAttribute('data-id');
    if (this.props.handleCreateActionClick) {
      this.props.handleCreateActionClick(controlId);
    }
  }

  render() {
    return (
      <div>
        <Table hover>
          <thead>
            <tr>
              <th>Id</th>
              <th>Description</th>
              <th>Actions</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {this.props.controls &&
              this.props.controls.map((model) => {
                const { id, description, actions } = model;
                return (
                  <tr key={id}>
                    <td>{id}</td>
                    <td>{description}</td>
                    <td>
                      {actions &&
                        actions.map((action) => (
                          <span key={action.id}>
                            {action.description}
                            <br />
                          </span>
                        ))}
                      {this.props.withCreateAction && (
                        <Button
                          data-id={id}
                          bsStyle="link"
                          bsSize="xsmall"
                          onClick={this.handleCreateActionClick}
                          style={{ paddingLeft: 0 }}
                        >
                          Add action...
                        </Button>
                      )}
                    </td>
                    <td>
                      {this.props.withEdit && (
                        <LinkContainer to={`/sms/controls/${id}/edit`}>
                          <Button bsStyle="link" bsSize="xsmall">
                            edit
                          </Button>
                        </LinkContainer>
                      )}
                      {this.props.withDelete && (
                        <Confirm
                          confirmId={`sms-control-delete-${id}`}
                          dataId={id}
                          onConfirm={this.handleDeleteClick}
                          title="Delete Control"
                          body="Are you sure you want to delete this control"
                          confirmText="Confirm"
                        >
                          <Button bsStyle="link" bsSize="xsmall">
                            delete
                          </Button>
                        </Confirm>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default SmsControlListTable;
