import get from 'lodash/get';
import memoize from 'lodash/memoize';

import { AircraftWeightDataMemo } from './aircraft_weight_data_memo';

export const BookingWeightDataMemo = memoize(
  ({ pilotId, copilotId, pilots, aircraftId, aircrafts, aircraftTypes }) => {
    if (aircraftId) {
      const aircraftWeightData = AircraftWeightDataMemo({
        aircraftId,
        aircrafts,
        aircraftTypes,
      });
      const pilot = pilots.find((p) => p.id === parseInt(pilotId));
      const copilot = pilots.find((p) => p.id === parseInt(copilotId));
      const weightData = Object.assign({}, aircraftWeightData, {
        pilotWeight: get(pilot, 'weight', 0),
        copilotWeight: get(copilot, 'weight', 0),
      });
      weightData.aircraftGrossWeight =
        get(weightData, 'aircraftTypeMaximumAllUpWeight', 0) -
        get(weightData, 'aircraftWeight', 0) -
        get(weightData, 'pilotWeight', 0) -
        get(weightData, 'copilotWeight', 0);
      return weightData;
    } else {
      return {};
    }
  },
  ({ aircraftId, pilotId, copilotId }) => {
    if (aircraftId) {
      return [aircraftId, pilotId, copilotId].map((i) => String(i)).join('.');
    } else {
      return 'unknown';
    }
  }
);
