import memoize from 'lodash/memoize';

const FlightPlanTableHeader = memoize(
  (props) => {
    if (props.hasAircraft) {
      return (
        <thead>
          <tr className="text-center">
            <th>Leg</th>
            <th className="text-center">
              Calc Dist
              <br />
              (nm)
            </th>
            <th className="text-center">
              Pilot Dist
              <br />
              (nm)
            </th>
            <th className="text-center">
              HWC
              <br />
              (+/- knots)
            </th>
            <th className="text-center">Leg ETE (min)</th>
            <th className="text-center">
              Leg Fuel
              <br />
              (kg / lt)
            </th>
            <th className="text-center">
              Refuelling
              <br />
              (+/- kg)
            </th>
            <th className="text-center">
              Start Fuel Calc
              <br />
              (kg / lt)
            </th>
            <th className="text-center">Pax & Bags (kg)</th>
            <th className="text-center">R-Payload (kg)</th>
            <th className="text-center">Bal?</th>
          </tr>
        </thead>
      );
    } else {
      return null;
    }
  },
  (props) => [props.hasAircraft].map((i) => String(i)).join('.')
);

export default FlightPlanTableHeader;
