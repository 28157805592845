import { createUseStyles } from 'react-jss';
import { Outlet } from 'react-router-dom';

const useStyles = createUseStyles({
  heightWrapper: {
    height: 'calc(100vh - 80px)', // - header - no footer
  },
  scrollWrapper: {
    height: '100%',
  },
});

export default function FluidAndNoScrollLayout() {
  const classes = useStyles();
  return (
    <div className={classes.heightWrapper}>
      <div className={classes.scrollWrapper}>
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
