import { Component } from 'react';
import {
  Row,
  Col,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  Button,
  Glyphicon,
} from 'react-bootstrap';
import { validationState, validationText } from './helpers';
import Confirm from '../confirm';

const { FileReader } = window;

class DocumentFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileExtension: this.props.document_file_extension.toLowerCase(),
    };
    this._handleDeleteClick = this._handleDeleteClick.bind(this);
    this._handleUpload = this._handleUpload.bind(this);
  }

  _handleDeleteClick() {
    if (this.props.onDeleteClicked) {
      this.props.onDeleteClicked(this.props.index, this.props.values.id);
    }
  }

  _handleUpload(e) {
    const file = e.target.files[0];
    let fileExtension = 'unknown';
    const fileParts = file.name.split('.');
    if (fileParts.length > 1) {
      fileExtension = fileParts[fileParts.length - 1];
    }
    this.setState({
      fileExtension: fileExtension.toLowerCase(),
    });
    if (this.props.onUpload) {
      this.props.onUpload(this.props.index, file);
    }
  }

  render() {
    const {
      documents_attributes: {
        [this.props.index]: { document, description },
      },
      values: { id, document_file_name: fileName },
      labelWidth,
      inputWidth,
      bsSize,
      groupStyle,
      inputStyle,
    } = this.props;

    return (
      <Col smOffset={labelWidth} sm={inputWidth}>
        <fieldset>
          <legend>
            <small>{fileName || 'New document'}</small>
          </legend>
          <Row>
            <Col sm={2}>
              <span
                style={{ marginTop: '12px' }}
                className={`filetypes x2 filetypes-${this.state.fileExtension}`}
              />
            </Col>
            <Col sm={6}>
              <FormGroup
                controlId={description.input.name}
                validationState={validationState(description.meta)}
                {...(bsSize && { bsSize })}
                {...(groupStyle && { style: groupStyle })}
              >
                <ControlLabel>Description</ControlLabel>
                <FormControl
                  {...description.input}
                  {...(inputStyle && { style: inputStyle })}
                />
                <HelpBlock>{validationText(description.meta, 'required')}</HelpBlock>
              </FormGroup>
            </Col>
            <Col sm={4}>
              <div style={{ marginTop: '30px' }}>
                <Confirm
                  onConfirm={this._handleDeleteClick}
                  title="Delete Document"
                  body={
                    id
                      ? 'Are you sure you want to delete this document? Note that while the document will be removed from the list, it will not be permanently deleted until you save this record.'
                      : 'Are you sure you want to delete this document?'
                  }
                  confirmText="Confirm"
                >
                  <Button type="button" bsStyle="default" bsSize="small">
                    <Glyphicon glyph="trash" />
                  </Button>
                </Confirm>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={6} smOffset={2}>
              <FormGroup
                controlId={document.input.name}
                validationState={validationState(document.meta)}
                {...(bsSize && { bsSize })}
                {...(groupStyle && { style: groupStyle })}
              >
                <ControlLabel>Document Upload</ControlLabel>
                <FormControl
                  type="file"
                  value={undefined}
                  onChange={this._handleUpload}
                  {...(inputStyle && { style: inputStyle })}
                />
                <HelpBlock>{validationText(document.meta)}</HelpBlock>
              </FormGroup>
            </Col>
          </Row>
        </fieldset>
      </Col>
    );
  }
}

DocumentFields.defaultProps = {
  labelWidth: 3,
  inputWidth: 9,
  helpText: '',
};

export default DocumentFields;
