import {
  Row,
  Col,
  Panel,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
} from 'react-bootstrap';
import { Component } from 'react';
import moment from 'moment';

import { Field, Fields, FieldArray } from 'redux-form';

import get from 'lodash/get';
import defaultTo from 'lodash/defaultTo';
import pick from 'lodash/pick';

import { validationState, validationText } from '../form/helpers';

import SeatAssignments from './seat_assignments';
import FlightSegmentPanelHeader from './flight_segment_panel_header';
import FlightSegmentStartDateLocationAndTimeInputFields from './flight_segment_start_date_location_and_time_input_fields';
import FlightSegmentEndDateLocationAndTimeInputFields from './flight_segment_end_date_location_and_time_input_fields';

moment.updateLocale('en-nz');

class FlightSegment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: !get(this.props, 'formValues.id'),
    };
    this._renderPanelHeader = this._renderPanelHeader.bind(this);
    this._handleToggleClicked = this._handleToggleClicked.bind(this);
    this._renderLuggageWeightInput = this._renderLuggageWeightInput.bind(this);
    this._renderSeatAssignmentsFieldArray =
      this._renderSeatAssignmentsFieldArray.bind(this);
    this._onToggle = this._onToggle.bind(this);
  }

  isSearching(startend) {
    const [flightSegmentIndex, startEnd] = this.props.locationsTypeaheadIndex;
    return flightSegmentIndex === this.props.index && startEnd === startend;
  }

  _handleToggleClicked() {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  _onToggle(e) {
    // todo this doesn't seem to do anything but props error if not provided in expanded controlled panel
    console.log(e);
  }

  _renderPanelHeader() {
    const { index, activeIndex, isXs, isMd, bookingPilotId, bookingAircraftId } =
      this.props;
    const formKeys = [
      'id',
      'start_at',
      'start_critical',
      'start_location_id',
      'end_location_id',
      'seat_assignments_attributes',
    ];
    return (
      <FlightSegmentPanelHeader
        index={index}
        activeIndex={activeIndex}
        isXs={isXs}
        isMd={isMd}
        bookingPilotId={bookingPilotId}
        bookingAircraftId={bookingAircraftId}
        alertFuelOver={this.props.alertFuelOver}
        alertFuelUnder={this.props.alertFuelUnder}
        bookingPassengerSeats={this.props.bookingPassengerSeats}
        handleDeleteClicked={this.props.handleDeleteClicked}
        handleToggleClicked={this._handleToggleClicked}
        handleCriticalLocationModalClicked={this.props.handleCriticalLocationModalClicked}
        handleAddFlightSegmentModalClicked={this.props.handleAddFlightSegmentModalClicked}
        aircraftsDataSelector={this.props.aircraftsDataSelector}
        contactsDataSelector={this.props.contactsDataSelector}
        locationsDataSelector={this.props.locationsDataSelector}
        {...pick(this.props.formValues, formKeys)}
      />
    );
  }

  _styleNetWeight() {
    const style = { padding: 0, height: 'inherit' };
    if (this.props.aircraftNetWeight) {
      if (this.props.aircraftNetWeight - this.props.startFuelCalcKg < 0) {
        style.color = 'red';
        style.fontWeight = 'bold';
      }
    }
    return style;
  }

  _renderNetWeight() {
    if (this.props.aircraftNetWeight) {
      return `${defaultTo(
        Math.round(this.props.aircraftNetWeight - this.props.startFuelCalcKg),
        '?'
      )}kg`;
    } else {
      return '-';
    }
  }

  _renderDistance() {
    if (this.props.adjustedFlightDistance && this.props.adjustedFlightDistance > 0) {
      const kms = Math.round(this.props.adjustedFlightDistance / 1000);
      const nms = Math.round(kms * 0.539957);
      return `${nms}NM`;
    } else {
      return '-';
    }
  }

  _renderFlighttime() {
    if (this.props.loadedFlighttime && this.props.loadedFlighttime > 0) {
      const loadedFlighttimeDuration = moment.duration(
        this.props.loadedFlighttime,
        'minutes'
      );
      const hours = `${loadedFlighttimeDuration.hours()}`;
      const mins = `${loadedFlighttimeDuration.minutes()}`;
      const flightTime = `${'00'.substring(0, 2 - hours.length) + hours}:${
        '00'.substring(0, 2 - mins.length) + mins
      }`;
      return flightTime;
    } else {
      return '-';
    }
  }

  _renderLuggageWeightInput(props) {
    const { input, meta } = props;

    return (
      <FormGroup
        bsSize="sm"
        controlId={`booking-flight-segment-${this.props.index}-luggage_weight`}
        validationState={validationState(meta)}
      >
        <FormControl type="text" placeholder="luggage kg" tabIndex={-1} {...input} />
        <HelpBlock>{validationText(meta)}</HelpBlock>
      </FormGroup>
    );
  }

  _renderSeatAssignmentsFieldArray(seatAssignments) {
    const formKeys = ['seat_assignments_attributes'];
    return (
      <SeatAssignments
        flightSegmentIndex={this.props.index}
        isXs={this.props.isXs}
        updating={this.props.updating}
        seatAssignments={seatAssignments}
        {...pick(this.props.formValues, formKeys)}
        bookingAircraftId={this.props.bookingAircraftId}
        bookingPassengerSeats={this.props.bookingPassengerSeats}
        addingPassenger={this.props.addingPassenger}
        typeaheadFetching={this.props.typeaheadFetching}
        passengersTypeaheadIndex={this.props.passengersTypeaheadIndex}
        passengersTypeaheadCollection={this.props.passengersTypeaheadCollection}
        handlePassengerSearch={this.props.handlePassengerSearch}
        handleAddPassengerModalClicked={this.props.handleAddPassengerModalClicked}
        handlePassengerChanged={this.props.handlePassengerChanged}
      />
    );
  }

  _renderPanelBody() {
    const { field, index, updating } = this.props;

    return (
      <Row>
        <Col sm={3}>
          <Fields
            field={field}
            index={index}
            updating={updating}
            names={[`${field}.start_at`, `${field}.start_location_id`]}
            component={FlightSegmentStartDateLocationAndTimeInputFields}
            addingLocation={this.props.addingLocation}
            handleAddLocationClicked={this.props.handleAddLocationClicked}
            handleAddLocationModalClicked={this.props.handleAddLocationModalClicked}
            handleLocationChanged={this.props.handleStartLocationChanged}
            handleLocationSearch={this.props.handleLocationSearch}
            typeaheadFetching={
              this.isSearching('start') ? this.props.typeaheadFetching : false
            }
            locationsTypeaheadCollection={this.props.locationsTypeaheadCollection}
            tog={this.props.tog}
            prevEndAt={this.props.prevEndAt}
            locationsDataSelector={this.props.locationsDataSelector}
            {...pick(this.props.formValues, [
              'start_at',
              'start_location_id',
              'start_critical',
            ])}
          />
          <Fields
            field={field}
            index={index}
            updating={updating}
            names={[`${field}.end_at`, `${field}.end_location_id`]}
            component={FlightSegmentEndDateLocationAndTimeInputFields}
            addingLocation={this.props.addingLocation}
            handleAddLocationClicked={this.props.handleAddLocationClicked}
            handleAddLocationModalClicked={this.props.handleAddLocationModalClicked}
            handleLocationChanged={this.props.handleEndLocationChanged}
            handleLocationSearch={this.props.handleLocationSearch}
            typeaheadFetching={
              this.isSearching('end') ? this.props.typeaheadFetching : false
            }
            locationsTypeaheadCollection={this.props.locationsTypeaheadCollection}
            loadedFlighttime={this.props.loadedFlighttime}
            locationsDataSelector={this.props.locationsDataSelector}
            {...pick(this.props.formValues, [
              'end_at',
              'end_location_id',
              'start_at',
              'end_critical',
            ])}
          />
        </Col>
        <Col sm={7}>
          <FieldArray
            name={`${field}.seat_assignments_attributes`}
            component={this._renderSeatAssignmentsFieldArray}
          />
        </Col>
        <Col sm={2}>
          <Row>
            <Col xs={6} className="compact_r">
              <FormGroup bsSize="sm">
                <ControlLabel>Distance</ControlLabel>
                <FormControl.Static style={{ padding: 0, height: 'inherit' }}>
                  {this._renderDistance()}
                </FormControl.Static>
              </FormGroup>
            </Col>
            <Col xs={6} className="compact_l">
              <FormGroup bsSize="sm">
                <ControlLabel>Flt/Time</ControlLabel>
                <FormControl.Static style={{ padding: 0, height: 'inherit' }}>
                  {this._renderFlighttime()}
                </FormControl.Static>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={6} className="compact_r">
              <Field
                type="text"
                name={`${field}.luggage_weight`}
                component={this._renderLuggageWeightInput}
              />
            </Col>
            <Col xs={6} className="compact_l">
              <FormGroup bsSize="sm">
                <FormControl.Static style={this._styleNetWeight()}>
                  {this._renderNetWeight()}
                </FormControl.Static>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  render() {
    const { isMd } = this.props;

    if (!isMd) {
      return (
        <Panel
          style={{ marginBottom: '10px' }}
          expanded={isMd ? true : this.state.expanded}
          onToggle={this._onToggle}
        >
          <Panel.Heading>{this._renderPanelHeader()}</Panel.Heading>
          <Panel.Collapse>
            <Panel.Body>{this._renderPanelBody()}</Panel.Body>
          </Panel.Collapse>
        </Panel>
      );
    } else {
      return (
        <Panel style={{ marginBottom: '10px' }}>
          <Panel.Heading>{this._renderPanelHeader()}</Panel.Heading>
          <Panel.Body>{this._renderPanelBody()}</Panel.Body>
        </Panel>
      );
    }
  }
}

export default FlightSegment;
