import moment from 'moment';
import SunCalc from 'suncalc';

import _compact from 'lodash/compact';
import _clone from 'lodash/clone';
import _flatten from 'lodash/flatten';
import _includes from 'lodash/includes';

import { paramCase, sentenceCase } from 'change-case';
import { isCalendarType, isAdminComplete, isPilotComplete } from '../lib/bookingHelpers';

const getBookingEventClasses = (booking, isFlight, isPlaceholder) => {
  const {
    calendar_type: calendarType,
    status,
    end_at: endAt,
    tia_tog_profile: tiaTogProfile,
  } = booking;
  const classNames = [calendarType];
  if (tiaTogProfile && tiaTogProfile.length > 0) {
    classNames.push('profiled');
  }
  const adminComplete = isAdminComplete(booking);
  const pilotComplete = isPilotComplete(booking);
  if (isPlaceholder) {
    classNames.push('placeholder');
  } else if (adminComplete) {
    classNames.push('admin_complete');
  } else if (pilotComplete) {
    classNames.push('pilot_complete');
  } else if (!isFlight && moment(endAt).isBefore(moment())) {
    classNames.push('time_complete');
  } else {
    classNames.push(status);
  }
  return classNames;
};

const getBookingEmployeeEvent = (booking, isFlight, calendarView) => {
  const isPlaceholder = false;
  const {
    id,
    start_at: startAt,
    end_at: endAt,
    calendar_type: calendarType,
    job_notes: jobNotes,
    employees,
  } = booking;
  const employeeFullNames = employees.map((e) => e.fullName).join(', ');
  const employeeEvents = employees.map((employee) => {
    const { id: employeeId, fullName, employee_color: employeeColor } = employee;
    if (fullName) {
      return {
        id: `${id}_employee_${employeeId}`,
        title: sentenceCase(calendarType),
        resourceType: 'Contact',
        resourceId: paramCase(fullName),
        start: startAt,
        end: endAt,
        classNames: getBookingEventClasses(booking, isFlight, isPlaceholder),
        textColor: 'inherit',
        extendedProps: {
          eventType: 'Booking',
          bookingId: id,
          jobNotes,
          employeeFullNames,
          contactFullName: fullName,
          contactColor: employeeColor || '#3a87ad',
          isFlight,
          isPlaceholder,
        },
      };
    }
    return undefined;
  });
  if (calendarView !== 'resourceTimelineDay') {
    return _compact(employeeEvents).map((event) => {
      if (moment(event.end).dayOfYear() !== moment(event.start).dayOfYear()) {
        const allDayEvent = _clone(event);
        allDayEvent.allDay = true;
        allDayEvent.extendedProps.start = moment(event.start).format();
        allDayEvent.extendedProps.end = moment(event.end).format();
        allDayEvent.start = moment(event.start).startOf('day').format();
        allDayEvent.end = moment(event.end).add(1, 'days').startOf('day').format();
        return allDayEvent;
      }
      return event;
    });
  }
  return _compact(employeeEvents);
};

const getBookingFlightEvent = (booking, isFlight, aircraftIds) => {
  const {
    id,
    aircraft,
    start_at: startAt,
    end_at: endAt,
    pilot,
    copilot,
    flightSegmentSummaries,
    chargeables,
    tia_tog_profile: tiaTogProfile,
  } = booking;
  let flightEvents = [];

  const { id: aircraftId, registration_abbreviated: registrationAbbreviated } = aircraft;
  const chargeableFullNames = chargeables.map((c) => c.fullName).join(', ');
  const isPlaceholder = !_includes(aircraftIds, aircraftId);

  const adminComplete = isAdminComplete(booking);

  if (pilot) {
    const {
      id: pilotId,
      fullName: pilotFullName,
      employee_color: pilotEmployeeColor,
    } = pilot;
    flightEvents = [
      {
        id: `${id}_pilot_${pilotId}`,
        title: registrationAbbreviated,
        resourceType: 'Contact',
        resourceId: paramCase(pilotFullName),
        start: startAt,
        end: endAt,
        classNames: getBookingEventClasses(booking, isFlight, isPlaceholder),
        textColor: 'inherit',
        extendedProps: {
          eventType: 'Booking',
          bookingId: id,
          adminComplete,
          flightSegmentSummaries,
          tiaTogProfile,
          chargeableFullNames,
          contactFullName: pilotFullName,
          contactColor: pilotEmployeeColor || '#3a87ad',
          isFlight,
          isPlaceholder,
        },
      },
    ];
    if (copilot) {
      const {
        id: copilotId,
        fullName: copilotFullName,
        employee_color: copilotEmployeeColor,
      } = copilot;
      flightEvents.push({
        id: `${id}_copilot_${copilotId}`,
        title: registrationAbbreviated,
        resourceType: 'Contact',
        resourceId: paramCase(copilotFullName),
        start: startAt,
        end: endAt,
        classNames: getBookingEventClasses(booking, isFlight, isPlaceholder),
        textColor: 'inherit',
        extendedProps: {
          eventType: 'Booking',
          bookingId: id,
          adminComplete,
          flightSegmentSummaries,
          tiaTogProfile,
          chargeableFullNames,
          contactFullName: copilotFullName,
          contactColor: copilotEmployeeColor || '#3a87ad',
          isFlight,
          isPlaceholder,
        },
      });
    }
  }
  return _compact(flightEvents);
};

const getBookingEvent = (booking, aircraftIds, calendarTypeFlight, calendarView) => {
  const { employees: bookingEmployees } = booking;
  let events = [];
  if (isCalendarType(calendarTypeFlight, booking)) {
    events = getBookingFlightEvent(booking, true, aircraftIds);
  }
  if (bookingEmployees.length > 0) {
    events = getBookingEmployeeEvent(booking, false, calendarView);
  }
  return _compact(_flatten(events));
};

const getSunEvents = (startStr, endStr) => {
  const sunEvents = [];
  const start = moment(startStr);
  const end = moment(endStr);
  const startOfDay = start.clone();
  while (startOfDay.isSameOrBefore(end)) {
    const endOfDay = startOfDay.clone().endOf('day');
    const times = SunCalc.getTimes(
      startOfDay.clone().add(12, 'hours').toDate(),
      -36.848461,
      174.763336
    );
    sunEvents.push({
      color: 'grey',
      start: startOfDay.toISOString(),
      end: moment(times.dawn).toISOString(),
      display: 'background',
      extendedProps: {
        eventType: 'Solar',
      },
    });
    sunEvents.push({
      color: 'grey',
      start: moment(times.dusk).toISOString(),
      end: endOfDay.toISOString(),
      display: 'background',
      extendedProps: {
        eventType: 'Solar',
      },
    });
    startOfDay.add(1, 'day');
  }
  return sunEvents;
};

const getEvents = (
  bookings,
  aircraftGroup,
  currentSettingsBookingCollectionStartDate,
  currentSettingsBookingCollectionEndDate,
  currentSettingsBookingCollectionView,
  currentSettingsBookingCalendarTypeFlight
) => {
  const aircraftIds = aircraftGroup.aircrafts.map((a) => a.id);
  const bookingEvents = _compact(
    _flatten(
      bookings.map((booking) =>
        getBookingEvent(
          booking,
          aircraftIds,
          currentSettingsBookingCalendarTypeFlight,
          currentSettingsBookingCollectionView
        )
      )
    )
  );
  let sunEvents = [];
  if (currentSettingsBookingCollectionView !== 'dayGridMonth') {
    sunEvents = _compact(
      _flatten(
        getSunEvents(
          currentSettingsBookingCollectionStartDate,
          currentSettingsBookingCollectionEndDate
        )
      )
    );
  }
  return [...bookingEvents, ...sunEvents];
};

export default getEvents;
