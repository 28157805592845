import { Component } from 'react';
// import PropTypes from 'prop-types'
import { graphql } from '@apollo/client/react/hoc';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Row, Col, Table, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import Confirm from '../components/confirm';

// import _debounce from 'lodash/debounce'
// import _last from 'lodash/last'

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import smsReportListQuery from '../queries/sms_report_list_query';
import smsReportDeleteMutation from '../mutations/sms_report_delete_mutation';

import { queriesReady } from '../lib/utils';
import Loader from '../components/loader';

class SmsReportList extends Component {
  constructor(props) {
    super(props);
    // this._handleFilter = this._handleFilter.bind(this)
    this._handleDeleteClick = this._handleDeleteClick.bind(this);
  }

  // componentWillReceiveProps (nextProps) {
  //   if (nextProps.filter_q && this.isLoading(this.props) && this.isLoaded(nextProps)) {
  //     this.delayedHandleFilter({ target: { value: nextProps.filter_q } })
  //   }
  // }

  // componentWillUnmount () {
  //   this.delayedHandleFilter.cancel()
  // }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(props.smsReportListQuery);
  }

  _handleDeleteClick(e) {
    this.props.mutationSet(true);
    const smsReportId = e.currentTarget.getAttribute('data-id');
    this.props
      .smsReportDeleteMutation({
        variables: {
          id: smsReportId,
        },
        refetchQueries: () => [{ query: smsReportListQuery }],
      })
      .then((res) => {
        this.props.mutationSuccess('SMS Report delete');
      })
      .catch((err) => {
        this.props.mutationFailure(err);
      });
  }

  // _handleFilter (e) {
  //   e.persist()
  //   this.delayedHandleFilter(e)
  // }

  _renderOverlay() {
    // if (this.props.filtering || this.props.currentSettingsMutating || this.isLoading()) { return <Loader /> }
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
  }

  _renderData() {
    if (this.isLoaded()) {
      // const filtered = this.props.filter_q
      return (
        <div>
          <Row>
            <Col sm={12}>
              <div className="clearfix">
                <div className="pull-left">
                  <h3>SMS Reports</h3>
                </div>
                <div className="pull-right">
                  {/*
                    <FormControl
                      style={{display: 'inline-block', verticalAlign: 'middle', width: '200px', marginRight: '10px'}}
                      type='text'
                      placeholder='filter...'
                      defaultValue={this.props.filter_q}
                      onChange={this._handleFilter}
                    />
                  */}
                  <div className="pull-right">
                    <ButtonToolbar>
                      <ButtonGroup>
                        <LinkContainer to="/sms/reports/new">
                          <Button bsStyle="primary">Add a SMS Report</Button>
                        </LinkContainer>
                      </ButtonGroup>
                    </ButtonToolbar>
                  </div>
                  {/*
                    <span
                      className='badge'
                      style={filtered ? { marginRight: '10px' } : { marginRight: '10px', opacity: '0.5' }}
                    >{filtered ? this.props.filter_ids.length : this.props.smsReportListQuery.data.length }</span>
                  */}
                </div>
              </div>
            </Col>
          </Row>
          <Table hover className="vertical-align">
            <thead>
              <tr>
                <th>Id</th>
                <th>Short Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.props.smsReportListQuery.data
                // .filter( model => { return filtered ? this.props.filter_ids.includes(model.id) : model })
                .map((model) => {
                  const { id, shortDescription } = model;
                  return (
                    <tr key={id}>
                      <td>{id}</td>
                      <td>{shortDescription}</td>
                      <td>
                        <Confirm
                          confirmId={`sms-report-delete-${id}`}
                          dataId={id}
                          onConfirm={this._handleDeleteClick}
                          title="Delete Report"
                          body="Are you sure you want to delete this report"
                          confirmText="Confirm"
                        >
                          <Button bsStyle="link" bsSize="xsmall">
                            delete
                          </Button>
                        </Confirm>
                        <LinkContainer to={`/sms/reports/${id}/edit`}>
                          <Button bsStyle="link" bsSize="xsmall">
                            edit
                          </Button>
                        </LinkContainer>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        {this._renderOverlay()}
        {this._renderData()}
      </div>
    );
  }
}

SmsReportList.propTypes = {
  // filter_ids: PropTypes.array.isRequired,
  // filtering: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  // const filter = state.filters.document
  return {
    // filter_ids: filter.ids,
    // filter_q: filter.q,
    // filtering: filter.filtering,
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default compose(
  graphql(smsReportDeleteMutation, {
    name: 'smsReportDeleteMutation',
  }),
  graphql(smsReportListQuery, {
    name: 'smsReportListQuery',
  }),
  connect(mapStateToProps, { mutationSuccess, mutationFailure, mutationSet })
)(SmsReportList);
