import { FormGroup, Col } from 'react-bootstrap';

import TagList from '../tag_list';
import { validationState } from '../form/helpers';

const ContactFormTagField = (props) => {
  const { input, meta, tagsCollection } = props;

  return (
    <FormGroup controlId="contactTagCollection" validationState={validationState(meta)}>
      <Col sm={9} smOffset={3}>
        <TagList
          suggestions={tagsCollection}
          tag_collection={input}
          onTagCreate={props.handleTagCreate}
        />
      </Col>
    </FormGroup>
  );
};

export default ContactFormTagField;
