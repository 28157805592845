import { Component } from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';

import defaultTo from 'lodash/defaultTo';
import get from 'lodash/get';

class FlightPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      distanceNmAdjusted: 0,
      airspeedKnotsAdj: 0,
      startFuelAdj: 0,
    };
    this._handleDistanceAdjChange = this._handleDistanceAdjChange.bind(this);
    this._handleDistanceAdjBlur = this._handleDistanceAdjBlur.bind(this);
    this._handleAirspeedAdjChange = this._handleAirspeedAdjChange.bind(this);
    this._handleAirspeedAdjBlur = this._handleAirspeedAdjBlur.bind(this);
    this._handleStartFuelAdjChange = this._handleStartFuelAdjChange.bind(this);
    this._handleStartFuelAdjBlur = this._handleStartFuelAdjBlur.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({
      distanceNmAdjusted: this._calculateDistanceNm(this.props, 'adjusted'),
      airspeedKnotsAdj: this._calculateAirspeedAdjKnots(
        get(this.props, 'formValues.airspeed_adj', 0)
      ),
      startFuelAdj: get(this.props, 'fuelData.startFuelAdj.adjustment', 0),
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      distanceNmAdjusted: this._calculateDistanceNm(nextProps, 'adjusted'),
      airspeedKnotsAdj: this._calculateAirspeedAdjKnots(
        get(nextProps, 'formValues.airspeed_adj', 0)
      ),
      startFuelAdj: get(nextProps, 'fuelData.startFuelAdj.adjustment', 0),
    });
    if (
      get(nextProps, 'fuelData.startFuelAdj.adjustment', 0) !==
      get(nextProps, 'formValues.start_fuel_adj.adjustment', 0)
    ) {
      nextProps.change(
        [nextProps.field, 'start_fuel_adj'].join('.'),
        get(nextProps, 'fuelData.startFuelAdj', {
          auto: false,
          manual: false,
          adjustment: 0,
        })
      );
    }
  }

  _calculateDistanceNm(props = this.props, type = 'raw') {
    const distance = get(props, ['distanceData', type]);
    if (distance) {
      const kms = Math.round(distance / 1000);
      const nms = Math.round(kms * 0.539957);
      return nms;
    } else {
      return 0;
    }
  }

  _calculateAirspeedAdjKnots(airspeed) {
    if (airspeed) {
      return Math.round(airspeed / 1.852);
    } else {
      return 0;
    }
  }

  _renderFlighttime() {
    if (this.props.loadedFlighttime && this.props.loadedFlighttime > 0) {
      return this.props.loadedFlighttime.toFixed(2);
    } else {
      return '-';
    }
  }

  _handleDistanceAdjChange(e) {
    const value = defaultTo(parseInt(e.target.value), '');
    this.setState({
      distanceNmAdjusted: value,
    });
  }

  _handleDistanceAdjBlur(e) {
    let value = defaultTo(parseInt(e.target.value), 0);
    const distanceNmRaw = this._calculateDistanceNm();
    if (value === 0) {
      value = distanceNmRaw;
    }
    this.setState({
      distanceNmAdjusted: value,
    });
    let distanceAdj = 0;
    if (value !== distanceNmRaw) {
      const distanceMetersRaw = get(this.props, 'distanceData.raw', 0);
      const distanceMetersAdjusted = (value / 0.539957) * 1000;
      distanceAdj = distanceMetersAdjusted - distanceMetersRaw;
    }
    if (distanceAdj !== get(this.props, 'formValues.distance_adj', '')) {
      this.props.change([this.props.field, 'distance_adj'].join('.'), distanceAdj);
    }
  }

  _handleAirspeedAdjChange(e) {
    const value = e.target.value === '-' ? '-' : defaultTo(parseInt(e.target.value), '');
    this.setState({
      airspeedKnotsAdj: value,
    });
  }

  _handleAirspeedAdjBlur(e) {
    const value = defaultTo(parseInt(e.target.value), 0);
    this.setState({
      airspeedKnotsAdj: value,
    });
    const airspeedAdj = value * 1.852;
    if (airspeedAdj !== get(this.props, 'formValues.airspeed_adj', '')) {
      this.props.change([this.props.field, 'airspeed_adj'].join('.'), airspeedAdj);
    }
  }

  _handleStartFuelAdjChange(e) {
    const value = e.target.value === '-' ? '-' : defaultTo(parseInt(e.target.value), '');
    this.setState({
      startFuelAdj: value,
    });
  }

  _handleStartFuelAdjBlur(e) {
    const value = defaultTo(parseInt(e.target.value), 0);
    this.setState({
      startFuelAdj: value,
    });
    if (value !== get(this.props, 'formValues.start_fuel_adj.adjustment', '')) {
      this.props.change([this.props.field, 'start_fuel_adj'].join('.'), {
        auto: get(this.props, 'formValues.start_fuel_adj.auto', false),
        manual: true,
        adjustment: value,
      });
    }
  }

  _renderFuelData() {
    const { bowsers, tankers } = this.props.fuelData;
    return [...bowsers, ...tankers].map((fuelData, index) => (
      <p key={index}>{fuelData.name}</p>
    ));
  }

  _renderTitle() {
    const startLocationShorthandName = get(this.props.locationsDataSelector, [
      get(this.props, 'formValues.start_location_id'),
      'shorthandName',
    ]);
    const endLocationShorthandName = get(this.props.locationsDataSelector, [
      get(this.props, 'formValues.end_location_id'),
      'shorthandName',
    ]);

    const text = [
      this.props.index,
      startLocationShorthandName,
      endLocationShorthandName,
    ].join('-');
    if (get(this.props, 'fuelData.hasFuel')) {
      const popover = (
        <Popover id={`popover-flight-plan-${this.props.index}`} title="Fuel Details">
          {this._renderFuelData()}
        </Popover>
      );
      return (
        <OverlayTrigger key={this.props.index} placement="right" overlay={popover}>
          <a>{text}</a>
        </OverlayTrigger>
      );
    } else {
      return text;
    }
  }

  _renderLegFuel() {
    return (
      <span>
        {Math.round(get(this.props, 'fuelData.legFlightFuelKg', 0))}
        {' / '}
        {Math.round(get(this.props, 'fuelData.legFlightFuelLt', 0))}
      </span>
    );
  }

  _renderStartFuel() {
    return (
      <span>
        {Math.round(get(this.props, 'fuelData.startFuelCalcKg', 0))}
        {' / '}
        {Math.round(get(this.props, 'fuelData.startFuelCalcLt', 0))}
      </span>
    );
  }

  _styleStartFuel() {
    let style = {};
    if (this.props.fuelData) {
      if (this.props.fuelData.alertFuelOver) {
        style = { backgroundColor: 'blue', color: 'white' };
      } else if (this.props.fuelData.alertFuelUnder) {
        style = { backgroundColor: 'red', color: 'white' };
      }
    }
    return style;
  }

  _renderCabinWeight() {
    return Math.round(get(this.props, ['weightData', 'cabinWeight'], 0));
  }

  _renderNetWeight() {
    return Math.round(
      get(this.props, ['weightData', 'aircraftNetWeight'], 0) -
        get(this.props, ['fuelData', 'startFuelCalcKg'], 0)
    );
  }

  render() {
    // console.log(this.state)
    // console.log(this.props.formValues)
    return (
      <tr>
        <td className="text-left">{this._renderTitle()}</td>
        <td className="text-center">{this._calculateDistanceNm()}</td>
        <td>
          <input
            type="text"
            style={{ width: '88px' }}
            value={this.state.distanceNmAdjusted}
            onChange={this._handleDistanceAdjChange}
            onBlur={this._handleDistanceAdjBlur}
          />
        </td>
        <td>
          <input
            type="text"
            style={{ width: '88px' }}
            value={this.state.airspeedKnotsAdj}
            onChange={this._handleAirspeedAdjChange}
            onBlur={this._handleAirspeedAdjBlur}
          />
        </td>
        <td className="text-center">{this._renderFlighttime()}</td>
        <td className="text-center">{this._renderLegFuel()}</td>
        <td>
          {get(this.props, 'fuelData.hasFuel') && (
            <input
              type="text"
              style={{ width: '88px' }}
              value={
                this.state.startFuelAdj === '-' || this.state.startFuelAdj === ''
                  ? this.state.startFuelAdj
                  : Math.round(this.state.startFuelAdj)
              }
              onChange={this._handleStartFuelAdjChange}
              onBlur={this._handleStartFuelAdjBlur}
            />
          )}
        </td>
        <td className="text-center" style={this._styleStartFuel()}>
          {this._renderStartFuel()}
        </td>
        <td className="text-center">{this._renderCabinWeight()}</td>
        <td className="text-center">{this._renderNetWeight()}</td>
        <td />
      </tr>
    );
  }
}

export default FlightPlan;
