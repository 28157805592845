import {
  Col,
  Form,
  FormGroup,
  Button,
  ButtonToolbar,
  ButtonGroup,
} from 'react-bootstrap';
import { Component } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';

import { reduxForm, Field } from 'redux-form';

import Validator from 'validatorjs';

import _set from 'lodash/set';

import InputField from '../form/input_field';
import ReactDateTimeField from '../form/react_date_time_field';

class SmsActionCreateFormModal extends Component {
  render() {
    const {
      handleCreateSubmit,
      handleCreateCancel,
      handleSubmit,
      pristine,
      submitting,
      valid,
    } = this.props;

    return (
      <Form horizontal onSubmit={handleSubmit(handleCreateSubmit)}>
        <Field type="text" name="description" component={InputField}>
          Description
        </Field>
        <Field
          type="text"
          name="status"
          inputWidth={3}
          noTab
          component={InputField}
          componentClass="select"
          defaultSelectOption={false}
          selectOptions={this.props.currentSettingsSmsActionStatuses.map((s) => ({
            id: s,
            name: s,
          }))}
        >
          Status
        </Field>
        <Field
          type="text"
          name="saturation"
          inputWidth={3}
          noTab
          component={InputField}
          componentClass="select"
          defaultSelectOption={false}
          selectOptions={this.props.currentSettingsSmsActionSaturations.map((s) => ({
            id: s,
            name: s,
          }))}
        >
          Saturation
        </Field>
        <Field
          name="dueOn"
          inputWidth={6}
          component={ReactDateTimeField}
          helpText="DD/MM/YYYY"
          dateFormat="DD/MM/YYYY"
          timeFormat={false}
          closeOnSelect
        >
          Action Due On
        </Field>
        <Field
          type="text"
          name="employeeId"
          inputWidth={6}
          component={InputField}
          componentClass="select"
          selectOptions={this.props.employees.map((m) => ({
            id: m.id,
            name: m.fullName,
          }))}
        >
          Responsible Employee
        </Field>
        <Field
          type="text"
          name="managerId"
          inputWidth={6}
          component={InputField}
          componentClass="select"
          selectOptions={this.props.managers.map((m) => ({ id: m.id, name: m.fullName }))}
        >
          Responsible Manager
        </Field>
        <FormGroup>
          <Col sm={12}>
            <div className="pull-right">
              <ButtonToolbar>
                <ButtonGroup>
                  <Button
                    type="reset"
                    bsStyle="danger"
                    disabled={submitting}
                    onClick={handleCreateCancel}
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
                <ButtonGroup>
                  <Button
                    type="submit"
                    bsStyle="primary"
                    disabled={pristine || submitting || !valid}
                  >
                    Create
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </div>
          </Col>
        </FormGroup>
      </Form>
    );
  }
}

const validationAliases = {
  // 'description': 'Description',
};

const validationRules = {
  description: 'required',
  status: 'required',
  saturation: 'required',
  dueOn: 'required',
  employeeId: 'required',
  managerId: 'required',
};

const validationCustomMessages = {};

function validate(data, props) {
  const validator = new Validator(data, validationRules, validationCustomMessages);
  validator.setAttributeNames(validationAliases);
  validator.passes();
  const errors = validator.errors.all();
  const result = {};
  Object.keys(errors).forEach((key) => {
    _set(result, key, errors[key].join(' '));
  });
  return result;
}

function mapStateToProps(state, props) {
  return {
    // currentSettingsMutating: state.currentSettings.mutating,
    currentSettingsSmsActionStatuses: state.currentSettings.sms_action_statuses,
    currentSettingsSmsActionSaturations: state.currentSettings.sms_action_saturations,
    // formValues: getFormValues('SmsActionCreateFormModal')(state)
  };
}

export default compose(
  connect(mapStateToProps, {}),
  reduxForm({
    validate,
  })
)(SmsActionCreateFormModal);
