import actionTypes from '../constants';

import { reset, loadSuccess, loadFailure, rehydrate } from '../lib/reducerHelpers';

const dataKey = 'currentTenant';

export const initialState = {};

export default function currentTenantReducer(state = initialState, action) {
  const { type, payload, error } = action;
  if (error) {
    return state;
  }

  switch (type) {
    case 'persist/REHYDRATE':
      return rehydrate(initialState, (payload || {})[dataKey] || {});
    case actionTypes.DATA_RESET:
      return reset(initialState);
    case actionTypes.DATA_LOAD_SUCCESS:
      return loadSuccess(initialState, payload[dataKey], false);
    case actionTypes.DATA_LOAD_FAILURE:
      return loadFailure(initialState);
    default:
      return state;
  }
}
