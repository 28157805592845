import { Modal, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { Component } from 'react';
import Toggle from 'react-bootstrap-toggle';

class FlightSegmentAddModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      includePax: false,
    };
    this._handlePaxToggle = this._handlePaxToggle.bind(this);
    this._handleAddCancel = this._handleAddCancel.bind(this);
    this._handleAddSubmit = this._handleAddSubmit.bind(this);
  }

  _resetState() {
    this.setState({
      includePax: false,
    });
  }

  _handleAddCancel() {
    this._resetState();
    if (this.props.handleAddFlightSegmentCancel) {
      this.props.handleAddFlightSegmentCancel();
    }
  }

  _handleAddSubmit(e) {
    const addType = e.currentTarget.getAttribute('data-add-type');
    if (this.props.handleAddFlightSegmentSubmit) {
      this.props.handleAddFlightSegmentSubmit(addType, this.state.includePax);
    }
    this._resetState();
  }

  _handlePaxToggle() {
    this.setState({
      includePax: !this.state.includePax,
    });
  }

  render() {
    const { show, index } = this.props;

    return (
      <Modal
        id={`booking-flight-segment-${index}-add-modal`}
        show={show}
        onHide={this._handleAddCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add flight segment</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <ButtonToolbar className="pull-right">
            <ButtonGroup>
              <Button type="button" bsStyle="danger" onClick={this._handleAddCancel}>
                Cancel
              </Button>
            </ButtonGroup>
            <ButtonGroup>
              <Toggle
                active={this.state.includePax}
                onClick={this._handlePaxToggle}
                on="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pax"
                onstyle="primary"
                off="No Pax"
                offstyle="info"
                handlestyle={this.state.includePax ? 'info' : 'primary'}
              />
            </ButtonGroup>
            <ButtonGroup>
              <Button
                data-add-type={index === 'last' ? 'new' : 'newAbove'}
                type="button"
                bsStyle="primary"
                onClick={this._handleAddSubmit}
              >
                {index === 'last' ? 'New' : 'Above'}
              </Button>
              <Button
                data-add-type={index === 'last' ? 'return' : 'newBelow'}
                type="button"
                bsStyle="primary"
                onClick={this._handleAddSubmit}
              >
                {index === 'last' ? 'Return' : 'Below'}
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default FlightSegmentAddModal;
