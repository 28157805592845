import actionTypes from '../constants';

export const initialState = {
  booking: { q: '', ids: [], filtering: false },
  contact: { q: '', ids: [], filtering: false },
  check: { q: '', ids: [], filtering: false },
  document: { q: '', ids: [], filtering: false },
  fuel_tanker: { q: '', ids: [], filtering: false },
  location: { q: '', ids: [], filtering: false },
};

function reset() {
  return initialState;
}

function clear(state, payload) {
  return {
    ...state,
    [payload.type]: { q: '', ids: [], filtering: false },
  };
}

function success(state, payload, meta) {
  return {
    ...state,
    [meta.type]: {
      ...payload,
      filtering: false,
    },
  };
}

function start(state, payload) {
  const typeData = state[payload.type];
  return {
    ...state,
    [payload.type]: {
      ...typeData,
      filtering: true,
    },
  };
}

function stop(state, payload) {
  const typeData = state[payload.type];
  return {
    ...state,
    [payload.type]: {
      ...typeData,
      filtering: false,
    },
  };
}

export default function filtersReducer(state = initialState, action = {}) {
  const { type, payload, meta } = action;

  switch (type) {
    case actionTypes.FILTER_RESET:
      return reset();
    case actionTypes.FILTER_CLEAR:
      return clear(state, payload);
    case actionTypes.FILTER_START:
      return start(state, payload);
    case actionTypes.FILTER_STOP:
      return stop(state, payload);
    case actionTypes.FILTER_SUCCESS:
      return success(state, payload, meta);
    default:
      return state;
  }
}
