import moment from 'moment';

import memoize from 'lodash/memoize';
import chunk from 'lodash/chunk';

moment.updateLocale('en-nz');

export const FlightSegmentsTogsMemo = memoize(
  (timestamps) => {
    const chunkedTimestamps = chunk(timestamps, 2);
    const togs = chunkedTimestamps.reduce((togs, [endAt, startAt]) => {
      let tog = '';
      if (endAt && startAt) {
        const togM = moment(startAt).diff(moment(endAt));
        if (togM > 0) {
          const hours = `${moment.duration(togM).hours()}`;
          const mins = `${moment.duration(togM).minutes()}`;
          tog = `${'00'.substring(0, 2 - hours.length) + hours}:${
            '00'.substring(0, 2 - mins.length) + mins
          }`;
        }
      }
      togs = [...togs, tog];
      return togs;
    }, []);
    return togs;
  },
  (timestamps) => timestamps.join('.')
);
