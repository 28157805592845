import { Col, FormGroup, ControlLabel } from 'react-bootstrap';
import { Component } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import difference from 'lodash/difference';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import without from 'lodash/without';
// import { validationState, validationText } from '../form/helpers';

class ChargeableInputField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chargeables: [],
      chargeableOptions: [],
    };
    this.handleleChargeableSearch = this.handleleChargeableSearch.bind(this);
    this.handleleChargeableIdChange = this.handleleChargeableIdChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.booking_chargeables_attributes) {
      this.setState({
        chargeables: this.getChargeables(this.props),
      });
    }
  }

  componentDidMount() {
    this.inputRef.focus();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { chargeables } = this.state;
    let { chargeableOptions } = this.state;
    let contactFightNotes;
    if (
      !isEqual(
        this.props.booking_chargeables_attributes,
        nextProps.booking_chargeables_attributes
      )
    ) {
      chargeables = this.getChargeables(nextProps);
      if (chargeables.length > 0) {
        contactFightNotes = chargeables
          .filter((chargeable) => chargeable.flightNotes)
          .map((chargeable) => `${chargeable.label}: ${chargeable.flightNotes}`)
          .join('\n');
      } else {
        contactFightNotes = '';
      }
    }
    if (
      !isEqual(
        this.props.chargeablesTypeaheadCollection,
        nextProps.chargeablesTypeaheadCollection
      )
    ) {
      chargeableOptions = this.getChargeableOptions(nextProps, chargeables);
    }
    this.setState({
      chargeables,
      chargeableOptions,
    });
    if (contactFightNotes || contactFightNotes === '') {
      this.props.change('contact_flight_notes', contactFightNotes);
    }
  }

  getChargeables = (props) => {
    if (props.booking_chargeables_attributes) {
      return (
        props.booking_chargeables_attributes
          // eslint-disable-next-line no-underscore-dangle
          .filter((bookingChargeable) => !bookingChargeable._destroy)
          .map((bookingChargeable) => {
            const chargeable = props.chargeables.find(
              (c) => c.id === bookingChargeable.chargeable_id
            );
            return {
              id: bookingChargeable.chargeable_id,
              label: get(chargeable, 'fullName', '404'),
              flightNotes: get(chargeable, 'flight_notes'),
            };
          })
      );
    } else {
      return [];
    }
  };

  getChargeableOptions(props, chargeables = this.state.chargeables) {
    const rejectIds = [...chargeables.map((c) => c.id), 0];
    return props.chargeablesTypeaheadCollection.filter(
      (typeahead) => !includes(rejectIds, typeahead.id)
    );
  }

  handleleChargeableSearch(value) {
    if (this.props.handleChargeableSearch) {
      this.props.handleChargeableSearch(value);
    }
  }

  handleleChargeableIdChange(chargeables) {
    const currentIds = this.props.booking_chargeables_attributes
      // eslint-disable-next-line no-underscore-dangle
      .filter((bookingChargeable) => !bookingChargeable._destroy)
      .map((bc) => bc.chargeable_id);
    const newIds = chargeables.map((c) => c.id);
    const deleteIds = difference(currentIds, newIds);
    let addIds = difference(newIds, currentIds);

    this.props.bookingChargeables.fields.forEach((name, index, fields) => {
      const { id, chargeable_id: chargeableId, _destroy } = get(this.props, name);
      if (includes(deleteIds, chargeableId)) {
        if (id) {
          this.props.change(`${name}._destroy`, true);
        } else {
          fields.remove(index);
        }
      } else if (includes(addIds, chargeableId) && _destroy) {
        addIds = without(addIds, chargeableId);
        this.props.change(`${name}._destroy`, false);
      }
    });
    if (addIds.length > 0) {
      addIds.forEach((addId) => {
        this.props.bookingChargeables.fields.push({ chargeable_id: addId });
      });
    }
    if (this.props.handleChargeableSearchClear) {
      this.props.handleChargeableSearchClear();
    }
  }

  render() {
    // console.log(this.props);
    return (
      <Col sm={12}>
        <FormGroup
          bsSize="sm"
          controlId="chargeable_ids"
          // validationState={validationState(chargeable_ids)}
        >
          <Col componentClass={ControlLabel} sm={4}>
            Charge
          </Col>
          <Col sm={8}>
            <AsyncTypeahead
              id="typeahead-chargeableids"
              isLoading={this.props.typeaheadFetching}
              ref={(el) => {
                this.inputRef = el;
              }}
              multiple
              useCache={false}
              onSearch={this.handleleChargeableSearch}
              onChange={this.handleleChargeableIdChange}
              options={this.state.chargeableOptions}
              selected={this.state.chargeables}
              filterBy={['searchable_label']}
            />
            {/* <HelpBlock>{validationText(chargeable_ids)}</HelpBlock> */}
          </Col>
        </FormGroup>
      </Col>
    );
  }
}

export default ChargeableInputField;
