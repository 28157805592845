import _sortBy from 'lodash/sortBy';
import _uniqBy from 'lodash/uniqBy';

import { paramCase } from 'change-case';

const getBookingContacts = (bookings, aircraftGroup) => {
  const groupedBookings = bookings.reduce(
    (result, booking) => {
      const { pilot, copilot, employees } = booking;
      const contacts = [...employees, pilot, copilot].filter((c) => c);
      contacts.forEach((contact) => {
        if (
          (booking.calendar_type !== 'flight' &&
            aircraftGroup.name === 'Advanced Flight') ||
          // some bookings come through with copilots etc, or shared meetings
          aircraftGroup.groupPilots.find((gp) => gp.id === contact.id)
        ) {
          if (contact.roles.some((r) => r.employee)) {
            result.employees.push(contact);
          } else {
            // eslint-disable-next-line no-lonely-if
            if (contact.roles.some((r) => r.pilot)) {
              result.otherPilots.push(contact);
            } else {
              result.nonEmployees.push(contact);
            }
          }
        } else {
          result.nonEmployees.push(contact);
        }
      });
      return result;
    },
    { employees: [], otherPilots: [], nonEmployees: [] }
  );
  return _sortBy(
    [
      ..._uniqBy(groupedBookings.employees, 'id'),
      ..._uniqBy(groupedBookings.otherPilots, 'id'),
    ],
    ['position', 'fullName']
  );
};

const getContactResource = ({ fullName }, index) => ({
  id: paramCase(fullName),
  title: fullName,
  groupId: 'Contact',
  extendedProps: {
    position: index,
  },
});

const getResources = (bookings, aircraftGroup) => {
  const bookingContacts = getBookingContacts(bookings, aircraftGroup);
  return bookingContacts.map((contact, index) => getContactResource(contact, index));
};

export default getResources;
