import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Row,
  Col,
  Panel,
  ButtonGroup,
  Button,
  Glyphicon,
  OverlayTrigger,
  Tooltip,
  Table,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import _get from 'lodash/get';
import _compact from 'lodash/compact';
import _last from 'lodash/last';
import _upperFirst from 'lodash/upperFirst';
import Confirm from '../confirm';

import {
  isStatus,
  isCalendarType,
  isAdminComplete,
  isPilotComplete,
  isAuditComplete,
} from '../lib/bookingHelpers';

class BookingManagerListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotes: false,
    };
    this.handleCloneHelicopterClick = this.handleCloneHelicopterClick.bind(this);
    this.handleCloneDayClick = this.handleCloneDayClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleCalendarClick = this.handleCalendarClick.bind(this);
    this.handleFlightCompleteClick = this.handleFlightCompleteClick.bind(this);
    this.handleShowNotes = this.handleShowNotes.bind(this);
  }

  handleCalendarClick(e) {
    if (this.props.onListItemCalendarClicked) {
      this.props.onListItemCalendarClicked(
        parseInt(_last(e.currentTarget.id.split('-')), 10)
      );
    }
  }

  handleFlightCompleteClick(e) {
    if (this.props.onFlightCompleteClicked) {
      this.props.onFlightCompleteClicked(
        parseInt(_last(e.currentTarget.id.split('-')), 10)
      );
    }
  }

  handleDeleteClick(e) {
    if (this.props.onDeleteClicked) {
      this.props.onDeleteClicked(_last(e.currentTarget.id.split('-')));
    }
  }

  handleCloneDayClick(e) {
    if (this.props.onCloneClicked) {
      this.props.onCloneClicked(_last(e.currentTarget.id.split('-')), 'day');
    }
  }

  handleCloneHelicopterClick(e) {
    if (this.props.onCloneClicked) {
      this.props.onCloneClicked(_last(e.currentTarget.id.split('-')), 'heli');
    }
  }

  _humanize(string) {
    return _upperFirst(string.split('_').join(' '));
  }

  renderHeaderDate(model) {
    const { start_at, end_at } = model;
    return (
      <span>
        {moment(start_at).isSame(moment(end_at), 'day')
          ? moment(start_at).format('ddd, DD MMM YYYY')
          : `${moment(start_at).format('ddd, DD MMM YYYY')} - ${moment(end_at).format(
              'ddd, DD MMM YYYY'
            )}`}
      </span>
    );
  }

  renderHeaderTime(model) {
    const isNotCalendarTypeBanner = !isCalendarType(
      this.props.currentSettingsBookingCalendarTypeBanner,
      model
    );
    if (isNotCalendarTypeBanner) {
      const { start_at, end_at } = model;
      return (
        <span>{`${moment(start_at).format('hh:mma')}-${moment(end_at).format(
          'hh:mma'
        )}`}</span>
      );
    }
  }

  renderDisplayCalendarType(calendar_type) {
    switch (calendar_type) {
      case 'rostered':
        return 'rostered off'.toUpperCase();
      default:
        return calendar_type.toUpperCase();
    }
  }

  _renderHeaderTop(model) {
    const {
      calendar_type: calendarType,
      reference,
      job_notes: jobNotes,
      aircraft_id: aircraftId,
    } = model;

    const aircraftRegistrationAbbreviated = _get(this.props.aircraftsDataSelector, [
      aircraftId,
      'registrationAbbreviated',
    ]);
    const aircraftAircraftTypeName = _get(this.props.aircraftsDataSelector, [
      aircraftId,
      'aircraftTypeName',
    ]);
    const isCalendarTypeFlight = isCalendarType(
      this.props.currentSettingsBookingCalendarTypeFlight,
      model
    );
    const isCalendarTypeBanner = isCalendarType(
      this.props.currentSettingsBookingCalendarTypeBanner,
      model
    );

    if (isCalendarTypeFlight) {
      return (
        <span>
          <span style={{ paddingRight: '0.5em' }}>
            <strong>{this.renderDisplayCalendarType(calendarType)}</strong>
          </span>
          <span style={{ paddingRight: '0.5em' }}>{`#${reference}`}</span>
          <span style={{ paddingRight: '0.5em' }}>
            {[aircraftRegistrationAbbreviated, aircraftAircraftTypeName]
              .map((a) => a)
              .join(' ')}
          </span>
        </span>
      );
    } else if (isCalendarTypeBanner) {
      return (
        <span>
          <span style={{ paddingRight: '0.5em' }}>
            <strong>{jobNotes.toUpperCase()}</strong>
          </span>
        </span>
      );
    } else {
      return (
        <span>
          <span style={{ paddingRight: '0.5em' }}>
            <strong>{this.renderDisplayCalendarType(calendarType)}</strong>
          </span>
          <span style={{ paddingRight: '0.5em' }}>
            {[aircraftRegistrationAbbreviated, aircraftAircraftTypeName]
              .map((a) => a)
              .join(' ')}
          </span>
        </span>
      );
    }
  }

  _renderHeaderPeople(model) {
    const { calendar_type, bookingChargeables, bookingEmployees } = model;
    const isCalendarTypeFlight = isCalendarType(
      this.props.currentSettingsBookingCalendarTypeFlight,
      model
    );
    const isCalendarTypeBanner = isCalendarType(
      this.props.currentSettingsBookingCalendarTypeBanner,
      model
    );
    if (isCalendarTypeFlight) {
      const chargeableFullNames = bookingChargeables
        .map((bca) =>
          _get(this.props.contactsDataSelector, [bca.chargeable_id, 'fullName'])
        )
        .join(', ');
      return <span>{chargeableFullNames}</span>;
    } else if (isCalendarTypeBanner) {
      return <span>{this._renderBodyButtons(model)}</span>;
    } else {
      const employeeFullNames = bookingEmployees
        .map((bea) =>
          _get(this.props.contactsDataSelector, [bea.employee_id, 'fullName'])
        )
        .join(', ');
      return <span>{employeeFullNames}</span>;
    }
  }

  _renderHumanStatus(calendar_type, status) {
    switch (calendar_type) {
      case 'leave':
        switch (status) {
          case 'tentative':
            return 'Request';
            break;
          default:
            return this._humanize(status);
            break;
        }
      case 'rostered':
        switch (status) {
          case 'confirmed':
            return 'Priority';
            break;
          case 'to_be_confirmed':
            return 'Planned';
            break;
          default:
            return this._humanize(status);
            break;
        }
      default:
        return this._humanize(status);
        break;
    }
  }

  _renderHeader(model) {
    const { calendar_type, pilot_id, copilot_id, status } = model;
    const pilotFullName = _get(this.props.contactsDataSelector, [pilot_id, 'fullName']);
    const copilotFullName = _get(this.props.contactsDataSelector, [
      copilot_id,
      'fullName',
    ]);
    return (
      <div>
        <Row>
          <Col xs={12}>
            <span className="pull-left">{this._renderHeaderTop(model)}</span>
            <span className="pull-right">{this._renderHeaderPeople(model)}</span>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <span className="pull-left">
              <span style={{ paddingRight: '0.5em' }}>
                {this.renderHeaderDate(model)}
              </span>
              <span style={{ paddingRight: '0.5em' }}>
                {this.renderHeaderTime(model)}
              </span>
              <span>{_compact([pilotFullName, copilotFullName]).join('/')}</span>
            </span>
            <span className="pull-right">
              {`(${this._renderHumanStatus(calendar_type, status)})`}
            </span>
          </Col>
        </Row>
      </div>
    );
  }

  _renderBodyButton(index, { icon, tooltip, onclick, confirm, id, link }) {
    const tip = <Tooltip id={`tooltip_${index}`}>{tooltip}</Tooltip>;
    if (confirm) {
      return (
        <Confirm
          confirmId={`booking-item-button-${index}-${id}`}
          key={index}
          onConfirm={onclick}
          title={tooltip}
          body={confirm}
          confirmText="Confirm"
          tip={tip}
        >
          <Button bsStyle="link" bsSize="xsmall">
            <Glyphicon glyph={icon} />
          </Button>
        </Confirm>
      );
    } else if (link) {
      return (
        <OverlayTrigger key={index} placement="top" overlay={tip}>
          <LinkContainer to={`/${link}/${id}/edit`}>
            <Button bsStyle="link" bsSize="xsmall" onClick={onclick}>
              <Glyphicon glyph={icon} />
            </Button>
          </LinkContainer>
        </OverlayTrigger>
      );
    } else {
      return (
        <OverlayTrigger key={index} placement="top" overlay={tip}>
          <Button
            id={`booking-item-button-${index}-${id}`}
            bsStyle="link"
            bsSize="xsmall"
            onClick={onclick}
          >
            <Glyphicon glyph={icon} />
          </Button>
        </OverlayTrigger>
      );
    }
  }

  _renderBodyButtons(model) {
    const { id, status, end_at, calendar_type } = model;
    const human_calendar_type = this._humanize(calendar_type);
    const isStatusCancelled = isStatus(
      this.props.currentSettingsBookingStatusCancelled,
      model
    );
    const isCalendarTypeFlight = isCalendarType(
      this.props.currentSettingsBookingCalendarTypeFlight,
      model
    );
    const isCalendarTypeBanner = isCalendarType(
      this.props.currentSettingsBookingCalendarTypeBanner,
      model
    );
    const adminComplete = isAdminComplete(model);
    const auditComplete = isAuditComplete(model);
    const buttons = [];
    if (!adminComplete) {
      if (isCalendarTypeFlight) {
        if (auditComplete) {
          buttons.push({
            id,
            link: 'flights',
            icon: 'plane',
            tooltip: 'Edit completed flight',
          });
        } else if (isStatusCancelled) {
          buttons.push({
            id,
            link: 'flights',
            icon: 'edit',
            tooltip: 'Edit flight',
          });
        } else {
          let complete_message = 'Are you sure you want to complete this booking?';
          if (moment(end_at).isAfter(moment(), 'minute')) {
            complete_message = `${complete_message} Booking is not due to end until ${moment(
              end_at
            ).format('ddd, DD MMM YYYY')} at ${moment(end_at).format('hh:mma')}.`;
          }
          buttons.push({
            id,
            link: 'flights',
            icon: 'edit',
            tooltip: 'Edit flight',
          });
          buttons.push({
            id,
            confirm: complete_message,
            icon: 'plane',
            tooltip: 'Complete flight',
            onclick: this.handleFlightCompleteClick,
          });
        }
      } else {
        buttons.push({
          id,
          link: 'nonflights',
          icon: 'edit',
          tooltip: `Edit ${human_calendar_type}`,
        });
      }
    }
    if (!(isStatusCancelled || isCalendarTypeBanner)) {
      buttons.push({
        id,
        icon: 'calendar',
        tooltip: 'Calendar',
        onclick: this.handleCalendarClick,
      });
    }
    if (!adminComplete) {
      if (this.props.currentContact['office_admin?']) {
        if (!isStatusCancelled && isCalendarTypeFlight && !auditComplete) {
          buttons.push({
            id,
            icon: 'copy',
            tooltip: 'Multi Day Clone',
            onclick: this.handleCloneDayClick,
          });
          buttons.push({
            id,
            icon: 'copy',
            tooltip: 'Multi Helicopter Clone',
            onclick: this.handleCloneHelicopterClick,
          });
        }
        buttons.push({
          id,
          confirm: 'Are you sure you want to delete this booking?',
          icon: 'trash',
          tooltip: 'Remove',
          onclick: this.handleDeleteClick,
        });
      }
    }

    return (
      <ButtonGroup className="pull-right">
        {buttons.map((button, index) => this._renderBodyButton(index, button))}
      </ButtonGroup>
    );
  }

  handleShowNotes() {
    this.setState({
      showNotes: !this.state.showNotes,
    });
  }

  _renderNotesButton(model) {
    const { id, contact_flight_notes, job_notes, pilot_notes, public_notes } = model;
    const isCalendarTypeFlight = isCalendarType(
      this.props.currentSettingsBookingCalendarTypeFlight,
      model
    );
    if (
      isCalendarTypeFlight &&
      (job_notes || pilot_notes || public_notes || contact_flight_notes)
    ) {
      const tip = <Tooltip id={`tooltip-show-notes-${id}`}>Display notes</Tooltip>;
      return (
        <OverlayTrigger placement="top" overlay={tip}>
          <Button
            className="pull-left"
            style={{ paddingLeft: 0 }}
            bsStyle="link"
            bsSize="xsmall"
            onClick={this.handleShowNotes}
          >
            <Glyphicon glyph="list-alt" />
          </Button>
        </OverlayTrigger>
      );
    }
  }

  _renderCharacterText(text) {
    if (text) {
      return text.split('\n').map((line, index) => (
        <span key={index}>
          {line}
          <br />
        </span>
      ));
    }
  }

  _renderFlightSegmentSummary(flightSegmentSummary) {
    const { id, start_at, start_location, end_location, pax } = flightSegmentSummary;
    return (
      <tr key={id}>
        <td
          style={{
            borderTop: 'none',
            padding: '0 10px 0 0',
            width: '1%',
            whiteSpace: 'nowrap',
          }}
          className="text-right"
        >
          <small>{start_at}</small>
        </td>
        <td
          style={{
            borderTop: 'none',
            padding: '0 10px 0 0',
            width: '1%',
            whiteSpace: 'nowrap',
          }}
          className="text-left"
        >
          <small>{`${start_location} - ${end_location}`}</small>
        </td>
        <td
          style={{ borderTop: 'none', padding: '0 10px 0 0', width: '97%' }}
          className="text-left"
        >
          <small>
            {pax ? <Glyphicon style={{ paddingRight: '5px' }} glyph="user" /> : ''}
            {pax}
          </small>
        </td>
        <td style={{ borderTop: 'none', padding: '0 10px 0 0' }}>&nbsp;</td>
      </tr>
    );
  }

  _renderFlightSegmentSummaries(flightSegmentSummaries) {
    return (
      <Table condensed>
        <tbody>
          {flightSegmentSummaries.map((flightSegmentSummary) =>
            this._renderFlightSegmentSummary(flightSegmentSummary)
          )}
        </tbody>
      </Table>
    );
  }

  _renderBody(model) {
    const {
      flightSegmentSummaries,
      contact_flight_notes,
      job_notes,
      pilot_notes,
      public_notes,
      bookingEmployees,
    } = model;
    const isCalendarTypeFlight = isCalendarType(
      this.props.currentSettingsBookingCalendarTypeFlight,
      model
    );
    const isNotCalendarTypeBanner = !isCalendarType(
      this.props.currentSettingsBookingCalendarTypeBanner,
      model
    );
    if (isNotCalendarTypeBanner) {
      const employeeFullNames = bookingEmployees
        .map((bea) =>
          _get(this.props.contactsDataSelector, [bea.employee_id, 'fullName'])
        )
        .join(', ');
      return (
        <Row style={{ marginTop: '-10px' }}>
          <Col xs={12}>
            <Row>
              <Col xs={12}>
                {this._renderNotesButton(model)}
                {this._renderBodyButtons(model)}
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                {flightSegmentSummaries
                  ? this._renderFlightSegmentSummaries(flightSegmentSummaries)
                  : ''}
                {isCalendarTypeFlight || <p>{employeeFullNames}</p>}
                {!isCalendarTypeFlight || this.state.showNotes ? (
                  <div>
                    <p className="small">
                      {this._renderCharacterText(contact_flight_notes)}
                    </p>
                    <p className="small">{this._renderCharacterText(pilot_notes)}</p>
                    <p className="small">{this._renderCharacterText(job_notes)}</p>
                    <p className="small">{this._renderCharacterText(public_notes)}</p>
                  </div>
                ) : (
                  ''
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }
  }

  _getPanelClassName(model) {
    const { calendar_type, status, end_at } = model;
    const isCalendarTypeFlight = isCalendarType(
      this.props.currentSettingsBookingCalendarTypeFlight,
      model
    );
    const adminComplete = isAdminComplete(model);
    const pilotComplete = isPilotComplete(model);
    const classNames = [calendar_type];
    if (adminComplete) {
      classNames.push('admin_complete');
    } else if (pilotComplete) {
      classNames.push('pilot_complete');
    } else if (!isCalendarTypeFlight && moment(end_at).isBefore(moment())) {
      classNames.push('time_complete');
    } else {
      classNames.push(status);
    }
    return classNames.join(' ');
  }

  render() {
    const { id, calendar_type, status } = this.props.model;
    const isStatusCancelled = isStatus(
      this.props.currentSettingsBookingStatusCancelled,
      this.props.model
    );
    const isNotCalendarTypeFlight = !isCalendarType(
      this.props.currentSettingsBookingCalendarTypeFlight,
      this.props.model
    );
    const isNotCalendarTypeBanner = !isCalendarType(
      this.props.currentSettingsBookingCalendarTypeBanner,
      this.props.model
    );
    const collapsible =
      (isNotCalendarTypeFlight && isNotCalendarTypeBanner) || isStatusCancelled;
    if (collapsible) {
      return (
        <Panel key={id} className={this._getPanelClassName(this.props.model)}>
          <Panel.Heading>
            <Panel.Title toggle style={{ fontSize: 'inherit' }}>
              {this._renderHeader(this.props.model)}
            </Panel.Title>
          </Panel.Heading>
          <Panel.Collapse>
            <Panel.Body>{this._renderBody(this.props.model)}</Panel.Body>
          </Panel.Collapse>
        </Panel>
      );
    } else {
      return (
        <Panel key={id} className={this._getPanelClassName(this.props.model)}>
          <Panel.Heading>{this._renderHeader(this.props.model)}</Panel.Heading>
          <Panel.Body>{this._renderBody(this.props.model)}</Panel.Body>
        </Panel>
      );
    }
  }
}

BookingManagerListItem.propTypes = {
  currentContact: PropTypes.object,
  onListItemCalendarClicked: PropTypes.func,
  onFlightCompleteClicked: PropTypes.func,
  onDeleteClicked: PropTypes.func,
  onCloneClicked: PropTypes.func,
};

export default BookingManagerListItem;
