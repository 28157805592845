import axios from 'axios';
import qs from 'qs';
import mime from 'mime-types';
import { getIdToken } from './local_storage';

const apiUrl = process.env.EXPRESS_API_URL;

class Api {
  static headers(token) {
    if (token && token !== undefined) {
      return {
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: `Bearer ${token}`,
      };
    } else {
      return {
        'X-Requested-With': 'XMLHttpRequest',
      };
    }
  }

  static get(route, data = null, args = {}) {
    return this.xhr(route, data, 'get', args);
  }

  static put(route, data) {
    return this.xhr(route, data, 'put');
  }

  static post(route, data) {
    return this.xhr(route, data, 'post');
  }

  static delete(route, data) {
    return this.xhr(route, data, 'delete');
  }

  static xhr(route, data, verb, args = {}) {
    let newRoute = route;
    let newData = data;

    const { responseType } = args;

    if (!newRoute.match(/^http/)) {
      newRoute = `${apiUrl}${newRoute}`;
    }

    if (!mime.lookup(newRoute)) {
      newRoute = `${newRoute}.json`;
    }

    if (newData) {
      if (verb === 'get' && Object.keys(newData).length > 0) {
        newRoute = `${newRoute}?${qs.stringify(newData, {
          indices: false,
          arrayFormat: 'brackets',
        })}`;
      }
    } else {
      newData = {};
    }
    return axios({
      method: verb,
      url: newRoute,
      headers: this.headers(getIdToken()),
      data: newData,
      ...(responseType && { responseType }),
    });
  }
}

export default Api;
