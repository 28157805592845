import { Row, Col, FormGroup, HelpBlock, Checkbox, ControlLabel } from 'react-bootstrap';
import { Component } from 'react';

import get from 'lodash/get';

import BlurOnlyFormControl from '../form/blur_only_form_control';
import { validationStates, validationText } from '../form/helpers';

class FlightSignoffFormPilotFlightExpenseFields extends Component {
  _renderControlLabel() {
    return get(this.props.contactsDataSelector, [
      get(this.props, 'formValues.pilot_id'),
      'fullName',
    ]);
  }

  render() {
    const {
      notes: { input: notesInput, meta: notesMeta },
      total: { input: totalInput, meta: totalMeta },
      includes_gst: { input: includesGstInput, meta: includesGstMeta },
      onchargeable: { input: onchargeableInput, meta: onchargeableMeta },
      reimbursable: { input: reimbursableInput, meta: reimbursableMeta },
    } = get(this.props, this.props.parentField);

    return (
      <Col sm={12} className="form-horizontal">
        <FormGroup
          style={{ marginBottom: '5px' }}
          controlId={this.props.parentField}
          validationState={validationStates([notesMeta, totalMeta])}
        >
          <Col componentClass={ControlLabel} sm={3}>
            {this._renderControlLabel()}
          </Col>
          <Col sm={4}>
            <Row>
              <Col sm={6}>
                <BlurOnlyFormControl
                  type="text"
                  {...notesInput}
                  disabled={this.props.overNight}
                />
                <HelpBlock>{validationText(notesMeta)}</HelpBlock>
              </Col>
              <Col sm={6}>
                <BlurOnlyFormControl type="text" {...totalInput} />
                <HelpBlock>{validationText(totalMeta)}</HelpBlock>
              </Col>
            </Row>
          </Col>
          <Col sm={5}>
            <Row>
              <Col sm={4}>
                <Checkbox
                  inline
                  {...onchargeableInput}
                  checked={onchargeableInput.value}
                  tabIndex={-1}
                >
                  Oncharge
                </Checkbox>
                <HelpBlock>{validationText(onchargeableMeta)}</HelpBlock>
              </Col>
              <Col sm={4}>
                <Checkbox
                  inline
                  {...reimbursableInput}
                  checked={reimbursableInput.value}
                  tabIndex={-1}
                >
                  Reimburse
                </Checkbox>
                <HelpBlock>{validationText(reimbursableMeta)}</HelpBlock>
              </Col>
              <Col sm={4}>
                <Checkbox
                  inline
                  {...includesGstInput}
                  checked={includesGstInput.value}
                  tabIndex={-1}
                >
                  GST Incl.
                </Checkbox>
                <HelpBlock>{validationText(includesGstMeta)}</HelpBlock>
              </Col>
            </Row>
          </Col>
        </FormGroup>
      </Col>
    );
  }
}

export default FlightSignoffFormPilotFlightExpenseFields;
