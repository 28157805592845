import get from 'lodash/get';
import memoize from 'lodash/memoize';
import { FlightSegmentAirspeedDataMemo } from './flight_segment_airspeed_data_memo';
import { FlightSegmentDistanceDataMemo } from './flight_segment_distance_data_memo';

export const FlightSegmentFlighttimeDataMemo = memoize(
  ({
    airspeedAdj,
    bookingCruiseAirspeedSlKnots,
    aircraftId,
    aircrafts,
    aircraftTypes,
    distanceAdj,
    locationIdA,
    locationIdB,
    locations,
  }) => {
    if (aircraftId && locationIdA && locationIdB) {
      const distances = FlightSegmentDistanceDataMemo({
        distanceAdj,
        locationIdA,
        locationIdB,
        locations,
      });
      const airspeeds = FlightSegmentAirspeedDataMemo({
        airspeedAdj,
        bookingCruiseAirspeedSlKnots,
        aircraftId,
        aircrafts,
        aircraftTypes,
      });
      const distanceAdjusted = get(distances, 'adjusted');
      const flightSegmentAirspeedKmph = get(airspeeds, 'flightSegmentAirspeedKmph');
      const aircraftTypeDepartureAndArrivalLoading = get(
        airspeeds,
        'aircraftTypeDepartureAndArrivalLoading',
        0
      );
      if (distanceAdjusted && flightSegmentAirspeedKmph) {
        const flightSegmentAdjustedFlighttime =
          (distanceAdjusted / 1000 / flightSegmentAirspeedKmph) * 60;
        const flightSegmentLoadedFlighttime =
          flightSegmentAdjustedFlighttime + aircraftTypeDepartureAndArrivalLoading;
        return {
          flightSegmentAdjustedFlighttime,
          flightSegmentLoadedFlighttime,
        };
      } else {
        return {};
      }
    } else {
      return {};
    }
  },
  ({
    airspeedAdj,
    bookingCruiseAirspeedSlKnots,
    aircraftId,
    distanceAdj,
    locationIdA,
    locationIdB,
  }) => {
    if (aircraftId && locationIdA && locationIdB) {
      return [
        aircraftId,
        bookingCruiseAirspeedSlKnots,
        airspeedAdj,
        locationIdA,
        locationIdB,
        distanceAdj,
      ]
        .map((i) => String(i))
        .join('.');
    } else {
      return 'unknown';
    }
  }
);
