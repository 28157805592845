import { Row, Col, Table, Form, FormGroup, FormControl, Button } from 'react-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment';

import debounce from 'lodash/debounce';

import { currentSettingsSet } from '../actions/current_setting_actions';

import { mutationSet, mutationFailure } from '../actions/mutation_actions';

import ReactDateTimeFilter from '../components/form/react_date_time_filter';
import Loader from '../components/loader';
import ReportHeader from '../components/report_header';

import bookingDetailForMonthByChargeableQuery from '../queries/booking_detail_for_month_by_chargeable_query';
import contactItemListQuery from '../queries/contact_item_list_query';

import { queriesReady, getExport } from '../lib/utils';

moment.updateLocale('en-nz');

class ReportBookingChargeable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterChargeableId: this.props.currentSettingsReportChargeableId,
      filterChargeableIds: [],
    };
    this._handleDisplayChargeableIdChange =
      this._handleDisplayChargeableIdChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.startAtDate) {
      const date = moment(this.props.params.startAtDate, 'MM-YYYY');
      this.props.currentSettingsSet({
        reportStart: date.clone().startOf('day').toISOString(),
        reportEnd: date.clone().endOf('month').toISOString(),
      });
    }
    if (this.props.params.chargeableId) {
      this._handleDisplayChargeableIdChange({
        target: { value: this.props.params.chargeableId },
      });
    }
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    this.delayedHandleRefetch = debounce((e) => {
      this.props.bookingDetailForMonthByChargeableQuery.refetch();
    }, 250);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { filterChargeableIds } = this.state;
    if (this.isLoaded(nextProps)) {
      filterChargeableIds = nextProps.bookingDetailForMonthByChargeableQuery.data.map(
        (data) => data.chargeable_id
      );
    }
    this.setState({
      filterChargeableIds,
    });
    let filterChargeableId = nextProps.currentSettingsReportChargeableId;
    if (filterChargeableId && this.isLoaded(nextProps)) {
      if (
        filterChargeableIds.length > 0 &&
        filterChargeableIds.indexOf(filterChargeableId) === -1
      ) {
        filterChargeableId = '';
      }
    }
    if (filterChargeableId !== this.props.currentSettingsReportChargeableId) {
      this._handleDisplayChargeableIdChange({ target: { value: filterChargeableId } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleRefetch.cancel();
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(
      props.bookingDetailForMonthByChargeableQuery,
      props.chargeableListQuery
    );
  }

  getExport = (e) => {
    this.props.mutationSet(true);
    const reportName = e.target.getAttribute('data-report-name');
    const args = {
      startAt: this.props.currentSettingsReportStart,
      endAt: this.props.currentSettingsReportEnd,
      chargeableId: this.props.currentSettingsReportChargeableId,
    };
    getExport(reportName, args)
      .then(() => {
        this.props.mutationSet(false);
      })
      .catch((err) => this.props.mutationFailure(err));
  };

  _handleDisplayChargeableIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value);
    this.setState({
      filterChargeableId: value,
    });
    this.props.currentSettingsSet({
      reportChargeableId: value,
    });
  }

  _renderFixed(value, symbol = '', precision = 2) {
    return value ? symbol + value.toFixed(precision) : '-';
  }

  _renderLandingFees(landingFees) {
    if (landingFees) {
      return (
        <span>
          {landingFees
            .filter((lf) => lf.oncharge)
            .map((lf) => {
              const fee = `$${lf.fee}`;
              return (
                <span
                  key={lf.id}
                  style={{ display: 'block' }}
                >{`${lf.name} Landing Fee - ${fee}`}</span>
              );
            })}
        </span>
      );
    }
  }

  _renderPilotFlightExpenses(pilotExpenses) {
    if (pilotExpenses) {
      return (
        <span>
          {pilotExpenses
            .filter((pe) => pe.oncharge)
            .map((pe) => {
              const fee = `$${pe.fee}`;
              return (
                <span
                  key={pe.id}
                  style={{ display: 'block' }}
                >{`Pilot Expense - ${pe.note} - ${fee}`}</span>
              );
            })}
        </span>
      );
    }
  }

  _renderInvoiceRow(invoiceReferenceString, chargeableFullName) {
    return (
      <tr>
        <th
          colSpan={8}
        >{`${chargeableFullName} bookings and expenses on Invoice #${invoiceReferenceString}`}</th>
      </tr>
    );
  }

  _renderHeaderRow() {
    return (
      <tr>
        <th>Ref#</th>
        <th>Date</th>
        <th>Pilot</th>
        <th>Registration</th>
        <th width="30%">Route Details</th>
        <th>Exp%</th>
        <th>Time</th>
        <th width="30%">Chargeable Expenses</th>
        <th>Total</th>
      </tr>
    );
  }

  _renderFlightSegmentSummary(flightSegmentSummary) {
    const {
      start_at: startAt,
      start_location: startLocation,
      end_location: endLocation,
      pax,
    } = flightSegmentSummary;
    const summary = `${startAt} ${startLocation} - ${endLocation}`;
    return (
      <span>
        {summary} {pax ? ' | ' : ''} {pax}
      </span>
    );
  }

  _renderRow(booking) {
    const {
      id,
      booking_id: bookingId,
      aircraft_registration_abbreviated: aircraftRegistrationAbbreviated,
      reference,
      start_at_s: startAtString,
      pilot_display_name: pilotDisplayName,
      copilot_display_name: copilotDisplayName,
      flight_segment_summaries: flightSegmentSummaries,
      chargeable_percentage: chargeablePercentage,
      chargeable_flight_time: chargeableFlightTime,
      pilot_expenses: pilotExpenses,
      landing_fees: landingFees,
      expense_total: expenseTotal,
    } = booking;

    return (
      <tr key={id}>
        <td>
          <LinkContainer
            style={{ display: 'block', textAlign: 'left' }}
            to={`/flights/${bookingId}/edit`}
          >
            <Button bsStyle="link" bsSize="xsmall">
              {`#${reference}`}
            </Button>
          </LinkContainer>
        </td>
        <td>{startAtString}</td>
        <td>
          {[pilotDisplayName, copilotDisplayName].filter((name) => name).join(', ')}
        </td>
        <td>{aircraftRegistrationAbbreviated}</td>
        <td>
          {flightSegmentSummaries.map((flightSegmentSummary, index) => (
            <span key={index} style={{ display: 'block' }}>
              {this._renderFlightSegmentSummary(flightSegmentSummary)}
            </span>
          ))}
        </td>
        <td className="text-right">
          {chargeablePercentage === 1
            ? ''
            : this._renderFixed(chargeablePercentage * 100, '', 0)}
        </td>
        <td className="text-right">{this._renderFixed(chargeableFlightTime)}</td>
        <td>
          <span>
            {this._renderLandingFees(landingFees)}
            {this._renderPilotFlightExpenses(pilotExpenses)}
          </span>
        </td>
        <td className="text-right">{this._renderFixed(expenseTotal, '$')}</td>
      </tr>
    );
  }

  _renderRows(bookings) {
    return <tbody>{bookings.map((booking) => this._renderRow(booking))}</tbody>;
  }

  _renderFooterRow(
    invoiceReferenceString,
    chargeableFullName,
    chargeableFlightTime,
    expenseTotal
  ) {
    return (
      <tr>
        <th
          colSpan={6}
          style={{ width: '50%', textAlign: 'right' }}
        >{`Total ${chargeableFullName} Flight Time for Invoice #${invoiceReferenceString}:`}</th>
        <th colSpan={1} style={{ textAlign: 'left' }}>
          {this._renderFixed(chargeableFlightTime)}
        </th>
        <th
          colSpan={1}
          style={{ width: '50%', textAlign: 'right' }}
        >{`Total ${chargeableFullName} Expenses for Invoice #${invoiceReferenceString}:`}</th>
        <th colSpan={1} style={{ textAlign: 'right' }}>
          {this._renderFixed(expenseTotal, '$')}
        </th>
      </tr>
    );
  }

  _renderInvoices(data, chargeableFullName) {
    const groupedBookings = data.bookings.reduce((bookings, booking) => {
      bookings[booking.invoice_reference_s]
        ? (bookings[booking.invoice_reference_s] = [
            ...bookings[booking.invoice_reference_s],
            booking,
          ])
        : (bookings[booking.invoice_reference_s] = [booking]);
      return bookings;
    }, {});

    return (
      <Col xs={12}>
        {Object.keys(groupedBookings).map((invoiceReferenceString) => {
          const invoiceBookings = groupedBookings[invoiceReferenceString];
          const chargeableFlightTime = invoiceBookings.reduce(
            (sum, booking) => sum + (booking.chargeable_flight_time || 0),
            0
          );
          const expenseTotal = invoiceBookings.reduce(
            (sum, booking) => sum + (booking.expense_total || 0),
            0
          );
          return (
            <Table key={data.id + invoiceReferenceString} striped>
              <thead>
                {this._renderInvoiceRow(invoiceReferenceString, chargeableFullName)}
                {this._renderHeaderRow()}
              </thead>
              {this._renderRows(invoiceBookings)}
              <tfoot>
                {this._renderFooterRow(
                  invoiceReferenceString,
                  chargeableFullName,
                  chargeableFlightTime,
                  expenseTotal
                )}
              </tfoot>
            </Table>
          );
        })}
      </Col>
    );
  }

  _renderChargeables() {
    return (
      <Row>
        <Col xs={12}>
          {this.props.bookingDetailForMonthByChargeableQuery.data
            .filter((data) => {
              if (
                this.state.filterChargeableId &&
                data.chargeable_id !== this.state.filterChargeableId
              ) {
                return false;
              }
              return true;
            })
            .map((data) => (
              <Row key={data.chargeable_id} style={{ marginBottom: '20px' }}>
                <h4>{data.chargeable_full_name}</h4>
                {this._renderInvoices(data, data.chargeable_full_name)}
              </Row>
            ))}
        </Col>
      </Row>
    );
  }

  _renderChargeableFilter() {
    const chargeables = this.props.chargeableListQuery.data.filter(
      (model) => this.state.filterChargeableIds.indexOf(model.id) > -1
    );
    return (
      <FormControl
        componentClass="select"
        value={this.state.filterChargeableId}
        onChange={this._handleDisplayChargeableIdChange}
      >
        <option key={0} value="">
          All
        </option>
        {chargeables.map((model) => {
          const { id, fullName } = model;
          return (
            <option key={id} value={id}>
              {fullName}
            </option>
          );
        })}
      </FormControl>
    );
  }

  render() {
    return (
      <div>
        {this.renderOverlay()}
        {this.renderData()}
      </div>
    );
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (this.isLoading()) {
      return undefined;
    }
    return (
      <div>
        <Row>
          <Col sm={12}>
            <div className="clearfix">
              <div className="pull-left">
                <ReportHeader
                  title="Customer Invoice Report"
                  start={this.props.currentSettingsReportStart}
                  end={this.props.currentSettingsReportEnd}
                />
              </div>
              <div className="pull-right">
                <Button
                  bsStyle="primary"
                  data-report-name="booking_detail_for_month_by_chargeable_invoice"
                  onClick={this.getExport}
                >
                  PDF
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: '10px' }}>
          <Col xs={12}>
            <Form inline>
              <Button
                style={{ margin: '0', padding: '0 0 8px 0' }}
                bsStyle="link"
                bsSize="xsmall"
                onClick={this.delayedHandleRefetch}
              >
                <span className="glyphicon glyphicon-repeat" />
              </Button>
              <ReactDateTimeFilter
                currentSettingsReportStart={this.props.currentSettingsReportStart}
                currentSettingsReportEnd={this.props.currentSettingsReportEnd}
                onChange={this.props.currentSettingsSet}
              />
              <FormGroup
                style={{ marginLeft: '16px', paddingBottom: '10px' }}
                bsSize="sm"
                controlId="filterId"
              >
                {this._renderChargeableFilter()}
              </FormGroup>
            </Form>
          </Col>
        </Row>
        {this._renderChargeables()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsReportStart: state.currentSettings.reportStart,
    currentSettingsReportEnd: state.currentSettings.reportEnd,
    currentSettingsReportChargeableId: state.currentSettings.reportChargeableId,
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default compose(
  connect(mapStateToProps, {
    currentSettingsSet,
    mutationFailure,
    mutationSet,
  }),
  graphql(contactItemListQuery, {
    name: 'chargeableListQuery',
    options: { variables: { role: 'chargeable' } },
  }),
  graphql(bookingDetailForMonthByChargeableQuery, {
    name: 'bookingDetailForMonthByChargeableQuery',
    options: (props) => ({
      variables: {
        startAt: props.currentSettingsReportStart,
        endAt: props.currentSettingsReportEnd,
      },
      fetchPolicy: 'cache-and-network',
    }),
  })
)(ReportBookingChargeable);
