import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useApolloClient } from '@apollo/client';

import _get from 'lodash/get';

import { currentSettingsSet } from '../actions/current_setting_actions';
import clientVersionQuery from '../queries/client_version_query';

export default function CheckVersion() {
  const currentSettingsVersionCurrent = useSelector(
    (state) => state.currentSettings.versionCurrent
  );
  const apolloClient = useApolloClient();
  const dispatch = useDispatch();

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (window.$NODE_ENV !== 'development') {
        let versionCurrent = true;
        apolloClient
          .query({
            query: clientVersionQuery,
            fetchPolicy: 'no-cache',
          })
          .then((resp) => {
            if (_get(resp, 'data.clientVersion')) {
              const { version, versionFound } = _get(resp, 'data.clientVersion', {});
              if (versionFound && version && window.$CLIENT_VERSION) {
                versionCurrent = version === window.$CLIENT_VERSION;
              }
            }
          })
          .catch(() => {
            versionCurrent = true;
          })
          .finally(() => {
            if (currentSettingsVersionCurrent !== versionCurrent) {
              dispatch(
                currentSettingsSet({
                  versionCurrent,
                })
              );
            }
          });
      }
    }, 60000);
    return () => clearInterval(intervalId);
  }, [currentSettingsVersionCurrent]);

  return null;
}
