import { Component } from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';
import BookingManagerListItem from './booking_manager_list_item';

class BookingManagerList extends Component {
  renderItems() {
    if (this.props.bookings.length === 0) {
      return (
        <Panel>
          <Panel.Body>
            <p className="text-center">No Records</p>
          </Panel.Body>
        </Panel>
      );
    }
    return this.props.bookings.map((model, index) => (
      <BookingManagerListItem
        key={index}
        model={model}
        currentContact={this.props.currentContact}
        onListItemCalendarClicked={this.props.onListItemCalendarClicked}
        onFlightCompleteClicked={this.props.onFlightCompleteClicked}
        onDeleteClicked={this.props.onDeleteClicked}
        onCloneClicked={this.props.onCloneClicked}
        currentSettingsBookingCalendarTypeBanner={
          this.props.currentSettingsBookingCalendarTypeBanner
        }
        currentSettingsBookingCalendarTypeFlight={
          this.props.currentSettingsBookingCalendarTypeFlight
        }
        currentSettingsBookingStatusCancelled={
          this.props.currentSettingsBookingStatusCancelled
        }
        aircraftsDataSelector={this.props.aircraftsDataSelector}
        contactsDataSelector={this.props.contactsDataSelector}
      />
    ));
  }

  render() {
    return <div>{this.renderItems()}</div>;
  }
}

BookingManagerList.propTypes = {
  currentContact: PropTypes.object,
  onListItemCalendarClicked: PropTypes.func,
  onFlightCompleteClicked: PropTypes.func,
  onDeleteClicked: PropTypes.func,
  onCloneClicked: PropTypes.func,
};

export default BookingManagerList;
