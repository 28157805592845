import { Row, Col } from 'react-bootstrap';
import { Component } from 'react';
import { Field } from 'redux-form';

import get from 'lodash/get';

import InputField from '../form/input_field';

class ChargeablePaxes extends Component {
  _getChargeableLabel(index) {
    const chargeableId = get(
      this.props,
      ['formValues', 'booking_chargeables_attributes', index, 'chargeable_id'],
      ''
    );
    return get(
      this.props.contactsDataSelector,
      [chargeableId, 'fullName'],
      'Select a chargeable'
    );
  }

  render() {
    return (
      <Row>
        <Col sm={2}>
          <p>
            <strong>Seat Allocation</strong>
          </p>
        </Col>
        <Col sm={10}>
          {this.props.bookingChargeables.fields.map((field, index) => {
            const values = get(this.props, `formValues.${field}`, {});
            if (!values._destroy) {
              return (
                <Row key={`bcpax-${index}`}>
                  <Col sm={6} style={{ marginLeft: '0', paddingLeft: '0' }}>
                    <Field
                      parentField={field}
                      name={`${field}.pax`}
                      component={InputField}
                      bsSize="sm"
                      labelWidth={4}
                      inputWidth={6}
                    >
                      {this._getChargeableLabel(index)}
                    </Field>
                  </Col>
                </Row>
              );
            }
          })}
        </Col>
      </Row>
    );
  }
}

export default ChargeablePaxes;
