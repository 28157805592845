/* eslint-disable arrow-body-style */
// import React from 'react'
// import ReactDOM from 'react-dom'

// const App = (props) => {
//   return <p>Hello</p>
// }

// const rootEl = document.getElementById('root')

// function render (Component) {
//   ReactDOM.render(
//     <App />,
//     rootEl
//   )
// }
// render(App)
import 'whatwg-fetch';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { PersistGate } from 'redux-persist/es/integration/react';
import { BrowserRouter } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import Rollbar from 'rollbar/dist/rollbar.umd';

import { store, history, persistor } from './store';
import { getIdToken, clearIdToken } from './lib/local_storage';
import Api from './lib/api';

// import makeApolloClient from './lib/apollo_client';
import apolloClient from './lib/apollo_client';
import subscriptionClient from './lib/subscription_client';
// import processMutationSubscription from './lib/process_mutation_subscription';
// import mutationSubscription from './subscriptions/mutation_subscription';
import {
  subscribeMutationObservable,
  unsubscribeMutationObservable,
} from './lib/process_mutation_subscription';

import Loader from './components/loader';

import { storeLoad, storeReload, storeLocalLoad } from './actions/store_actions';
import { authLoadSuccess } from './actions/auth_actions';
import { currentSettingsSet } from './actions/current_setting_actions';

import App from './app';

// const App = (props) => {
//   return <p>Hello1</p>
// }

let DEVICE_ONLINE = window.navigator.onLine;
let SOCKET_CONNECTED = false;
// let SOCKET_AUTH_ERROR = false;
// let SOCKET_CONNECTED = true;
// let SOCKET_AUTH_ERROR = true;

let appRollbar;

if (process.env.NODE_ENV === 'production') {
  const rollbarConfig = {
    accessToken: process.env.ROLLBAR_CLIENT_ACCESS_TOKEN, // eslint-disable-line no-undef
    captureUncaught: true,
    payload: {
      environment: process.env.NODE_ENV,
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: process.env.GIT_REVISION, // eslint-disable-line no-undef
          guess_uncaught_frames: true,
        },
      },
    },
  };
  appRollbar = new Rollbar(rollbarConfig);
  window.Rollbar = appRollbar;
}

// apolloClient.subscriptionClient.unsubscribeAll();
// subscriptionClient.unsubscribeAll();
// apolloClient.client

// let subscribedMutationObservable;

// const subscribeMutationObservable = () => {
//   console.log('subscribe');
//   const mutationObservable = apolloClient.subscribe({
//     query: mutationSubscription,
//     variables: {},
//     fetchPolicy: 'no-cache',
//   });
//   subscribedMutationObservable = mutationObservable.subscribe({
//     next(mutation) {
//       processMutationSubscription(mutation);
//     },
//     error(error) {
//       console.log('mutationSubscriptionError: ', error);
//     },
//     complete() {
//       console.log('mutationSubscriptionFinished');
//     },
//   });
// };

// const unsubscribeMutationObservable = () => {
//   console.log('unsubscribe');
//   if (subscribedMutationObservable) {
//     console.log('unsubscribing');
//     subscribedMutationObservable.unsubscribe();
//     // subscribedMutationObservable();
//   }
//   subscribedMutationObservable = null;
// };

// const setOnlineStatus = () => {
//   return;
//   console.log('set online status');
//   const online =
//     DEVICE_ONLINE && (SOCKET_CONNECTED || (!SOCKET_CONNECTED && SOCKET_AUTH_ERROR));
//   console.log({
//     online,
//     deviceOnline: DEVICE_ONLINE,
//     serverOnline: SOCKET_CONNECTED || (!SOCKET_CONNECTED && SOCKET_AUTH_ERROR),
//   });
//   store.dispatch(
//     currentSettingsSet({
//       online,
//       deviceOnline: DEVICE_ONLINE,
//       serverOnline: SOCKET_CONNECTED || (!SOCKET_CONNECTED && SOCKET_AUTH_ERROR),
//     })
//   );
//   // let subscription;
//   if (online && !SOCKET_AUTH_ERROR) {
//     console.log('subscribe mutation');
//     // subscription = subscribeMutation();
//     // subscribeMutationObservable();
//   } else {
//     console.log('unsubscribe mutation');
//     // console.log(subscription);
//     // apolloClient.subscriptionClient.unsubscribeAll();
//     // subscriptionClient.unsubscribeAll();
//     // unsubscribeMutationObservable();
//   }
// };

const setOnlineStatus = () => {
  const online = DEVICE_ONLINE && SOCKET_CONNECTED;
  // console.log({
  //   online,
  //   deviceOnline: DEVICE_ONLINE,
  //   serverOnline: SOCKET_CONNECTED,
  // });
  store.dispatch(
    currentSettingsSet({
      online,
      deviceOnline: DEVICE_ONLINE,
      serverOnline: SOCKET_CONNECTED,
    })
  );
  // let subscription;
  if (online) {
    // console.log('subscribe mutation');
    // subscription = subscribeMutation();
    subscribeMutationObservable();
  } else {
    // console.log('unsubscribe mutation');
    // console.log(subscription);
    // apolloClient.subscriptionClient.unsubscribeAll();
    // subscriptionClient.unsubscribeAll();
    unsubscribeMutationObservable();
  }
};

window.addEventListener('offline', () => {
  DEVICE_ONLINE = false;
  setOnlineStatus();
});

window.addEventListener('online', () => {
  DEVICE_ONLINE = true;
  setOnlineStatus();
});

subscriptionClient.on('connected', () => {
  // console.log('connected');
  if (!SOCKET_CONNECTED) {
    SOCKET_CONNECTED = true;
    // SOCKET_AUTH_ERROR = false;
    setOnlineStatus();
    // subscribeMutationObservable();
  }
});

subscriptionClient.on('closed', () => {
  // console.log('closed');
  if (SOCKET_CONNECTED) {
    SOCKET_CONNECTED = false;
    // SOCKET_AUTH_ERROR = false;
    setOnlineStatus();
    // subscribeMutationObservable();
  }
});

// subscriptionClient.on('error', () => {
//   console.log('ws error');
//   // SOCKET_CONNECTED = true;
//   // SOCKET_AUTH_ERROR = false;
//   // setOnlineStatus();
//   unsubscribeMutationObservable();
// });

// apolloClient.subscriptionClient.onDisconnected(() => {
//   SOCKET_CONNECTED = false;
//   setOnlineStatus();
// });

// apolloClient.subscriptionClient.onConnected(() => {
//   SOCKET_CONNECTED = true;
//   SOCKET_AUTH_ERROR = false;
//   setOnlineStatus();
// });

// apolloClient.subscriptionClient.onReconnected(() => {
//   SOCKET_CONNECTED = true;
//   SOCKET_AUTH_ERROR = false;
//   setOnlineStatus();
// });

// apolloClient.subscriptionClient.onError(() => {
//   SOCKET_AUTH_ERROR = false;
// });

const token = getIdToken();

const restoreAuth = async () => {
  return new Promise((resolve) => {
    try {
      const decoded = jwtDecode(token);
      store.dispatch(authLoadSuccess(token, decoded));
      resolve(true);
    } catch (err) {
      resolve(false);
      if (!history.location.pathname.includes('login')) {
        history.push('/logout');
      }
    }
  });
};

const restoreStore = async () => {
  return new Promise((resolve) => {
    const { cacheUpdatedAt } = store.getState().currentSettings;
    if (!cacheUpdatedAt) {
      store.dispatch(storeLoad());
      resolve();
    } else {
      Api.get('/api/cache', { cache_updated_at: cacheUpdatedAt })
        .then((resp) => {
          if (resp.data.stale) {
            store.dispatch(storeReload());
            resolve();
          } else {
            store.dispatch(storeLocalLoad());
            resolve();
          }
        })
        .catch(() => {
          // something dodgy going on with token, decodeable but backend says not signed
          clearIdToken();
          window.location.reload();
        });
    }
  });
};

const rootEl = document.getElementById('root');

const initApp = async (Container) => {
  const authed = await restoreAuth();
  if (authed) {
    await restoreStore();
  }
  // apolloClient.hydrateCache();
  return (
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        <ApolloProvider client={apolloClient}>
          <BrowserRouter>
            <Container />
          </BrowserRouter>
        </ApolloProvider>
      </PersistGate>
    </Provider>
  );
};

initApp(App).then((app) => render(app, rootEl));

// export { apolloClient };
