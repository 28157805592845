import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import L from 'leaflet';
import {
  reduxForm,
  Field,
  formValueSelector,
  change as changeFieldValue,
} from 'redux-form';

import { LinkContainer } from 'react-router-bootstrap';
import {
  Col,
  Form,
  FormGroup,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Panel,
} from 'react-bootstrap';

import _isNil from 'lodash/isNil';
import _merge from 'lodash/merge';
import _omit from 'lodash/omit';
import _omitBy from 'lodash/omitBy';
import InputField from '../components/form/input_field';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import fuelTypeListQuery from '../queries/fuel_type_list_query';
import fuelTankerQuery from '../queries/fuel_tanker_query';
import fuelTankerUpdateMutation from '../mutations/fuel_tanker_update_mutation';
import fuelTankerCreateMutation from '../mutations/fuel_tanker_create_mutation';

import { queriesReady, queryReady, typeInput } from '../lib/utils';
import Loader from '../components/loader';

const formSelector = formValueSelector('FuelTankerForm');

const MAPBOX_API_TOKEN =
  'pk.eyJ1IjoiZ29yZG9ua2luZzAyIiwiYSI6ImNpbnUxbWJ3NjExZ2x1aWtqaThraGM3dmsifQ.rKb2iQMCm5ZPhJGfsSg_mg';

const baseLayerTemplates = [
  {
    name: 'Mapbox - Satellite Streets',
    maptype: 'mapbox/satellite-streets-v11',
    url: `https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${MAPBOX_API_TOKEN}`,
    attribution:
      '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  },
  {
    name: 'Mapbox - Street',
    maptype: 'mapbox/streets-v11',
    url: `https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${MAPBOX_API_TOKEN}`,
    attribution:
      '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  },
  {
    name: 'Mapbox - Satellite',
    maptype: 'mapbox/satellite-v9',
    url: `https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${MAPBOX_API_TOKEN}`,
    attribution:
      '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  },
];

class FuelTankerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: !!this.props.params.id,
      zoom: 10,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.mapMounted = false;
    this.mapRef = null;
    this.setMapRef = (element) => {
      this.mapRef = element;
    };
  }

  componentDidUpdate(prevProps) {
    if (this.mapMounted) {
      if (
        this.props.formValues.latitude !== prevProps.formValues.latitude ||
        this.props.formValues.longitude !== prevProps.formValues.longitude ||
        this.props.formValues.service_radius !== prevProps.formValues.service_radius
      ) {
        const {
          name,
          latitude,
          longitude,
          service_radius: serviceRadius,
        } = this.props.formValues;
        const position = [latitude, longitude];
        this.map.setView(position, this.state.zoom);
        this.marker.setLatLng(position).setPopupContent(name);
        this.circle.setLatLng(position).setRadius(serviceRadius);
      }
    } else {
      // todo convert this map to the ref style used with other maps.
      if (this.mapRef && this.props.formValues.latitude && this.isLoaded(this.props)) {
        this.mapMounted = true;
        const {
          name,
          latitude,
          longitude,
          service_radius: serviceRadius,
        } = this.props.formValues;
        const position = [latitude, longitude];
        this.map = L.map('fuel-tanker-form-map', {
          zoom: this.state.zoom,
          center: position,
        });
        this.map.on('zoomend', this._onZoomEnd.bind(this));

        this.baseLayers = baseLayerTemplates.map((baseLayer) =>
          this.createBaseLayer(baseLayer)
        );
        const baseLayersMenu = this.baseLayers.reduce(
          (result, layer) => _merge({}, result, { [layer.name]: layer.element }),
          {}
        );
        L.control.layers(baseLayersMenu, {}, { position: 'topright' }).addTo(this.map);
        baseLayersMenu[this.baseLayers[0].name].addTo(this.map);
        this.marker = L.marker(position, { draggable: true })
          .addTo(this.map)
          .bindPopup(name)
          .openPopup();
        this.marker.on('dragend', this._onDragEnd.bind(this));
        this.circle = L.circle(position, serviceRadius).addTo(this.map);
      }
    }
  }

  componentWillUnmount() {
    this.map.remove();
    this.mapMounted = false;
    this.map = null;
    this.marker = null;
    this.circle = null;
    this.baseLayers = null;
  }

  createBaseLayer = (baseLayer) => {
    const { maptype, url, attribution } = baseLayer;
    const element = L.tileLayer(url, {
      attribution,
      id: maptype,
      accessToken: MAPBOX_API_TOKEN,
      tileSize: 512,
      zoomOffset: -1,
    });
    return Object.assign({}, baseLayer, { element });
  };

  _onZoomEnd(e) {
    this.setState({
      zoom: e.target.getZoom(),
    });
  }

  _onDragEnd(e) {
    const { lat: latitude, lng: longitude } = e.target.getLatLng();
    this.props.changeFieldValue(this.props.form, 'latitude', latitude);
    this.props.changeFieldValue(this.props.form, 'longitude', longitude);
  }

  onSubmit(data) {
    this.props.mutationSet(true);
    let mutation;
    let mutationMessage;
    const mutationData = { variables: { input: typeInput(data) } };
    if (this.state.updating) {
      mutation = this.props.fuelTankerUpdateMutation;
      mutationMessage = 'Fuel Tanker update';
      mutationData.variables.id = this.props.params.id;
    } else {
      mutation = this.props.fuelTankerCreateMutation;
      mutationMessage = 'Fuel Tanker create';
    }
    return mutation(mutationData)
      .then((res) => {
        this.props.mutationSuccess(mutationMessage);
        this.props.navigate('/fuel_tankers');
      })
      .catch((err) => this.props.mutationFailure(err, true));
  }

  _renderTitle() {
    if (this.state.updating) {
      return 'Edit Fuel Tanker';
    } else {
      return 'New Fuel Tanker';
    }
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(props.fuelTypeListQuery, [props.fuelTankerQuery, true]);
  }

  _renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
  }

  _renderData() {
    if (this.isLoaded()) {
      const { handleSubmit, pristine, submitting } = this.props;
      return (
        <Form horizontal onSubmit={handleSubmit(this.onSubmit)}>
          <h2>{this._renderTitle()}</h2>
          <fieldset>
            <legend>Fuel Tanker Details</legend>
            <Field
              type="text"
              name="name"
              inputWidth={3}
              component={InputField}
              componentClass="input"
            >
              Name
            </Field>
            <Field
              type="text"
              name="fuel_type_id"
              inputWidth={3}
              component={InputField}
              componentClass="select"
              selectOptions={this.props.fuelTypeListQuery.data.map((model) => ({
                id: model.id,
                name: model.name,
              }))}
            >
              Fuel Type
            </Field>
            <Field
              type="text"
              name="capacity_unit"
              inputWidth={3}
              component={InputField}
              componentClass="select"
              defaultSelectOption={false}
              selectOptions={this.props.currentSettingsFuelTankerCapacityUnits.map(
                (unit) => ({
                  id: unit,
                  name: unit,
                })
              )}
            >
              Capacity Unit
            </Field>
            <Field
              type="text"
              name="capacity_value"
              inputWidth={3}
              component={InputField}
              componentClass="input"
            >
              Capacity Value
            </Field>
            <Field
              type="text"
              name="capacity_start"
              inputWidth={3}
              component={InputField}
              componentClass="input"
            >
              Capacity Start
            </Field>
          </fieldset>
          <fieldset>
            <legend>Location Details</legend>
            <Col sm={9} smOffset={3}>
              <Panel>
                <Panel.Body>
                  <div
                    id="fuel-tanker-form-map"
                    ref={this.setMapRef}
                    style={{ height: '600px' }}
                  />
                </Panel.Body>
              </Panel>
            </Col>
            <Field
              type="text"
              blurOnly
              name="service_radius"
              inputWidth={3}
              component={InputField}
              componentClass="input"
              helpText="(meters) Locations within this radius will have this tanker available as a refilling option"
            >
              Service Radius
            </Field>
          </fieldset>
          <FormGroup>
            <Col sm={12}>
              <ButtonToolbar className="pull-right">
                <ButtonGroup>
                  <LinkContainer to="/fuel_tankers">
                    <Button type="reset" bsStyle="danger" disabled={submitting}>
                      Cancel
                    </Button>
                  </LinkContainer>
                  <Button
                    type="submit"
                    bsStyle="primary"
                    disabled={pristine || submitting}
                  >
                    {submitting ? (
                      <span className="glyphicon glyphicon-refresh glyphicon-spin" />
                    ) : (
                      ''
                    )}{' '}
                    {this.state.updating ? 'Update' : 'Create'}
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </Col>
          </FormGroup>
        </Form>
      );
    }
  }

  render() {
    return (
      <div>
        {this._renderOverlay()}
        {this._renderData()}
      </div>
    );
  }
}

function validate(values) {
  return {};
}

const attributeBlackList = ['__typename', 'service_location_ids'];

function pickInitialValues(fuelTankerQuery) {
  const baseValues = {
    name: '',
    capacity_unit: 'litres',
    capacity_value: 0,
    capacity_start: 0,
    latitude: -36.8484,
    longitude: 174.7622,
    service_radius: 10000,
  };
  if (queryReady(fuelTankerQuery)) {
    return Object.assign(
      baseValues,
      _omit(_omitBy(fuelTankerQuery.data, _isNil), attributeBlackList)
    );
  } else {
    return baseValues;
  }
}

function mapStateToProps(state, props) {
  // todo convert this to the initialized form style used in other shared create/update forms ir documents_form.
  const initialValues = pickInitialValues(props.fuelTankerQuery);
  return {
    initialValues,
    currentSettingsMutating: state.currentSettings.mutating,
    currentSettingsFuelTankerCapacityUnits:
      state.currentSettings.fuel_tanker_capacity_units,
    formValues: formSelector(state, 'name', 'latitude', 'longitude', 'service_radius'),
  };
}

export default compose(
  graphql(fuelTankerCreateMutation, {
    name: 'fuelTankerCreateMutation',
  }),
  graphql(fuelTankerUpdateMutation, {
    name: 'fuelTankerUpdateMutation',
  }),
  graphql(fuelTypeListQuery, {
    name: 'fuelTypeListQuery',
  }),
  graphql(fuelTankerQuery, {
    name: 'fuelTankerQuery',
    skip: (props) => !props.params.id,
    // todo convert this bank once newstyle form initialization id done.
    // options: (props) => { return { variables: { id: props.params.id }, fetchPolicy: 'cache-and-network' } }
    options: (props) => ({ variables: { id: props.params.id } }),
  }),
  connect(mapStateToProps, {
    changeFieldValue,
    mutationSuccess,
    mutationFailure,
    mutationSet,
  }),
  reduxForm({
    form: 'FuelTankerForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate,
  })
)(FuelTankerForm);
